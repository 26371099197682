import { useInitialEffect, setPageInfo } from "core/react-utils";
import { useParams } from "react-router";
import { PipelineStore } from "stores";
import Page from "pages/page";
import { Icons } from "core/constants";
import { observer } from "mobx-react-lite";
import AuditTrail from "components/audit-trail";
import { useState } from "react";
import Spinner from "components/spinner";




const PipelineStageAuditLogPage = observer(() => {
  const logItems = PipelineStore.pipelineStageAuditLogItems;
  const [loading, setLoading] = useState(true);
  const loadingMessage = "Loading Audit Trail. This can take up to a minute, please wait."

  useInitialEffect(async () => {
    await PipelineStore.loadPipelineStageAuditLog(parseInt(pipelineStageId));
    setLoading(false);
  });

  const { pipelineStageId } = useParams<{ pipelineStageId: string }>();
  const title = "Pipeline Stage Audit Trail";

  setPageInfo("pipeline-stage-audit-trail", title);
  return (
    <>
    <Page name="pipeline-auditTrail" title={title} icon={Icons.pipelines}>
      {loading && (
        <Spinner size={16} text={loadingMessage} />
      )}
      {!loading && logItems.length > 0 && (
        <AuditTrail logItems={logItems} />
      )}
      {!loading && logItems.length === 0 && (
        <p>No audit trail available for this item.</p>
      )}
      </Page>
    </>
  )
});

export default PipelineStageAuditLogPage;
