import { observer } from "mobx-react-lite";
import TestRunForm from "models/forms/test-run-form";
import { TriggerType } from "models/test-trigger";
import { Grid, Icon, Popup, Form } from "semantic-ui-react";
import ProjectSelectMulti from "./project-select-multi";
import TestPlanSelect from "./test-plan-select";
import TriggerTypeSelect from "./trigger-type-select";

interface Props {
  form: TestRunForm;
  scheduleOnly?: boolean;
  namePosition?: "top" | "bottom";
}

const TriggerTypeOptions = observer(({ form, scheduleOnly, namePosition }: Props) => {
  const nameField = (
    <Form.Input
      label="Trigger Name"
      value={form.name || ""}
      onChange={form.onChange("name")}
      error={form.getErrorText("name")}
    />
  );
  const showNameField = form.triggerType != TriggerType.now && !scheduleOnly;

  return (
    <Grid>
      {showNameField && namePosition == "top" && (
        <Grid.Row>
          <Grid.Column>{nameField}</Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row columns={2}>
        <Grid.Column>
          <TriggerTypeSelect
            label="Trigger Type"
            value={form.triggerType}
            onChange={form.onChange("triggerType")}
            noNowOption={scheduleOnly}
            testPlanOption={scheduleOnly}
          />
          {form.isTestPlanTrigger && (
            <TestPlanSelect
              label="Test Plan"
              placeholder="Select Test Plan"
              value={form.testPlanId}
              onChange={form.onChange("testPlanId")}
              error={form.getErrorText("testPlanId")}
            />
          )}
        </Grid.Column>
        <Grid.Column>
          {(form.triggerType == TriggerType.interval || form.triggerType == TriggerType.testPlanInterval) && (
            <Form.Input
              label={
                <label>
                  Cron Expression{" "}
                  <Popup
                    hoverable={true}
                    content={
                      <span>
                        <a href="https://en.wikipedia.org/wiki/Cron" target="_blank">
                          Click Here to read more about cron expressions
                        </a>
                      </span>
                    }
                    trigger={<Icon name="question circle" />}
                  />
                </label>
              }
              value={form.cron || ""}
              onChange={form.onChange("cron")}
              error={form.getErrorText("cron")}
            />
          )}
          {form.onDeploy && (
            <ProjectSelectMulti
              label="Deploy Project"
              allowOtherFramework
              value={form.deployedProjectIds}
              onChange={form.onChange("deployedProjectIds")}
              error={form.getErrorText("deployedProjectIds")}
              anyOption
            />
          )}
        </Grid.Column>
      </Grid.Row>
      {showNameField && namePosition != "top" && (
        <Grid.Row>
          <Grid.Column>{nameField}</Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
});

export default TriggerTypeOptions;
