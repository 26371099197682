import "./test-run-edit.scss";
import EnvironmentSelect from "components/form/environment-select";
import { observer } from "mobx-react-lite";
import { ProjectStore } from "stores";
import { Form, Card } from "semantic-ui-react";
import TestRunForm from "models/forms/test-run-form";
import ProjectSelect from "components/form/project-select";
import { useInitialEffect } from "core/react-utils";
import { RefObject } from "react";
import TestRunVersionSelect from "components/form/test-run-version-select";
import TestRunOptions from "components/form/test-run-options";
import TriggerTypeOptions from "components/form/trigger-type-options";
interface Props {
  form: TestRunForm;
  scheduleOnly?: boolean;
  projectElement?: RefObject<HTMLElement>;
}

const TestRunEdit = observer(({ form, projectElement, scheduleOnly }: Props) => {
  useInitialEffect(() => {
    ProjectStore.ensureLatestDeploysLoaded();
  });

  const project = ProjectStore.find(form.projectId);

  return (
    <Form className="test-run-edit">
      <Card>
        <Card.Content>
          <ProjectSelect
            label="Project With Tests"
            itemRef={projectElement}
            value={form.projectId}
            onlyWithTests={true}
            onChange={form.onChange("projectId")}
            error={form.getErrorText("projectId")}
            allowOtherFramework={true}
          />
          <EnvironmentSelect
            label="Environment To Run Tests Against"
            value={form.environmentId}
            onChange={form.onChange("environmentId")}
            error={form.getErrorText("environmentId")}
          />
          <TriggerTypeOptions form={form} scheduleOnly={scheduleOnly} />
          <TestRunVersionSelect project={project} form={form} />
        </Card.Content>
      </Card>
      <Card style={{ width: "100%" }}>
        <Card.Content>
          <TestRunOptions project={project} form={form} useAdvancedToggle={true} />
        </Card.Content>
      </Card>
    </Form>
  );
});

export default TestRunEdit;
