import Header from "components/header";
import LeftNav from "components/left-nav";
import * as Storage from "core/storage";
import { observer } from "mobx-react-lite";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AppStore, ModalStore } from "stores";
import Modals from "../components/modals";
import AcceptPage from "./accept";
import AlertEditPage from "./alert-edit";
import AlertsPage from "./alerts";
import AnalyticsPage from "./analytics";
import AuthReturnPage from "./auth-return";
import CreateAccountPage from "./create-account";
import DashboardPage from "./dashboard";
import EmployeePage from "./employee";
import EmployeeRunnerLogsPage from "./employee/runner-logs";
import EmployeeTestListResultPage from "./employee/test-list-result";
import EmployeeTestResultPage from "./employee/test-result";
import EmployeeTestRunPage from "./employee/test-run";
import EnvironmentEditPage from "./environment-edit";
import EnvironmentsPage from "./environments";
import ForgotPasswordPage from "./forgot-password";
import GitHubPage from "./git-hub";
import HomePage from "./home";
import TellAFriendPage from "./tell-a-friend";
import LoginPage from "./login";
import NoAccountPage from "./no-account";
import PasswordResetPage from "./password-reset";
import PipelineStageEditPage from "./pipeline-stage-edit";
import ProfilePage from "./profile";
import ProjectPage from "./project";
import ProjectNewPage from "./project-new";
import ProjectsPage from "./projects";
import SettingsPage from "./settings";
import TestPlanPage from "./test-plan";
import TestPlanNewPage from "./test-plan-new";
import TestPlansPage from "./test-plans";
import TestResultPage from "./test-result";
import TestRunResultsPage from "./test-run-results";
import TestRunsPage from "./test-runs";
import TestSuiteEditPage from "./test-suite-edit";
import BuildEditPage from './build-edit';
import TriggerEdit from "./trigger-edit";
import TriggersPage from "./triggers";
import VerifyEmailPage from "./verify-email";
import WorkflowPage from "./workflow";
import WorkflowNewPage from "./workflow-new";
import WorkflowsPage from "./workflows";
import InsightsPage from "./insights";
import { useEffect } from "react";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { GROWTHBOOK_CLIENT_KEY } from "core/constants";
import RunnerPoolEditPage from "./runner-pool-edit";
import ReleaseReadinessPage from "./release";
import PipelineStageAuditLogPage from "./pipeline-stage-audit-log";
import EnvironmentAuditLogPage from "./environment-audit-log";
import TestDistributionAnalysisRuleEditPage from "./test-distribution-rule-edit";
import GetStartedPage from "./get-started";
import ExpressSetupPage from "./express-setup";

function preLogin(gb: GrowthBook) {
  return (
    <GrowthBookProvider growthbook={gb}>
      <BrowserRouter>
        <Header />
        <main>
          <Switch>
            <Route path="/auth-return" exact={true} component={AuthReturnPage} />
            <Route path="/create-account" exact={true} component={CreateAccountPage} />
            <Route path="/verify-email" exact={true} component={VerifyEmailPage} />
            <Route path="/forgot-password" exact={true} component={ForgotPasswordPage} />
            <Route path="/password-reset" exact={true} component={PasswordResetPage} />
            <Route path="/accept" exact={true} component={AcceptPage} />
            <Route component={LoginPage} />
          </Switch>
        </main>
      </BrowserRouter>
    </GrowthBookProvider>
  );
}

function setup(gb: GrowthBook) {
  return (
    <GrowthBookProvider growthbook={gb}>
      <BrowserRouter>
        <Header />
        <main>
          <Switch>
            <Route path="/auth-return" exact={true} component={AuthReturnPage} />
            <Route path="/alerts" exact={true} component={AuthReturnPage} />
            <Route path="/github" component={GitHubPage} exact={true} />
            <Route component={ExpressSetupPage} />
          </Switch>
          <Modals />
        </main>
      </BrowserRouter>
    </GrowthBookProvider>

  );
}

function noAccount(gb: GrowthBook) {
  return (
    <GrowthBookProvider growthbook={gb}>
      <BrowserRouter>
        <Header />
        <main>
          <Switch>
            <Route path="/auth-return" exact={true} component={AuthReturnPage} />
            <Route component={NoAccountPage} />
          </Switch>
          <Modals />
        </main>
      </BrowserRouter>
    </GrowthBookProvider>
  );
}

function postLogin(gb: GrowthBook) {
  if (AppStore.user.roles.length == 0) return noAccount(gb);

  if (!ModalStore.updatePassword.showing && !AppStore.accountSetup) return setup(gb);

  const params = new URLSearchParams(window.location.search);

  if (params.get("slackMemberId")) {
    Storage.setSlackMemberId(params.get("slackMemberId"));
    AppStore.connectSlackUser();
  }

  return (
    <GrowthBookProvider growthbook={gb}>
      <BrowserRouter>
        <Header />

        <div id="outer-nav-container" className={AppStore.isLeftNavCollapsed ? "nav-collapsed" : "nav-full"}>
          <div className="left-nav-cell">
            <LeftNav />
          </div>
          <div className="right-content-cell">
            <main>
              <Switch>
                <Route path="/github" component={GitHubPage} exact={true} />
                <Route path="/employee" exact={true} component={EmployeePage} />
                <Route path="/employee/:tab" exact={true} component={EmployeePage} />
                <Route path="/employee/test-runs/:testRunId" exact={true} component={EmployeeTestRunPage} />
                <Route
                  path="/employee/test-runs/:testRunId/tests/:testId"
                  exact={true}
                  component={EmployeeTestResultPage}
                />
                <Route
                  path="/employee/test-runs/:testRunId/runners/:runnerId"
                  exact={true}
                  component={EmployeeRunnerLogsPage}
                />
                <Route
                  path="/employee/test-runs/:testRunId/test-list-result"
                  exact={true}
                  component={EmployeeTestListResultPage}
                />
                <Route path="/employee/test-runs/:testRunId/logs" exact={true} component={EmployeeRunnerLogsPage} />
                <Route path="/employee/agents/:agentId/logs" exact={true} component={EmployeeRunnerLogsPage} />
                <Route path="/:organization/dashboard" component={DashboardPage} exact={true} />
                <Route path="/:organization/get-started" component={GetStartedPage} exact={true} />

                <Route path="/:organization/test-plans" component={TestPlansPage} exact={true} />
                <Route path="/:organization/test-plans/new" component={TestPlanNewPage} exact={true} />
                <Route path="/:organization/test-plans/:testPlanId" component={TestPlanPage} exact={true} />
                <Route path="/:organization/test-plans/:testPlanId/:tab" component={TestPlanPage} exact={true} />

                <Route path="/:organization/test-runs" component={TestRunsPage} exact={true} />
                <Route path="/:organization/test-runs/:testRunId" component={TestRunResultsPage} exact={true} />
                <Route path="/:organization/test-runs/:testRunId/:tab" component={TestRunResultsPage} exact={true} />
                <Route path="/:organization/test-runs/:testRunId/tests/:testId" component={TestResultPage} exact={true} />
                <Route path="/:organization/test-runs/:testRunId/tests/:testId/:tab" component={TestResultPage} exact={true} />
                <Route path="/:organization/test-run-results/:testRunId" component={TestRunResultsPage} exact={true} />
                <Route path="/:organization/test-run-results/:testRunId/tests/:testId" component={TestResultPage} />

                <Route path="/:organization/profile" component={ProfilePage} exact={true} />

                <Route path="/:organization/projects" component={ProjectsPage} exact={true} />

                <Route
                  path="/:organization/projects/:projectKey/test-suites/new"
                  component={TestSuiteEditPage}
                  exact={true}
                />

                <Route
                  path="/:organization/projects/:projectKey/test-suites/:testSuiteId"
                  component={TestSuiteEditPage}
                  exact={true}
                />

                <Route
                  path="/:organization/projects/:projectKey/builds/new"
                  component={BuildEditPage}
                  exact={true}
                />

                <Route
                  path="/:organization/projects/:projectKey/builds/:buildId"
                  component={BuildEditPage}
                  exact={true}
                />

                <Route path="/:organization/projects/new" component={ProjectNewPage} exact={true} />

                <Route path="/:organization/projects/:projectKey/:tab" component={ProjectPage} exact={true} />

                <Route path="/:organization/projects/:projectKey" component={ProjectPage} exact={true} />

                <Route path="/:organization/workflows" component={WorkflowsPage} exact={true} />
                <Route path="/:organization/workflows/new" component={WorkflowNewPage} exact={true} />
                <Route path="/:organization/workflows/:workflowId" component={WorkflowPage} exact={true} />
                <Route path="/:organization/workflows/:workflowId/:tab" component={WorkflowPage} exact={true} />

                <Route path="/:organization/triggers" component={TriggersPage} exact={true} />

                <Route path="/:organization/triggers/new" component={TriggerEdit} exact={true} />

                <Route path="/:organization/dashboard/insights" component={InsightsPage} exact={true} />

                <Route path="/:organization/dashboard/analytics" component={AnalyticsPage} exact={true} />
                <Route path="/:organization/dashboard/analytics/:tab" component={AnalyticsPage} exact={true} />

                <Route path="/:organization/dashboard/release-readiness" component={ReleaseReadinessPage}
                       exact={true} />
                <Route path="/:organization/dashboard/release-readiness/:tab" component={ReleaseReadinessPage}
                       exact={true} />

                <Route path="/:organization/triggers/:triggerId" component={TriggerEdit} exact={true} />

                <Route path="/:organization/environments" component={EnvironmentsPage} exact={true} />

                <Route path="/:organization/environments/pipelines" component={EnvironmentsPage} exact={true} />

                <Route path="/:organization/environments/pipelines/new" component={PipelineStageEditPage}
                       exact={true} />

                <Route
                  path="/:organization/environments/pipelines/:pipelineStageId"
                  component={PipelineStageEditPage}
                  exact={true}
                />

<Route
                  path="/:organization/environments/pipelines/:pipelineStageId/audit-log"
                  component={PipelineStageAuditLogPage}
                  exact={true}
                />

                <Route path="/:organization/environments/runner-pools" component={EnvironmentsPage} exact={true} />

                <Route path="/:organization/environments/runner-pools/new" component={RunnerPoolEditPage}
                       exact={true} />

                <Route
                  path="/:organization/environments/runner-pools/:runnerPoolId"
                  component={RunnerPoolEditPage}
                  exact={true}
                />

                <Route path="/:organization/environments/new" component={EnvironmentEditPage} exact={true} />

                <Route path="/:organization/environments/:environmentId" component={EnvironmentEditPage} exact={true} />
                <Route path="/:organization/environments/:environmentId/audit-log" component={EnvironmentAuditLogPage} exact={true} />

                <Route path="/:organization/settings" component={SettingsPage} exact={true} />
                <Route path="/:organization/settings/:tab" component={SettingsPage} />

                <Route path="/:organization/test-distribution-analysis-rule/:ruleId" component={TestDistributionAnalysisRuleEditPage} />

                <Route path="/:organization/alerts" component={AlertsPage} exact={true} />
                <Route path="/:organization/alerts/new" component={AlertEditPage} exact={true} />
                <Route path="/:organization/alerts/:alertId" component={AlertEditPage} />
                <Route path="/auth-return" exact={true} component={AuthReturnPage} />
                <Route path="/alerts" exact={true} component={AuthReturnPage} />
                <Route path="/password-reset" exact={true} component={PasswordResetPage} />
                <Route path="/:organization/tell-a-friend" exact={true} component={TellAFriendPage} />
                <Route component={HomePage} />
              </Switch>
              <Modals />
            </main>
          </div>
        </div>
      </BrowserRouter>
    </GrowthBookProvider>
  );
}

const gb = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: GROWTHBOOK_CLIENT_KEY,
});

const loadFeatureFlags = async () => {
  await gb.loadFeatures({ timeout: 1000 });
};
loadFeatureFlags();

const AppRoot = observer(() => {

  useEffect(() => {
    gb.setAttributes({
      id: "account-" + AppStore.selectedAccountName,
    });
  }, []);
  return (AppStore.isLoggedIn ? postLogin(gb) : preLogin(gb));
});

export default AppRoot;
