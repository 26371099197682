import { useState } from "react";
import { RouteComponentProps } from "react-router";
import "./login.scss";
import { AuthReason } from "models/settings";
import { loginAuthorize } from "core/auth0";
import * as Storage from "core/storage";
import { useInitialEffect } from "core/react-utils";
import { AppStore } from "stores";
import { Button, Divider, Form, Grid, Icon, Message, Segment } from "semantic-ui-react";
import Page from "pages/page";
import LoginForm from "models/forms/login-form";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { getUrlParam } from "../../core/utils";

interface Props extends RouteComponentProps<any> {}

const loginForm = new LoginForm();

const LoginPage = observer((props: Props) => {
  const [loginFailed, setLoginFailed] = useState(false);

  useInitialEffect(() => {
    loginForm.clearFormData();

    const slackMemberId = getUrlParam("slackMemberId");
    Storage.setSlackMemberId(slackMemberId);

    if (window.location.pathname != "/login" && window.location.pathname != "/") {
      let redirect = window.location.pathname + window.location.search;
      Storage.setAuthInfo(redirect, AuthReason.Login);
    } else {
      Storage.setAuthInfo(null, AuthReason.Login);
    }

    loginForm.slackMemberId = slackMemberId;
  });

  const login = async () => {
    try {
      await AppStore.login(loginForm);

      const { url } = Storage.popAuthInfo();

      if (!url) {
        props.history.replace("/" + AppStore.selectedAccountName + "/test-runs");
      } else {
        props.history.replace(url);
      }
    } catch (error) {
      if (error == "login-failed") {
        setLoginFailed(true);
      }
    }
  };

  return (
    <Page name="login" title="Login" titleSize="huge">
      <Segment placeholder>
        <Grid columns={2} relaxed="very" stackable>
          <Grid.Column verticalAlign="middle">
            <div className="buttons">
              <div className="github" onClick={() => loginAuthorize("github")}>
                <Icon name="github" />
                <div>Log In With GitHub</div>
              </div>
              <br />
              <div className="bitbucket" onClick={() => loginAuthorize("bitbucket")}>
                <Icon name="bitbucket" />
                <div>Log In With BitBucket</div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column verticalAlign="middle">
            <Form id="login-form" error={loginFailed}>
              <Form.Input
                error={loginForm.getErrorText("email")}
                placeholder="Email"
                icon="envelope"
                iconPosition="left"
                type="text"
                value={loginForm.email}
                onChange={loginForm.onChange("email")}
                autoComplete="username"
              />
              <Form.Input
                placeholder="Password"
                icon="key"
                iconPosition="left"
                type="password"
                error={loginForm.getErrorText("password")}
                value={loginForm.password}
                onChange={loginForm.onChange("password")}
                autoComplete="current-password"
              />
              <Button
                loading={loginForm.isSubmitting}
                type="submit"
                color="green"
                size="large"
                onClick={login}
              >
                Log In With Email
              </Button>
              <Message error content="Invalid email or password" />
            </Form>
            <div className="forgot-password">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          </Grid.Column>
        </Grid>
        <Divider vertical content="or" />
      </Segment>
    </Page>
  );
});

export default LoginPage;
