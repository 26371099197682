import Form from "./form";
import { observable, action } from "mobx";
import { notEmpty } from "core/validation";
import { toLowerWithDashes } from "core/utils";
import { TestPlanStore } from "stores";
import { some } from "lodash";



export default class TestPlanForm extends Form {
  @observable id: number;
  @observable name: string;
  @observable goal: string;
  @observable key: string;

  validators = {
    name: notEmpty,
    key: [
      notEmpty,
      {
        validator: (key: string) =>
          !some(TestPlanStore.items, (p) => p.id != this.id && p.key == key),
        message: "This key is already in use",
      },
    ],
  };

  @action
  updateNameAndKey(value: string) {
    this.updateField("name", value);
    this.updateField("key", toLowerWithDashes(value));
  }

  @action
  setDefaults() {
    this.name = "";
    this.goal = "";
  }
}
