import Form from "./form";
import { action, observable } from "mobx";

export default class NotificationForm extends Form {
  @observable notifyByEmail: boolean;
  @observable notifyBySlack: boolean;

  @action
  setDefaults() {
    this.notifyByEmail = false;
    this.notifyBySlack = false;
  }
}
