import "./test-filters.scss";
import { Icon, Popup } from "semantic-ui-react";

interface Props {
  includeTags: string[];
  excludedTags: string[];
  testFilters: string[];
  fade?: boolean;
}

export default function TestFilters({ includeTags, excludedTags, testFilters, fade }: Props) {
  return (
    <div className={`test-filters${fade ? " test-filters-fade" : ""}`}>
      {includeTags && includeTags.length > 0 && (
        <div className="item">
          <ul>
            {includeTags.map(i => (<Popup key={i} content={i} position={"left center"}
                                          trigger={<li><Icon color={"green"} name={"tag"} />{i}</li>} />))}
          </ul>
        </div>
      )}
      {excludedTags && excludedTags.length > 0 && (
        <div className="item">
          <ul>
            {excludedTags.map(e => (<Popup key={e} content={e} position={"left center"}
                                           trigger={<li><Icon color={"red"} name={"tag"} />{e}</li>} />))}
          </ul>
        </div>
      )}
      {testFilters && testFilters.length > 0 && (
        <div className="item">
          <ul>
            {testFilters.map(t => (<Popup key={t} content={t.replace("Regex: ", "")} position={"left center"}
                                          trigger={<li><Icon name={"filter"} />{t.replace("Regex: ", "")}</li>} />))}
          </ul>
        </div>
      )}
    </div>
  );
}
