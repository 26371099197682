import { ItemStoreList } from "./item-store";
import API, { Response } from "core/api";
import { action, observable, runInAction, computed } from "mobx";
import TestTrigger from "models/test-trigger";
import TestRunForm from "models/forms/test-run-form";
import { chain } from "lodash";
import { TestRunStoreClass } from "./test-run-store";

export class TriggerStoreClass extends ItemStoreList<TestTrigger> {
  private readonly TestRunStore: TestRunStoreClass;
  @observable loaded = false;

  constructor(TestRunStore: TestRunStoreClass) {
    super();
    this.TestRunStore = TestRunStore;
  }

  async load(id: number) {
    const { data } = await API.get(`/triggers/${id}`, TestTrigger);
    return this.add(data);
  }

  @action
  async doLoadAll() {
    const { data } = await API.getList("/triggers", TestTrigger);
    return runInAction(() => {
      this.items = data;
      this.loaded = true;
      return data;
    });
  }

  @computed
  get active() {
    return chain(this.items)
      .filter((p) => !p.archived)
      .orderBy(["lastRunAt", "id"], ["desc", "asc"])
      .value();
  }

  @action
  async delete(id: number) {
    this.find(id).archived = true;
    await API.delete(`/triggers/${id}`);
  }

  @action
  async save(form: TestRunForm) {
    if (form.includeTags && form.includeTags[0] == "all") {
      form.includeTags = [];
    }

    return (form as TestRunForm).submit(async (data) => {
      let result: Response<TestTrigger>;
      if (data.id) {
        result = await API.put(`/triggers/${data.id}`, data, TestTrigger);
      } else {
        result = await API.post("/triggers", data, TestTrigger);
      }

      return this.update(result.data);
    });
  }

  @action
  async runTrigger(scheduleId: number): Promise<number> {
    const id = ((await API.post(`triggers/${scheduleId}/run-now`, {})) as Response<number>).data;
    this.TestRunStore.loadTestRuns(false);
    return id;
  }

  @action
  async runTriggerForEnvironment(scheduleId: number, environmentId: number): Promise<number> {
    const id = (
      (await API.post(
        `triggers/${scheduleId}/run-now?environmentId=${environmentId}`,
        {},
      )) as Response<number>
    ).data;
    this.TestRunStore.loadTestRuns(false);
    return id;
  }

  @action
  async toggleEnabled(sheduele: TestTrigger) {
    sheduele.enabled = !sheduele.enabled;
    const result = await API.post(`/triggers/${sheduele.id}/toggle-enabled`, {}, TestTrigger);
    return this.update(result.data);
  }
}
