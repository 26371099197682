import { removeSpaces, spacesInCamelCase } from "core/utils";
import { keys } from "lodash";
import { TimeframeFrequency } from "models/analytics";
import { ReactNode } from "react";
import { JsxElement } from "typescript";
import Select from "./select";

interface Props {
  value: TimeframeFrequency;
  onChange: (value: TimeframeFrequency) => any;
  error?: boolean | string;
  label?: string | JsxElement | ReactNode;
  hideHourly?: boolean;
}

const TimeframeFrequencySelect = ({ onChange, value, error, label, hideHourly }: Props) => {
  let values = keys(TimeframeFrequency);

  if (hideHourly) {
    values = values.filter((v) => v != TimeframeFrequency.Hourly);
  }

  return (
    <Select
      className="timeframe-frequency-select"
      onChange={(v) => onChange(TimeframeFrequency[removeSpaces(v)])}
      value={spacesInCamelCase(value)}
      options={values.map(spacesInCamelCase)}
      error={error}
      label={label}
    />
  );
};

export default TimeframeFrequencySelect;
