import { VariablesInfoIcon } from "components/variables-info-icon";
import { Icons } from "core/constants";
import { observer } from "mobx-react-lite";
import { WithEnvironmentVarsForm } from "models/forms/environment-var-form";
import { Button, Form, Icon, Popup } from "semantic-ui-react";
import { ModalStore } from "stores";
import "./environment-variables.scss";

interface Props {
  form: WithEnvironmentVarsForm;
}

const EnvironmentVariables = observer(({ form }: Props) => (
  <section className="environment-variables">
    <h4>
      Variables <VariablesInfoIcon />
    </h4>
    <Button size="tiny" color="green" onClick={async () => {
      await form.addVariable();

      const variableListContainer = document.getElementById("environment-variable-table-container");
      variableListContainer.scrollIntoView({ behavior: "smooth", block: "end" });
      variableListContainer.scrollTop = variableListContainer.scrollHeight;
    }}>
      <Icon name="plus" />
      Add Variable
    </Button>
    {form.variables.filter(v => !v.file).length > 0 && (
      <div id={"environment-variable-table-container"} className={"environment-table-container"}>
        <table className="variables">
          <thead>
          <tr>
            <th className="name">Name</th>
            <th className="value">Value</th>
            <th className="encrypted">Encrypted?</th>
            <th className="action">Delete?</th>
          </tr>
          </thead>
          <tbody>
          {form.variables.map((v, i) => !v.file && (
            <tr className="variable" key={i}>
              <td className="name">
                <Form.Input type="text" placeholder={"Variable Name"} value={v.key} onChange={v.onChange("key")} read
                            disabled={!v.newVariable} />
              </td>
              <td className="value">
                <Form.Input
                  type="text"
                  placeholder={"Variable Value"}
                  value={v.encrypted && v.value == "" ? "****" : v.value}
                  onChange={v.onChange("value")}
                />
              </td>
              <td className="encrypted">
                <Form.Checkbox
                  type="checkbox"
                  checked={v.encrypted}
                  onClick={() => v.updateField("encrypted", !v.encrypted)}
                />
              </td>
              <td className="actions">
                <Icon name={Icons.delete} onClick={() => form.deleteVariable(i)} />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    )}
    <h4>
      Files <Popup
      hoverable={true}
      content={
        <span>
          An environment file will be put on the test runner in the working directory of the test runner
        </span>
      }
      trigger={<Icon name="question circle" />}
    />
    </h4>
    <Button size="tiny" color="green" onClick={() => ModalStore.environmentFileNew.show(null, form)}>
      <Icon name="plus" />
      Add File
    </Button>
    {form.variables.filter(v => v.file).length > 0 && (
      <div id={"environment-file-table-container"} className={"environment-table-container"}>
        <table className="variables">
          <thead>
          <tr>
            <th className="name">Name</th>
            <th className="action">Delete?</th>
          </tr>
          </thead>
          <tbody>
          {form.variables.map((v, i) => (
            v.file && (<tr className={("variable" + (v.newVariable && v.value == "" ? " empty-value-error" : ""))} key={i}>
              <td className="name">
                {v.key}
              </td>
              <td className="actions">
                <Icon name={Icons.delete} onClick={() => form.deleteVariable(i)} />
              </td>
            </tr>)
          ))}
          </tbody>
        </table>
      </div>
    )}
  </section>
));

export default EnvironmentVariables;
