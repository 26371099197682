import { observer } from "mobx-react-lite";
import { TestRun } from "models/test-run";
import { Card } from "semantic-ui-react";
import { SettingsStore } from "stores";
import EnvironmentCard from "./environment-card";
import "./pipeline-stage-card.scss";
import { chain } from "lodash";
import IconButton from "components/icon-button";
import { useHistory } from "react-router";

interface Props {
  pipelineStageName: string;
  pipelineStageId: number;
  testRuns: Map<number, TestRun[]>;
  showCreatePipelineInfo?: boolean;
}

const PipelineStageCard = observer(
  ({ pipelineStageName, pipelineStageId, testRuns, showCreatePipelineInfo }: Props) => {
    const history = useHistory();

    const header = showCreatePipelineInfo ? (
      <Card className="pipeline-header with-info">
        <Card.Content>
          <Card.Header textAlign="center">
            {pipelineStageName}
            <IconButton icon="plus circle" onClick={() => history.push("environments/pipelines")} />
          </Card.Header>
        </Card.Content>
        <Card.Content className="info-message">
          Add stages to see tests across environments
        </Card.Content>
      </Card>
    ) : (
      <Card className="pipeline-header">
        <Card.Content>
          <Card.Header textAlign="center">{pipelineStageName}</Card.Header>
        </Card.Content>
      </Card>
    );

    return (
      <Card className="pipeline-stage-card">
        {header}
        {chain(SettingsStore.activeEnvironments)
          .filter((e) => e.pipelineStageId == pipelineStageId)
          .sortBy((e) => (testRuns[e.id] || []).length == 0)
          .map((e) => (
            <EnvironmentCard key={e.id} environment={e} testRuns={testRuns[e.id] || []} />
          ))
          .value()}
      </Card>
    );
  },
);

export default PipelineStageCard;
