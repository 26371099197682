import { observable, action, reaction } from "mobx";
import { notEmpty, valueSelected } from "core/validation";
import { RunnerPool } from "models/settings";
import Form from "./form";
import { AppStore } from "stores";


export default class RunnerPoolForm extends Form {
  @observable id: number;
  @observable name: string;
  @observable maxRunners: number;
  @observable runnerType: string; 
  @observable maxRunnersAllowed: number;


  constructor(runnerPool?: RunnerPool) {
    super();
    if (runnerPool) {
      this.populateFromRunnerPool(runnerPool);
    }
    this.resetMaxRunnersAllowed();
    reaction(
      () => this.runnerType,
      () => {
        this.resetMaxRunnersAllowed();
      });
  }

  validators = { name: notEmpty, runnerType: valueSelected, maxRunners: notEmpty };

  async loadAccount() {
    await AppStore.loadAccount()
  };

  @action
  setDefaults() {
    super.setDefaults();
    this.name = "";
    this.resetMaxRunnersAllowed();
  }

  @action
  populateFromRunnerPool(runnerPool: RunnerPool) {
    this.populate(runnerPool);
  }

  resetMaxRunnersAllowed() {
    if (AppStore.account) {
      this.maxRunnersAllowed = AppStore.account.maxRunnersByType(this.runnerType);
    } else {
      this.maxRunnersAllowed = 10;
    }
  }
}
