import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { ReviewStore, UserStore } from "../../stores";
import { ReviewType } from "../../models/review-data";
import { NotificationManager } from "react-notifications";
import { Response } from "core/api";
import { userNameComparator } from "../../core/utils";

interface Props {
  reviewType: ReviewType;
  testRunId: number;
  testRunReviewerUserId: number;
  testRunTestId: number;
  testRunTestReviewerUserId: number;
}

const AssignReviewUserSelect = ({ reviewType, testRunId, testRunReviewerUserId, testRunTestId, testRunTestReviewerUserId }: Props) => {
  const [value, setValue] = useState<string>("");
  const users = UserStore.usersForDropdown;
  const options = users.sort((a, b) => userNameComparator(a, b)).map(user => {
    return <MenuItem key={user.id} value={user.id}>{user.fullName}</MenuItem>;
  });

  useEffect(() => {
    let currentUserId: number | null;
    switch (reviewType) {
      case ReviewType.TEST_RUN:
        currentUserId = testRunReviewerUserId;
        break;
      case ReviewType.TEST_RUN_TEST:
        currentUserId = testRunTestReviewerUserId;
        break;
    }

    const value = users.find(user => user.id === currentUserId)?.id;
    setValue(!value ? "" : value.toString());
  }, [users]);

  async function handleChange(e) {
    let assigneeUserId = e.target.value;

    if (reviewType === ReviewType.TEST_RUN) {
      if (assigneeUserId === testRunReviewerUserId)
        return;

      testRunReviewerUserId = assigneeUserId;
    } else {
      if (assigneeUserId === testRunTestReviewerUserId)
        return;

      testRunTestReviewerUserId = assigneeUserId;
    }

    setValue(assigneeUserId.toString());

    const response: void | Response<any> = await ReviewStore.updateAssignedReviewer({
      reviewType: reviewType,
      testRunId: testRunId,
      testRunTestId: testRunTestId,
      assigneeUserId: assigneeUserId,
    })
      .catch(() => NotificationManager.error("Please try again", "Something went wrong"));

    let message;
    if (reviewType === ReviewType.TEST_RUN)
      message = "Test run successfully assigned for review";
    else
      message = "Test run test successfully assigned for review";

    if (response && response.status === 200)
      NotificationManager.success(message, "Success", 2000);
  }

  return (
    <>
      <Select style={{ background: "#FFF", textAlign: "left", width: "200px" }}
              displayEmpty
              value={value}
              onChange={handleChange}>
        <MenuItem key={""} value={""}><em>Assign Reviewer (None)</em></MenuItem>
        {options}
      </Select>
    </>
  );
};

export default AssignReviewUserSelect;
