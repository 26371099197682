import { EntityWithCreatedAt } from "./entity";

export enum FeatureName {
  SCREEN_RECORDING = "SCREEN_RECORDING",
  NUMBER_OF_RUNNERS = "NUMBER_OF_RUNNERS",
  WINDOWS_RUNNERS = "WINDOWS_RUNNERS",
  MAX_TESTING_MINUTES = "MAX_TESTING_MINUTES",
  MONTHLY_AI_LIMIT = "MONTHLY_AI_LIMIT"
}

export class Feature extends EntityWithCreatedAt {
  name: FeatureName;
}
