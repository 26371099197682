import Spinner from "components/spinner";
import { useInitialEffect } from "core/react-utils";
import { findIndex } from "lodash";
import { observer } from "mobx-react-lite";
import Page from "pages/page";
import { Redirect, useHistory, useParams } from "react-router";
import { Tab, TabProps } from "semantic-ui-react";
import { ProjectStore, SettingsStore, TestPlanStore, TestSuiteStore } from "stores";
import { organizationUrl, relativeLink } from "core/utils";
import StagesTab from "./stages-tab";
import SettingsTab from "./settings-tab";
import TestPlanResults from "components/test-plan-results";

const ProjectPage = observer(() => {
  const { testPlanId, tab } = useParams<{ testPlanId: string; tab: string }>();
  const history = useHistory();
  const testPlandIdInt = testPlanId ? parseInt(testPlanId) : 0;

  useInitialEffect(() => {
    TestPlanStore.selectTestPlan(testPlandIdInt);
    TestPlanStore.load(testPlandIdInt);
    SettingsStore.ensureEnvironments();
    TestSuiteStore.ensureLoaded();
    ProjectStore.ensureLoaded();
  });

  const testPlan = TestPlanStore.find(testPlandIdInt);

  if (!testPlan)
    return (
      <Page name="test-plan" title="Test Plan">
        <Spinner text="Loading Test Plan" />
      </Page>
    );

  if (!tab) {
    return (
    <Redirect push to={`${testPlan.id}/stages`} />
    )
  }

  const tabPanes = [
    {
      menuItem: { key: "home", icon: "home", content: "" },
      render: () => <div></div>,
    },
    {
      menuItem: { key: "stages", icon: null, content: "Stages" },
      render: () => <StagesTab key={1} testPlan={testPlan} />,
    },
    {
      menuItem: { key: "settings", icon: null, content: "Settings" },
      render: () => <SettingsTab key={2} testPlan={testPlan} />,
    },
    {
      menuItem: { key: "runs", icon: null, content: "Runs" },
      render: () => <TestPlanResults key={3} testPlanId={testPlan.id} />,
    },
  ];

  const tabIndex = findIndex(tabPanes, (i) => i.menuItem.key == tab);

  const navigate = (data: TabProps) => {
    const name = tabPanes[data.activeIndex].menuItem.key;
    history.push(name == "home" ? organizationUrl("test-plans") : relativeLink(name));
  };

  return (
    <Page name="test-plan" title={testPlan.name}>
      <Tab
        onTabChange={(_, data) => navigate(data)}
        activeIndex={tabIndex}
        menu={{ secondary: true, pointing: true }}
        panes={tabPanes}
      />
    </Page>
  );
});

export default ProjectPage;
