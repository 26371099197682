import { setPageInfo, useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import { Icon, Tab } from "semantic-ui-react";
import { TestResult } from "../../models/test-run";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./screenshots-tab.scss";
import { useState } from "react";
import { useHistory } from "react-router";

interface Props {
  testResult: TestResult;
}

const ScreenshotsTab = observer(({ testResult }: Props) => {
  setPageInfo("testResult", "Screenshots");
  const [currentScreenshotIndex, setCurrentScreenshotIndex] = useState(0);
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  useInitialEffect(() => {
    const index = params.get("screenshotIndex");
    if (index)
      setCurrentScreenshotIndex(Number(index));
  });

  if (testResult.screenShots.length === 0) {
    return (<Tab.Pane>No screenshots were captured for this test. Click <a
      style={{ textDecoration: "underline" }}
      href={"https://docs.testery.io/get-to-know-testery/screenshots"} target={"_blank"}>here</a> to learn how to enable
      screenshots.</Tab.Pane>);
  }

  function updateScreenshotIndex(index: number) {
    setCurrentScreenshotIndex(index);
    params.delete("screenshotIndex");
    params.append("screenshotIndex", String(index));
    history.push({ search: params.toString() });
  }

  return (
    <Tab.Pane style={{ position: "relative" }}>
      <Carousel
        swipeable={true}
        emulateTouch={true}
        useKeyboardArrows={true}
        onChange={(index) => updateScreenshotIndex(index)}
        selectedItem={currentScreenshotIndex}
      >
        {testResult.screenShots.map((url) => {
          let parsedUrl = decodeURIComponent(/[^/]*$/.exec(url)[0]);
          if (parsedUrl.includes(".png"))
            parsedUrl = parsedUrl.substring(0, parsedUrl.indexOf(".png") + 4);
          else if (parsedUrl.includes(".jpg"))
            parsedUrl = parsedUrl.substring(0, parsedUrl.indexOf(".jpg") + 4);
          else if (parsedUrl.includes(".jpeg"))
            parsedUrl = parsedUrl.substring(0, parsedUrl.indexOf(".jpeg") + 5);

          return <div key={Math.random()}>
            <img alt={""} src={url} />
            <p className={"legend"}>{parsedUrl}</p>
          </div>;
        })}
      </Carousel>
      <a className={"download-image-link"} href={testResult.screenShots[currentScreenshotIndex]} download={true}><Icon
        name={"download"} /> Download Screenshot</a>
    </Tab.Pane>
  );
});

export default ScreenshotsTab;
