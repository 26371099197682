import { useInitialEffect, useUnloadEffect, setPageInfo } from "core/react-utils";
import { useParams, useHistory } from "react-router";
import "./runner-pool-edit.scss";
import { Button, Form, Icon } from "semantic-ui-react";
import { RunnerPoolStore } from "stores";
import Page from "pages/page";
import { Icons } from "core/constants";
import { observer } from "mobx-react-lite";
import NumberSelect from "components/form/number-select";
import RunnerPoolForm from "models/forms/runner-pool-form";
import RunnerTypeSelect from "components/form/runner-type-select";

const form = new RunnerPoolForm();

async function loadRunnerPool(id: string) {
  await RunnerPoolStore.ensureLoaded();
  if (id) {
    form.populateFromRunnerPool(RunnerPoolStore.find(parseInt(id)));
  } 
}

async function saveRunnerPool(push: (path: string) => any) {
  await RunnerPoolStore.save(form);
  push("../runner-pools");
}

const RunnerPoolEditPage = observer(() => {
  const { runnerPoolId } = useParams<{ runnerPoolId: string }>();
  const history = useHistory();
  const title = (runnerPoolId ? "Edit" : "New") + " Runner Pool";

  setPageInfo("runner-pool-edit", title);

  useInitialEffect(() => {
    RunnerPoolStore.ensureLoaded();
    loadRunnerPool(runnerPoolId);
  });

  useUnloadEffect(() => form.clearFormData());

  return (
    <Page name="runner-pools-edit" title={title} icon={Icons.pipelines}>
      <Form onSubmit={() => null}>
        <Form.Input
          label="Name"
          type="text"
          name="Name"
          placeholder="Runner Pool (Window Develop, Linux Staging, QA, etc)"
          value={form.name}
          error={form.getErrorText("name")}
          onChange={form.onChange("name")}
          autoFocus
        />
        <RunnerTypeSelect
          label="Runner Type"
          value={form.runnerType}
          onChange={form.onChange("runnerType")}
          error={form.getErrorText("runnerType")}
        />
        <NumberSelect
          label="Maximum Runners"
          value={form.maxRunners}
          onChange={form.onChange("maxRunners")}
          min={1}
          max={form.maxRunnersAllowed}
        />
      </Form>
      <section className="form-actions">
        <Button onClick={() => history.goBack()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button color="green" onClick={() => saveRunnerPool(history.push)}>
          <Icon name="check" />
          Save
        </Button>
      </section>
    </Page>
  );
});

export default RunnerPoolEditPage;
