import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router";
import { Button, Card, Form, Grid, Icon } from "semantic-ui-react";
import TestRunForm from "models/forms/test-run-form";
import { Icons } from "core/constants";
import Page from "pages/page";
import "./trigger-edit.scss";
import { AppStore, PipelineStore, ProjectStore, SettingsStore, TriggerStore } from "stores";
import { useInitialEffect } from "core/react-utils";
import TestAdvancedOptions from "components/form/test-advanced-options";
import ProjectSelect from "components/form/project-select";
import TriggerTypeOptions from "components/form/trigger-type-options";
import TestRunVersionSelect from "components/form/test-run-version-select";
import EnvironmentSelect from "components/form/environment-select";
import TestRunOptions from "components/form/test-run-options";
import NumberSelect from "components/form/number-select";
import PipelineStageSelect from "components/form/pipeline-stage-select";

let form = new TestRunForm();

async function loadTrigger(id: string, setTriggerLoaded: (v: boolean) => any) {
  await ProjectStore.ensureLoaded();
  await PipelineStore.ensureLoaded();
  await SettingsStore.ensureEnvironments();

  if (id) {
    await TriggerStore.ensureLoaded();
    form = new TestRunForm(TriggerStore.find(parseInt(id)));
    setTriggerLoaded(true);
  } else {
    form.clearForSchedule();
    setTriggerLoaded(true);
  }
  form.scheduleOnly = true;
}

async function saveTrigger(push: (path: string) => any) {
  await TriggerStore.save(form);
  push("../triggers");
}

const TriggerEdit = observer(() => {
  const { triggerId } = useParams<{ triggerId: string }>();
  const [triggerLoaded, setTriggerLoaded] = useState(false);
  const history = useHistory();

  const isNew = !triggerId;
  const title = (isNew ? "New" : "Edit") + " Test Trigger";
  const project = ProjectStore.find(form.projectId);

  useInitialEffect(() => {
    loadTrigger(triggerId, setTriggerLoaded);
  });

  return (
    <Page name="trigger-edit" title={title} icon={Icons.triggers}>
      <Form>
        <Card>
          <Card.Content>
            <h4>Create test trigger...</h4>
            <Form.Input
              label="Trigger Name"
              value={form.name || ""}
              onChange={form.onChange("name")}
              error={form.getErrorText("name")}
            />
            <TriggerTypeOptions form={form} scheduleOnly />
          </Card.Content>
        </Card>
        {!form.isTestPlanTrigger && (
          <>
            <Card>
              <Card.Content>
                <h4>I want to trigger tests in...</h4>
                <ProjectSelect
                  label="Project With Tests"
                  value={form.projectId}
                  onlyWithTests={true}
                  onChange={form.onChange("projectId")}
                  error={form.getErrorText("projectId")}
                />
                <TestRunOptions form={form} project={project} hideAdvancedOptions hideTitle />
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <h4>In this...</h4>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <PipelineStageSelect
                        label="Pipeline Stage"
                        placeholder="All"
                        value={form.pipelineStageId}
                        onChange={form.onChange("pipelineStageId")}
                        error={form.getErrorText("pipelineStageId")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      {triggerLoaded && (
                        <EnvironmentSelect
                          label="Environment"
                          placeholder="All"
                          value={form.environmentId}
                          onChange={form.onChange("environmentId")}
                          error={form.getErrorText("environmentId")}
                          pipelineStageId={form.pipelineStageId}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <h4>With this version of code...</h4>
                <TestRunVersionSelect project={project} form={form} />
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <h4>Advanced Options</h4>
                {form.testOptionsType == "suite" && (
                  <NumberSelect
                    min={1}
                    max={10}
                    label="Priority"
                    value={form.priority}
                    onChange={form.onChange("priority")}
                    error={form.getErrorText("priority")}
                  />
                )}
                {form.testOptionsType != "suite" && <TestAdvancedOptions project={project} form={form} />}
              </Card.Content>
            </Card>
          </>
        )}
        {form.isTestPlanTrigger && form.onDeploy && (
          <Card>
            <Card.Content>
              <h4>When project deployed to this...</h4>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <PipelineStageSelect
                      label="Pipeline Stage"
                      placeholder="All"
                      value={form.pipelineStageId}
                      onChange={form.onChange("pipelineStageId")}
                      error={form.getErrorText("pipelineStageId")}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    {triggerLoaded && (
                      <EnvironmentSelect
                        label="Environment"
                        placeholder="All"
                        value={form.environmentId}
                        onChange={form.onChange("environmentId")}
                        error={form.getErrorText("environmentId")}
                        pipelineStageId={form.pipelineStageId}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>
        )}
      </Form>
      <section className="form-actions">
        <Button type="button" onClick={() => history.goBack()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button
          onClick={() => saveTrigger(history.push)}
          loading={!triggerLoaded || form.isSubmitting}
          color="green"
          type="submit"
          disabled={!AppStore.userIsAdmin}
        >
          <Icon name="check" />
          Save
        </Button>
      </section>
    </Page>
  );
});

export default TriggerEdit;
