import API from "core/api";
import { formatForMillisOffset, parseServerDateTime } from "core/date-utils";
import { autoRetry, isBlank, sleep } from "core/utils";
import { chain, findIndex, maxBy, sortBy } from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import { Deploy } from "models/project";
import {
  BooleanMap,
  FiltersMap,
  groupTestRunTestsByFile,
  Message,
  StringMap,
  TestFile,
  TestListResult,
  TestResult,
  TestRun,
  TestRunAnalysis,
  TestRunnerData,
  TestRunStatus,
  TestRunTest,
  TestRunTestAnalysis,
  TestStatus,
} from "models/test-run";
import { UserFollow } from "models/user";
import { AppStoreClass } from "./app-store";
import { ProjectStoreClass } from "./project-store";
import { SettingsStoreClass } from "./settings-store";
import { PipelineStore } from "./index";

type OpenItemsMap = { [index: number]: BooleanMap };

export class TestRunStoreClass {
  private readonly AppStore: AppStoreClass;
  private readonly ProjectStore: ProjectStoreClass;
  private readonly SettingsStore: SettingsStoreClass;
  @observable testRuns: TestRun[] = [];
  @observable testRunResults: Record<number, TestFile[]> = {};
  @observable testRunTests: Record<number, TestRunTest[]> = {};
  @observable testRunMessages: Record<number, Message[]> = {};
  @observable testRunFollowers: Record<number, UserFollow[]> = {};
  @observable filterDate: Date = null;
  @observable filterEnvironmentIds: Array<number> = [];
  @observable filterPipelineStageIds: Array<number> = [];
  @observable filterStatus: TestRunStatus[] = [];
  @observable filterReviewerIds: number[] = [];
  @observable filterProjectId: number = null;
  @observable filteredTestSuiteId: number = null;
  @observable filterBranches: Array<string> = [];
  @observable loading: boolean = false;
  @observable changingStatus: boolean = false;
  @observable testRunsLoaded: boolean = false;
  @observable refreshTestRuns: boolean = false;
  @observable currentOffset = 0;
  @observable statusFiltersByTestRun = {} as FiltersMap;
  @observable testRunTabSetting = {} as StringMap;
  @observable loadingTestRunResults = false;
  @observable openItemsMap = {} as OpenItemsMap;
  @observable openTestRunTests: Record<number, boolean> = {};
  @observable testRunComparison1: TestRun = null;
  @observable testRunComparison2: TestRun = null;
  refreshTimer: any;
  pageSize = 10;

  constructor(AppStore: AppStoreClass, ProjectStore: ProjectStoreClass, SettingsStore: SettingsStoreClass) {
    this.AppStore = AppStore;
    this.ProjectStore = ProjectStore;
    this.SettingsStore = SettingsStore;
  }

  @action
  setTestRuns(testRuns: TestRun[]) {
    this.testRuns = testRuns;
  }

  @action
  clear() {
    this.testRuns = [];
    this.testRunResults = {};
    this.filterDate = null;
    this.filterEnvironmentIds = [];
    this.filterPipelineStageIds = [];
    this.filterBranches = [];
    this.filterProjectId = null;
    this.filteredTestSuiteId = null;
    this.filterStatus = [];
    this.filterReviewerIds = [];
    this.loading = false;
    this.refreshTestRuns = false;
    this.changingStatus = false;
    this.testRunsLoaded = false;
    this.currentOffset = 0;
  }

  @action
  async loadTestRun(testRunId: number) {
    const result = await API.get(`/test-runs/${testRunId}`, TestRun);
    const testRunnerDataResult = await API.getList(`/test-runs/${testRunId}/test-runners`, TestRunnerData);
    if (typeof result.data !== "string") {
      result.data.testRunners = testRunnerDataResult.data;
      return this.storeTestRun(result.data);
    }
  }

  getTestRun(testRunId: number) {
    return this.testRuns.find((t) => t.id == testRunId);
  }

  @action.bound
  async ensureLoaded(refresh: boolean = false): Promise<Array<TestRun>> {
    this.refreshTestRuns = refresh;
    if (!this.testRunsLoaded) {
      await this.loadTestRuns(true);
    }

    return this.testRuns;
  }

  @action.bound
  updateFilterDate(value: Date) {
    this.filterDate = value;
    this.loadTestRuns(true);
  }

  @action.bound
  updateFilterPipelineStage(pipelineStageIds: Array<number>) {
    this.filterPipelineStageIds = pipelineStageIds;
    this.loadTestRuns(true);
  }

  @action
  setFilterPipelineStage(pipelineStageIds: Array<number>) {
    this.filterPipelineStageIds = pipelineStageIds;
  }

  @action.bound
  updateFilterEnvironment(environmentIds: Array<number>) {
    this.filterEnvironmentIds = environmentIds;
    this.loadTestRuns(true);
  }

  @action
  setFilterEnvironment(environmentIds: Array<number>) {
    this.filterEnvironmentIds = environmentIds;
  }

  @action.bound
  updateFilterBranch(branches: Array<string>) {
    this.filterBranches = branches;
    this.loadTestRuns(true);
  }

  @action
  setFilterBranch(branch: Array<string>) {
    this.filterBranches = branch;
  }

  @action.bound
  updateFilterTestSuite(testSuiteId: number) {
    this.filteredTestSuiteId = testSuiteId;
    this.loadTestRuns(true);
  }

  @action
  setFilterTestSuite(testSuiteId: number) {
    this.filteredTestSuiteId = testSuiteId;
  }

  @action.bound
  updateFilterProject(projectId: number) {
    this.filterProjectId = projectId;
    this.loadTestRuns(true);
  }

  @action
  setFilterProject(projectId: number) {
    this.filterProjectId = projectId;
  }

  @action.bound
  updateFilterStatus(value: TestRunStatus[]) {
    this.filterStatus = value;
    this.loadTestRuns(true);
  }

  @action
  setFilterStatus(value: TestRunStatus[]) {
    this.filterStatus = value;
  }

  @action.bound
  updateFilterReviewerIds(value: number[]) {
    this.filterReviewerIds = value;
    this.loadTestRuns(true);
  }

  @action
  setFilterReviewerIds(value: number[]) {
    this.filterReviewerIds = value;
  }

  @action
  updateTestRunComparison1(testRun: TestRun) {
    this.testRunComparison1 = testRun;
  }

  @action
  updateTestRunComparison2(testRun: TestRun) {
    this.testRunComparison2 = testRun;
  }

  @action
  async uploadTestRun(testRunUpload: any) {
    const result = await API.postMultipart("/test-runs-upload", testRunUpload, TestRun);

    if (!result.status)
      throw Error("Test run upload failed");

    await this.loadTestRuns(true);
    return result.data;
  }

  @action
  async uploadTestRunFromSample(testRunUpload: any) {
    const result = await API.post("/test-runs-upload/sample_pytest", testRunUpload, TestRun);

    if (!result.status)
      throw Error("Test run upload failed");

    await this.loadTestRuns(true);
    return result.data;
  }

  @action
  async createTestRun(testRunRequest: any) {
    const result = await API.post("/test-runs", testRunRequest, TestRun);
    await this.loadTestRuns(true);
    return result.data;
  }

  hasRunningTests(testRun: TestRun) {
    return testRun && (testRun.notCompleted || this.getTestRunTests(testRun.id).some((t) => t.notCompleted));
  }

  @action.bound
  async loadTestRuns(resetList = true, offset = 0) {
    if (resetList) {
      this.loading = true;
      this.currentOffset = 0;
      this.testRuns = [];
    }

    const project = this.ProjectStore.find(this.filterProjectId);
    const environments = this.SettingsStore.environments.filter((e) => this.filterEnvironmentIds.includes(e.id));
    const pipelineStages = PipelineStore.sortedItems.filter((ps) => this.filterPipelineStageIds.includes(ps.id));
    const testRuns = await autoRetry(async () => {
      const projectKey = project && project.key;
      const environmentKeys = environments && environments.map(e => e.key);
      const pipelineStageIds = pipelineStages && pipelineStages.map(ps => ps.id);
      const testSuiteId = this.filteredTestSuiteId;
      const status = this.filterStatus;
      const reviewerIds = this.filterReviewerIds;
      const date = this.filterDate;
      const branches = this.filterBranches;

      let url = `/test-runs?offset=${offset || 0}&limit=${this.pageSize}`;
      if (status && status.length > 0) url += `&status=${status.join(",")}`;
      if (reviewerIds && reviewerIds.length > 0) url += `&reviewerIds=${reviewerIds.join(",")}`;
      if (environmentKeys && environmentKeys.length > 0) url += `&environment=${environmentKeys.join(",")}`;
      if (pipelineStageIds && pipelineStageIds.length > 0) url += `&pipelineStage=${pipelineStageIds.join(",")}`;
      if (date) url += `&date=${formatForMillisOffset(date)}`;
      if (projectKey) {
        url += `&project=${projectKey}`;
        if (branches && branches.length > 0) url += `&branch=${branches.join(",")}`;
      }
      if (testSuiteId) url += `&testSuiteId=${testSuiteId}`;
      const result = await API.getList(url, TestRun);
      return result.data;
    }, 2000);

    runInAction(() => {
      this.testRuns = testRuns;
      this.loading = false;
      this.testRunsLoaded = true;
      this.scheduleLoadMore();
    });
  }

  @action.bound
  async loadPageChange(page: number) {
    this.loading = true;
    this.currentOffset = (page - 1) * this.pageSize;
    await this.loadTestRuns(false, this.currentOffset);
  }

  @action
  scheduleLoadMore() {
    const hasRunningTests = this.testRuns.some((t) => t.notCompleted);
    const hasSubmittedTestWithNoTests = this.testRuns.some(
      (t) => t.status == TestRunStatus.SUBMITTED && t.totalCount == 0,
    );
    const refreshTime = hasSubmittedTestWithNoTests || !this.testRunsLoaded ? 5000 : hasRunningTests ? 10000 : 60000;

    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }

    this.refreshTimer = setTimeout(() => {
      if (this.refreshTestRuns) {
        this.loadTestRuns(false, 0);
      } else {
        this.scheduleLoadMore();
      }
    }, refreshTime);
  }

  @action
  async cancelTestRun(testRun: TestRun) {
    await API.patch(`/test-runs/${testRun.id}`, {
      status: TestRunStatus.CANCELED,
    });

    return this.loadTestRuns(false);
  }

  @action
  async rerunTestRun(testRun: TestRun) {
    const newTestRun = await this.callRerun(testRun.id, { rerunTestRun: true });
    this.toggleFollow(newTestRun.id);
    this.loadTestRuns(false);

    return newTestRun;
  }

  async callRerun(testRunId, data) {
    const result = await API.post(`/test-runs/${testRunId}/rerun`, data, TestRun);
    return result.data;
  }

  @action
  async rerunTests(testResults: Array<TestRunTest>) {
    if (!testResults || testResults.length == 0) return;

    const testRunId = testResults[0].testRunId;

    const newTestRun = await this.callRerun(testRunId, {
      testIds: testResults.map((r) => r.id),
    });

    await this.loadTestRuns(false);

    await this.loadTestRunTests(testRunId, false);

    return newTestRun;
  }

  @action
  async rerunFailedTests(testRunId) {
    const newTestRun = await this.callRerun(testRunId, { rerunFailedTests: true });

    await this.loadTestRuns(false);

    await this.loadTestRunTests(testRunId, false);

    return newTestRun;
  }

  @action
  async loadTestRunTests(testRunId: number, setLoading = true, loadAll = false) {
    this.loadingTestRunResults = setLoading;

    const currentTests = this.testRunTests[testRunId] || [];

    let url = `/test-runs/${testRunId}/results`;
    const isRefresh = currentTests.length > 0;

    if (isRefresh && !loadAll) {
      const max = maxBy(currentTests, "updatedAt");
      url += "?since=" + parseServerDateTime(max.updatedAt).getTime();
    }

    const results = await API.getList(url, TestRunTest);

    return runInAction(() => {
      const selected = this.getTestRunTests(testRunId).find((t) => t.selected);
      const testsReceived = results.data.filter((t) => t.isTestPhase);

      if (isRefresh) {
        testsReceived.forEach((t) => {
          t.selected = t.parentId == selected?.parentId;
          const index = findIndex(currentTests, (o) => o.id == t.id);
          if (index == -1) {
            currentTests.push(t);
          } else {
            currentTests[index] = t;
          }
        });

        this.testRunTests[testRunId] = currentTests;
        this.testRunResults[testRunId] = groupTestRunTestsByFile(testRunId, currentTests);
      } else {
        testsReceived.forEach((t) => (t.selected = t.parentId == selected?.parentId));
        this.testRunTests[testRunId] = testsReceived;
        this.testRunResults[testRunId] = groupTestRunTestsByFile(testRunId, testsReceived);
      }
      this.loadingTestRunResults = false;

      return this.getTestRunResults(testRunId);
    });
  }

  @action
  async createTestRunAnalysis(testRunId: number) {
    const result = await API.post(`test-runs/${testRunId}/analysis`, {}, TestRunAnalysis);
    return result.data;
  }

  @action
  async getTestRunAnalysis(testRunId: number) {
    const result = await API.get(`test-runs/${testRunId}/analysis`, TestRunAnalysis);
    return result.data;
  }

  @action
  async getSuggestion(testRunId: number, testRunTestId: number) {
    const result = await API.get(`test-runs/${testRunId}/results/${testRunTestId}/analysis`, TestRunTestAnalysis);
    return result.data;
  }

  @action
  async createTestRunTestAnalysis(testRunId: number, testRunTestId: number) {
    const result = await API.post(`test-runs/${testRunId}/results/${testRunTestId}/analysis`, TestRunTestAnalysis);
    return result.data;
  }

  @action
  async refreshTestRunTests(testRunId: number, since: number) {
    const results = await API.getList(`/test-runs/${testRunId}/results?since=${since}`, TestRunTest);

    if (results.data.length > 0) {
      runInAction(() => {
        const selected = this.getTestRunTests(testRunId).find((t) => t.selected);
        const updatedTests = results.data;
        const allTests = [...this.testRunTests[testRunId]];
        updatedTests.forEach((t) => {
          t.selected = t.parentId == selected?.parentId;
          const index = findIndex(allTests, (o) => o.id == t.id);
          if (index == -1) {
            allTests.push(t);
          } else {
            allTests[index] = t;
          }
        });

        this.testRunTests[testRunId] = allTests;
        this.testRunResults[testRunId] = groupTestRunTestsByFile(testRunId, allTests);
      });
    }
  }

  hasTestRunResults(testRunId: number) {
    return !!this.testRunResults[testRunId];
  }

  getTestRunResults(testRunId: number) {
    return this.testRunResults[testRunId] || [];
  }

  getTestRunTests(testRunId: number) {
    return this.testRunTests[testRunId] || [];
  }

  async getTestResult(testRunId: number, testRunTestId: number) {
    const result = await API.get(`test-runs/${testRunId}/results/${testRunTestId}`, TestResult);
    return result.data;
  }

  async loadTestRunTest(testRunTestId: number) {
    const result = await API.get(`test-run-test/${testRunTestId}`, TestRunTest);
    return result.data;
  }

  @action
  async updateStatus(testRunId: number, status: TestRunStatus) {
    this.changingStatus = true;
    const result = await API.patch(`/test-runs/${testRunId}`, { status }, TestRun);
    let testRun = result.data;
    let maxRuns = 60;

    while (testRun.status != status && maxRuns-- > 0) {
      await sleep(1000);
      testRun = (await API.get(`/test-runs/${testRunId}`, TestRun)).data;
    }

    await this.loadTestRunTests(testRunId, false);

    runInAction(() => {
      this.changingStatus = false;
      this.storeTestRun(testRun);
    });
    return testRun;
  }

  @action
  storeTestRun(testRun: TestRun) {
    const index = this.testRuns.findIndex((t) => t.id == testRun.id);
    if (index < 0) {
      this.testRuns.push(testRun);
    } else {
      this.testRuns[index] = testRun;
    }
    return testRun;
  }

  async loadDeploysForTestRun(testRunId: number) {
    const result = await API.getList(`/test-runs/${testRunId}/deploys`, Deploy);
    return result.data;
  }

  getStatusFilters(testRunId: number) {
    return this.statusFiltersByTestRun[testRunId] || [];
  }

  @action
  updateStatusFilters(testRunId: number, statuses: TestStatus[]) {
    this.statusFiltersByTestRun[testRunId] = statuses;
    return statuses;
  }

  @action
  updateTestRunTab(testRunId: number, tab: string) {
    this.testRunTabSetting[testRunId] = tab;
    this.updateSelectedParentId(testRunId, null);
  }

  getTestRunTab(testRunId: number) {
    return this.testRunTabSetting[testRunId];
  }

  @action
  updateSelectedParentId(testRunId: number, parentId: number) {
    this.getTestRunTests(testRunId).forEach((t) => {
      if (t.parentId == parentId) {
        t.selected = true;
      } else if (t.selected) {
        t.selected = false;
      }
    });
  }

  @action
  updateOpenItems(testRunId: number, data: BooleanMap) {
    this.openItemsMap[testRunId] = data;
  }

  getOpenItems(testRunId: number): BooleanMap {
    return this.openItemsMap[testRunId] || {};
  }

  @action
  toggleTestRunTestOpen(testRunTestId: number) {
    this.openTestRunTests[testRunTestId] = !this.openTestRunTests[testRunTestId];
  }

  @action
  clearTestRunTestOpen() {
    this.openTestRunTests = {};
  }

  @computed
  get eligibleForComparison() {
    return chain(this.testRuns)
      .filter((p) => p.hasResults)
      .orderBy(["createdAt", "id"], ["desc", "asc"])
      .value();
  }

  isTestRunTestOpen(testRunTestId: number) {
    return !!this.openTestRunTests[testRunTestId];
  }

  async loadMessages(testRunId: number) {
    const result = await API.getList(`/test-runs/${testRunId}/messages`, Message);
    return runInAction(() => (this.testRunMessages[testRunId] = sortBy(result.data, "createdAt").reverse()));
  }

  getMessages(testRunId: number) {
    return this.testRunMessages[testRunId] || [];
  }

  async refreshMessages(testRunId: number) {
    const messages = this.testRunMessages[testRunId] || [];

    if (messages.length == 0) return this.loadMessages(testRunId);

    const since = parseServerDateTime(messages[0].createdAt).getTime() + 1;
    const result = await API.getList(`/test-runs/${testRunId}/messages?since=${since}`, Message);
    return runInAction(
      () => (this.testRunMessages[testRunId] = [...sortBy(result.data, "createdAt").reverse(), ...messages]),
    );
  }

  async saveMessage(testRunId: number, text: string) {
    if (isBlank(text)) return this.testRunMessages[testRunId];

    const result = await API.post(`/test-runs/${testRunId}/messages`, { text }, Message);

    return runInAction(() => {
      if (!this.currentUserIsFollowingTestRun(testRunId)) {
        this.loadFollowers(testRunId);
      }
      const messages = this.testRunMessages[testRunId] || [];
      this.testRunMessages[testRunId] = [result.data, ...messages];
      return result.data;
    });
  }

  async loadTestListResult(testRunId: number) {
    const result = await API.get(`/test-runs/${testRunId}/test-list-result`, TestListResult);
    return result.data;
  }

  async loadFollowers(testRunId: number) {
    const result = await API.getList(`/test-runs/${testRunId}/followers`, UserFollow);
    return runInAction(() => (this.testRunFollowers[testRunId] = result.data));
  }

  currentUserIsFollowingTestRun(testRunId: number) {
    const userId = this.AppStore.user.id;
    return !!this.getFollowers(testRunId).find((f) => f.userId == userId);
  }

  getFollowers(testRunId: number) {
    return this.testRunFollowers[testRunId] || [];
  }

  async toggleFollow(testRunId: number) {
    await API.post("users/me/toggle-follow", { testRunId }, UserFollow);
    await this.loadFollowers(testRunId);
  }

  async getFirstTestRun(): Promise<TestRun> {
    const result = await API.get("/test-runs/first", TestRun);
    return result.data;
  }
}

