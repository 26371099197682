import { keys } from "lodash";
import { ExecutionType } from "models/test-plan";
import { ReactNode } from "react";
import { JsxElement } from "typescript";
import Select from "./select";

interface Props {
  value: ExecutionType;
  onChange: (value: ExecutionType) => any;
  error?: boolean | string;
  label?: string | JsxElement | ReactNode;
}

const TestPlanStageExecutionTypeSelect = ({ onChange, value, error, label }: Props) => (
  <Select
    className="test-plan-stage-execution-type-select"
    onChange={(v) => onChange(ExecutionType[v])}
    value={value}
    options={keys(ExecutionType)}
    error={error}
    label={label}
  />
);

export default TestPlanStageExecutionTypeSelect;
