import { useEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import TestRunForm from "models/forms/test-run-form";
import TestSuiteForm from "models/forms/test-suite-form";
import Project from "models/project";
import { useState } from "react";
import { Grid, Radio } from "semantic-ui-react";
import { TestSuiteStore } from "stores";
import NumberSelect from "./number-select";
import TestAdvancedOptions from "./test-advanced-options";
import TestFilters from "./test-filters";
import TestSuiteInfo from "./test-suite-info";
import TestSuiteSelect from "./test-suite-select";

interface Props {
  project: Project;
  form: TestRunForm | TestSuiteForm;
  useAdvancedToggle?: boolean;
  hidePriority?: boolean;
  title?: string;
  createTestSuite?: boolean;
  hideAdvancedOptions?: boolean;
  hideTitle?: boolean;
}

const TestRunOptions = observer(
  ({
     project,
     form,
     useAdvancedToggle,
     hidePriority,
     title,
     createTestSuite,
     hideAdvancedOptions,
     hideTitle,
   }: Props) => {
    const [hasTestSuites, setHasTestSuites] = useState(false);

    useEffect(async () => {
      await TestSuiteStore.ensureLoaded();
      setHasTestSuites(TestSuiteStore.projectHasActiveTestSuites(project?.id));
    }, [project?.id]);

    return (
      <div>
        {!hideTitle && <h4>{title || "Options"}</h4>}
        <Grid>
          <Grid.Row columns={hasTestSuites ? 3 : 2}>
            {form instanceof TestRunForm &&
              <Grid.Column>
                <Radio
                  checked={form.testOptionsType == "all"}
                  onChange={() => form.updateField("testOptionsType", "all")}
                  name="useTestSuite"
                  label="Run all tests in project"
                />
              </Grid.Column>
            }
            {hasTestSuites && (
              <Grid.Column>
                <Radio
                  checked={form.testOptionsType == "suite"}
                  onChange={() => form.updateField("testOptionsType", "suite")}
                  name="useTestSuite"
                  label="Select test suite"
                />
              </Grid.Column>
            )}
            <Grid.Column>
              <Radio
                checked={form.testOptionsType == "specify"}
                name="useTestSuite"
                label={createTestSuite ? "Create Test Suite" : "Filter tests in project"}
                onChange={() => form.updateField("testOptionsType", "specify")}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {form.testOptionsType == "suite" && project && (
          <div>
            <TestSuiteSelect
              projectId={project.id}
              value={form.testSuiteId}
              onChange={form.onChange("testSuiteId")}
              error={form.getErrorText("testSuiteId")}
              placeholder="Select Test Suite"
            />
            {!hideAdvancedOptions && !hidePriority && (
              <NumberSelect
                min={1}
                max={10}
                label="Priority"
                value={form.priority}
                onChange={form.onChange("priority")}
                error={form.getErrorText("priority")}
              />
            )}
          </div>
        )}
        {form.testOptionsType == "specify" && (
          <>
            {createTestSuite && <TestSuiteInfo form={form as TestSuiteForm} />}
            <TestFilters project={project} form={form} />
          </>
        )}
        {form.testOptionsType != "suite" && !hideAdvancedOptions && (
          <>
            <br />
            <TestAdvancedOptions
              project={project}
              form={form}
              useAdvancedToggle={useAdvancedToggle}
              hidePriority={hidePriority}
            />
          </>
        )}
      </div>
    );
  },
);

export default TestRunOptions;
