import "./test-result.scss";
import { format } from "date-fns";
import { Button, Icon, Popup, Tab, TabProps } from "semantic-ui-react";
import { StatusColors, TestResult, TestRunTest } from "models/test-run";
import { Icons } from "../../core/constants";
import ConsoleTab from "./console-tab";
import ScreenshotsTab from "./screenshots-tab";
import VideosTab from "./videos-tab";
import TroubleshootingTab from "./troubleshooting-tab";

import CapsuleDisplay from "../capsule-display";
import { millisToMinutesAndSeconds, parseServerDateTime } from "../../core/date-utils";
import AssignReviewUserSelect from "../form/assign-review-user-select";
import { ReviewType } from "../../models/review-data";
import ReviewStatusSelect from "../form/review-status-select";
import { ProjectStore, SettingsStore, TestRunStore, UserStore } from "../../stores";
import { useInitialEffect } from "../../core/react-utils";
import { Authorization, AuthorizationProvider } from "../../models/settings";
import { useEffect, useState } from "react";
import Spinner from "../spinner";
import { useHistory, useParams } from "react-router";
import { findIndex } from "lodash";

interface Props {
  testResult: TestResult;
}

const TestResultDisplay = ({ testResult }: Props) => {
  const [jiraAuth, setJiraAuth] = useState<Authorization>(null);
  const [testRunTest, setTestRunTest] = useState<TestRunTest>(null);
  const history = useHistory();
  const { tab, testId } = useParams<{ tab: string, testId: string }>();

  useInitialEffect(async () => {
    await UserStore.loadUserRoles();
    const authorizations = await SettingsStore.loadAuthorizations();
    setJiraAuth(authorizations.find(auth => auth.provider == AuthorizationProvider.Jira));
  });

  useEffect(() => {
    if (!tab) {
      history.replace(`${testId}/console`);
    }
  }, [tab]);

  useEffect(() => {
    if (!testResult)
      return;

    reloadTestRunTests();
  }, [testResult]);

  async function handleDequarantine(testRunTest: TestRunTest) {
    await ProjectStore.dequarantineTest(testRunTest.projectTest.projectId, testRunTest.projectTest.id);
    reloadTestRunTests();
  }

  async function handleQuarantine(testRunTest: TestRunTest) {
    await ProjectStore.quarantineTest(testRunTest.projectTest.projectId, testRunTest.projectTest.id);
    reloadTestRunTests();
  }

  const reloadTestRunTests = () => {
    TestRunStore.loadTestRunTest(testResult.testRunTestId).then(testRunTest => setTestRunTest(testRunTest));
  };

  const navigate = (data: TabProps) => {
    history.push(panes[data.activeIndex].menuItem.key);
  };

  const panes = [
    {
      menuItem: { key: "console", icon: Icons.terminal, content: "Console" },
      render: () => <ConsoleTab testResult={testResult} />,
    },
    {
      menuItem: { key: "screenshots", icon: Icons.image, content: "Screenshots" },
      render: () => <ScreenshotsTab testResult={testResult} />,
    },
    {
      menuItem: { key: "videos", icon: Icons.video, content: "Videos", className: "video-tab" },
      render: () => <VideosTab testResult={testResult} />,
    },
  ];

  if (testResult && testResult.error && testResult.error.length > 0) {
    panes.push({
      menuItem: {
        key: "troubleshooting",
        icon: Icons.stethoscope,
        content: "Troubleshooting",
        className: "troubleshooting-tab",
      },
      render: () => <TroubleshootingTab testResult={testResult} />,
    });
  }
  if (!testResult || !testRunTest)
    return (
      <Spinner text={"Loading Result"} />
    );

    return (
    <div className="test-result">
      <div className="main">
        <Popup key={Math.random()} content={testResult.name} position={"bottom center"}
               style={{ maxWidth: "500px", wordBreak: "break-all" }}
               trigger={<span className={"text"} style={{ lineHeight: "normal" }}>{testResult.name}</span>} />
        {!testRunTest.projectTest.quarantined && (
          <Button  primary={true} onClick={async () => await handleQuarantine(testRunTest)}><Icon name={"first aid"} />
            Quarantine
          </Button>
        )}
        {testRunTest.projectTest.quarantined && (
          <Button  primary={true} onClick={async () => await handleDequarantine(testRunTest)}><Icon name={"first aid"} />
            Remove Quarantine
          </Button>
        )}
        <AssignReviewUserSelect reviewType={ReviewType.TEST_RUN_TEST}
                                testRunId={testResult.testRunId}
                                testRunReviewerUserId={null}
                                testRunTestId={testResult.testRunTestId}
                                testRunTestReviewerUserId={testResult.testRunTestReviewerUserId} />
        <ReviewStatusSelect reviewType={ReviewType.TEST_RUN_TEST}
                            testRunId={testResult.testRunId}
                            testRunReviewStatusId={null}
                            testRunTestId={testResult.testRunTestId}
                            testRunTestReviewStatusId={testResult.testRunTestReviewStatusId}
                            refresh={reloadTestRunTests} />
      </div>
      <div style={{ margin: "10px 0" }}>
        {testRunTest.testRunTestTrackedIssues?.map(trackedIssue => <a
          href={`${jiraAuth.baseUrl}/browse/${trackedIssue.issueKey}`}
          target={"_blank"}>{trackedIssue.issueKey} <Icon name={"external alternate"} /></a>)}
      </div>
      <div className={"metrics"}>
        <CapsuleDisplay label={"Status"}
                        value={testResult.status}
                        labelBackgroundColor={StatusColors[testResult.status]}
                        labelTextColor={"#FFF"} valueBackgroundColor={"#555"} valueTextColor={"#FFF"}
                        secondaryValue={testResult.flaky ? "FLAKY" : null} secondaryValueBackgroundColor={"orange"}
                        secondaryValueTextColor={"#FFF"} />
        <CapsuleDisplay label={"Start Time"}
                        value={format(parseServerDateTime(testResult.startTime), "MMM dd, yyyy h:mm:ss a")}
                        labelBackgroundColor={"#007ec6"}
                        labelTextColor={"#FFF"} valueBackgroundColor={"#555"} valueTextColor={"#FFF"} />
        <CapsuleDisplay label={"End Time"}
                        value={format(parseServerDateTime(testResult.endTime), "MMM dd, yyyy h:mm:ss a")}
                        labelBackgroundColor={"#d4ad21"}
                        labelTextColor={"#FFF"} valueBackgroundColor={"#555"} valueTextColor={"#FFF"} />
        <CapsuleDisplay label={"Duration"} value={millisToMinutesAndSeconds(testResult.duration)}
                        labelBackgroundColor={"#48c21a"}
                        labelTextColor={"#FFF"} valueBackgroundColor={"#555"} valueTextColor={"#FFF"} />
      </div>
      <Tab
        onTabChange={(_, data) => navigate(data)}
        activeIndex={findIndex(panes, (i) => i.menuItem.key == tab)}
        style={{ marginTop: "50px" }}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </div>
  );
};

export default TestResultDisplay;
