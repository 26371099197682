import { EntityWithCreatedAt } from "./entity";

export class AccountSetupStep extends EntityWithCreatedAt {
  constructor(type?: AccountSetupStepType) {
    super();
    this.type = type;
  }

  accountId: number;
  type: AccountSetupStepType;
}

export enum AccountSetupStepType {
  Connect = "Connect",
  Upload = "Upload",
  Payment = "Payment",
  Final = "Final"
}

export enum ConnectChoice {
  slack,
  email
}
