import { observer } from "mobx-react-lite";
import { TestRun } from "models/test-run";
import { Button, Card, Grid } from "semantic-ui-react";
import { isNotBlank } from "core/utils";
import "./test-run-card.scss";
import { ProjectStore, ModalStore } from "stores";
import TestRunProgress from "components/test-run-progress";
import { sinceText } from "core/date-utils";
import { Link } from "react-router-dom";

interface Props {
  testRun: TestRun;
}

const TestRunCard = observer(({ testRun }: Props) => {
  const project = ProjectStore.find(testRun.projectId);
  let dateOutput = "";
  if (testRun.startTime) {
    dateOutput = sinceText(testRun.startTime);
  }

  return (
    <Card className="test-run-card">
      <Card.Content>
        <Grid columns={2}>
          <Grid.Column>
            <div className="project">{project.name}</div>
            {isNotBlank(testRun.branch) && <div className="info">{testRun.branch}</div>}
            {testRun.build && <div className="info">{testRun.build.name}</div>}
          </Grid.Column>
          <Grid.Column>
            <Link to={`test-runs/${testRun.id}`}>
              <TestRunProgress
                pass={testRun.passCount}
                fail={testRun.failCount}
                total={testRun.totalCount - testRun.ignoredCount}
              />
              <div className="time">{dateOutput}</div>
            </Link>
          </Grid.Column>
        </Grid>
      </Card.Content>
      <Card.Content>
        <Button onClick={() => ModalStore.commitInfo.show(null, testRun)}>Commit Info</Button>
      </Card.Content>
    </Card>
  );
});

export default TestRunCard;
