import { useState } from "react";
import { useInitialEffect } from "core/react-utils";
import { ActionType, Authorization, AuthorizationProvider, AuthReason, EventType } from "models/settings";
import { observer } from "mobx-react-lite";
import { Button, Form, Icon, Input, Tab } from "semantic-ui-react";
import { repositoryAuthorize } from "core/auth0";
import * as Storage from "core/storage";
import { AppStore, ModalStore, SettingsStore } from "stores";
import BitBucketServerForm from "models/forms/bit-bucket-server-form";
import { getSlackAuthUrl } from "core/slack";
import MicrosoftTeamsForm from "../../models/forms/microsoft-teams-form";
import AlertForm from "models/forms/alert-form";
import { runInAction } from "mobx";
import { getJiraAuthUrl } from "../../core/jira";

const bitBucketServerForm = new BitBucketServerForm();
const microsoftTeamsForm = new MicrosoftTeamsForm();

function authorizeOpsgenie(token: string, setter: (v: string) => any) {
  setter("");
  return SettingsStore.createAuthorization(AuthorizationProvider.OpsGenie, token);
}

function doDeleteAuthorization(auth: Authorization) {
  if (auth.installationId && auth.provider === AuthorizationProvider.GitHub) {
    window.location.href = `https://github.com/organizations/${auth.identifier}/settings/installations/${auth.installationId}`;
  } else {
    SettingsStore.deleteAuthorization(auth.id);
  }
}

function deleteAuthorization(name: string, auth: Authorization) {
  if (auth.installationId && auth.provider === AuthorizationProvider.GitHub) {
    ModalStore.confirmation.show(null, {
      message: (
        <div>
          <p>
            To remove this GitHub integration you will have to uninstall the Testery GitHub from
            your organization.
          </p>
          <p>
            To do that <b>you will have to be a GitHub admin in your organization.</b>
          </p>
          <p>
            Click the button below and we'll take you to the GitHub page where you can uninstall the
            app.
          </p>
          <Button
            color="red"
            href={`https://github.com/organizations/${auth.identifier}/settings/installations/${auth.installationId}`}
          >
            Remove Testery GitHub App
          </Button>
        </div>
      ),
      header: "Remove GitHub Integration",
      hideOk: true,
      size: "small",
    });
  } else {
    ModalStore.confirmation.show((okClicked: boolean) => okClicked && doDeleteAuthorization(auth), {
      message: `Are you sure you want to remove the ${name} integration?`,
      okText: "Remove",
      okColor: "red",
    });
  }
}

async function authorizeBitBucketServer() {
  bitBucketServerForm.submit(async (data) => {
    await SettingsStore.createAuthorization(
      AuthorizationProvider.BitBucketServer,
      data.token,
      data.url,
    );
    bitBucketServerForm.clearFormData();
  });
}

async function authorizeMicrosoftTeams() {
  microsoftTeamsForm.submit(async (data) => {
    await SettingsStore.createAuthorization(
      AuthorizationProvider.MicrosoftTeams,
      data.webhookUrl
    );
    microsoftTeamsForm.clearFormData();
  });
}

function doAuth(provider: string) {
  Storage.setAuthInfo(
    "/" + AppStore.selectedAccountName + "/settings/integrations",
    AuthReason.Integration,
  );
  repositoryAuthorize(provider);
}

export async function registerEmailAlert() {
  runInAction(() => {
    const form = new AlertForm();
    form.actionType = ActionType.SEND_EMAIL
    form.eventType = EventType.TESTRUN_ANALYSIS_COMPLETE
    form.destination = AppStore.user.email
    SettingsStore.saveAlert(form);
  })
}

export function authorizeSlack() {
  Storage.setAuthInfo(
    "/" + AppStore.selectedAccountName + "/settings/integrations",
    AuthReason.SlackIntegration,
  );
  window.location.href = getSlackAuthUrl();
}

export function authorizeJira() {
  Storage.setAuthInfo(
    "/" + AppStore.selectedAccountName + "/settings/integrations",
    AuthReason.JiraIntegration,
  );
  window.location.href = getJiraAuthUrl();
}

const IntegrationsTab = observer(() => {
  const [opsGenieKey, setOpsGenieKey] = useState("");
  const [apiKey, setApiKey] = useState(null);

  useInitialEffect(async () => {
    bitBucketServerForm.clearFormData();
    await SettingsStore.loadAuthorizations();
    SettingsStore.checkAuthsForOrganizationNeeded();
  });

  const loadApiKey = async () => {
    setApiKey(await SettingsStore.loadApiKey());
  };

  const updateApiKey = () => {
    ModalStore.confirmation.show(
      async (okClicked) => okClicked && setApiKey(await SettingsStore.updateApiKey()),
      {
        message: "Are you sure you want to update your API key? This will invalidate your old key!",
        okColor: "red",
        okText: "Update Key",
      },
    );
  };

  if (!SettingsStore.authorizationsLoaded)
    return (
      <div className="page">
        <div className="page-content">
          <div className="big-spinner">
            <div className="spinner-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );

  const { authorizations } = SettingsStore;
  const gitHubAuth = authorizations.find((a) => a.provider == AuthorizationProvider.GitHub);
  const slackAuth = authorizations.find((a) => a.provider == AuthorizationProvider.Slack);
  const microsoftTeamsAuth = authorizations.find((a) => a.provider == AuthorizationProvider.MicrosoftTeams);
  const bitBucketAuth = authorizations.find((a) => a.provider == AuthorizationProvider.BitBucket);
  const jiraAuth = authorizations.find((a) => a.provider == AuthorizationProvider.Jira);
  const bitBucketServerAuth = authorizations.find(
    (a) => a.provider == AuthorizationProvider.BitBucketServer,
  );
  const opsGenieAuth = authorizations.find((a) => a.provider == AuthorizationProvider.OpsGenie);
  const gitlabAuth = authorizations.find((a) => a.provider == AuthorizationProvider.Gitlab);
  return (
    <Tab.Pane className="integrations">
      {gitHubAuth ? (
        <div>
          <Button onClick={() => deleteAuthorization("GitHub", gitHubAuth)} color="red">
            <Icon name="minus circle" />
            Remove GitHub Integration
          </Button>
        </div>
      ) : (
        <div>
          <Button color="green" onClick={() => doAuth("github")}>
            <Icon name="check square" />
            Authorize GitHub Integration
          </Button>
        </div>
      )}
      <br />
      {gitlabAuth ? (
        <div>
          <Button onClick={() => deleteAuthorization("Gitlab", gitlabAuth)} color="red">
            <Icon name="minus circle" />
            Remove Gitlab Integration
          </Button>
        </div>
      ) : (
        <div>
          <Button color="green" onClick={() => doAuth("Gitlab")}>
            <Icon name="check square" />
            Authorize Gitlab Integration
          </Button>
        </div>
      )}
      <br />
      {bitBucketAuth ? (
        <div>
          <Button onClick={() => deleteAuthorization("BitBucket", bitBucketAuth)} color="red">
            <Icon name="minus circle" />
            Remove BitBucket Integration
          </Button>
        </div>
      ) : (
        <div>
          <Button color="green" onClick={() => doAuth("bitbucket")}>
            <Icon name="check square" />
            Authorize BitBucket Integration
          </Button>
        </div>
      )}
      <br />
      {bitBucketServerAuth ? (
        <div>
          <Button
            onClick={() => deleteAuthorization("BitBucketServer", bitBucketServerAuth)}
            color="red"
          >
            <Icon name="minus circle" />
            Remove BitBucket Server Integration
          </Button>
          <label>BitBucket Server URL: {bitBucketServerAuth.identifier}</label>
        </div>
      ) : (
        <div>
          <Button
            color="green"
            onClick={() => {
              if (bitBucketServerForm.showing) {
                authorizeBitBucketServer();
              } else {
                bitBucketServerForm.updateField("showing", true);
              }
            }}
            loading={bitBucketServerForm.isSubmitting}
          >
            <Icon name="check square" />
            {bitBucketServerForm.showing
              ? "Save BitBucket Server Integration"
              : "Authorize BitBucket Server Integration"}
          </Button>
          {bitBucketServerForm.showing && (
            <Form className="bit-bucket-server-form">
              <label>Personal Access Token</label>
              <Form.Input
                value={bitBucketServerForm.token}
                onChange={bitBucketServerForm.onChange("token")}
                error={bitBucketServerForm.getErrorText("token")}
              />
              <label>Server Url</label>
              <Form.Input
                value={bitBucketServerForm.url}
                onChange={bitBucketServerForm.onChange("url")}
                error={bitBucketServerForm.getErrorText("url")}
              />
            </Form>
          )}
        </div>
      )}
      <br />
      {microsoftTeamsAuth ? (
        <div>
          <Button
            onClick={() => deleteAuthorization("Teams", microsoftTeamsAuth)}
            color="red"
          >
            <Icon name="minus circle" />
            Remove Microsoft Teams Integration
          </Button>
        </div>
      ) : (
        <div>
          <Button
            color="green"
            onClick={() => {
              if (microsoftTeamsForm.showing) {
                authorizeMicrosoftTeams();
              } else {
                microsoftTeamsForm.updateField("showing", true);
              }
            }}
            loading={microsoftTeamsForm.isSubmitting}
          >
            <Icon name="check square" />
            {microsoftTeamsForm.showing
              ? "Save Microsoft Teams API Key"
              : "Authorize Microsoft Teams Integration"}
          </Button>
          {microsoftTeamsForm.showing && (
            <Form>
              <label>Incoming Webhook URL</label>
              <Form.Input
                value={microsoftTeamsForm.webhookUrl}
                onChange={microsoftTeamsForm.onChange("webhookUrl")}
                error={microsoftTeamsForm.getErrorText("webhookUrl")}
              />
            </Form>
          )}
        </div>
      )}
      <br />
      {slackAuth ? (
        <div>
          <Button onClick={() => deleteAuthorization("Slack", slackAuth)} color="red">
            <Icon name="minus circle" />
            Remove Slack Integration
          </Button>
        </div>
      ) : (
        <div>
          <Button color="green" onClick={authorizeSlack}>
            <Icon name="check square" />
            Authorize Slack Integration
          </Button>
        </div>
      )}
      <br />
      {jiraAuth ? (
        <div>
          <Button onClick={() => deleteAuthorization("Jira", jiraAuth)} color="red">
            <Icon name="minus circle" />
            Remove Jira Integration
          </Button>
        </div>
      ) : (
        <div>
          <Button color="green" onClick={() => authorizeJira()}>
            <Icon name="check square" />
            Authorize Jira Integration
          </Button>
        </div>
      )}
      <br />
      {opsGenieAuth ? (
        <div>
          <Button onClick={() => deleteAuthorization("OpsGenie", opsGenieAuth)} color="red">
            <Icon name="minus circle" />
            Remove OpsGenie Integration
          </Button>
        </div>
      ) : (
        <div>
          <label>OpsGenie API Key</label>
          <div className="ops-genie">
            <Input
              type="password"
              value={opsGenieKey}
              onChange={(e) => setOpsGenieKey(e.target.value)}
            />{" "}
            <Button color="green" onClick={() => authorizeOpsgenie(opsGenieKey, setOpsGenieKey)}>
              <Icon name="check square" />
              Authorize OpsGenie Integration
            </Button>
          </div>
        </div>
      )}
      <br /> <br />
      {apiKey ? (
        <div>
          API Token: {apiKey}
          <br />
          <Button onClick={updateApiKey} color="red">
            <Icon name="sync" />
            Update API Token
          </Button>
        </div>
      ) : (
        <div>
          <Button color="green" onClick={loadApiKey}>
            <Icon name="eye" inverted />
            Show API Token
          </Button>
        </div>
      )}
    </Tab.Pane>
  );
});

export default IntegrationsTab;
