import { isObject, orderBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Icon } from "semantic-ui-react";
import Spinner from "./spinner";

export interface HeaderColumn<T> {
  className?: string;
  content?: string | JSX.Element;
  hide?: boolean;
  sortKey?: string;
  sortTransform?: (v: T) => any;
}

interface Props<ObjectType> {
  className?: string;
  headerColumns: HeaderColumn<ObjectType>[];
  items: ObjectType[];
  renderRow: (o: ObjectType) => JSX.Element;
  defaultSortKey?: string;
  defaultDecending?: boolean;
  filter?: (o: ObjectType) => boolean;
  emptyMessage?: string | JSX.Element;
  loading?: boolean;
  loadingText?: string;
  title?: string;
}

const SortableTable = observer(function <ObjectType>({
  className,
  headerColumns,
  defaultSortKey,
  items,
  renderRow,
  defaultDecending,
  filter,
  emptyMessage,
  loading,
  loadingText,
  title,
}: Props<ObjectType>) {
  const [currentSort, setCurrentSort] = useState(
    headerColumns.find((h) => h.sortKey == defaultSortKey),
  );
  const [descending, setDecending] = useState(!!defaultDecending);

  if (loading) return <Spinner size={16} text={loadingText} />;

  if (!items) return null;

  const updateSort = (header: HeaderColumn<ObjectType>) => {
    if (!header.sortKey) return;

    if (header.sortKey == currentSort.sortKey) {
      setDecending(!descending);
    } else {
      setDecending(false);
      setCurrentSort(header);
    }
  };

  let builtColumns: JSX.Element[] = [];

  if (headerColumns && headerColumns.length > 0) {
    builtColumns = headerColumns
      .filter((h) => !h.hide)
      .map((h, i) => (
        <th
          key={i}
          onClick={() => updateSort(h)}
          className={`${h.className}${h.sortKey ? " clickable" : ""}`}
        >
          {h.content}
          {currentSort && currentSort.sortKey && h.sortKey == currentSort.sortKey && (
            <Icon name={descending ? "triangle down" : "triangle up"} />
          )}
        </th>
      ));
  }

  const sortedItems = orderBy(
    filter ? items.filter(filter) : items,
    [currentSort ? currentSort.sortTransform || currentSort.sortKey : null],
    [descending ? "desc" : "asc"],
  );

  if (sortedItems.length == 0) {
    return isObject(emptyMessage) ? emptyMessage : <h3>{emptyMessage || "No Items Found"}</h3>;
  }

  return (
    <div>
      {title && <h3>{title}</h3>}
      <table className={`table ${className}`}>
        <thead>
          <tr>{builtColumns}</tr>
        </thead>
        <tbody>{sortedItems.map((i) => renderRow(i))}</tbody>
      </table>
    </div>
  );
});

export default SortableTable;
