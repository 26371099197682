import ProjectForm from "./forms/project-form";
import Project, { TestFramework } from "./project";
import { Plan } from "./plan";
import { AccountPlan, FeaturePair } from "./account-plan";
import { FeatureName } from "./feature";
import { round } from "lodash";

export class Account {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  display: string;
  defectBaseURL: string;
  currentAccountPlan?: AccountPlan;
  currentPlan?: Plan;
  currentPlanFeatures?: Array<FeaturePair>;
  numberOfRunners: number;
  windowsRunners: number;
  windowsMonthlyPriceOverride: number;
  setupComplete: boolean;
  monthlyBilling: boolean;
  cardBrand: string;
  cardLastFour: string;
  cardExpireMonth: number;
  cardExpireYear: number;
  monthlyAiQuotaRemaining: number;
  monthlyTestRunAnalysisQuotaRemaining: number;
  legacyPlan: boolean;
  aiOptOut: boolean;

  maxRunners(project: Project | ProjectForm) {
    return project.testingFramework == TestFramework.NUnit && !project.usingDotnetCore
      ? this.windowsRunners
      : this.numberOfRunners;
  }

  maxRunnersByType(runnerType: string) {
    return runnerType == "WINDOWS"
      ? this.windowsRunners
      : this.numberOfRunners;
  }

  canGetAiSuggestions() {
    return this.monthlyAiQuotaRemaining != null && this.monthlyAiQuotaRemaining > 0;
  }

  canGetTestRunAnalysis() {
    return this.aiOptOut == false && this.monthlyTestRunAnalysisQuotaRemaining != null && this.monthlyTestRunAnalysisQuotaRemaining > 0;
  }

  hasFeature(featureName: FeatureName): boolean {
    let feature: FeaturePair;
    if (this.currentPlanFeatures)
      feature = this.currentPlanFeatures.find(featurePair => featurePair["first"] === featureName);
    return feature != undefined;
  }

  getFeature(featureName: FeatureName): FeaturePair {
    let feature: FeaturePair;
    if (this.currentPlanFeatures)
      feature = this.currentPlanFeatures.find(featurePair => featurePair["first"] === featureName);
    return feature;
  }

  getFeatureValue(featureName: FeatureName): number {
    let feature = this.getFeature(featureName);
    if (feature) {
      return feature["second"];
    }
  }

  monthlyTestRunAnalysisLimit(): number {
    let featureLimit = this.getFeatureValue(FeatureName.MONTHLY_AI_LIMIT)
    if (featureLimit) {
      return featureLimit;
    } else {
      return 10; // Protects Legacy Accounts until migration is done
    }
  }

  monthlyTestExecutionHoursLimit(): number {
    let featureLimit = this.getFeatureValue(FeatureName.MAX_TESTING_MINUTES)
    if (featureLimit) {
      return round((featureLimit / 60), 0);
    } else {
      return 5; // Protects Legacy Accounts until migration is done
    }
  }


  isLegacyOrTierTwoPlusPlan(): Boolean {
    return this.isLegacy() || (this.currentPlan && this.currentPlan.tier >=2);
  }

  isLegacyOrQualifiedTier(requiredTier: number): Boolean {
    return this.isLegacy() || (this.currentPlan && this.currentPlan.tier >= requiredTier);
  }

  isLegacy(): Boolean {
    return this.legacyPlan || (this.currentPlan && this.currentPlan.tier == 0);
  }
}
