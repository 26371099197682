import { observer } from "mobx-react-lite";
import Select from "./select";
import { TestDistributionAnalysis } from "models/analytics";


interface Props {
    analysisList: TestDistributionAnalysis[];
    selectedAnalysis: TestDistributionAnalysis;
    onChange: (v: TestDistributionAnalysis) => any;
  }


const TestDistributionAnalysisSelect = observer(({ analysisList, selectedAnalysis, onChange }: Props) => {

  return (
    <Select
        onChange={onChange}
        options={analysisList}
        valueField="id"
        labelField="label" value={selectedAnalysis}    />
  );
});

export default TestDistributionAnalysisSelect;
