import { useInitialEffect } from "core/react-utils";
import { EmployeeStore } from "stores";
import Page from "pages/page";
import "./employee.scss";
import { Button, Card, CardContent, CardGroup, CardHeader, Tab, TabProps } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import { Icons, IS_NOT_PROD } from "core/constants";
import TestRunsTab from "./test-runs-tab";
import PerformanceTab from "./performance-tab";
import { findIndex } from "lodash";
import DatetimeDisplay from "components/datetime-display";
import { numberWithCommas } from "core/utils";
import AgentsTab from "./agents-tab";
import JobsTab from "./jobs-tab";
import RolesTab from "./roles-tab";

const panes = [
  {
    menuItem: { key: "test-run-search", icon: Icons.testRuns, content: "Test Runs" },
    render: () => <TestRunsTab />,
  },
  {
    menuItem: { key: "performance", icon: "clock outline", content: "Performance" },
    render: () => <PerformanceTab />,
  },
  {
    menuItem: { key: "agents", icon: "sitemap", content: "Agents" },
    render: () => <AgentsTab />,
  },
  {
    menuItem: { key: "jobs", icon: "calendar check outline", content: "Scheduled Jobs" },
    render: () => <JobsTab />,
  },
  {
    menuItem: { key: "roles", icon: "users", content: "Roles" },
    render: () => <RolesTab />,
  },
];

const EmployeePage = observer(() => {
  const { tab } = useParams<{ tab: string }>();
  const history = useHistory();

  useInitialEffect(() => {
    if (!tab) {
      history.replace("employee/test-run-search");
    }
    EmployeeStore.init(tab);
  });

  const headerData = EmployeeStore.headerData;
  const account = EmployeeStore.getFilteredAccount();

  const navigate = (data: TabProps) => {
    const name = panes[data.activeIndex].menuItem.key;
    EmployeeStore.updateTab(name);
    history.push(name);
  };

  if (!EmployeeStore.initialized || !EmployeeStore.isEmployee) return null;

  const thisMonthCounts = EmployeeStore.getThisMonthAccountCounts();
  const lastMonthCounts = EmployeeStore.getLastMonthAccountCounts();

  const viewStripAccount = async (accountId: number) => {
    const customerId = await EmployeeStore.loadAccountCustomerId(accountId);
    const url = IS_NOT_PROD
      ? "https://dashboard.stripe.com/test/customers"
      : "https://dashboard.stripe.com/customers";
    window.open(`${url}/${customerId}`, "_blank");
  };

  return (
    <Page name="employee" title="Employee" titleSize="none">
      <CardGroup>
        <Card>
          <CardContent>
            <CardHeader>System Info</CardHeader>
            <hr />
            <div className="item">Accounts: {headerData.accounts}</div>
            <div className="item">Users: {headerData.users}</div>
            <br />
            <br />
            <div className="item">Last Hour API Performance</div>
            <hr />
            <div className="item">Requests: {headerData.lastHourPerformance.web.requests}</div>
            <div className="item">
              Average Time: {headerData.lastHourPerformance.web.averageTime} ms
            </div>
            <div className="item">
              Average DB Time: {headerData.lastHourPerformance.web.averageDbTime} ms
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <CardHeader>Test Runs</CardHeader>
            <hr />
            <div className="item">Running: {headerData.testRuns.running}</div>
            <div className="item">Submitted: {headerData.testRuns.submitted}</div>
            <div className="item">Queued: {headerData.testRuns.queued}</div>
            <br />
            <div className="item">Last Hour Message Performance</div>
            <hr />
            <div className="item">Messages: {headerData.lastHourPerformance.message.requests}</div>
            <div className="item">
              Average Time: {headerData.lastHourPerformance.message.averageTime} ms
            </div>
            <div className="item">
              Average DB Time: {headerData.lastHourPerformance.message.averageDbTime} ms
            </div>
          </CardContent>
        </Card>
        {account && (
          <Card>
            <CardContent>
              <CardHeader>{account.name}</CardHeader>
              <hr />
              <div className="item">
                Created: <DatetimeDisplay datetime={account.createdAt} />
              </div>
              <div className="item">Setup Complete: {account.setupComplete.toString()}</div>
              <div className="item">Max Linux Runners: {account.numberOfRunners}</div>
              <div className="item">
                Current Linux Runners: {EmployeeStore.accountData.runnerCounts.linux}
              </div>
              <div className="item">Max Windows Runners: {account.windowsRunners}</div>
              <div className="item">
                Current Windows Runners: {EmployeeStore.accountData.runnerCounts.windows}
              </div>
              {account.cardBrand && (
                <Button color="green" inverted onClick={() => viewStripAccount(account.id)}>
                  View Stripe
                </Button>
              )}
            </CardContent>
          </Card>
        )}
        {account && (
          <Card className="test-run-info">
            <CardContent>
              <CardHeader>Test Run Info</CardHeader>
              <hr />
              <CardGroup itemsPerRow={2}>
                <Card>
                  <CardContent>
                    <h4>This Month</h4>
                    <div className="item">
                      Test Runs: {numberWithCommas(thisMonthCounts.testRuns)}
                    </div>
                    <div className="item">
                      Total Tests: {numberWithCommas(thisMonthCounts.totalTests)}
                    </div>
                    <div className="item">
                      Total Testing Minutes: {numberWithCommas(thisMonthCounts.totalTestingMinutes)}
                    </div>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <h4>Last Month</h4>
                    <div className="item">
                      Test Runs: {numberWithCommas(lastMonthCounts.testRuns)}
                    </div>
                    <div className="item">
                      Total Tests: {numberWithCommas(lastMonthCounts.totalTests)}
                    </div>
                    <div className="item">
                      Total Testing Minutes: {numberWithCommas(lastMonthCounts.totalTestingMinutes)}
                    </div>
                  </CardContent>
                </Card>
              </CardGroup>
            </CardContent>
          </Card>
        )}
        <div>
          <br />
          <Button color="green" onClick={EmployeeStore.refreshHeader}>
            Refresh Data
          </Button>
        </div>
      </CardGroup>
      <Tab
        onTabChange={(_, data) => navigate(data)}
        activeIndex={findIndex(panes, (i) => i.menuItem.key == tab)}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </Page>
  );
});

export default EmployeePage;
