import Page from "pages/page";
import ProjectSettings from "components/form/project-settings";

const ProjectEditPage = () => (
  <Page name="project-edit" title="New Project">
    <ProjectSettings />
  </Page>
);

export default ProjectEditPage;
