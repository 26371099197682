import DatetimeDisplay from "components/datetime-display";
import EnvironmentSelect from "components/form/environment-select";
import RepoLink from "components/repo-link";
import SortableTable from "components/sortable-table";
import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import Project, { Deploy } from "models/project";
import { useState } from "react";
import { ProjectStore } from "stores";

interface Props {
  project: Project;
}

const DeploymentsTab = observer(({ project }: Props) => {
  const [deploys, setDeploys] = useState(null as Deploy[]);
  const [environment, setEnvironment] = useState(null as number);

  useInitialEffect(async () => {
    setDeploys(await ProjectStore.loadDeploys(project.id));
  });

  const filteredDeploys =
    deploys &&
    deploys.filter(
      (d) => !d.environment?.archived && (!environment || d.environmentId == environment),
    );

  const hasCommit = deploys && filteredDeploys.some((d) => d.commit);
  const hasBuild = deploys && filteredDeploys.some((d) => d.buildName != "");
  const hasBranch = deploys && filteredDeploys.some((d) => d.branch);

  let headerColumns = [
    {
      className: "date",
      content: "Date",
      sortKey: "updatedAt",
    },
    {
      className: "environment",
      content: "Environment",
      sortKey: "environment.name",
    },
    {
      className: "build",
      content: "Build",
      sortKey: "buildName",
    },
    {
      className: "commit",
      content: "Git Ref",
      sortKey: "commit",
    },
    {
      className: "branch",
      content: "Branch",
      sortKey: "branch",
    },
  ];

  if (!hasCommit) {
    headerColumns = headerColumns.filter((c) => c.sortKey != "commit");
  }

  if (!hasBuild) {
    headerColumns = headerColumns.filter((c) => c.sortKey != "buildName");
  }

  if (!hasBranch) {
    headerColumns = headerColumns.filter((c) => c.sortKey != "branch");
  }

  return (
    <div className="deployments-tab">
      <EnvironmentSelect
        placeholder="Any Environment"
        value={environment}
        onChange={setEnvironment}
      />
      <SortableTable
        className="deploys"
        loading={deploys == null}
        headerColumns={headerColumns}
        items={filteredDeploys}
        defaultSortKey="updatedAt"
        defaultDecending={true}
        renderRow={(d) => (
          <tr key={d.id}>
            <td className="date">
              <DatetimeDisplay datetime={d.updatedAt} />
            </td>
            <td className="environment">{d.environment?.name}</td>
            {hasBuild && <td className="build">{d.buildName}</td>}
            {hasCommit && (
              <td className="commit">
                <RepoLink
                  newWindow={true}
                  hideProvierIcon={true}
                  project={project}
                  gitRef={d.commit}
                />
              </td>
            )}
            {hasBranch && <td className="branch">{d.branch}</td>}
          </tr>
        )}
      />
    </div>
  );
});

export default DeploymentsTab;
