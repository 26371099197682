import API from "core/api";
import { action, computed, observable, runInAction } from "mobx";
import TestPlanForm from "models/forms/test-plan-form";
import { ItemStoreList } from "./item-store";
import { TestPlan, TestPlanRun, TestPlanStage, TestPlanSuite } from "models/test-plan";
import TestPlanStageForm from "models/forms/test-plan-stage-form";
import TestPlanSuiteForm from "models/forms/test-plan-suite-form";
import { flatMap, orderBy } from "lodash";
import { mergeEntityLists } from "core/utils";

export class TestPlanStoreClass extends ItemStoreList<TestPlan> {
  @observable openTestPlanRuns: Record<number, boolean> = {};
  @observable openTestPlanRunStages: Record<number, boolean> = {};
  @observable testPlanRuns: TestPlanRun[];
  @observable lastTestPlanRunsPageLoaded = false;
  @observable testPlanRunsPage = 0;

  @action
  selectTestPlan(id: number) {
    this.testPlanRuns = null;
    this.lastTestPlanRunsPageLoaded = false;
    this.testPlanRunsPage = 0;
    return super.select(id);
  }

  async doLoadAll() {
    const result = await API.getList("/test-plans", TestPlan);
    return result.data;
  }

  async load(id: number) {
    const result = await API.get(`/test-plans/${id}`, TestPlan);
    return this.update(result.data);
  }

  @computed
  get active() {
    return this.items.filter((s) => !s.archived);
  }

  async saveTestPlan(data: TestPlanForm) {
    return data.submit(async (data) => {
      const [action, url] = data.id
        ? [API.patch, `/test-plans/${data.id}`]
        : [API.post, `/test-plans`];
      const result = await action(url, data, TestPlan);
      return this.update(result.data);
    });
  }

  @action
  async deleteTestPlan(testPlanId: number) {
    const testPlan = this.find(testPlanId);
    testPlan.archived = true;
    API.delete(`/test-plans/${testPlanId}`);
    return testPlan;
  }

  async saveTestPlanStage(form: TestPlanStageForm) {
    return form.submit(async (data) => {
      const testPlan = this.find(form.testPlanId);
      const newItem = !data.id;

      const [action, url] = newItem
        ? [API.post, `/test-plans/${form.testPlanId}/stages`]
        : [API.patch, `/test-plans/${form.testPlanId}/stages/${data.id}`];

      const result = await action(url, data, TestPlanStage);

      return runInAction(() => {
        if (newItem) {
          testPlan.stages.push(result.data);
        } else {
          const index = testPlan.stages.findIndex((s) => s.id == data.id);
          const existing = testPlan.stages[index];
          testPlan.stages[index] = result.data;
          testPlan.stages[index].suites = existing.suites;
        }
      });
    });
  }

  findTestPlanStage(stageId: number) {
    return flatMap(this.items, (t) => t.stages).find((s) => s.id == stageId);
  }

  @action
  async saveTestPlanStageOrder(stageId: number, order: number) {
    const stage = this.findTestPlanStage(stageId);
    const testPlan = this.find(stage.testPlanId);
    const stages = orderBy(
      testPlan.activeStages.filter((s) => s.id != stageId),
      "order",
    );
    stages.splice(order, 0, stage);

    stages.forEach((stage, index) => {
      if (stage.order != index) {
        runInAction(() => (stage.order = index));
        API.patch(`/test-plans/${testPlan.id}/stages/${stage.id}`, { order: index });
      }
    });
  }

  @action
  async deleteTestPlanStage(stageId: number) {
    const stage = this.findTestPlanStage(stageId);
    stage.archived = true;
    API.delete(`/test-plans/${stage.testPlanId}/stages/${stageId}`);
  }

  async saveTestPlanSuite(form: TestPlanSuiteForm) {
    return form.submit(async (data) => {
      const stage = this.findTestPlanStage(form.testPlanStageId);
      const newItem = !data.id;

      const [action, url] = newItem
        ? [API.post, `/test-plans/${stage.testPlanId}/stages/${stage.id}/suites`]
        : [API.patch, `/test-plans/${stage.testPlanId}/stages/${stage.id}/suites/${data.id}`];

      const result = await action(url, data, TestPlanSuite);

      return runInAction(() => {
        if (newItem) {
          stage.suites.push(result.data);
        } else {
          const index = stage.suites.findIndex((s) => s.id == data.id);
          stage.suites[index] = result.data;
        }
      });
    });
  }

  findTestPlanSuiteByStageAndId(stageId: number, testPlanSuiteId: number): TestPlanSuite | null {
    return this.findTestPlanStage(stageId)?.suites.find((s) => s.id == testPlanSuiteId);
  }

  findTestPlanSuiteById(testPlanSuiteId: number): TestPlanSuite {
    let suite = null;
    flatMap(this.items, (testPlan) => testPlan.stages).forEach(testPlanStage => {
      const potentialSuite = testPlanStage.suites.find(testPlanSuite => testPlanSuite.id == testPlanSuiteId);
      if (potentialSuite)
        suite = potentialSuite;
    });

    if (suite)
      return suite;

    throw Error("No suite found");
  }

  @action
  async saveTestPlanSuiteOrder(
    stageId: number,
    testPlanSuiteId: number,
    order: number,
    newStageId: number,
  ) {
    let stage = this.findTestPlanStage(stageId);
    const updateSuite = stage.suites.find((s) => s.id == testPlanSuiteId);

    if (stageId != newStageId) {
      stage.suites = stage.suites.filter((s) => s.id != testPlanSuiteId);
      updateSuite.testPlanStageId = newStageId;
      updateSuite.order = order;
      API.patch(`/test-plans/${stage.testPlanId}/stages/${stageId}/suites/${updateSuite.id}`, {
        order,
        testPlanStageId: newStageId,
      });
      stage = this.findTestPlanStage(newStageId);
      stage.suites.push(updateSuite);
    }

    const suites = stage.activeSuites.filter((s) => s.id != testPlanSuiteId);
    suites.splice(order, 0, updateSuite);

    suites.forEach((suite, index) => {
      if (suite.order != index) {
        runInAction(() => (suite.order = index));
        API.patch(`/test-plans/${stage.testPlanId}/stages/${stageId}/suites/${suite.id}`, {
          order: index,
        });
      }
    });
  }

  @action
  async deleteTestPlanSuite(stageId: number, testPlanSuiteId: number) {
    const stage = this.findTestPlanStage(stageId);
    const suite = stage.suites.find((s) => s.id == testPlanSuiteId);
    suite.archived = true;
    API.delete(`/test-plans/${stage.testPlanId}/stages/${stageId}/suites/${testPlanSuiteId}`);
  }

  @action
  async runTestPlan(testPlanId: number) {
    await API.post(`/test-plans/${testPlanId}/runs`, {});
    return this.load(testPlanId);
  }

  @action
  async stopRun(testPlanId: number, runId: number) {
    await API.post(`/test-plans/${testPlanId}/runs/${runId}/cancel`, {});
    return this.load(testPlanId);
  }

  @action.bound
  async loadTestPlanRuns(page: number, testPlanId: number) {
    const result = await API.getList(
      `/test-plans/${testPlanId}/runs?page=${page}&limit=${10}`,
      TestPlanRun,
    );
    return runInAction(() => {
      this.lastTestPlanRunsPageLoaded = result.data.length < 10;
      return (this.testPlanRuns = mergeEntityLists(this.testPlanRuns || [], result.data));
    });
  }

  @action.bound
  loadNextTestPlanRunsPage(testPlanId: number) {
    this.testPlanRunsPage++;
    this.loadTestPlanRuns(this.testPlanRunsPage, testPlanId);
  }

  @action
  toggleTestPlanRunOpen(testPlanRunId: number) {
    this.openTestPlanRuns[testPlanRunId] = !this.openTestPlanRuns[testPlanRunId];
  }

  getTestPlanRunStageKey(testPlanRunId: number, stageId: number) {
    return `${testPlanRunId}-${stageId}`;
  }

  @action
  toggleTestPlanRunStageOpen(testPlanRunStageId: number) {
    this.openTestPlanRunStages[testPlanRunStageId] =
      !this.openTestPlanRunStages[testPlanRunStageId];
  }
}
