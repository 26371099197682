import API from "core/api";
import { action, observable, runInAction } from "mobx";
import { Insight, InsightResponse } from "../models/insight";

export class InsightStoreClass {
  @observable unreadInsights: Array<Insight>;
  @observable readInsights: Array<Insight>;
  @observable insightsLoaded = false;

  @action
  async clear() {
    this.readInsights = new Array<Insight>();
    this.unreadInsights = new Array<Insight>();
    this.insightsLoaded = false;
  }

  @action.bound
  async loadInsights() {
    const result = await API.get("/insight", InsightResponse);

    return runInAction(() => {
      this.insightsLoaded = true;
      this.readInsights = result.data.read;
      this.unreadInsights = result.data.unread;
      return [this.readInsights, this.unreadInsights];
    });
  }

  @action.bound
  async ensureLoaded() {
    if (this.insightsLoaded)
      return;

    await this.loadInsights();
  }

  @action.bound
  async markRead(insightId: number) {
    await API.put("/insight/mark", { insightId: insightId, mark: "read" });
    await this.loadInsights();
  }

  @action.bound
  async markUnread(insightId: number) {
    await API.put("/insight/mark", { insightId: insightId, mark: "unread" });
    await this.loadInsights();
  }
}
