import { observer } from "mobx-react-lite";
import TestSuiteForm from "models/forms/test-suite-form";
import { Form, Grid } from "semantic-ui-react";
import TestSuiteTypeSelect from "./test-suite-type-select";

interface Props {
  form: TestSuiteForm;
}

const TestSuiteInfo = observer(({ form }: Props) => (
  <>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <Form.Input
            label="Test Suite Name"
            value={form.name}
            onChange={form.onChange("name")}
            error={form.getErrorText("name")}
          />
          <TestSuiteTypeSelect
            label="Test Type"
            value={form.type}
            onChange={form.onChange("type")}
            error={form.getErrorText("type")}
          />
        </Grid.Column>
        <Grid.Column>
          <Form.TextArea
            label="Description"
            value={form.description}
            onChange={form.onChange("description")}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
));

export default TestSuiteInfo;
