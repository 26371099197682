import Form from "./form";
import { observable, action } from "mobx";
import { lengthAtLeast } from "core/validation";

export default class UpdatePasswordForm extends Form {
  @observable password: string;
  @observable passwordRepeat: string;

  validators = {
    password: { validator: lengthAtLeast(8), message: "Password must be at least 8 characters" },
    passwordRepeat: {
      validator: (value: string) => {
        return this.password == value;
      },
      message: "Passwords must match",
    },
  };

  ignoreOnPostFields = ["passwordRepeat"];

  @action.bound
  setDefaults() {
    this.password = "";
    this.passwordRepeat = "";
  }
}
