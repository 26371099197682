import TestPlanSettings from "components/form/test-plan-settings";
import { TestPlan } from "models/test-plan";

interface Props {
  testPlan: TestPlan;
}

const SettingsTab = ({ testPlan }: Props) => (
  <div className="settings-tab">
    <TestPlanSettings testPlan={testPlan} />
  </div>
);

export default SettingsTab;
