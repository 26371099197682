import { setPageInfo, useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import { Button, Grid, Icon, Tab } from "semantic-ui-react";
import { Counts, TestRun, TestRunAnalysisRootCause, TestRunTestTrackedIssue } from "../../models/test-run";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { AppStore, ModalStore, SettingsStore, TestRunStore, TrackedIssueStore } from "stores";
import { runInAction } from "mobx";
import Spinner from "components/spinner";
import "./analysis-tab.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useLocation } from "react-router-dom";
import { organizationUrl } from "core/utils";
import { AuthorizationProvider } from "../../models/settings";
import JiraIssueForm from "../../models/forms/jira-issue-form";

interface Props {
  testRun: TestRun;
  counts: Counts;
  running: boolean;
}

const AnalysisTab = observer(({ testRun }: Props) => {
  const { hash } = useLocation();
  setPageInfo("testResult", "Troubleshooting");
  const [text, setText] = useState("");
  const [testRunAnalysisRootCauses, setTestRunAnalysisRootCauses] = useState<TestRunAnalysisRootCause[]>([]);
  const [testRunTestsTrackedIssues, setTestRunTestsTrackedIssues] = useState<Map<string, TestRunTestTrackedIssue>>(new Map<string, TestRunTestTrackedIssue>());
  const [loading, setLoading] = useState(false);

  useInitialEffect(async () => {
    if (AppStore.account.aiOptOut == false) {
      const result = await TestRunStore.getTestRunAnalysis(testRun.id);
      if (result && result.status) {
        if (result.status == "COMPLETE") {
          runInAction(() => {
            setText(result.summary);
            setTestRunAnalysisRootCauses(result.testRunAnalysisRootCauses);
            setLoading(false);
          });
        } else {
          setLoading(true);
          let pollDelay = 5 * 1000; // 5s
          setTimeout(() => refreshAnalysis(testRun.id), pollDelay);
        }
      }
    } else {
      setLoading(false);
    }
  });

  useEffect(() => {
    loadJiraIssues();
    const element = document.getElementById(hash.replace("#", ""));
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [testRunAnalysisRootCauses]);

  async function loadJiraIssues() {
    const updatedJiraIssues = new Map([...testRunTestsTrackedIssues]);
    let i = 0;
    testRunAnalysisRootCauses.forEach(rootCause => {
      TrackedIssueStore.loadByRootCauseId(rootCause.id)
        .then(trackedIssues => {
          i++;
          trackedIssues.forEach(trackedIssue => updatedJiraIssues.set(trackedIssue.keyForAnalysisTab, trackedIssue));
        })
        .finally(() => {
          if (testRunAnalysisRootCauses.length == i)
            setTestRunTestsTrackedIssues(updatedJiraIssues);
        });
    });
  }

  async function getSuggestion() {
    setLoading(true);
    await TestRunStore.createTestRunAnalysis(testRun.id);
    runInAction(() => {
      refreshAnalysis(testRun.id);
    });
  }

  async function refreshAnalysis(testRunId: number) {
    const result = await TestRunStore.getTestRunAnalysis(testRunId);
    if (result.status == "COMPLETE") {
      runInAction(() => {
        setText(result.summary);
        setTestRunAnalysisRootCauses(result.testRunAnalysisRootCauses);
        TestRunStore.loadTestRunTests(testRunId, false, true);
        setLoading(false);
      });
    } else {
      let pollDelay = 5 * 1000; // 5s
      setTimeout(() => refreshAnalysis(testRunId), pollDelay);
    }
  }

  const jiraAuth = SettingsStore.authorizations.find(auth => auth.provider == AuthorizationProvider.Jira);

  return (
    <Tab.Pane>
      <div className="analysis-tab">
        <>
          <Grid celled>
            <Grid.Row>
              <Grid.Column width={3} only="computer">
                <CardMedia
                  sx={{ height: 150, width: 160, margin: "auto" }}
                  image="/img/tai-bot.png"
                  title="Tai"
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={13} className="analysisColumn"
                           style={{ textAlign: "left" }}>
                {text && text.length > 0 && (
                  <CardContent>
                    <h2>Test Run Analysis is complete. {text}</h2>
                    {testRunAnalysisRootCauses.map((rootCause, index) => (
                      <div key={"root-cause-" + rootCause.id.toString()} className="root-cause">
                        <div key={"summary-" + rootCause.id.toString()}>
                          <h3>
                            Root Cause {index + 1} - <Link
                            to={organizationUrl(`test-runs/${testRun.id}?rootCauseId=${rootCause.id}`)}> View {rootCause.affectedTestRunTestAnalysis.length} affected
                            Tests</Link>
                          </h3>
                          <p className="rootCauseDescription">
                            <strong>{rootCause.name}</strong> - {rootCause.description}</p>
                          <div style={{ marginBottom: "10px", marginLeft: "40px" }}>
                            {jiraAuth && Array.from(testRunTestsTrackedIssues)
                              .filter(([_id, trackedIssue]) => trackedIssue.testRunAnalysisRootCauseId === rootCause.id)
                              .map(([_id, trackedIssue]) => {
                                  return <a key={trackedIssue.id} style={{ marginRight: "5px" }}
                                            href={`${jiraAuth.baseUrl}/browse/${trackedIssue.issueKey}`}
                                            target={"_blank"}>
                                    {trackedIssue.issueKey} <Icon name={"external alternate"} />
                                  </a>;
                                },
                              )}
                          </div>
                          {jiraAuth && (
                            <Button style={{ marginLeft: "40px" }} positive={true} onClick={async () => {
                              const jiraIssueForm = new JiraIssueForm();
                              jiraIssueForm.updateField("testRunId", testRun.id);
                              jiraIssueForm.updateField("rootCauseId", rootCause.id);
                              jiraIssueForm.updateField("testRunTestIds", rootCause.affectedTestRunTestAnalysis.map(t => t.testRunTestId));

                              let description = "Affected Tests";
                              rootCause.affectedTestRunTestAnalysis.forEach(t => description += `\n${window.location.origin + organizationUrl(`test-runs/${testRun.id}/tests/${t.testRunTestId}`)}`);
                              description += "\n\nSuggested fixes";
                              rootCause.affectedTestRunTestAnalysis.forEach(t => JSON.parse(t.analysisText)["suggestions"].forEach(a => description += "\n" + a["text"]));

                              jiraIssueForm.updateField("summary", `Fix ${rootCause.name}`);
                              jiraIssueForm.updateField("description", description);

                              ModalStore.createJiraModal.show(async () => await loadJiraIssues(), jiraIssueForm);
                            }}>Create Jira Issue</Button>
                          )}
                        </div>
                        <br />
                        <Accordion key={"accordion" + rootCause.id}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={"panel-" + rootCause.id + "-content"}
                            id={"root-cause-" + rootCause.id}
                            key={"accordionSummary-" + rootCause.id}
                          >
                            <div className="test-name" key={"name-" + rootCause.id}>Troubleshooting Advice</div>
                          </AccordionSummary>
                          <AccordionDetails key={"accordionDetails-" + rootCause.id}>
                            <p style={{ textAlign: "left", whiteSpace: "pre-line" }}
                               dangerouslySetInnerHTML={{ __html: TestRunAnalysisRootCause.formatAnalysisText(rootCause) }}></p>

                          </AccordionDetails>
                        </Accordion>
                      </div>
                    ))}
                  </CardContent>
                )}
                {text.length == 0 && (
                <>
                  {AppStore.account.aiOptOut && (
                    <>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          I'd love to help you, your organization has opted out of Testery AI features. Contact Testery sales to discuss enabling this on your plan.
                        </Typography>
                      </CardContent>
                    </>
                  )}
                  {AppStore.account.aiOptOut == false && testRun.failCount == null && (
                    <>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          I'd love to help you, but this test run doesn't have any failures.
                        </Typography>
                      </CardContent>
                    </>
                  )}
                  {AppStore.account.aiOptOut == false && testRun.failCount > 0 && !AppStore.account.canGetTestRunAnalysis() && (
                    <>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          You've used all {AppStore.account.monthlyTestRunAnalysisLimit()} of the monthly Test Run
                          Analyses on your plan.
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <a href="https://testery.com/contact" target="_blank">Contact Testery sales</a> to discuss
                          adding more to your plan.
                        </Typography>
                      </CardContent>
                    </>
                  )}
                  {AppStore.account.aiOptOut == false && testRun.failCount > 0 && AppStore.account.canGetTestRunAnalysis() && (
                    <>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Let Testery help you get back to green.
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Need a little help? Tai uses cutting edge AI to analyze your test run, find the root causes, and
                          suggest ways to fix them, while keeping your team focused on your product.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button onClick={getSuggestion}>
                          {loading && (
                            <><Spinner size={16} text="Analyzing Your Test Run" /></>
                          )}
                          {!loading && (
                            <><Icon name="chat" />Analyze this Test Run</>
                          )}
                        </Button>
                      </CardActions>
                    </>
                  )}
                </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      </div>
    </Tab.Pane>
  );
});

export default AnalysisTab;
