import API from "core/api";
import { action, computed, observable, runInAction } from "mobx";
import { TestRun } from "models/test-run";
import { SettingsStoreClass } from "./settings-store";
import { formatForMillisOffset } from "../core/date-utils";

export class DashboardStoreClass {
  @observable testRuns: Map<number, TestRun[]>;
  @observable testRunsLoaded = false;
  @observable selectedDate: Date = null;
  @observable selectedProjectId: number = null;
  @observable selectedBranch: string = null;
  @observable selectedBuild: string = null;
  private readonly SettingsStore: SettingsStoreClass;

  constructor(SettingsStore: SettingsStoreClass) {
    this.SettingsStore = SettingsStore;
  }

  @action
  async clear() {
    this.testRuns = new Map<number, TestRun[]>();
    this.testRunsLoaded = false;
    this.selectedDate = null;
    this.selectedProjectId = null;
    this.selectedBuild = null;
    this.selectedBranch = null;
  }

  @action.bound
  updateSelectedDate(date: Date) {
    this.selectedDate = date;
    this.loadLatestForEnvironments();
  }

  @action.bound
  updateSelectedProject(projectId: number) {
    this.selectedProjectId = projectId;
    this.selectedBranch = null;
    this.selectedBuild = null;
    this.loadLatestForEnvironments();
  }

  @action.bound
  updateSelectedBranch(branch: string) {
    this.selectedBranch = branch;
    this.loadLatestForEnvironments();
  }

  @action.bound
  updateSelectedBuild(build: string) {
    this.selectedBuild = build;
    this.loadLatestForEnvironments();
  }

  @computed
  get noStageEnvironmentHasTestRun() {
    return !!this.SettingsStore.activeEnvironments.find(
      (e) => !e.pipelineStageId && (this.testRuns[e.id] || []).length > 0,
    );
  }

  async loadLatestForEnvironments() {
    await this.SettingsStore.ensureEnvironments();

    var url = "/test-runs/recent-for-environments?filter=true";
    if (this.selectedProjectId) url += `&projectId=${this.selectedProjectId}`;
    if (this.selectedBranch) url += `&branch=${this.selectedBranch}`;
    if (this.selectedDate) url += `&date=${formatForMillisOffset(this.selectedDate)}`;
    if (this.selectedBuild) url += `&build=${this.selectedBuild}`;

    const result = await API.getList(url, TestRun);
    const testRuns = result.data;
    const data = {} as Map<number, TestRun[]>;

    this.SettingsStore.activeEnvironments.forEach((e) => {
      data[e.id] = testRuns.filter((t) => t.environmentId == e.id && !t.project?.archived);
    });

    return runInAction(() => {
      this.testRunsLoaded = true;
      console.log("setting data")
      console.log(data);
      return (this.testRuns = data);
    });
  }
}
