import Select from "components/form/select";
import { showNotificationSettingsIfNeeded } from "components/modals/notification-settings";
import { displayPieChart } from "core/charts";
import { organizationUrl, substringAfterLast } from "core/utils";
import { observer } from "mobx-react-lite";
import { Counts, getCounts, StatusColors, TestCounts, TestRun, TestRunStatus, TestStatus } from "models/test-run";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Button, Card, Icon, Label, Menu } from "semantic-ui-react";
import { AppStore, ProjectStore, TestRunStore, TriggerStore } from "stores";
import AssignReviewUserSelect from "../../components/form/assign-review-user-select";
import { ReviewType } from "../../models/review-data";
import "./test-run-overview.scss";
import DatetimeDisplay from "../../components/datetime-display";
import RepoLink from "../../components/repo-link";
import { TestRunType } from "../../models/settings";
import Project from "../../models/project";
import EnvironmentLink from "../../components/environment-link";

interface Props {
  testRun: TestRun;
  counts: Counts;
  running: boolean;
  refresh: (testRunId: number) => any;
}

const TestRunOverview = observer(({ testRun, counts, running, refresh }: Props) => {
  const history = useHistory();
  const [testCounts, setTestCounts] = useState<TestCounts>(new TestCounts());

  useEffect(() => {
    getCounts(testRun).then(count => {
      setTestCounts(count);
      testRun &&
      displayPieChart("resultsChart", [
        { value: count.passCount, label: "Passing", color: StatusColors[TestStatus.PASS] },
        { value: count.failCount, label: "Failing", color: StatusColors[TestStatus.FAIL] },
        { value: count.runningCount, label: "Running", color: StatusColors[TestStatus.RUNNING] },
        { value: count.pendingCount, label: "Pending", color: StatusColors[TestStatus.PENDING] },
      ]);
    });
  }, [counts.pass, counts.fail, counts.totalNotPassFile]);

  const [selectedStatus, setSelectedStatus] = useState(testRun && testRun.status);

  const rerunTest = async () => {
    const newTestRun = await TestRunStore.rerunTestRun(testRun);
    const lastPathSection = substringAfterLast(window.location.href, "/");
    if (isNaN(Number(lastPathSection)))
      history.push(`../${newTestRun.id}`);
    else
      history.push(newTestRun.id.toString());
    refresh(newTestRun.id);
  };

  const followers = TestRunStore.getFollowers(testRun.id);
  const isFollowing = TestRunStore.currentUserIsFollowingTestRun(testRun.id);

  const doToggle = () => TestRunStore.toggleFollow(testRun.id);

  const toggleFollow = () => {
    if (isFollowing || !showNotificationSettingsIfNeeded(doToggle)) {
      doToggle();
    }
  };

  const project = ProjectStore.find(testRun.projectId) || new Project();
  const triggerProject = ProjectStore.find(testRun.deploy?.projectId);
  const trigger = testRun.triggeredTestRunId && TriggerStore.find(testRun.triggeredTestRunId);

  return (
    <div className="overview-tab">
      <div className="followers">
        <Menu compact>
          <Menu.Item onClick={toggleFollow}>
            <Icon name="eye" /> {isFollowing ? "Unfollow" : "Follow"} Test Run
            {followers.length > 0 && (
              <Label color="green" floating prompt>
                {followers.length}
              </Label>
            )}
          </Menu.Item>
        </Menu>
      </div>
      <div className="summary">
        <div className="left">
          <Card className="counts">
            <Card.Content>
              <Card.Header>Overview</Card.Header>
              <div className="value chart">
                <div className={`status-text ${testRun.status.toLowerCase()}`}>{testRun.statusDisplay}</div>
                {testRun.statusInfo && testRun.statusInfo.includes("<testerylink>") ?
                  <Link className={"status-info"}
                        onClick={(e) => e.stopPropagation()}
                        target="_blank" rel="noopener noreferrer"
                        to={{ pathname: testRun.statusInfo.substring(testRun.statusInfo.indexOf("<testerylink>") + "<testerylink>".length, testRun.statusInfo.lastIndexOf("</testerylink>")) }}>
                    <div
                      dangerouslySetInnerHTML={{ __html: testRun.statusInfo.substring(testRun.statusInfo.indexOf("<testerytext>") + "<testerytext>".length, testRun.statusInfo.lastIndexOf("</testerytext>")) }} />
                  </Link>
                  :
                  <div className="info" style={{
                    fontSize: ".7em",
                    whiteSpace: "initial",
                    textAlign: "center",
                  }}>{testRun.statusInfo}</div>
                }
                <div className="report-summary-charts">
                  <canvas id="resultsChart" className="report-summary-chart"></canvas>
                </div>
              </div>
              <div className="value totals">
                <table>
                  <tbody>
                  <tr className="total">
                    <td className="number">{testCounts.totalCount}</td>
                    <td>Tests</td>
                  </tr>
                  <tr>
                    <td className="number">{testCounts.passCount}</td>
                    <td>Passing</td>
                  </tr>
                  <tr>
                    <td className="number">{testCounts.failCount}</td>
                    <td>Failing</td>
                  </tr>
                  {testCounts.pendingCount > 0 && (
                    <tr>
                      <td className="number">{testCounts.pendingCount}</td>
                      <td>Pending</td>
                    </tr>
                  )}
                  {testCounts.runningCount > 0 && (
                    <tr>
                      <td className="number">{testCounts.runningCount}</td>
                      <td>Running</td>
                    </tr>
                  )}
                  {testCounts.notRunCount > 0 && (
                    <tr>
                      <td className="number">{testCounts.notRunCount}</td>
                      <td>Not Run</td>
                    </tr>
                  )}
                  {testCounts.ignoredCount > 0 && (
                    <tr>
                      <td className="number">{testCounts.ignoredCount}</td>
                      <td>Ignored</td>
                    </tr>
                  )}
                  {counts.flaky > 0 && (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <hr />
                        </td>
                      </tr>
                      <tr>
                        <td className="number">{counts.flaky}</td>
                        <td>Flaky</td>
                      </tr>
                    </>
                  )}
                  </tbody>
                </table>
              </div>
              <div className="value status-update">
                <Select
                  className="status"
                  value={selectedStatus}
                  options={[TestRunStatus.PASS, TestRunStatus.FAIL, TestRunStatus.CANCELED]}
                  onChange={(s) => setSelectedStatus(s)}
                />
                <Button
                  color="green"
                  loading={TestRunStore.changingStatus}
                  onClick={() => selectedStatus && TestRunStore.updateStatus(testRun.id, selectedStatus)}
                >
                  <Icon name="edit" />
                  Change
                </Button>
                <div style={{ margin: "5px 0" }}>
                  <AssignReviewUserSelect
                    reviewType={ReviewType.TEST_RUN}
                    testRunId={testRun.id}
                    testRunReviewerUserId={testRun.reviewerUserId}
                    testRunTestId={null}
                    testRunTestReviewerUserId={null}
                  />
                  {/*<ReviewStatusSelect*/}
                  {/*  reviewType={ReviewType.TEST_RUN}*/}
                  {/*  testRunId={testRun.id}*/}
                  {/*  testRunReviewStatusId={testRun.reviewStatusId}*/}
                  {/*/>*/}
                </div>
                {!running && AppStore.account.isLegacyOrTierTwoPlusPlan() && (
                  <div className="rerun-testrun">
                    <Button size="small" color="green" onClick={rerunTest}>
                      <Icon name="redo" />
                      Rerun Test Run
                    </Button>
                  </div>
                )}
              </div>
            </Card.Content>
          </Card>
        </div>
        <Card className={"summary-item summary-source"}>
          <Card.Content>
            <Card.Header>Source</Card.Header>
            <div className="value">
              {testRun.testSuiteName && <div>Test Suite: {testRun.testSuiteName}</div>}
              <div>Test Run ID: {testRun.id}</div>
              <div>
                Created: <DatetimeDisplay datetime={testRun.createdAt} />
              </div>
              {testRun.build && <div>Build ID: {testRun.build.name}</div>}
              <div>
                Project: <Link to={organizationUrl(`projects/${project.key}`)}>{project.name}</Link>
              </div>
              {project.hasRepo && (
                <div>
                  Git owner/repo: <RepoLink project={project} />
                </div>
              )}
              {project.hasRepo && testRun.branch && testRun.branch != "" && <div>Branch: {testRun.branch}</div>}
              {project.hasRepo && (
                <div>
                  Git ref: <RepoLink project={project} gitRef={testRun.gitRef} hideProvierIcon={true} />
                </div>
              )}
              {triggerProject && (testRun.gitRef != testRun.deploy?.commit || triggerProject.id != project.id) && (
                <div>
                  Triggered By:{" "}
                  <RepoLink project={triggerProject} gitRef={testRun.deploy?.commit} hideProvierIcon={true} />
                </div>
              )}
              {testRun.runnerConfigurationId && testRun.runnerConfigurationName != "" && (
                <div className="runnerConfiguration">
                  Test Stack: {testRun.runnerConfigurationName}
                </div>
              )}
              {testRun.environmentName && testRun.environmentName != "" && (
                <div className={"text-overflow"}>Environment: <EnvironmentLink environment={testRun.environment} /></div>
              )}
              {trigger && trigger.name != "" && <div>Trigger: {trigger.name}</div>}
              {<div>Parallelized By: {testRun.defaultToParallelizeByFile ? "File/Feature" : "Test/Scenario"} </div>}
              {<div>Max Number of Runners: {testRun.maxRunners}</div>}
              {running && <div> Current Number of Runners: {testRun.nonExitedRunners.length}</div>}
              {testRun.includeTags && testRun.includeTags.length > 0 && (
                <div>Included Tags: {testRun.includeTags.join(", ")}</div>
              )}
              {testRun.excludedTags && testRun.excludedTags.length > 0 && (
                <div>Excluded Tags: {testRun.excludedTags.join(", ")}</div>
              )}
              {testRun.testFilters && testRun.testFilters.length > 0 && (
                <div className="filters">
                  Filters:
                  {testRun.testFilters.map((f) => (
                    <div key={f} className="filter">{f}</div>
                  ))}
                </div>
              )}
              {testRun.playwrightProject && <>Playwright Project: {testRun.playwrightProject}</>}
              {testRun.recordVideo != null && testRun.project.runner != TestRunType.dotnet && (
                <div>
                  Record Video: {testRun.recordVideo ? "Yes" : "No"}
                </div>
              )}
              <div>
                Include Quarantined Tests in Results: {testRun.ignoreQuarantine ? "Yes" : "No"}
              </div>
              <div>
                Retry Failed Tests: {testRun.retryFailedTests ? "Yes" : "No"}
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    </div>
  );
});

export default TestRunOverview;
