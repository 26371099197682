import { organizationUrl } from "core/utils";
import { TestPlan } from "models/test-plan";
import { Link } from "react-router-dom";

interface Props {
  testPlan: TestPlan;
  destination?: "runs" | "settings" | "stages";
}

export default function TestPlanLink({ testPlan, destination }: Props) {
  if (!testPlan) return <span />;

  return (
    <div className="test-plan-link">
      <Link to={organizationUrl(`test-plans/${testPlan.id}/${destination || "runs"}`)}>{testPlan.name}</Link>
    </div>
  );
}
