import API from "core/api";
import { ItemStoreList } from "./item-store";
import { Build } from "models/project";
import { action, observable } from "mobx";
import BuildForm from "models/forms/build-form";

export class BuildStoreClass extends ItemStoreList<Build> {
  async download(projectId: number, buildId: number): Promise<Object> {
    const result = await API.get(`/projects/${projectId}/builds/${buildId}/download`, Object);
    return result.data
  }
  load(id: number): Promise<Build> {
    throw new Error("Method not implemented. " + id);
  }

  @observable loaded = false;

  @action
  clear() {
    super.clear();
    this.loaded = false;
  }

  @action
  async save(projectId: Number, form: BuildForm) {
    const result = await API.postMultipart("/projects/" + projectId + "/build", {
      projectId: form.projectId,
      name: form.name,
      branch: form.branch,
      commit: form.commit == "latest" ? null : form.commit,
      file: form.file,
    }, Build);
    if (!result.status)
      throw Error("Test run upload failed");

      return result.data;
  }
}
