import Form from "./form";
import { action, observable, reaction } from "mobx";
import { notEmpty } from "core/validation";
import Project, { TestSuiteType } from "models/project";
import { AppStore } from "stores";

export default class TestSuiteForm extends Form {
  @observable id: number;
  @observable projectId: number;
  @observable name: string;
  @observable description: string;
  @observable maxRunners: number;
  @observable maxRunnersAllowed: number;
  @observable includeTags: string[];
  @observable excludedTags: string[];
  @observable testFilters: string[];
  @observable testTimeoutSeconds: number;
  @observable timeoutMinutes: number;
  @observable parallelizeByFile: boolean;
  @observable defaultToParallelizeByFile: boolean;
  @observable retryFailedTests: boolean;
  @observable testOptionsType: "all" | "suite" | "specify";
  @observable type: TestSuiteType;
  @observable priority: number;
  @observable testSuiteId: number;
  @observable playwrightProject: string;

  validators = {
    name: notEmpty,
    testTimeoutSeconds: notEmpty,
    timeoutMinutes: notEmpty,
  };

  constructor() {
    super();
    reaction(
      () => this.defaultToParallelizeByFile,
      () => (this.parallelizeByFile = this.defaultToParallelizeByFile),
    );
  }

  @action
  setDefaults() {
    this.name = "";
    this.description = "";
    this.maxRunners = null;
    this.maxRunnersAllowed = 10;
    this.includeTags = [];
    this.excludedTags = [];
    this.testFilters = [];
    this.testTimeoutSeconds = null;
    this.timeoutMinutes = null;
    this.defaultToParallelizeByFile = null;
    this.retryFailedTests = null;
    this.type = null;
    this.testOptionsType = "suite";
    this.testSuiteId = null;
    this.playwrightProject = null;
  }

  @action
  populateFromProject(project: Project) {
    this.defaultToParallelizeByFile = project.defaultToParallelizeByFile;
    this.timeoutMinutes = project.testRunTimeoutMinutes;
    this.testTimeoutSeconds = project.testTimeoutSeconds;
    this.retryFailedTests = project.retryFailedTests;
    const count = AppStore.account.maxRunners(project);
    this.maxRunners = project.defaultMaxRunners || count;
    this.maxRunnersAllowed = count;
  }
}
