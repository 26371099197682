import { EnvironmentVar } from "./settings";
import { computed, observable } from "mobx";
import Project, { Build } from "./project";
import { type } from "core/serialization";
import { PipelineStore, ProjectStore, SettingsStore, TestPlanStore, TestSuiteStore } from "stores";

export default class TestTrigger {
  id: number;
  latestTestRunId: number;
  @observable
  archived: boolean;
  createdAt: string;
  updatedAt: string;
  lastRunAt: string;
  name: string;
  cron: string;
  projectId: number;
  testPlanId: number;
  deployedProjectIds: number[];
  environmentId: number;
  pipelineStageId: number;
  @type(Build)
  build: Build;
  maxRunners: number;
  copies: number;
  commit: string;
  includeTags: string[];
  excludedTags: string[];
  testFilters: string[];
  variables: EnvironmentVar[];
  testName: string;
  defaultToParallelizeByFile: boolean;
  testTimeoutSeconds: number;
  timeoutMinutes: number;
  @observable
  enabled: boolean;
  latestDeploy: boolean;
  onDeploy: boolean;
  retryFailedTests: boolean;
  testSuiteId: number;

  @computed
  get project() {
    return ProjectStore.find(this.projectId);
  }

  @computed
  get testSuite() {
    return TestSuiteStore.find(this.testSuiteId);
  }

  @computed
  get testPlan() {
    return TestPlanStore.find(this.testPlanId);
  }

  @computed
  get deployProjects() {
    return this.deployedProjectIds.map((id) =>
      id == 0 ? new Project({ id: 0, name: "Any Project" }) : ProjectStore.find(id),
    );
  }

  @computed
  get environment() {
    return SettingsStore.findEnvironment(this.environmentId);
  }

  @computed
  get pipelineStage() {
    return PipelineStore.find(this.pipelineStageId);
  }

  get hasFilters() {
    return this.includeTags.length > 0 || this.excludedTags.length > 0 || this.testFilters.length > 0;
  }

  get isTestPlanTrigger() {
    return this.testPlanId != null;
  }
}

export enum TriggerType {
  now = "now",
  interval = "interval",
  deploy = "deploy",
  testPlanInterval = "testPlanInterval",
  testPlanDeploy = "testPlanDeploy",
}
