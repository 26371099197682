import { format, formatDistance, parseISO } from "date-fns";
import { getTimezoneOffset, zonedTimeToUtc } from "date-fns-tz";
import { IS_LOCAL } from "./constants";

export function millisToMinutesAndSeconds(millis): string {
  const totalSeconds = Math.round(millis / 1000);

  if (totalSeconds < 60) {
    return totalSeconds + "s";
  }

  const totalMinutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;

  if (totalMinutes < 60) {
    return totalMinutes + "m " + (seconds < 10 ? "0" : "") + seconds + "s";
  }

  let minutes = totalMinutes % 60;
  let hours = Math.floor(totalMinutes / 60);

  return (
    hours +
    "h " +
    (minutes < 10 ? "0" : "") +
    minutes +
    "m " +
    (seconds < 10 ? "0" : "") +
    seconds +
    "s"
  );
}

export function parseServerDateTime(datetime: string) {
  if (!datetime)
    return null;

  return parseISO(datetime.indexOf("+") == -1 && !IS_LOCAL ? datetime + "+0000" : datetime);
}

export function calculateDuration(start: string, end: string = null): number {
  if (!start) return null;
  const startDate = parseServerDateTime(start);
  const endDate = end ? parseServerDateTime(end) : new Date();
  return endDate.getTime() - startDate.getTime();
}

export function sinceText(dateTime: string) {
  const date = parseServerDateTime(dateTime);
  const timeSince = calculateDuration(dateTime);
  if (timeSince < 1000 * 60 * 60 * 24) {
    return formatDistance(date, new Date(), { addSuffix: true });
  } else {
    return format(date, "MM/dd/yy h:mm a");
  }
}

export function toUTCDate(date: string): Date {
  return zonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function formatForMillisOffset(date: Date): string {
  const offsetInMillis = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(date, `yyyy-MM-dd ${offsetInMillis}`)
}
