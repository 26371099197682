import API, { Response } from "core/api";
import { chain } from "lodash";
import { action, computed } from "mobx";
import WorkflowForm from "models/forms/workflow-form";
import { Workflow } from "models/workflow";
import { ItemStoreList } from "./item-store";

const baseUrl = "/workflows";

export class WorkflowStoreClass extends ItemStoreList<Workflow> {
  async doLoadAll() {
    return (await API.getList(baseUrl, Workflow)).data;
  }

  async load(id: number): Promise<Workflow> {
    const { data } = await API.get(`${baseUrl}/${id}`, Workflow);
    return this.add(data);
  }

  @computed
  get active() {
    return chain(this.items)
      .filter((p) => !p.archived)
      .sortBy((p) => p.name.toLocaleLowerCase())
      .value();
  }

  @action
  async save(form: WorkflowForm) {
    return form.submit(async (data) => {
      let result: Response<Workflow>;
      if (data.id) {
        result = await API.patch(`${baseUrl}/${data.id}`, data, Workflow);
      } else {
        result = await API.post(baseUrl, data, Workflow);
      }

      return this.update(result.data);
    });
  }

  @action
  async delete(id: number) {
    this.find(id).archived = true;
    await API.delete(`${baseUrl}/${id}`);
  }
}
