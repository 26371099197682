import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import Page from "pages/page";
import { useState } from "react";
import { useHistory } from "react-router";
import { Button, Header, Segment } from "semantic-ui-react";
import { AppStore } from "stores";
import "./password-reset.scss";

const PasswordReset = observer(() => {
  const history = useHistory();
  const [invalid, setInvalid] = useState(false);

  useInitialEffect(async () => {
    await AppStore.passwordReset(() => history.push("/"));
    setInvalid(!AppStore.isLoggedIn);
  });

  return (
    <Page name="password-reset" title="Password Reset" titleSize="none">
      {invalid && (
        <>
          <Header size="huge">Invalid Password Reset Token</Header>
          <Segment placeholder>
            <div>
              <h3>To get a new token go to the forgot password screen</h3>
              <Button color="green" onClick={() => history.push("/forgot-password")}>
                Go to Forgot Password
              </Button>
            </div>
          </Segment>
        </>
      )}
    </Page>
  );
});

export default PasswordReset;
