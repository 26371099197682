import WorkflowSettings from "components/form/workflow-settings";
import Page from "pages/page";

const WorkflowNewPage = () => (
  <Page name="workflow-new" title="New Quality Workflow">
    <WorkflowSettings />
  </Page>
);

export default WorkflowNewPage;
