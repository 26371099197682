import Page from "pages/page";
import { Button, Header, Segment, Divider } from "semantic-ui-react";
import { AppStore } from "stores";
import "./no-account.scss";
import { useHistory } from "react-router";

const NoAccountPage = () => {
  const history = useHistory();

  const createOrganization = async () => {
    await AppStore.createOrganization(null);
    history.push("/");
  };

  return (
    <Page name="no-account" title="No Organization" noHeader titleSize="none">
      <Header size="large">What would you like to do?</Header>
      <Segment>
        <h3>I want to join an existing organization</h3>
        <br />
        If you want to be added to an existing organization, the administrator of the organization
        needs to invite you using email:
        <div className="email">{AppStore.user.email}</div>
        <Divider section content="or" />
        <h3>
          I want to create a new organization
          <br />
          <Button color="green" onClick={createOrganization}>
            Create Organization
          </Button>
        </h3>
        <Divider section content="or" />
        <h3>
          <b>Log out and log in with different credentials</b>
          <br />
          <Button color="black" onClick={() => AppStore.logout()}>
            Log Out
          </Button>
        </h3>
      </Segment>
    </Page>
  );
};

export default NoAccountPage;
