import { useInitialEffect } from "core/react-utils";
import { organizationUrl } from "core/utils";
import { observer } from "mobx-react-lite";
import TestPlanForm from "models/forms/test-plan-form";
import { TestPlan } from "models/test-plan";
import { useHistory } from "react-router";
import { Button, Form, Grid, Icon } from "semantic-ui-react";
import { TestPlanStore, ProjectStore } from "stores";
import "./test-plan-settings.scss";

const form = new TestPlanForm();

async function saveTestPlan(navigate: (path: string) => any) {
  const newPlan = !form.id;
  const plan = await TestPlanStore.saveTestPlan(form);

  if (newPlan) {
    navigate(organizationUrl(`test-plans/${plan.id}`));
  }
}

interface Props {
  testPlan?: TestPlan;
}

const TestPlanSettings = observer(({ testPlan }: Props) => {
  const history = useHistory();

  useInitialEffect(async () => {
    await ProjectStore.ensureLoaded();
    form.clearFormData();

    if (testPlan) {
      form.populate(testPlan);
    }
  });

  const setName = (e: any) => form.updateNameAndKey(e.target.value);


  return (
    <div className="test-plan-settings">
      <Form>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Test Plan Name"
                value={form.name}
                onChange={setName}
                error={form.getErrorText("name")}
              />
              <Form.Input
                label="Test Plan Key"
                value={form.key}
                onChange={form.onChange("key")}
                error={form.getErrorText("key")}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.TextArea
                label="Objective/Goal"
                value={form.goal}
                onChange={form.onChange("goal")}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <section className="form-actions">
          {!testPlan && (
            <Button type="button" onClick={() => history.goBack()}>
              <Icon name="times" />
              Cancel
            </Button>
          )}
          <Button
            onClick={() => saveTestPlan(history.replace)}
            loading={form.isSubmitting}
            color="green"
            type="submit"
          >
            <Icon name="check" />
            Save
          </Button>
        </section>
      </Form>
    </div>
  );
});

export default TestPlanSettings;
