import IconButton from "./icon-button";

interface Props {
  open: boolean;
  update: (open: boolean) => any;
}

const CollapseButton = ({ open, update }: Props) => (
  <IconButton
    icon={open ? "minus square outline" : "plus square outline"}
    onClick={() => update(!open)}
  />
);

export default CollapseButton;
