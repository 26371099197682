import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import { Invitation, RoleText, UserRole } from "models/user";
import RoleSelect from "components/form/role-select";
import { Button, Card, CardContent, CardHeader, Form, Icon, Tab } from "semantic-ui-react";
import { AppStore, ModalStore, UserStore } from "stores";
import UserInvitationForm from "models/forms/user-invitation-form";
import IconButton from "components/icon-button";
import DatetimeDisplay from "components/datetime-display";
import SortableTable from "components/sortable-table";

const invitationForm = new UserInvitationForm();

const UsersTab = observer(() => {
  useInitialEffect(() => {
    invitationForm.clearFormData();
    UserStore.loadUserRoles();
    UserStore.loadInvitations();
  });

  const currentUser = AppStore.user;

  const addNewUserRole = async () => {
    await UserStore.inviteUser(invitationForm);
    invitationForm.clearFormData();
  };

  const deleteRole = (role: UserRole) => {
    ModalStore.confirmation.show(
      (ok: boolean) => {
        if (ok) {
          UserStore.deleteRole(role.id);
        }
      },
      {
        message: `Are you sure you want to delete the role for ${role.user.email}?`,
        okColor: "red",
        okText: "Delete",
        okIcon: "trash",
      },
    );
  };

  const deleteInvitation = (invitation: Invitation) => {
    ModalStore.confirmation.show(
      (ok: boolean) => {
        if (ok) {
          UserStore.deleteInvitation(invitation.id);
        }
      },
      {
        message: `Are you sure you want to delete the invitation for ${invitation.email}?`,
        okColor: "red",
        okText: "Delete",
      },
    );
  };

  const invitations = UserStore.invitations;

  let roles = UserStore.userRoles;

  if (!AppStore.isTesteryAccount) {
    roles = roles.filter((r) => !r.user.email.endsWith("@testery.io"));
  }

  return (
    <Tab.Pane className="users">
      {AppStore.userIsAdmin &&
        <Card className="add-user-form">
          <CardContent>
            <CardHeader>Add a New User</CardHeader>
          </CardContent>
          <CardContent>
            <Form>
              <Form.Input
                label="Email"
                type="email"
                value={invitationForm.email}
                onChange={invitationForm.onChange("email")}
                error={invitationForm.getErrorText("email")}
              />
              <Form.Input
                label="First Name"
                value={invitationForm.firstName}
                onChange={invitationForm.onChange("firstName")}
                error={invitationForm.getErrorText("firstName")}
              />
              <Form.Input
                label="Last Name"
                value={invitationForm.lastName}
                onChange={invitationForm.onChange("lastName")}
                error={invitationForm.getErrorText("lastName")}
              />
              <RoleSelect
                label="Role"
                value={invitationForm.roleType}
                onChange={invitationForm.onChange("roleType")}
                error={invitationForm.getErrorText("roleType")}
              />
              <Button color="green" onClick={addNewUserRole} loading={invitationForm.isSubmitting}>
                <Icon name="plus" />
                Add User
              </Button>
            </Form>
          </CardContent>
        </Card>
      }
      {AppStore.userIsAdmin && (
        <SortableTable
          title="Invitations"
          loading={!UserStore.invitationsLoaded && UserStore.userRolesLoaded}
          loadingText="Loading Invitations"
          className="invitations"
          headerColumns={[
            { content: "Date", sortKey: "createdAt" },
            { content: "Name", sortKey: "fullName" },
            { content: "Email", sortKey: "email" },
            { content: "Role", sortKey: "roleType" },
            { content: "" },
          ]}
          items={invitations}
          renderRow={(invitation) => (
            <tr key={invitation.id}>
              <td className="date">
                <DatetimeDisplay datetime={invitation.createdAt} />
              </td>
              <td>{invitation.fullName}</td>
              <td>{invitation.email}</td>
              <td className="role">{RoleText[invitation.roleType]}</td>
              <td>
                <IconButton
                  label="Delete Invitation"
                  icon="trash"
                  onClick={() => deleteInvitation(invitation)}
                />
              </td>
            </tr>
          )}
          defaultSortKey="createdAt"
        />
      )}
      <SortableTable
        title="Current Users"
        loading={!UserStore.userRolesLoaded}
        loadingText="Loading Users"
        className="users"
        headerColumns={[
          {
            content: "Name",
            sortKey: "user.fullName",
            sortTransform: (r) => r.user.fullName.toLowerCase(),
          },
          { content: "Email", sortKey: "user.email" },
          { content: "Role", sortKey: "roleType" },
          { content: "" },
        ]}
        items={roles}
        renderRow={(role) => (
          <tr key={role.id}>
            <td>{role.user.fullName}</td>
            <td>{role.user.email}</td>
            <td className="role">
              <RoleSelect
                usePortal={true}
                value={role.roleType}
                onChange={(type) => UserStore.updateRole(role.id, type)}
              />
            </td>
            <td>
              {role.user.id != currentUser.id && (
                <IconButton
                  label="Delete User"
                  icon="trash"
                  onClick={() => deleteRole(role)}
                />
              )}
            </td>
          </tr>
        )}
        defaultSortKey="user.email"
      />
    </Tab.Pane>
  );
});

export default UsersTab;
