import { setPageInfo } from "core/react-utils";
import { observer } from "mobx-react-lite";
import { Tab } from "semantic-ui-react";
import CollapseButton from "../collapse-button";
import { TestResult, TestStatus } from "../../models/test-run";
import Spinner from "../spinner";
import { useState } from "react";

interface Props {
  testResult: TestResult;
}

const ConsoleTab = observer(({ testResult }: Props) => {
  setPageInfo("testResult", "Console");

  const [showOutput, setShowOutput] = useState(true);
  const [showProcessOutput, setShowProcessOutput] = useState(true);
  const [showError, setShowError] = useState(true);

  return (
    <Tab.Pane>
      <div className="detail">
        {testResult.error && testResult.error.length > 0 && (
          <>
            <div className="output-error">
              <h3>
                <CollapseButton open={showError} update={setShowError} />
                Errors
              </h3>
              {showError && (
                <>
                  <div>
                    <pre>
                      <code>{testResult.error}</code>
                    </pre>
                  </div>
                  {testResult.stackTrace
                    ? [
                      <h4 key={`testResult-header-${testResult.testRunTestId}`}>Stacktrace</h4>,
                      <div key={`testResult-${testResult.testRunTestId}`}>
                          <pre>
                            <code>{testResult.stackTrace}</code>
                          </pre>
                      </div>,
                    ]
                    : null}
                </>
              )}
            </div>
          </>
        )}
        {testResult.output &&
          testResult.output.length > 0 &&
          testResult.output.trim() != testResult.processOutput?.trim() && (
            <div className="output">
              <h3>
                <CollapseButton open={showOutput} update={setShowOutput} />
                Test Output
              </h3>
              {showOutput && (
                <div>
                  <pre>
                    <code>{testResult.output.replace(testResult.processOutput, "").trim()}</code>
                  </pre>
                </div>
              )}
            </div>
          )}
        {testResult.processOutput && testResult.processOutput.length > 0 && (
          <div className="output">
            <h3>
              <CollapseButton open={showProcessOutput} update={setShowProcessOutput} />
              Process Output
            </h3>
            {showProcessOutput && (
              <div>
                <pre>
                  <code>{testResult.processOutput}</code>
                  {testResult.status == TestStatus.RUNNING && (
                    <div id="loading-spinner">
                      {testResult.output && testResult.output.length > 0 && <br />}
                      <Spinner text="Running..." />
                    </div>
                  )}
                </pre>
              </div>
            )}
          </div>
        )}
      </div>
    </Tab.Pane>
  );
});

export default ConsoleTab;
