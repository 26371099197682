import { Tab } from "semantic-ui-react";
import { PlanInfo } from "../../components/payment-info";

const PaymentTab = () => (
  <Tab.Pane className="payment">
    <PlanInfo />
  </Tab.Pane>
);

export default PaymentTab;
