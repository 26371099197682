import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { ModalStore, ReviewStore, SettingsStore, TestRunStore } from "../../stores";
import { ReviewStatus, ReviewType } from "../../models/review-data";
import { NotificationManager } from "react-notifications";
import { Response } from "core/api";
import { AuthorizationProvider } from "../../models/settings";
import JiraIssueForm from "../../models/forms/jira-issue-form";
import { useInitialEffect } from "../../core/react-utils";
import { TestRunTest } from "../../models/test-run";
import { organizationUrl } from "../../core/utils";

interface Props {
  reviewType: ReviewType;
  testRunId: number;
  testRunReviewStatusId: number;
  testRunTestId: number;
  testRunTestReviewStatusId: number;
  refresh?: (testRunId: number) => any;
}

const ReviewStatusSelect = ({
                              reviewType,
                              testRunId,
                              testRunReviewStatusId,
                              testRunTestId,
                              testRunTestReviewStatusId,
                              refresh,
                            }: Props) => {
  const [value, setValue] = useState<string>("");
  const [testRunTest, setTestRunTest] = useState<TestRunTest>(null);
  const [currentTestRunTestReviewStatusId, setCurrentTestRunTestReviewStatusId] = useState<number>(null);
  const [reviewStatuses, setReviewStatuses] = useState<Array<ReviewStatus>>([]);

  const options = reviewStatuses.map(status => {
    return <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>;
  });

  useInitialEffect(async () => {
    setTestRunTest(await TestRunStore.loadTestRunTest(testRunTestId));
    setReviewStatuses(await ReviewStore.loadReviewStatuses());
    setCurrentTestRunTestReviewStatusId(testRunTestReviewStatusId);
  });

  useEffect(() => {
    let currentStatusId: number | null;
    switch (reviewType) {
      case ReviewType.TEST_RUN:
        currentStatusId = testRunReviewStatusId;
        break;
      case ReviewType.TEST_RUN_TEST:
        currentStatusId = testRunTestReviewStatusId;
        break;
    }

    const value = reviewStatuses.find(status => status.id === currentStatusId)?.id;
    setValue(!value ? "" : value.toString());
  }, [reviewStatuses]);

  async function handleChange(e) {
    let reviewStatusId = e.target.value;

    if (reviewType === ReviewType.TEST_RUN) {
      if (reviewStatusId === testRunReviewStatusId)
        return;

      testRunReviewStatusId = reviewStatusId;
    } else {
      if (reviewStatusId === currentTestRunTestReviewStatusId)
        return;

      setCurrentTestRunTestReviewStatusId(reviewStatusId);
    }

    setValue(reviewStatusId.toString());

    const response: void | Response<any> = await ReviewStore.updateReviewStatus({
      reviewType: reviewType,
      testRunId: testRunId,
      testRunTestId: testRunTest.id,
      reviewStatusId: reviewStatusId,
    })
      .catch(() => NotificationManager.error("Please try again", "Something went wrong"));

    let message;
    if (reviewType === ReviewType.TEST_RUN)
      message = "Test run review status successfully updated";
    else
      message = "Test review status successfully updated";

    if (response && response.status === 200)
      NotificationManager.success(message, "Success", 2000);

    if (reviewType === ReviewType.TEST_RUN_TEST && SettingsStore.authorizations.find(authorization => authorization.provider === AuthorizationProvider.Jira) != null) {
      const jiraIssueForm = new JiraIssueForm();
      jiraIssueForm.updateField("testRunId", [testRunId]);
      jiraIssueForm.updateField("testRunTestIds", [testRunTest.id]);
      jiraIssueForm.updateField("summary", `Fix "${testRunTest.projectTest.name}" Test`);
      jiraIssueForm.updateField("rootCauseId", testRunTest.testRunAnalysisRootCauseId);

      let description = `Test: ${window.location.origin + organizationUrl(`test-runs/${testRunId}/tests/${testRunTest.id}`)}`;
      jiraIssueForm.updateField("description", description);

      ModalStore.confirmation.show(() => {
      }, {
        message: "Would you like to create a Jira Issue",
        okText: "Yes",
        cancelText: "No",
        okClick: () => ModalStore.createJiraModal.show(() => {
            if (refresh)
              refresh(testRunId);
          }, jiraIssueForm,
        ),
      });
    }
  }

  return (
    <>
      <Select style={{ background: "#FFF", textAlign: "left", width: "200px" }}
              displayEmpty
              value={value}
              onChange={handleChange}>
        <MenuItem key={""} value={""}><em>Review Status (None)</em></MenuItem>
        {options}
      </Select>
    </>
  );
};

export default ReviewStatusSelect;
