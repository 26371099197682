import { TestRunStatus } from "models/test-run";
import "./test-run-progress.scss";

interface Props {
  pass?: number;
  fail?: number;
  total?: number;
  status?: TestRunStatus;
  showPassPercentage?: boolean;
  showPassPercentagePlaceholder?: boolean;
}

const TestRunProgress = ({
  pass,
  fail,
  total,
  showPassPercentage,
  showPassPercentagePlaceholder,
}: Props) => {
  const pending = total - pass - fail;
  const showPercentageHolder = showPassPercentage || showPassPercentagePlaceholder;

  if (!total)
    return <div />;

  return (
    <div className="test-run-progress">
      <div className="bar-container" style={showPercentageHolder ? { width: "90%" } : null}>
        <div className="bar">
          {pass > 0 && (
            <div className="passing" style={{ flexGrow: pass }}>
              {pass}
            </div>
          )}
          {fail > 0 && (
            <div className="failing" style={{ flexGrow: fail }}>
              {fail}
            </div>
          )}
          {pending > 0 && (
            <div className="pending" style={{ flexGrow: pending }}>
              {pending}
            </div>
          )}
        </div>
      </div>
      {showPercentageHolder && (
        <div className="pass-percentage">
          {showPassPercentage && <span>{Math.floor((pass * 100) / total)}%</span>}
        </div>
      )}
    </div>
  );
};

export default TestRunProgress;
