import { EmployeeStore } from "stores";
import "./employee.scss";
import { Button, Tab } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import AccountSelect from "./account-select";
import TestRunMultiStatusSelect from "components/form/testrun-multi-status-select";
import DatetimeDisplay from "components/datetime-display";
import { Link } from "react-router-dom";
import DurationDisplay from "components/duration-display";
import DatePicker from "react-datepicker";
import { TestRunType } from "models/settings";
import SortableTable from "components/sortable-table";

const TestRunsTab = observer(() => {
  return (
    <Tab.Pane className="test-runs-tab">
      <div className="list-filters">
        <DatePicker
          isClearable
          selected={EmployeeStore.dateFilter}
          onChange={EmployeeStore.updateDateFilter}
          placeholderText="Any Date"
        />
        <AccountSelect
          value={EmployeeStore.accountFilter}
          onChange={EmployeeStore.updateAccountFilter}
          placeholder="All Accounts"
        />
        <TestRunMultiStatusSelect
          value={EmployeeStore.statusFilter}
          onChange={EmployeeStore.updateStatusFilter}
          placeholder="All Statuses"
        />
      </div>
      <Button color="green" onClick={() => EmployeeStore.searchForTestRuns()}>
        Refresh Data
      </Button>
      <br />
      <SortableTable
        loading={EmployeeStore.testRunsLoading}
        loadingText="Loading Test Runs"
        emptyMessage="No Test Runs Found"
        headerColumns={[
          { content: "Account", sortKey: "account.id" },
          { content: "Framework", sortKey: "project.testingFramework" },
          { content: "ID", sortKey: "id" },
          { content: "Status", sortKey: "status" },
          { content: "Created At", sortKey: "createdAt" },
          { content: "Started At", sortKey: "startTime" },
          { content: "Duration", sortKey: "calculatedDuration" },
          { content: "Tests", sortKey: "testCount" },
          { content: "Remaining", sortKey: "remainingTests" },
          { content: "Max Runners", sortKey: "maxRunners" },
          { content: "Messages Left", sortKey: "remainingMessages" },
          { content: "Runners", sortKey: "currentRunnerCount" },
          { content: "Runners Needed", sortKey: "runnersNeeded" },
          { content: "Idle Runners", sortKey: "idleRunnerCount"}
        ]}
        defaultDecending={true}
        defaultSortKey="createdAt"
        items={EmployeeStore.testRuns}
        renderRow={(t) => (
          <tr key={t.id}>
            <td>
              <a onClick={() => EmployeeStore.updateAccountFilter(t.account.id)}>
                {t.account.name}
              </a>
            </td>
            <td>
              {t.project.testingFramework}
              {t.project.runner == TestRunType.dotnet && " (.Net)"}
              {t.project.runner == TestRunType.dotnetcore && " (.Net Core)"}
            </td>
            <td>
              <Link to={`/employee/test-runs/${t.id}`}>{t.id}</Link>
            </td>
            <td>{t.status}</td>
            <td>
              <DatetimeDisplay datetime={t.createdAt} showSecond />
            </td>
            <td>
              <DatetimeDisplay datetime={t.startTime} showSecond />
            </td>
            <td>
              <DurationDisplay duration={t.calculatedDuration} />
            </td>
            <td>{t.testCount}</td>
            <td>{t.remainingTests}</td>
            <td>{t.maxRunners}</td>
            <td>{t.remainingMessages}</td>
            <td>{t.currentRunnerCount}</td>
            <td>{t.runnersNeeded}</td>
            <td>{t.idleRunnerCount}</td>
          </tr>
        )}
      />
    </Tab.Pane>
  );
});

export default TestRunsTab;
