import { useState } from "react";
import { Form, Input } from "semantic-ui-react";
import { TestRunStore } from "stores";
import "./message-create.scss";

interface Props {
  testRunId: number;
}

const MessageCreate = ({ testRunId }: Props) => {
  const [text, setText] = useState("");

  const send = () => {
    TestRunStore.saveMessage(testRunId, text);
    setText("");
  };

  return (
    <div className="message-create">
      <Form onSubmit={send}>
        <Input
          placeholder="Type a message..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          icon={{ name: "arrow circle up", size: "large", link: true, onClick: send }}
        />
      </Form>
    </div>
  );
};

export default MessageCreate;
