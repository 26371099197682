import Steps from "components/workflow/steps";
import { observer } from "mobx-react-lite";
import { Workflow } from "models/workflow";

interface Props {
  workflow: Workflow;
}

const StepsTab = observer(({ workflow }: Props) => {
  return (
    <div className="steps-tab">
      <div className="page-actions"></div>
      <Steps workflow={workflow} />
    </div>
  );
});

export default StepsTab;
