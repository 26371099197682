import IconButton from "../icon-button";
import { observer } from "mobx-react-lite";

const JunitUploadHelpButton = observer(() => {
  const handleClick = () => {
    window.open("https://docs.testery.io/how-to/upload-test-run-for-analysis", "_blank", "noreferrer");
  };

  return <IconButton icon={"help circle"} onClick={handleClick} />;
});

export default JunitUploadHelpButton;
