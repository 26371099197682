import { TestFramework } from "models/project";
import Select from "./select";

interface Props {
  value: TestFramework;
  label?: string;
  error?: string;
  onChange: (v: TestFramework) => any;
  hideOther?: boolean;
}

const FrameworkSelect = ({ value, label, error, onChange, hideOther }: Props) => {
  const options = Object.keys(TestFramework);

  if (hideOther) {
    options.pop();
  }

  return (
    <Select
      error={error}
      label={label}
      className="framework-select"
      value={value}
      options={options}
      onChange={(v) => onChange(TestFramework[v] || null)}
    />
  );
};

export default FrameworkSelect;
