import DatetimeDisplay from "components/datetime-display";
import IconButton from "components/icon-button";
import SortableTable from "components/sortable-table";
import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import { ScheduledJob } from "models/employee";
import { useState } from "react";
import { Button, Checkbox, Tab } from "semantic-ui-react";
import { EmployeeStore } from "stores";

const JobsTab = observer(() => {
  const [jobs, setJobs] = useState(null as ScheduledJob[]);

  const loadJobs = async () => {
    setJobs(null);
    setJobs(await EmployeeStore.loadJobs());
  };

  useInitialEffect(() => {
    loadJobs();
  });

  return (
    <Tab.Pane className="agents-tab">
      <Button color="green" onClick={loadJobs}>
        Refresh Data
      </Button>
      <br />
      <SortableTable
        title="Scheduled Jobs"
        loadingText="Loading Scheduled Jobs"
        loading={jobs == null}
        emptyMessage="No Scheduled Jobs Found"
        headerColumns={[
          { content: "ID", sortKey: "id" },
          { content: "Created At", sortKey: "insertedAt" },
          { content: "Last Start Time", sortKey: "lastStartTime" },
          {
            content: "Function",
            sortKey: "function",
            sortTransform: (j) => `${j.className}.${j.functionName}`,
          },
          { content: "Frequency", sortKey: "frequencyDisplay" },
          { content: "Enabled", sortKey: "enabled" },
          { content: "" },
        ]}
        items={jobs}
        defaultSortKey="lastStartTime"
        defaultDecending={true}
        renderRow={(job) => (
          <tr key={job.id}>
            <td>{job.id}</td>
            <td>
              <DatetimeDisplay datetime={job.createdAt} />
            </td>
            <td>
              <DatetimeDisplay datetime={job.lastStartTime} />
            </td>
            <td>
              {job.className}.{job.functionName}
            </td>
            <td>{job.frequencyDisplay}</td>
            <td>
              <Checkbox
                className="enabeled-toggle"
                checked={job.enabled}
                toggle
                onChange={() => EmployeeStore.toggleJobEnabled(job)}
              />
            </td>
            <td>
              <IconButton label="Run Job" icon="play" onClick={() => EmployeeStore.runJob(job)} />
            </td>
          </tr>
        )}
      />
    </Tab.Pane>
  );
});

export default JobsTab;
