import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useInitialEffect } from "core/react-utils";
import { EmployeeStore } from "stores";
import { TestInfo, TestListResult } from "models/test-run";
import Page from "pages/page";
import { Button, Icon } from "semantic-ui-react";
import "./test-list-result.scss";
import CollapseButton from "components/collapse-button";
import { flatMap } from "lodash";

const EmployeeTestListResultPage = () => {
  const { testRunId } = useParams<{ testRunId: string }>();
  const history = useHistory();
  const testRunIdn = parseInt(testRunId);
  const [testListResult, setTestListResult] = useState(null as TestListResult);
  const [showOutput, setShowOutput] = useState(true);
  const [showError, setShowError] = useState(true);
  const [showResults, setShowResults] = useState(true);
  const [showUnfilteredResults, setShowUnfilteredResults] = useState(true);
  const [showFilters, setShowFilters] = useState(true);

  useInitialEffect(async () => {
    await EmployeeStore.init();
    setTestListResult(await EmployeeStore.loadTestListResult(testRunIdn));
  });

  const outputTests = (infos: TestInfo[]) =>
    flatMap(infos, (info) => {
      const isNunit = info.file.endsWith(".dll");
      const isPython = info.file.endsWith(".py");
      return info.testItems.map((ti) => {
        if (isNunit) {
          return ti.name + "\n";
        } else if (isPython) {
          return `${ti.testFilter}\n`;
        } else if (ti.testFilter.indexOf(":") == -1) {
          return `${ti.testFilter}\n`;
        } else {
          return `${info.file}/${ti.name}\n`;
        }
      });
    });

  const hasFilters = testListResult && testListResult.filters.length > 0;

  return (
    <Page name="employee-test-list-result" title="Employee Test List Result">
      <Button onClick={history.goBack}>
        <Icon name="chevron left" />
        Back
      </Button>
      {testListResult && (
        <div>
          <div className="output">
            <h3>
              <CollapseButton open={showOutput} update={setShowOutput} /> Process Output
            </h3>
            {showOutput && (
              <pre>
                <code>{testListResult.output}</code>
              </pre>
            )}
          </div>
          {hasFilters && (
            <div className="output">
              <h3>
                <CollapseButton open={showFilters} update={setShowFilters} /> Filters
              </h3>
              {showFilters && (
                <pre>
                  <code>
                    {testListResult.filters.map((f) => (
                      <div key={f}>{f}</div>
                    ))}
                  </code>
                </pre>
              )}
            </div>
          )}
          {testListResult.error.trim() != "" && (
            <div className="output-error">
              <h3>
                <CollapseButton open={showError} update={setShowError} /> Errors
              </h3>
              {showError && (
                <pre>
                  <code>{testListResult.error}</code>
                </pre>
              )}
            </div>
          )}
          <div className="output">
            <h3>
              <CollapseButton open={showResults} update={setShowResults} /> Tests Found
            </h3>
            {showResults && (
              <pre>
                <code>{outputTests(testListResult.infos)}</code>
              </pre>
            )}
          </div>
          {hasFilters && (
            <div className="output">
              <h3>
                <CollapseButton open={showUnfilteredResults} update={setShowUnfilteredResults} />{" "}
                Unfiltered Tests Found
              </h3>
              {showUnfilteredResults && (
                <pre>
                  <code>{outputTests(testListResult.unfilteredInfos)}</code>
                </pre>
              )}
            </div>
          )}
        </div>
      )}
    </Page>
  );
};

export default EmployeeTestListResultPage;
