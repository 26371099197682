import { useInitialEffect } from "core/react-utils";
import { organizationUrl } from "core/utils";
import { observer } from "mobx-react-lite";
import WorkflowForm from "models/forms/workflow-form";
import { Workflow } from "models/workflow";
import { useHistory } from "react-router";
import { Button, Form, Grid, Icon } from "semantic-ui-react";
import { WorkflowStore } from "stores";
import "./workflow-settings.scss";

const form = new WorkflowForm();

async function saveWorkflow(navigate: (path: string) => any) {
  const newWorkflow = !form.id;
  const workflow = await WorkflowStore.save(form);

  if (newWorkflow) {
    navigate(organizationUrl(`workflows/${workflow.id}`));
  }
}

interface Props {
  workflow?: Workflow;
}

const WorkflowSettings = observer(({ workflow }: Props) => {
  const history = useHistory();

  useInitialEffect(async () => {
    await WorkflowStore.ensureLoaded();
    form.clearFormData();

    if (workflow) {
      form.populate(workflow);
    }
  });

  return (
    <div className="workflow-settings">
      <Form>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Workflow Name"
                value={form.name}
                onChange={form.onChange("name")}
                error={form.getErrorText("name")}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <section className="form-actions">
          {!workflow && (
            <Button type="button" onClick={() => history.goBack()}>
              <Icon name="times" />
              Cancel
            </Button>
          )}
          <Button onClick={() => saveWorkflow(history.replace)} loading={form.isSubmitting} color="green" type="submit">
            <Icon name="check" />
            Save
          </Button>
        </section>
      </Form>
    </div>
  );
});

export default WorkflowSettings;
