import { useState } from "react";
import "./organization-select.scss";
import { Organization } from "models/settings";
import { sortBy } from "lodash";
import { observer } from "mobx-react-lite";
import { SettingsStore, ModalStore } from "stores";
import { Modal } from "semantic-ui-react";
import Spinner from "components/spinner";

const OrganizationSelect = observer(() => {
  const { organizationSelect } = ModalStore;
  const [organizations, setOrgaizations] = useState(null as Organization[]);

  const selectOrganization = async (org: Organization) => {
    await SettingsStore.setAuthorizationIdentifier(organizationSelect.state.id, org.key);
    organizationSelect.hide(org.key);
  };

  organizationSelect.onShown = async () => {
    const orgs = await SettingsStore.loadOrganizations(organizationSelect.state.id);

    if (orgs.length == 1) {
      selectOrganization(orgs[0]);
    } else {
      setOrgaizations(sortBy(orgs, ["name"]));
    }
  };

  if (!organizations) return null;

  return (
    <Modal size="mini" open={organizationSelect.showing} id="organization-select">
      <Modal.Header>Select an Organization</Modal.Header>
      <Modal.Content>
        <div className="orgs">
          {!organizations && <Spinner text="Loading Organizations" size={12} />}
          {organizations &&
            organizations.length > 0 &&
            organizations.map((o) => (
              <div key={o.key} className="org" onClick={() => selectOrganization(o)}>
                <img src={o.logoUrl} />
                <div className="info">
                  <div className="name">{o.key}</div>
                  <div className="provider">{organizationSelect.state.provider}</div>
                </div>
              </div>
            ))}
        </div>
      </Modal.Content>
    </Modal>
  );
});

export default OrganizationSelect;
