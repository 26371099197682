import API from "core/api";
import { action, observable, runInAction } from "mobx";
import JiraIssueForm from "../models/forms/jira-issue-form";
import { TestRunTestTrackedIssue } from "../models/test-run";

export class JiraDataResponse {
  projects?: Array<Map<string, string>>;
  types?: Array<Map<string, string>>;
  priorities?: Array<Map<string, string>>;
  users?: Array<Map<string, string>>;
}

export class TrackedIssueStoreClass {
  @observable jiraProjects: Array<Map<string, string>>;
  @observable jiraIssueTypes: Array<Map<string, string>>;
  @observable jiraIssuePriorities: Array<Map<string, string>>;
  @observable jiraUsers: Array<Map<string, string>>;

  @action
  async clear() {
    this.jiraProjects = [];
    this.jiraIssueTypes = [];
    this.jiraIssuePriorities = [];
  }

  @action.bound
  async loadJiraProjects(query: string): Promise<Array<Map<string, string>>> {
    const result = await API.get(`/jira/project?query=${query}`, JiraDataResponse);

    return runInAction(() => {
      this.jiraProjects = result.data.projects;
      return this.jiraProjects;
    });
  }

  @action.bound
  async loadMetadata(projectId: string) {
    const result = await API.get(`/jira/issue/metadata?jiraProjectId=${projectId}`, JiraDataResponse);

    return runInAction(() => {
      this.jiraIssueTypes = result.data.types;
      this.jiraIssuePriorities = result.data.priorities;
      this.jiraUsers = result.data.users;
    });
  }

  @action.bound
  async loadByRootCauseId(rootCauseId: number): Promise<Array<TestRunTestTrackedIssue>> {
    const result = await API.getList(`/jira/issue/root-cause/${rootCauseId}`, TestRunTestTrackedIssue);

    return runInAction(() => {
      return result.data;
    });
  }

  @action
  async createJiraIssue(jiraIssueForm: JiraIssueForm) {
    await API.post("/jira/issue", jiraIssueForm);
  }
}
