import "./test-run-upload-edit.scss";
import EnvironmentSelect from "components/form/environment-select";
import { observer } from "mobx-react-lite";
import { AppStore, ProjectStore } from "stores";
import { Card, Form } from "semantic-ui-react";
import TestRunUploadForm from "models/forms/test-run-upload-form";
import ProjectSelect from "components/form/project-select";
import { useInitialEffect } from "core/react-utils";
import React, { RefObject } from "react";
import JunitUploadHelpButton from "./junit-upload-help-button";

interface Props {
  form: TestRunUploadForm;
  scheduleOnly?: boolean;
  projectElement?: RefObject<HTMLElement>;
  setFileToUpload?: (file: File) => any;
  fileUploadContainerRef?: RefObject<any>;
}

const TestRunUploadEdit = observer(({ form, projectElement, setFileToUpload, fileUploadContainerRef }: Props) => {

  useInitialEffect(() => {
    ProjectStore.ensureLatestDeploysLoaded();
  });

  const accountSetupComplete = AppStore.account.setupComplete;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      form.file = e.target.files[0];
      if (setFileToUpload) {
        setFileToUpload(form.file);
      }
    }
  };

  return (
    <Form className="test-run-edit">
      {accountSetupComplete &&
        <Card>
          <Card.Content>
            <ProjectSelect
              label="Project With Tests"
              itemRef={projectElement}
              value={form.projectId}
              onlyWithTests={true}
              onChange={form.onChange("projectId")}
              error={form.getErrorText("projectId")}
              allowOtherFramework={true}
            />
            <EnvironmentSelect
              label="Environment To Run Tests Against"
              value={form.environmentId}
              onChange={form.onChange("environmentId")}
              error={form.getErrorText("environmentId")}
            />
          </Card.Content>
        </Card>
      }
      <Card>
        <Card.Content>
          <div>
            <label htmlFor="file" className="sr-only">
              <div style={{ display: "flex" }}>
                <JunitUploadHelpButton />Upload the JUnit XML file you would like to analyze.
              </div>
            </label>
            <p>For more information on how to export JUnit XML for your framework, see: <a target="_blank"
                                                                                           href="https://docs.testery.io/how-to/export-test-results-in-junit-xml-format">How
              To Export Test Results in JUnit XML Format</a></p>
            <input id="file" type="file" onChange={handleFileChange} />
          </div>
          <div className={"error"} ref={fileUploadContainerRef}>File upload failed. Please make sure your test results
            are in JUnit.xml format.
          </div>
        </Card.Content>
      </Card>
    </Form>
  );
});

export default TestRunUploadEdit;
