import { useHistory, useParams } from "react-router";
import { AlertStatus, EventType } from "models/settings";
import { useInitialEffect } from "core/react-utils";
import "./alert-edit.scss";
import Page from "pages/page";
import { SettingsStore } from "stores";
import { Button, Form, Icon } from "semantic-ui-react";
import YesNoSelect from "components/form/yes-no-select";
import { Icons } from "core/constants";
import AlertForm from "models/forms/alert-form";
import { observer } from "mobx-react-lite";
import AlertEventSelect from "components/form/alert-event-select";
import AlertActionSelect from "components/form/alert-action-select";

const form = new AlertForm();

async function loadAlert(alertIdParam: string) {
  form.clearFormData();

  if (alertIdParam) {
    await SettingsStore.ensureAlerts();
    form.populate(SettingsStore.getAlert(parseInt(alertIdParam)));
  } else {
    form.updateField("eventType", EventType.TESTRUN_COMPLETE);
  }
}

const AlertsEditPage = observer(() => {
  const { alertId } = useParams<{ alertId: string }>();
  const history = useHistory();

  useInitialEffect(() => {
    loadAlert(alertId);
  });

  const save = async () => {
    await SettingsStore.saveAlert(form);
    history.push("../alerts");
  };

  return (
    <Page name="alerts-edit" title={(alertId ? "Edit" : "New") + " Alert"} icon={Icons.alert}>
      <Form>
        <AlertEventSelect
          label="Event"
          onChange={form.onChange("eventType")}
          value={form.eventType}
          error={form.getErrorText("eventType")}
        />
        {form.eventType != EventType.TESTRUN_ANALYSIS_COMPLETE && (
          <div id={"alert-criteria-container"}>
            <Form.Input
              label="Criteria (e.g. environment.key=='foo' &amp;&amp; passCount &lt; 100)"
              value={form.criteria}
              onChange={form.onChange("criteria")}
              error={form.getErrorText("criteria")}
            />
            <div id={'alert-docs-link'}>
              See our <a href={"https://docs.testery.io/get-to-know-testery/alerts"} target={"_blank"}>Alert
              Docs</a> for more information
            </div>
          </div>
        )}
        <AlertActionSelect
          label="Take Action"
          onChange={form.onChange("actionType")}
          value={form.actionType}
          error={form.getErrorText("actionType")}
          eventType={form.eventType}
        />
        {form.destinationLabel && (
          <Form.Input
            label={form.destinationLabel}
            value={form.destination}
            onChange={form.onChange("destination")}
            error={form.getErrorText("destination")}
          />
        )}
        <YesNoSelect
          label="Active"
          value={form.status == AlertStatus.ACTIVE}
          onChange={(active) =>
            form.updateField("status", active ? AlertStatus.ACTIVE : AlertStatus.INACTIVE)
          }
        />
      </Form>
      <section className="form-actions">
        <Button onClick={() => history.goBack()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button color="green" loading={form.isSubmitting} onClick={save}>
          <Icon name="check" />
          Save
        </Button>
      </section>
    </Page>
  );
});

export default AlertsEditPage;
