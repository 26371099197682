import * as ReactDOM from "react-dom";
import * as Mobx from "mobx";
import "mobx-react-lite/batchingForReactDom";
import "./app.scss";
import Root from "./pages/root";
import "react-datepicker/dist/react-datepicker.css";
import { AppStore } from "stores";
import { getUrlParam } from "core/utils";
import { doSignup, loginAuthorize } from "core/auth0";
import { setAuthInfo } from "core/storage";
import { AuthReason } from "models/settings";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_ID, IS_PROD, RUNNING_LOCALLY } from "core/constants";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { extendTheme } from '@mui/joy/styles';

import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';


ReactGA.initialize(GOOGLE_ANALYTICS_ID);

Mobx.configure({ enforceActions: "observed" });
import type { PaletteRange } from '@mui/joy/styles';

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    secondary: true;
  }

  interface Palette {
    // this will make the node `secondary` configurable in `extendTheme`
    // and add `secondary` to the theme's palette.
    secondary: PaletteRange;
  }
}
const theme = materialExtendTheme();
const joyUiTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          solidBg: 'linear-gradient(90deg, #5fc953 0%, #339900 100%)',
        },
        neutral: {
          solidBg: 'linear-gradient(90deg, #5fc953 0%, #339900 100%)',
        },
      },
    },
  },
});
const history = createBrowserHistory();

if (!RUNNING_LOCALLY) {
  Sentry.init({
    dsn: "https://e83bd47924c64021af5edb4dae9e9928@o322292.ingest.sentry.io/4505358228193280",
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    ],
    environment: IS_PROD ? "prod" : "dev",
    tracesSampleRate: 1.0,
    ignoreErrors: ["Form validation failed"],
  });
}

if (getUrlParam("signup") == "true") {
  doSignup(getUrlParam("provider"), getUrlParam("plan"), getUrlParam("promoCode"));
} else if (getUrlParam("login") == "true") {
  setAuthInfo(null, AuthReason.Login);
  loginAuthorize(getUrlParam("provider"));
} else {
  window.onload = async () => {
    await AppStore.initialize();
    ReactDOM.render(
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: theme }}>
      <JoyCssVarsProvider theme={joyUiTheme}>
        <CssBaseline enableColorScheme />
        <Root />
        <NotificationContainer />
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>, 
    document.getElementById("app"));
  };
}