import Chart, { ChartConfiguration } from "chart.js/auto";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import DatetimeDisplay from "components/datetime-display";
import DurationDisplay from "components/duration-display";
import ProjectSelect from "components/form/project-select";
import TestSuiteSelect from "components/form/test-suite-select";
import TestRunMultiStatusSelect from "components/form/testrun-multi-status-select";
import IconButton from "components/icon-button";
import Spinner from "components/spinner";
import TestFilters from "components/test-filters";
import TestSuiteLink from "components/test-suite-link";
import { calculateDuration } from "core/date-utils";
import { formatISO, parseISO } from "date-fns";
import { setPageInfo, useInitialEffect } from "core/react-utils";
import { buildRefUrl, isBlank, organizationUrl } from "core/utils";
import { map, } from "lodash";
import { observer } from "mobx-react-lite";
import { completedTestRunStatuses, getCounts, StatusColors, TestRunStatus, TestStatus } from "models/test-run";
import Page from "pages/page";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link, useHistory } from "react-router-dom";
import { Button, Dropdown, Icon, Table } from "semantic-ui-react";
import {
  AppStore,
  ModalStore,
  PipelineStore,
  ProjectStore,
  ReviewStore,
  SettingsStore,
  TestRunStore,
  TestSuiteStore,
  TriggerStore,
  UserStore,
} from "stores";
import AssignReviewUserSelect from "../../components/form/assign-review-user-select";
import TestRunReviewerSelect from "../../components/form/test-run-reviewer-select";
import { ReviewType } from "../../models/review-data";
import "./test-runs.scss";
import TestRunCard from "components/dashboard/test-run-card";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import * as Storage from "core/storage";
import EnvironmentMultiSelect from "../../components/form/environment-multi-select";
import PipelineStageMultiSelect from "../../components/form/pipeline-stage-multi-select";
import BranchMultiSelect from "../../components/form/branch-multi-select";

const TestRunsPage = observer(() => {
  const [activePage, setActivePage] = useState(1);
  const history = useHistory();
  const testRunComparisonEnabled = useFeatureIsOn("test-run-comparison-report");
  const allowUploadEnabled = useFeatureIsOn("allow-upload-test-run-override");

  setPageInfo("testruns", "Test Runs");
  Chart.register(ChartDataLabels);

  const handleQueryParams = async () => {
    let params = new URLSearchParams(window.location.search);
    const storedParams = new URLSearchParams(Storage.getTestRunsTabFilters());
    if (isBlank(params.toString()) && storedParams.size > 0) {
      params = storedParams;
      history.replace(organizationUrl(`test-runs?${params.toString()}`));
    }

    const filterEnvironmentIdParam = (params.get("environment")) ? params.get("environment").split(",").map(env => Number(env)) : [];
    const filterPipelineStageIdParam = (params.get("pipelineStage")) ? params.get("pipelineStage").split(",").map(ps => Number(ps)) : [];
    const filterProjectIdParam = parseInt(params.get("project"));
    const filterBranchParam = (params.get("branch")) ? params.get("branch").split(",") : [];
    const filterSuiteIdParam = parseInt(params.get("suite"));
    const filterReviewerIdParam = (params.get("reviewer")) ? params.get("reviewer").split(",").map(k => parseInt(k)) : null;
    const filterStatusIdParam = (params.get("status")) ? params.get("status").split(",").map(k => TestRunStatus[k]) : null;
    const filterDateParam = (params.get("date") ? parseISO(params.get("date")) : null);
    TestRunStore.setFilterProject(filterProjectIdParam);
    TestRunStore.updateFilterBranch(filterBranchParam);
    TestRunStore.setFilterTestSuite(filterSuiteIdParam);
    TestRunStore.setFilterStatus(filterStatusIdParam);
    await UserStore.ensureUserRoles();
    if (filterReviewerIdParam && filterReviewerIdParam.length > 0 || TestRunStore.filterReviewerIds && TestRunStore.filterReviewerIds.length > 0) {
      TestRunStore.setFilterReviewerIds(filterReviewerIdParam);
    }
    if (filterDateParam || TestRunStore.filterDate != null) {
      TestRunStore.updateFilterDate(filterDateParam);
    }
    if (filterEnvironmentIdParam && filterEnvironmentIdParam.length > 0 || TestRunStore.filterEnvironmentIds && TestRunStore.filterEnvironmentIds.length > 0) {
      TestRunStore.updateFilterEnvironment(filterEnvironmentIdParam);
    }
    if (filterPipelineStageIdParam && filterPipelineStageIdParam.length > 0 || TestRunStore.filterPipelineStageIds && TestRunStore.filterPipelineStageIds.length > 0) {
      TestRunStore.updateFilterPipelineStage(filterPipelineStageIdParam);
    }
    if (filterBranchParam && filterBranchParam.length > 0 || TestRunStore.filterBranches && TestRunStore.filterBranches.length > 0) {
      TestRunStore.updateFilterBranch(filterBranchParam);
    }
  };

  useInitialEffect(() => {
    SettingsStore.ensureEnvironments();
    SettingsStore.loadAuthorizations();
    ProjectStore.ensureLoaded();
    PipelineStore.ensureLoaded();
    TestRunStore.ensureLoaded(true);
    AppStore.loadAccount();
    UserStore.loadUserRoles();
    ReviewStore.loadReviewStatuses();
    return async () => {
      TestRunStore.ensureLoaded(false);
    };
  });

  useEffect(() => {
    handleQueryParams();
    SettingsStore.ensureEnvironments();
    ProjectStore.ensureLoaded();
    TriggerStore.ensureLoaded();
    TestRunStore.ensureLoaded(true);
  }, [TestRunStore.testRunsLoaded]);

  useEffect(() => {
    refreshScoreCanvas();
  });

  const newTestRunButton = (
    <Button positive={true} onClick={() => ModalStore.newTestRun.show()}>
      <Icon name="plus" />
      New Test Run
    </Button>
  );

  const uploadTestRunButton = (
    <Button positive={true} onClick={() => ModalStore.uploadTestRun.show()}>
      <Icon name="plus" />
      Upload Test Run
    </Button>
  );


  let message: any = null;

  const loader = <Spinner size={16} key={2} />;

  if (TestRunStore.testRuns.length == 0) {
    message = TestRunStore.loading ? (
      loader
    ) : (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <h2>No test runs found</h2>
        {AppStore.account.isLegacyOrTierTwoPlusPlan() && (
          <div>{newTestRunButton}</div>
        )}
        {(allowUploadEnabled || !AppStore.account.isLegacyOrTierTwoPlusPlan()) && (
          <div style={{ marginTop: "25px" }}>{uploadTestRunButton}</div>
        )}
      </div>
    );
  }

  let tableBody = [];
  loadTableBody(false);

  function loadTableBody(refreshScore: boolean) {
    TestRunStore.testRuns.forEach((testRun) => {
      const project = testRun.project;
      const completed = completedTestRunStatuses.includes(testRun.status);
      const linkToTestRun = organizationUrl(`test-runs/${testRun.id}`);
      const linkToEnvironment = organizationUrl(`environments/${testRun.environment?.id}`);
      const linkToTrigger = organizationUrl(`triggers/${testRun.triggeredTestRunId}`);
      const trigger = testRun.triggeredTestRunId && TriggerStore.find(testRun.triggeredTestRunId);
      const refLink = buildRefUrl(project, testRun);
      const triggerRefLink = buildRefUrl(ProjectStore.find(testRun.deploy?.projectId), testRun.deploy?.commit);
      const hasBranch = testRun.branch && testRun.branch != "";
      const hasRepo = project && project.hasRepo;
      const allowRerunFailed = testRun.status == TestRunStatus.FAIL && completed;

      let status = "";
      if (testRun.status === TestRunStatus.PASS) status = "pass";
      if (testRun.status === TestRunStatus.FAIL) status = "fail";

      tableBody.push(
        <Table.Row key={testRun.id} onClick={() => history.push(linkToTestRun)}>
          <Table.Cell className={`status-bar-indicator ${status}`} />
          <Table.Cell className={"general-info"}>
            <div>
              <Link onClick={(e) => e.stopPropagation()} to={linkToTestRun}>
                <DatetimeDisplay datetime={testRun.createdAt} />
              </Link>
            </div>
            {project && (
              <div>
                Proj:{" "}
                <Link onClick={(e) => e.stopPropagation()} to={organizationUrl(`projects/${project.key}`)}>
                  {testRun.project.name}
                </Link>
              </div>
            )}
            {testRun.testSuite && (
              <div style={{ display: "inline-flex" }}>
                Suite:&nbsp;
                <TestSuiteLink testSuite={testRun.testSuite} />
              </div>
            )}
            {testRun.environmentName && testRun.environmentName != "" && (
              <div>
                Env:{" "}
                <Link onClick={(e) => e.stopPropagation()} to={linkToEnvironment}>
                  {testRun.environmentName}
                </Link>
              </div>
            )}
            {trigger && trigger.name != "" && (
              <div>
                Trigger:{" "}
                <Link onClick={(e) => e.stopPropagation()} to={linkToTrigger}>
                  {trigger.name}
                </Link>
              </div>
            )}
            {testRun.build && <div>Build: {testRun.build.name}</div>}
            {hasRepo && hasBranch && <div>Branch: {testRun.branch}</div>}
            {refLink && (
              <div>
                GitRef:{" "}
                <a onClick={(e) => e.stopPropagation()} href={refLink} target={"_blank"}>
                  {testRun.gitRef.substring(0, 10)}...
                </a>
              </div>
            )}
            {triggerRefLink && triggerRefLink != refLink && (
              <div>
                Triggered By:{" "}
                <a onClick={(e) => e.stopPropagation()} href={triggerRefLink} target={"_blank"}>
                  {testRun.deploy.commit}
                </a>
              </div>
            )}
          </Table.Cell>
          {!testRun.status && (
            <Table.Cell></Table.Cell>
          )}
          {testRun.status && (
            <Table.Cell className={`status-column status-${testRun.status.toLowerCase()}`}>
              <div className="status">
                {testRun.status == TestRunStatus.RUNNING && <Spinner noText={true} size={18} />}
                {testRun.statusDisplay}
              </div>
              {testRun.statusInfo && testRun.statusInfo.includes("<testerylink>") ?
                <Link className={"info"} style={{ fontSize: ".7em", whiteSpace: "initial" }}
                      onClick={(e) => e.stopPropagation()}
                      target="_blank" rel="noopener noreferrer"
                      to={{ pathname: testRun.statusInfo.substring(testRun.statusInfo.indexOf("<testerylink>") + "<testerylink>".length, testRun.statusInfo.lastIndexOf("</testerylink>")) }}>
                  <div
                    dangerouslySetInnerHTML={{ __html: testRun.statusInfo.substring(testRun.statusInfo.indexOf("<testerytext>") + "<testerytext>".length, testRun.statusInfo.lastIndexOf("</testerytext>")) }} />
                </Link>
                :
                <div className="info" style={{ fontSize: ".7em", whiteSpace: "initial" }}>{testRun.statusInfo}</div>
              }
              <DurationDisplay duration={calculateDuration(testRun.startTime, testRun.endTime)} />
            </Table.Cell>
          )}
          <Table.Cell>
            <div id={`progressChart${testRun.id}Container`} style={{ position: "relative", maxHeight: "110px" }} />
          </Table.Cell>
          <Table.Cell className={"tagsAndFilters"}>
            <TestFilters
              includeTags={testRun.includeTags}
              excludedTags={testRun.excludedTags}
              testFilters={testRun.testFilters}
              fade={true}
            />
          </Table.Cell>
          <Table.Cell className={"assign-user"} onClick={(e) => e.stopPropagation()}>
            <div>
              <AssignReviewUserSelect
                reviewType={ReviewType.TEST_RUN}
                testRunId={testRun.id}
                testRunReviewerUserId={testRun.reviewerUserId}
                testRunTestId={null}
                testRunTestReviewerUserId={null}
              />
              {/*<ReviewStatusSelect*/}
              {/*  reviewType={ReviewType.TEST_RUN}*/}
              {/*  testRunId={testRun.id}*/}
              {/*  testRunReviewStatusId={testRun.reviewStatusId}*/}
              {/*/>*/}
              {testRunComparisonEnabled && (
                <Button onClick={() => {
                  if (TestRunStore.testRunComparison1) {
                    TestRunStore.updateTestRunComparison2(testRun);
                  } else {
                    TestRunStore.updateTestRunComparison1(testRun);

                  }
                }}>
                  Add to Test Comparison
                </Button>
              )}
            </div>
          </Table.Cell>
          <Table.Cell className={"actions"} onClick={(e) => e.stopPropagation()}>
            <div>
              {AppStore.account.isLegacyOrTierTwoPlusPlan() && (
                <>
                  {completed ? (
                    <>
                      {allowRerunFailed ? (
                        <Dropdown icon={"refresh"} direction={"left"}>
                          <Dropdown.Menu>
                            <Dropdown.Header content={"Rerun Options"} />
                            <Dropdown.Divider />
                            <Dropdown.Item
                              icon={"redo"}
                              content={"Rerun Test Run"}
                              onClick={() => {
                                TestRunStore.rerunTestRun(testRun);
                                handlePageChange(0);
                              }}
                            />
                            <Dropdown.Item
                              icon={"undo"}
                              content={"Rerun Failed Tests"}
                              onClick={() => TestRunStore.rerunFailedTests(testRun.id)}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <IconButton
                          icon="redo"
                          label="Rerun"
                          onClick={() => {
                            TestRunStore.rerunTestRun(testRun);
                            handlePageChange(0);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <IconButton
                      icon="stop circle outline"
                      label="Cancel"
                      onClick={() => TestRunStore.cancelTestRun(testRun)}
                    />
                  )}
                </>
              )}
              <a href={linkToTestRun} target="_blank">
                <Icon title="Open test run in new window" name="external alternate" />
              </a>
            </div>
          </Table.Cell>
        </Table.Row>,
      );
    });

    if (refreshScore) refreshScoreCanvas();
  }

  function refreshScoreCanvas() {
    TestRunStore.testRuns.forEach((testRun) => {
      getCounts(testRun).then(counts => {
          const { totalCount, passCount, failCount, pendingCount, runningCount } = counts;
          const completed = completedTestRunStatuses.includes(testRun.status);
          const canceled = testRun.status === TestRunStatus.CANCELED;

          const canvasContainer = document.getElementById(`progressChart${testRun.id}Container`);
          if (!canvasContainer) return;

          while (canvasContainer.firstChild) canvasContainer.removeChild(canvasContainer.firstChild);

          const canvas = document.createElement("canvas");
          canvasContainer.append(canvas);
          if (completed && !canceled) {
            const passPercentageContainer = document.createElement("div");
            passPercentageContainer.className = "pass-percentage";
            const passPercentage = document.createElement("h5");

            if (totalCount == 0) return;

            passPercentage.innerText = `${Math.floor((passCount * 100) / totalCount)}%`;
            passPercentageContainer.append(passPercentage);

            canvasContainer.append(passPercentageContainer);
          }

          const doughnutChartConfig: ChartConfiguration = {
            type: "doughnut",
            data: {
              datasets: [],
              labels: [],
            },
            options: {
              cutout: "70%",
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  top: 12,
                  bottom: 12,
                },
              },
              animation: {
                duration: 0,
              },
              plugins: {
                legend: {
                  display: false,
                  position: "right",
                  labels: {
                    usePointStyle: true,
                  },
                },
                datalabels: {
                  // @ts-ignore
                  backgroundColor: (context: Context) => {
                    return context.dataset.backgroundColor;
                  },
                  borderColor: "#FFF",
                  borderRadius: 25,
                  borderWidth: 2,
                  color: "#FFF",
                  font: {
                    weight: "bold",
                    size: 13,
                  },
                  padding: 3,
                  formatter: Math.round,
                },
              },
              events: ["mousemove", "mouseout", "touchstart", "touchmove"],
            },
            plugins: [ChartDataLabels],
          };

          const chart = new Chart(canvas.getContext("2d"), doughnutChartConfig);
          const data = [];
          if (passCount > 0) data.push({
            value: passCount,
            label: "Passing",
            backgroundColor: StatusColors[TestStatus.PASS],
          });
          if (failCount > 0) data.push({
            value: failCount,
            label: "Failing",
            backgroundColor: StatusColors[TestStatus.FAIL],
          });
          if (runningCount > 0) data.push({
            value: runningCount,
            label: "Running",
            backgroundColor: StatusColors[TestStatus.RUNNING],
          });
          if (pendingCount > 0) data.push({
            value: pendingCount,
            label: "Pending",
            backgroundColor: StatusColors[TestStatus.PENDING],
          });

          chart.data = {
            labels: map(data, (d) => d.label),
            datasets: [
              {
                data: map(data, (d) => d.value),
                backgroundColor: map(data, (d) => d.backgroundColor),
                datalabels: {
                  anchor: "end",
                },
              },
            ],
          };

          chart.update();
        },
      );
    });
  }

  const projectOptions = [];
  ProjectStore.active.forEach((proj) => {
    projectOptions.push({ key: proj.id, text: proj.name, value: proj.id });
  });

  const handlePageChange = async (direction: number) => {
    const newPage = direction == 0 ? 1 : (activePage + direction);
    setActivePage(newPage);
    TestRunStore.loadPageChange(newPage).then(() => {
      loadTableBody(true);
    });
  };

  const onChangeProject = (projectId: number) => {
    if (ProjectStore.loaded) {
      setActivePage(1);
      updateHistory("project", projectId?.toString());
      TestRunStore.updateFilterProject(projectId);
      if (!projectId)
        onChangeBranch([]);
    }
  };

  const onChangeEnvironment = (environmentIds: Array<number>) => {
    setActivePage(1);
    updateHistory("environment", environmentIds?.join(","));
    TestRunStore.updateFilterEnvironment(environmentIds);
  };

  const onChangePipelineStage = (pipelineStageIds: Array<number>) => {
    setActivePage(1);
    updateHistory("pipelineStage", pipelineStageIds?.join(","));
    TestRunStore.updateFilterPipelineStage(pipelineStageIds);
  };

  const onChangeBranch = (branches: Array<string>) => {
    setActivePage(1);
    updateHistory("branch", branches?.join(","));
    TestRunStore.updateFilterBranch(branches);
  };

  const onChangeStatus = (statuses: TestRunStatus[]) => {
    setActivePage(1);
    updateHistory("status", statuses.join(","));
    TestRunStore.updateFilterStatus(statuses);
  };

  const onChangeReviewer = (reviewerIds: number[]) => {
    setActivePage(1);
    updateHistory("reviewer", reviewerIds.join(","));
    TestRunStore.updateFilterReviewerIds(reviewerIds);
  };

  const onChangeTestSuite = (testSuiteId: number) => {
    if (TestSuiteStore.loaded) {
      setActivePage(1);
      updateHistory("suite", testSuiteId?.toString());
      TestRunStore.updateFilterTestSuite(testSuiteId);
    }
  };

  const onChangeDate = (date: Date) => {
    setActivePage(1);
    updateHistory("date", (date) ? formatISO(date) : null);
    TestRunStore.updateFilterDate(date);
  };

  const updateHistory = (newKey: string, newValue: string) => {
    const params = new URLSearchParams(window.location.search);
    if (params.toString().includes("null"))
      params.delete("null");

    if (newValue) {
      params.set(newKey, newValue);
    } else {
      params.delete(newKey);
    }
    if (params.size > 0)
      Storage.setTestRunsTabFilters(params.toString());
    else
      Storage.clearTestRunsTabFilters();
    history.replace(organizationUrl(`test-runs?${params.toString()}`));
  };

  const linkToTestRunComparisonReport = () => {
    history.push(`/testery/dashboard/analytics/test-run-comparison?test_run_ids=${TestRunStore.testRunComparison1.id},${TestRunStore.testRunComparison2.id}`);
  };

  const clearFilters = () => {
    const params = new URLSearchParams(window.location.search);
    params.forEach((_value, key) => updateHistory(key, null));
    handleQueryParams();
  };

  return (
    <Page
      name="testruns"
      title="Test Runs"
      additionalCssClass={TestRunStore.loading ? " no-scroll" : null}
      noHeader={true}
    >
      <div className="list-filter-container">
        <div className="list-filters">
          {AppStore.account.isLegacyOrTierTwoPlusPlan() && (
            <div>{newTestRunButton}</div>
          )}
          {(allowUploadEnabled || !AppStore.account.isLegacyOrTierTwoPlusPlan()) && (
            <div>{uploadTestRunButton}</div>
          )}
          <DatePicker
            isClearable
            selected={TestRunStore.filterDate}
            onChange={onChangeDate}
            placeholderText="Any Date"
          />
          <ProjectSelect
            value={TestRunStore.filterProjectId}
            placeholder="Any Project"
            onChange={onChangeProject}
            allowOtherFramework={true}
          />
          <BranchMultiSelect
            value={TestRunStore.filterBranches}
            placeholder={!TestRunStore.filterProjectId || (TestRunStore.filterProjectId && ProjectStore.find(TestRunStore.filterProjectId)?.gitProvider) ? "Any Branch" : "No Repo Linked To Project"}
            onChange={onChangeBranch}
          />
          <TestSuiteSelect
            projectId={TestRunStore.filterProjectId}
            value={TestRunStore.filteredTestSuiteId}
            placeholder="Any Test Suite"
            onChange={onChangeTestSuite}
          />
          <EnvironmentMultiSelect
            value={TestRunStore.filterEnvironmentIds}
            placeholder="Any Environment"
            onChange={onChangeEnvironment}
          />
          <PipelineStageMultiSelect
            value={TestRunStore.filterPipelineStageIds}
            placeholder="Any Pipeline Stage"
            onChange={onChangePipelineStage}
          />
          <TestRunMultiStatusSelect
            value={TestRunStore.filterStatus}
            placeholder="Any Status"
            onChange={onChangeStatus}
          />
          <TestRunReviewerSelect
            value={TestRunStore.filterReviewerIds}
            placeholder="Any Reviewer"
            onChange={onChangeReviewer}
          />
          <div><Button onClick={clearFilters}><Icon name={"x"} />Clear Filters</Button></div>
        </div>
      </div>
      {testRunComparisonEnabled && (
        <div className="comparison-container">
          {TestRunStore.testRuns.length > 1 && (
            <div>
              <div className="ui grid">
                {TestRunStore.testRunComparison1 && (
                  <div className="eight wide column">
                    <TestRunCard key={TestRunStore.testRunComparison1.id} testRun={TestRunStore.testRunComparison1} />
                  </div>
                )}
                {TestRunStore.testRunComparison2 && (
                  <div className="eight wide column">
                    <TestRunCard key={TestRunStore.testRunComparison2.id} testRun={TestRunStore.testRunComparison2} />
                  </div>
                )}
              </div>
              {TestRunStore.testRunComparison1 && TestRunStore.testRunComparison2 && (
                <Button className="ui positive button" color="green" onClick={() => {
                  linkToTestRunComparisonReport();
                }}>
                  Compare Test Runs
                </Button>
              )}
            </div>
          )}
        </div>
      )}
      <div className={message ? "noTestRuns" : "testRuns"}>
        {message || (
          <Table selectable={true} striped={true} unstackable={true}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className={"status-bar-indicator"} />
                <Table.HeaderCell className={"general-info"}>General Info</Table.HeaderCell>
                <Table.HeaderCell className={"status-column"}>Status</Table.HeaderCell>
                <Table.HeaderCell className={"score"}>Score</Table.HeaderCell>
                <Table.HeaderCell className={"tagsAndFilters"}>Tags / Filters</Table.HeaderCell>
                <Table.HeaderCell className={"assign-user"} />
                <Table.HeaderCell className={"actions"} />
              </Table.Row>
            </Table.Header>

            <Table.Body>{tableBody}</Table.Body>

            <Table.Footer fullWidth={true}>
              <Table.Row>
                <Table.Cell colSpan={2}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {activePage > 1 &&
                      <IconButton marginRight={0} icon={"chevron left"} onClick={() => handlePageChange(-1)} />
                    }
                    <div style={{ display: "flex", paddingLeft: "5px", paddingRight: "5px" }}>Page {activePage}</div>
                    {TestRunStore.testRuns && TestRunStore.testRuns.length > 0 && (
                      <IconButton marginRight={0} icon={"chevron right"} onClick={() => handlePageChange(1)} />
                    )}
                    <Button style={{ background: "transparent" }} disabled={activePage == 1}
                            onClick={() => handlePageChange(0)}>Reset to page 1</Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </div>
    </Page>
  );
});

export default TestRunsPage;

