import Form from "./form";
import { action, observable } from "mobx";
import { notEmpty } from "core/validation";
import Project from "models/project";

export default class BuildForm extends Form {
  @observable id: number;
  @observable projectId: number;
  @observable name: string;
  @observable branch: string;
  @observable commit: string;
  @observable file: File;


  validators = {
    name: notEmpty,
  };

  constructor() {
    super();
  }

  @action
  setDefaults() {
    this.name = "";
  }

  @action
  populateFromProject(project: Project) {
    this.projectId = project.id;
  }
}
