import { TestDistributionRule, TestDistributionRuleAssignedTo, TestDistributionRuleType } from "models/settings";
import Form from "./form";
import { action, observable } from "mobx";

export default class TestDistributionAnalysisRuleForm extends Form {
  
  @action
  populateFromRule(form: TestDistributionRule) {
    this.populate(form);
  }

  @observable id: number;
  @observable orderToApply: number;
  @observable ruleType: TestDistributionRuleType;
  @observable assignedTo: TestDistributionRuleAssignedTo;
  @observable projectId: number;
  @observable testSuiteId: number;
  @observable includePatterns: string[];

  validators = { };

  @action
  setDefaults() {
    this.includePatterns = [];
  }
}
