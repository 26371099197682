import { format } from "date-fns";
import { parseServerDateTime } from "core/date-utils";

interface Props {
  datetime: string;
  displayFormat?: string;
  showSecond?: boolean;
  hideDate?: boolean;
  hideYear?: boolean;
  dateOnly?: boolean;
}

const DatetimeDisplay = ({
  datetime,
  showSecond,
  hideDate,
  hideYear,
  displayFormat,
  dateOnly,
}: Props) => {
  const formatDate = hideDate ? "" : hideYear ? "MMM dd " : "MMM dd, yyyy ";
  let formatTemplate = formatDate + (showSecond ? "h:mm:ss a" : "h:mm a");

  if (dateOnly) {
    formatTemplate = "MM/dd/yyyy";
  }

  if (displayFormat) {
    formatTemplate = displayFormat;
  }

  return datetime ? <span>{format(parseServerDateTime(datetime), formatTemplate)}</span> : null;
};

export default DatetimeDisplay;
