import { useState } from "react";
import { Dropdown, Icon, Popup, SemanticICONS } from "semantic-ui-react";
import "./popup-menu.scss";

export interface MenuItem {
  icon?: SemanticICONS;
  text: string;
  onClick: () => any;
}

export type PopupMenuItem = MenuItem | "divider";

interface Props {
  items: PopupMenuItem[];
  direction?: "left" | "right";
  icon: SemanticICONS;
}

export default function PopupMenu({ items, direction, icon }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <Popup
      className="popup-menu"
      position={direction == "right" ? "right center" : "left center"}
      trigger={<Icon name={icon} />}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      content={
        <Dropdown.Menu className={"ui dropdown" + (items.length == 1 ? " single" : "")}>
          {items.map((item, i) =>
            item == "divider" ? (
              <Dropdown.Divider key={i} />
            ) : (
              <Dropdown.Item
                key={i}
                onClick={(e) => {
                  e.stopPropagation();
                  item.onClick();
                  setOpen(false);
                }}
              >
                {item.icon && <Icon name={item.icon} />}
                {item.text}
              </Dropdown.Item>
            ),
          )}
        </Dropdown.Menu>
      }
      hoverable
    />
  );
}
