import { calculateDuration, parseServerDateTime } from "core/date-utils";
import { type } from "core/serialization";
import { isNotBlank } from "core/utils";
import { findIndex, orderBy, sumBy } from "lodash";
import { computed, observable } from "mobx";
import { SettingsStore, TestPlanStore, TestSuiteStore } from "stores";
import { EntityWithDates } from "./entity";
import { Build } from "./project";
import { TestRun, TestRunStatus } from "./test-run";
import { User } from "./user";

export enum ExecutionType {
  Parallel = "Parallel",
  Sequential = "Sequential",
}

export class TestPlanSuite extends EntityWithDates {
  @observable
  testPlanStageId: number;
  @observable
  order: number;
  testSuiteId: number;
  environmentId: number;
  dynamicEnvironment: string;
  latestDeploy: boolean;
  @observable
  archived: boolean;
  branch: string;
  commit: string;
  buildId: number;
  @type(Build)
  build: Build;

  @computed
  get environment() {
    return SettingsStore.findEnvironment(this.environmentId);
  }

  @computed
  get suite() {
    return TestSuiteStore.find(this.testSuiteId);
  }
  
  @computed
  get environmentDisplay() {
    return this.environment ? this.environment.name : this.dynamicEnvironment;
  }
}

export class TestPlanStage extends EntityWithDates {
  @observable
  order: number;
  @observable
  archived: boolean;
  name: string;
  description: string;
  executionType: ExecutionType;
  alwaysRun: boolean;
  testPlanId: number;
  @observable
  @type(TestPlanSuite)
  suites: TestPlanSuite[] = [];

  @computed
  get activeSuites() {
    return this.suites.filter((t) => !t.archived);
  }
}

export class TestPlan extends EntityWithDates {
  @observable
  archived: boolean;
  name: string;
  goal: string;
  lastExecutedAt: string;
  @observable
  @type(TestPlanStage)
  stages: TestPlanStage[] = [];
  key: string;

  @computed
  get activeStages() {
    return orderBy(
      this.stages.filter((t) => !t.archived),
      "order",
    );
  }

  @computed
  get lastExecutedAtDate() {
    return parseServerDateTime(this.lastExecutedAt);
  }

  @computed
  get hasTestSuites() {
    return this.activeStages.some((stage) => stage.activeSuites.length > 0);
  }

  @computed
  get hasDynamicEnvironments() {
    return this.activeStages.some((stage) => stage.activeSuites.some((suite) => suite.dynamicEnvironment));
  }
}

enum TestPlanRunStatus {
  Running = "Running",
  Error = "Error",
  Completed = "Completed",
  Canceled = "Canceled",
}

export class TestPlanRunSuite extends EntityWithDates {
  testPlanRunStageId: number;
  testPlanSuiteId: number;
  order: number;
  environmentId: number;
  dynamicEnvironment: string;
  branch: string;
  commit: string;
  buildId: number;
  @type(Build)
  build: Build;
  testRunId: number;
  @type(TestRun)
  testRun: TestRun;

  get isPass() {
    return this.testRun?.status == TestRunStatus.PASS;
  }

  get isFail() {
    return this.isComplete && !this.isPass;
  }

  get isComplete() {
    return !this.isIncomplete;
  }

  get isNotRun() {
    return !this.testRun;
  }

  get isRunning() {
    return !this.isNotRun && this.isIncomplete;
  }

  get isIncomplete() {
    return !this.testRun || this.testRun.notCompleted;
  }

  get isError() {
    return this.isFail && isNotBlank(this.testRun?.statusInfo);
  }

  @computed
  get passTestCount() {
    return this.testRun?.passCount || 0;
  }

  @computed
  get failTestCount() {
    return this.testRun?.failCount || 0;
  }

  @computed
  get totalTestCount() {
    return this.testRun ? this.testRun.totalCount - this.testRun.ignoredCount : 0;
  }

  @computed
  get duration() {
    return this.testRun ? calculateDuration(this.testRun.startTime, this.testRun.endTime) : 0;
  }

  @computed
  get environment() {
    return SettingsStore.findEnvironment(this.environmentId);
  }
}

export class TestPlanRunStage extends EntityWithDates {
  name: string;
  testPlanRunId: number;
  testPlanStageId: number;
  order: number;
  executionType: ExecutionType;
  alwaysRun: boolean;
  @type(TestPlanRunSuite)
  suites: TestPlanRunSuite[] = [];

  @computed
  get isPass() {
    return !this.suites.some((s) => !s.isPass);
  }

  @computed
  get isFail() {
    return !this.suites.some((s) => s.isIncomplete) && this.suites.some((s) => s.isFail);
  }

  @computed
  get isIncomplete() {
    return this.suites.some((s) => s.isIncomplete);
  }

  @computed
  get isError() {
    return this.suites.some((s) => s.isError);
  }

  get isOpen() {
    return TestPlanStore.openTestPlanRunStages[this.id] === true;
  }

  @computed
  get isRunning() {
    return this.hasResults && this.isIncomplete;
  }

  get hasResults() {
    return this.suites.some((s) => !s.isNotRun);
  }

  get isSkipped() {
    return !this.hasResults;
  }

  @computed
  get duration() {
    return sumBy(this.suites, (s) => s.duration);
  }

  @computed
  get passTestCount() {
    return sumBy(this.suites, (t) => t.passTestCount);
  }

  @computed
  get failTestCount() {
    return sumBy(this.suites, (t) => t.failTestCount);
  }

  @computed
  get totalTestCount() {
    return sumBy(this.suites, (t) => t.totalTestCount);
  }
}
export class TestPlanRun extends EntityWithDates {
  testPlanId: number;
  status: TestPlanRunStatus;
  statusUpdatedBy: User;
  @type(TestPlanRunStage)
  stages: TestPlanRunStage[] = [];
  completedAt: string;

  @computed
  get isCompleted() {
    return this.status != TestPlanRunStatus.Running;
  }

  @computed
  get isRunning() {
    return this.status == TestPlanRunStatus.Running;
  }

  get isCanceled() {
    return this.status == TestPlanRunStatus.Canceled;
  }

  @computed
  get testPlan() {
    return TestPlanStore.find(this.testPlanId);
  }

  @computed
  get isOpen() {
    return TestPlanStore.openTestPlanRuns[this.id] === true;
  }

  @computed
  get passTestCount() {
    return sumBy(this.stages, (t) => t.passTestCount);
  }

  @computed
  get failTestCount() {
    return sumBy(this.stages, (t) => t.failTestCount);
  }

  @computed
  get totalTestCount() {
    return sumBy(this.stages, (t) => t.totalTestCount);
  }

  @computed
  get passStageCount() {
    return this.stages.filter((s) => s.isPass).length;
  }

  @computed
  get failStageCount() {
    return this.stages.filter((s) => s.isFail).length;
  }

  @computed
  get skippedStageCount() {
    return this.stages.filter((s) => this.isStageSkipped(s)).length;
  }

  @computed
  get totalStageCount() {
    return this.testPlan.activeStages.length;
  }

  isStageSkipped(stage: TestPlanRunStage) {
    if (this.isCompleted || stage.hasResults) {
      return stage.isSkipped;
    }

    return this.nextStageStarted(stage.id);
  }

  nextStageStarted(stageId: number) {
    const index = findIndex(this.stages, (s) => s.id == stageId);

    return this.stages.some((s, i) => {
      return i <= index ? false : s.hasResults;
    });
  }
}
