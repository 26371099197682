import { Invitation, RoleType, User, UserRole } from "models/user";
import { action, computed, observable, runInAction } from "mobx";
import API from "core/api";
import UserInvitationForm from "models/forms/user-invitation-form";
import { sortBy } from "lodash";
import TellAFriendForm from "models/forms/tell-a-friend-form";

export class UserStoreClass {
  @observable userRoles: UserRole[] = [];
  usersForSelect: User[] = [];
  @observable userRolesLoaded = false;
  @observable invitations: Invitation[] = [];
  @observable invitationsLoaded = false;

  @action
  clear() {
    this.userRolesLoaded = false;
    this.invitationsLoaded = false;
    this.invitations = [];
    this.userRoles = [];
    this.usersForSelect = [];
  }

  @action
  async loadUserRoles() {
    const { data } = await API.getList("/user-roles", UserRole);
    runInAction(() => {
      this.userRolesLoaded = true;
      this.userRoles = data;
    });
    return data;
  }

  async ensureUserRoles() {
    if (!this.userRolesLoaded || this.userRoles.length === 0) {
      await this.loadUserRoles();
    }
  }

  resetUsersForSelect() {
    this.usersForSelect = [];
  }

  @computed
  get usersForDropdown() {
    if (this.usersForSelect && this.usersForSelect.length > 0)
      return this.usersForSelect;

    this.usersForSelect = [];
    this.userRoles.forEach(role => this.usersForSelect.push(role.user));

    return this.usersForSelect;
  }

  @computed
  get usersForReviewFilter(): Array<User> {
    const unassignedUser = new User();
    unassignedUser.id = 0;
    unassignedUser.firstName = "Not";
    unassignedUser.lastName = "Assigned";

    const users = [...this.usersForDropdown];
    users.push(unassignedUser);

    return sortBy(
      users,
      u => u.id,
    );
  }

  getUser(userId: number) {
    return this.userRoles.find((r) => r.user.id == userId)?.user;
  }

  @action
  async loadInvitations() {
    const { data } = await API.getList("/account/invitations", Invitation);
    runInAction(() => {
      this.invitationsLoaded = true;
      this.invitations = data;
    });
    return data;
  }

  @action
  async deleteInvitation(id: number) {
    await API.delete(`/account/invitations/${id}`);
    await this.loadInvitations();
  }

  @action
  async updateRole(id: number, roleType: RoleType) {
    await API.put(`/user-roles/${id}`, { id, roleType });
    await this.loadUserRoles();
  }

  @action
  async deleteRole(id: number) {
    await API.delete(`/user-roles/${id}`);
    runInAction(() => (this.userRoles = this.userRoles.filter((r) => r.id != id)));
  }

  @action
  async inviteUser(form: UserInvitationForm) {
    return form.submit(async (data) => {
      await API.post("/user-roles", data);
      await this.loadInvitations();
    });
  }

  @action
  async tellAFriend(form: TellAFriendForm) {
    return form.submit(async (data) => {
      await API.post("/tell-a-friend", data);
      runInAction(() => {
        form.isSubmitted = true;
      });
    });
  }

  isEmailInUse(email: string) {
    return (
      !!this.invitations.find((i) => i.email == email) ||
      !!this.userRoles.find((u) => u.user.email == email)
    );
  }
}
