import { observer } from "mobx-react-lite";
import { ModalStore } from "stores";
import { Button, Icon, Modal, Form } from "semantic-ui-react";
import PipelineEnvironmentForm from "models/forms/pipeline-environment-form";
import PipelineStageSelect from "components/form/pipeline-stage-select";
import EnvironmentSelect from "components/form/environment-select";

const form = new PipelineEnvironmentForm();

const PipelineEnvironment = observer(() => {
  const { pipelineEnvironment } = ModalStore;

  pipelineEnvironment.onShown = async () => {
    form.clearFormData();
    form.populateValues(
      pipelineEnvironment.state.pipelineStageId,
      pipelineEnvironment.state.environmentId,
    );
  };

  const save = () =>
    form.submit(async (data) => {
      pipelineEnvironment.hide({
        ...pipelineEnvironment.state,
        pipelineStageId: data.pipelineStageId,
        environmentId: data.environmentId,
      });
    });

  return (
    <Modal size="mini" open={pipelineEnvironment.showing} id="organization-select">
      <Modal.Header>Select an Environment</Modal.Header>
      <Modal.Content>
        <Form className="pipeline-environment">
          <PipelineStageSelect
            value={form.pipelineStageId}
            label="Pipeline Stage"
            onChange={form.onChange("pipelineStageId")}
            error={form.getErrorText("pipelineStageId")}
          />
          <EnvironmentSelect
            pipelineStageId={form.pipelineStageId}
            value={form.environmentId}
            label="Environment"
            onChange={form.onChange("environmentId")}
            error={form.getErrorText("environmentId")}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button size="small" onClick={() => pipelineEnvironment.hide()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button size="small" onClick={save} color="green" loading={form.isSubmitting}>
          <Icon name="check" />
          {pipelineEnvironment.state.saveText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default PipelineEnvironment;
