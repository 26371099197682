import { action, observable } from "mobx";
import { emptyOrValidUrl, isNumeric, keyNotInUse, nameNotInUse, notEmpty } from "core/validation";
import { Environment } from "models/settings";
import { SettingsStore } from "stores";
import { WithEnvironmentVarsForm } from "./environment-var-form";

export default class EnvironmentForm extends WithEnvironmentVarsForm {
  @observable id: number;
  @observable pipelineStageId: number;
  @observable name: string;
  @observable key: string;
  @observable url: string;
  @observable maximumParallelTestRuns: number;

  validators = {
    name: [notEmpty, nameNotInUse(this, () => SettingsStore.activeEnvironments)],
    key: [notEmpty, keyNotInUse(this, () => SettingsStore.environments)],
    url: emptyOrValidUrl,
    maximumParallelTestRuns: isNumeric,
  };

  emptyToNull = ["url"];

  @action
  setDefaults() {
    super.setDefaults();
    this.name = "";
    this.key = "";
    this.url = "";
    this.pipelineStageId = null;
    this.maximumParallelTestRuns = 0;
  }

  @action
  populateFromEnvironment(env: Environment) {
    this.populate(env);
    this.populateVariables(env.variables);
  }
}
