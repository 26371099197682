import { observer } from "mobx-react-lite";
import { AppStore, SettingsStore } from "stores";
import { Checkbox, Form, Icon, Popup } from "semantic-ui-react";
import NotificationForm from "models/forms/notification-form";
import { AuthorizationProvider } from "models/settings";
import { useInitialEffect } from "core/react-utils";

const form = new NotificationForm();

export async function saveNotificationSettings() {
  await AppStore.updateNotificationSettings(form);
}

const NotificationSettings = observer(() => {
  useInitialEffect(() => {
    SettingsStore.loadAuthorizations();
    form.populate(AppStore.getSelectedAccountRole());
  });

  const hasSlackIntegration = SettingsStore.authorizations.find(
    (a) => a.provider == AuthorizationProvider.Slack,
  );

  return (
    <Form onSubmit={saveNotificationSettings}>
      <Checkbox
        label="Send Email Notifications"
        checked={form.notifyByEmail}
        toggle
        onChange={() => form.updateField("notifyByEmail", !form.notifyByEmail)}
      />
      <br />
      <br />
      {hasSlackIntegration && (
        <>
          <Checkbox
            label={"Send Slack Notifications"}
            checked={form.notifyBySlack}
            toggle
            onChange={() => form.updateField("notifyBySlack", !form.notifyBySlack)}
          />
          <Popup
            hoverable={true}
            content={
              <span>Click here for information about finding your Slack Member ID</span>
            }
            trigger={<a href={"https://docs.testery.io/get-to-know-testery/settings"} target={"_blank"}> <Icon
              name="question circle" /></a>}
          />
          <br />
          <br />
        </>
      )}
    </Form>
  );
});

export default NotificationSettings;
