import SortableTable from "components/sortable-table";
import TestFilters from "components/test-filters";
import ThreeDotMenu from "components/three-dot-menu";
import { Icons } from "core/constants";
import { useInitialEffect } from "core/react-utils";
import { relativeLink, spacesInCamelCase } from "core/utils";
import { observer } from "mobx-react-lite";
import Project, { TestSuite } from "models/project";
import { useHistory } from "react-router";
import { Button, Icon } from "semantic-ui-react";
import { TestSuiteStore, ModalStore } from "stores";

interface Props {
  project: Project;
}

const TestSuitesTab = observer(({ project }: Props) => {
  const history = useHistory();

  useInitialEffect(() => {
    TestSuiteStore.loadAll();
  });

  const deleteTestSuite = (testSuite: TestSuite) => {
    ModalStore.confirmation.show(
      async (okClicked: boolean) => {
        if (okClicked) {
          await TestSuiteStore.delete(testSuite.projectId, testSuite.id);
          TestSuiteStore.loadAll();
        }
      },
      {
        message: `Are you sure you want to archive ${testSuite.name}?`,
        okText: "Archive",
        okColor: "red",
      },
    );
  };

  let headerColumns = [
    {
      className: "name",
      content: "Name",
      sortKey: "name",
    },
    {
      className: "type",
      content: "Type",
      sortKey: "type",
    },
    {
      className: "config",
      content: "Config",
    },
    {
      className: "actions",
      content: "",
    },
  ];

  const edit = (id: string | number) => history.push(relativeLink(`test-suites/${id}`));
  const run = (suite: TestSuite) => ModalStore.runTestSuite.show(null, suite);

  if (!project.hasTests) {
    return (
      <div className="test-suites-tab">
        <h3>
          This project does not contain tests. If this is a mistake change the project setting to
          allow tests.
        </h3>
        <Button color="green" onClick={() => history.push("settings")}>
          Edit Project Settings
        </Button>
      </div>
    );
  }

  return (
    <div className="test-suites-tab">
      <Button color="green" onClick={() => edit("new")}>
        <Icon name="plus" />
        Add Test Suite
      </Button>
      <br />
      <SortableTable
        className="deploys"
        loading={!TestSuiteStore.loaded}
        headerColumns={headerColumns}
        items={TestSuiteStore.getActiveForProject(project.id)}
        defaultSortKey="name"
        renderRow={(s) => (
          <tr key={s.id}>
            <td className="name">{s.name}</td>
            <td className="type">{spacesInCamelCase(s.type)}</td>
            <td className="config">
              <TestFilters
                includeTags={s.includeTags}
                excludedTags={s.excludedTags}
                testFilters={s.testFilters}
              />
            </td>
            <td className="actions">
              <ThreeDotMenu
                items={[
                  { icon: Icons.run, text: "Run Test Suite", onClick: () => run(s) },
                  { icon: Icons.edit, text: "Edit Suite", onClick: () => edit(s.id) },
                  { icon: Icons.delete, text: "Delete Suite", onClick: () => deleteTestSuite(s) },
                ]}
              />
            </td>
          </tr>
        )}
      />
    </div>
  );
});

export default TestSuitesTab;
