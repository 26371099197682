import WorkflowSettings from "components/form/workflow-settings";
import { Workflow } from "models/workflow";

interface Props {
  workflow: Workflow;
}

const SettingsTab = ({ workflow }: Props) => (
  <div className="settings-tab">
    <WorkflowSettings workflow={workflow} />
  </div>
);

export default SettingsTab;
