import { observer } from "mobx-react-lite";
import { Button, Dropdown, Form, Modal } from "semantic-ui-react";
import { ModalStore, TrackedIssueStore } from "../../../stores";
import { useEffect, useState } from "react";
import { useInitialEffect } from "../../../core/react-utils";
import ReactSelect from "react-select";
import JiraIssueForm from "../../../models/forms/jira-issue-form";
import { isBlank } from "../../../core/utils";
import Spinner from "../../spinner";

const { createJiraModal } = ModalStore;

const JiraModal = observer(() => {
  const [loading, setLoading] = useState(true);
  const [projectSelectedLoading, setProjectSelectedLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Map<string, string>>(null);
  const [projectOptions, setProjectOptions] = useState<Array<Map<string, string>>>(null);
  const [issueTypeOptions, setIssueTypeOptions] = useState<Array<Map<string, string>>>(null);
  const [issuePriorityOptions, setIssuePriorityOptions] = useState<Array<Map<string, string>>>(null);
  const [userOptions, setUserOptions] = useState<Array<Map<string, string>>>(null);
  const jiraIssueForm: JiraIssueForm = createJiraModal.state;

  createJiraModal.onHidden = () => {
    jiraIssueForm.clearFormData();
  };

  useInitialEffect(async () => {
    await TrackedIssueStore.loadJiraProjects("");
    setLoading(false);
  });

  useEffect(() => {
    setProjectOptions(getProjectOptions());
  }, [TrackedIssueStore.jiraProjects]);

  useEffect(() => {
    const issueTypes = TrackedIssueStore.jiraIssueTypes;
    const issueTypeOptions = [];
    if (issueTypes)
      issueTypes.forEach((k) => issueTypeOptions.push({ label: k["name"], value: k["id"] }));
    setIssueTypeOptions(issueTypeOptions.length > 0 ? issueTypeOptions : null);

    const issuePriorities = TrackedIssueStore.jiraIssuePriorities;
    const issuePriorityOptions = [];
    if (issuePriorities)
      issuePriorities.forEach((k) => issuePriorityOptions.push({ label: k["name"], value: k["id"] }));
    setIssuePriorityOptions(issuePriorityOptions.length > 0 ? issuePriorityOptions : null);

    const users = TrackedIssueStore.jiraUsers;
    const userOptions = [];
    if (users)
      users.forEach((k) => userOptions.push({ label: k["displayName"], value: k["accountId"] }));
    setUserOptions(userOptions.length > 0 ? userOptions : null);
  }, [TrackedIssueStore.jiraIssueTypes]);

  async function updateSelectedProject(_e, { value }) {
    setProjectSelectedLoading(true);
    if (isBlank(value)) {
      setProjectSelectedLoading(false);
      return;
    }

    await TrackedIssueStore.loadMetadata(value);
    const project = TrackedIssueStore.jiraProjects.find(project => project["id"] == value);
    const selectedProjectMap = new Map<string, string>();
    selectedProjectMap["id"] = [];
    selectedProjectMap["name"] = [];
    selectedProjectMap["id"].push(project["id"]);
    selectedProjectMap["name"].push(project["name"]);
    setSelectedProject(selectedProjectMap);
    jiraIssueForm.updateField("projectName", project["name"]);
    jiraIssueForm.updateField("projectId", value);
    jiraIssueForm.updateField("issueTypeName", null);
    jiraIssueForm.updateField("issueTypeId", null);
    jiraIssueForm.updateField("issuePriorityName", null);
    jiraIssueForm.updateField("issuePriorityId", null);
    jiraIssueForm.updateField("issueUserName", null);
    jiraIssueForm.updateField("issueUserId", null);
    setProjectSelectedLoading(false);
  }

  function getProjectOptions(): any[] {
    const projects = TrackedIssueStore.jiraProjects;
    if (!projects || projects.length == 0)
      return [];

    return projects.map(k => {
      return { text: k["name"], value: k["id"], key: k["name"] };
    });
  }

  let typingTimeout: NodeJS.Timeout;

  async function handleSearch(_e, { searchQuery }) {
    setLoading(true);
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(async () => {
      await TrackedIssueStore.loadJiraProjects(searchQuery);
      setLoading(false);
    }, 250);
  }

  async function updateIssueType(e) {
    jiraIssueForm.updateField("issueTypeName", e["label"]);
    jiraIssueForm.updateField("issueTypeId", e["value"]);
  }

  async function updateIssuePriority(e) {
    jiraIssueForm.updateField("issuePriorityName", e["label"]);
    jiraIssueForm.updateField("issuePriorityId", e["value"]);
  }

  async function updateIssueUser(e) {
    jiraIssueForm.updateField("issueUserName", e["label"]);
    jiraIssueForm.updateField("issueUserId", e["value"]);
  }

  async function createJiraIssue() {
    setSubmitting(true);
    setSelectedProject(null);
    await TrackedIssueStore.createJiraIssue(jiraIssueForm);
    closeModal();
    setSubmitting(false);
  }

  function closeModal() {
    createJiraModal.hide();
    setSelectedProject(null);
  }

  if (jiraIssueForm.onChange == null)
    return (<></>);

  return (
    <Modal open={createJiraModal.showing}>
      <Modal.Header>Create Jira Issue</Modal.Header>
      <Modal.Content>
        {projectSelectedLoading && <Spinner text={"Loading"} />}
        {!projectSelectedLoading && (
          <Form>
            <label>Jira Project</label>
            <Dropdown style={{
              border: "1px solid rgba(34,36,38,.15)",
              borderRadius: "3px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: ".67857143em 1em",
              marginBottom: "10px",
              color: "rgba(0,0,0,.87)",
              fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
            }} multiple={false} search={true} onSearchChange={handleSearch} options={projectOptions}
                      placeholder={"Type to search"}
                      value={selectedProject ? selectedProject["id"][0] : null} onChange={updateSelectedProject}
                      loading={loading} deburr={true}
                      fluid={true} floating={true} icon={null}
                      text={selectedProject ? selectedProject["name"][0] : "Type to search"}
            />
            {jiraIssueForm.projectId != null && (
              <>
                <label>Issue Summary</label>
                <Form.Input value={jiraIssueForm.summary} onChange={jiraIssueForm.onChange("summary")} />
                <label>Issue Description</label>
                <Form.TextArea value={jiraIssueForm.description} onChange={jiraIssueForm.onChange("description")}
                               type={"textarea"} style={{ resize: "none", height: "200px" }} />
                <label>Issue Type</label>
                <ReactSelect options={issueTypeOptions} onChange={updateIssueType} />
                <label>Issue Priority</label>
                <ReactSelect options={issuePriorityOptions} onChange={updateIssuePriority} />
                <label>Reporter</label>
                <ReactSelect options={userOptions} onChange={updateIssueUser} />
              </>
            )}
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} negative={true}>Cancel</Button>
        <Button onClick={createJiraIssue} loading={submitting} primary={true}
                disabled={isBlank(jiraIssueForm.projectId) || isBlank(jiraIssueForm.summary) || isBlank(jiraIssueForm.issueTypeId) || isBlank(jiraIssueForm.issuePriorityId) || isBlank(jiraIssueForm.issueUserId)}>
          Create Jira Issue
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default JiraModal;
