import API from "core/api";
import { action, observable, runInAction } from "mobx";
import TestDistributionAnalysisRuleForm from "models/forms/test-distribution-analysis-rule-form";
import { TestDistributionRule } from "models/settings";
import { ItemStoreList } from "./item-store";

export class TestDistributionAnalysisRuleStoreClass extends ItemStoreList<TestDistributionRule> {
    
    async load(id: number) {
        const { data } = await API.get(`/test-distribution-analysis-rule/${id}`, TestDistributionRule);
        return this.add(data);
    }

    @observable testDistributionRulesLoaded = false;

    @action
    async loadTestDistributionRules() {
      const result = await API.getList("/test-distribution-analysis-rule", TestDistributionRule);
      const rules = result.data;
      runInAction(() => {
        this.items = rules;
        this.testDistributionRulesLoaded = true;
      });
      return rules;
    }

    @action
    async deleteRule(id: number) {
      await API.delete(`/test-distribution-analysis-rule/${id}`);
      runInAction(() => (this.items = this.items.filter((a) => a.id != id)));
    }
    
    @action
    async saveRule(form: TestDistributionAnalysisRuleForm, forceSubmit?: boolean) {
      return form.submit(async (data) => {
        if (data.id) {
          const result = await API.put(`/test-distribution-analysis-rule/${data.id}`, data, TestDistributionRule);
          return this.update(result.data);
        } else {
          console.log(data)
          const result = await API.post("/test-distribution-analysis-rule", data, TestDistributionRule);
          return this.add(result.data);
        }
      }, forceSubmit);
    }


}


