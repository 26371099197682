import { observer } from "mobx-react-lite";
import { PlanInfo } from "../../components/payment-info";
import ReactGA from "react-ga4";

interface Props {
  next: () => any;
  back?: () => any;
}

const PaymentStep = observer(({ next, back }: Props) => {
  
  ReactGA.event({
    category: "signup-onboarding",
    action: "payment-step",
  });

  return (
    <PlanInfo
      forSetup={true}
      onSave={next}
      onCancel={back}
      windowsOnly={false}
      linuxOnly={true}
    />
  );
});

export default PaymentStep;
