import { observer } from "mobx-react-lite";
import { useInitialEffect } from "core/react-utils";
import { AppStore, ReleaseStore, ModalStore } from "stores";
import { Button, Card, Icon } from "semantic-ui-react";
import ReleaseCriteriaTemplateRow from "../../components/release/release-criteria-template-row";

const ReleaseCriteriaTemplateTab = observer(() => {

  useInitialEffect(async () => {
    await ReleaseStore.loadReleaseCriteriaTemplates();
  });

  const releaseCriteria = [];
  let index = 0;
  ReleaseStore.releaseCriteriaTemplates.forEach(releaseCriteriaTemplate => {
    releaseCriteria.push(<ReleaseCriteriaTemplateRow key={index} releaseCriteriaTemplate={releaseCriteriaTemplate} />);
    index++;
  });

  return (
    <>
      <div className="page-actions">
        {AppStore.userIsAdmin && (
          <Button color="green" onClick={() => {
            ModalStore.releaseCriteriaTemplateModal.show();
          }}>
            <Icon name="plus" />
            Add New Release Criteria Template
          </Button>
        )}
      </div>
      {releaseCriteria.length > 0 &&
        <Card style={{ padding: "10px", minWidth: "500px" }}>
          {releaseCriteria}
        </Card>
      }
      {releaseCriteria.length == 0 &&
        <h4>No Release Criteria Templates to Display</h4>
      }
    </>
  );
});

export default ReleaseCriteriaTemplateTab;
