import Select from "./select";
import { EventType } from "models/settings";

interface Props {
  value: EventType;
  onChange: (value: EventType) => any;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
  hideMatchesOption?: boolean;
}

const allOptions = [
  { label: "When Test Run Matches", value: EventType.TESTRUN_MATCHES },
  { label: "When Test Run Completes", value: EventType.TESTRUN_COMPLETE },
  { label: "When Test Run Fails", value: EventType.TESTRUN_FAIL },
  { label: "When Test Run Passes", value: EventType.TESTRUN_PASS },
  { label: "When Test Run Completes and has Flaky test", value: EventType.TESTRUN_FLAKY },
  { label: "When Test Run Analysis Completes", value: EventType.TESTRUN_ANALYSIS_COMPLETE}
];

const AlertEventSelect = ({
  onChange,
  value,
  placeholder,
  error,
  label,
  hideMatchesOption,
}: Props) => {
  let options = allOptions;

  if (hideMatchesOption) {
    options = options.filter((o) => o.value != EventType.TESTRUN_MATCHES);
  }

  return (
    <Select
      className="alert-event-select"
      placeholder={placeholder || ""}
      onChange={(e) => onChange(e && e.value)}
      value={allOptions.find((o) => o.value == value) || null}
      isClearable={!!value && !!placeholder}
      options={options}
      error={error}
      label={label}
    />
  );
};

export default AlertEventSelect;
