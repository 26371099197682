import { setPageInfo } from "core/react-utils";
import { Header, type SemanticICONS } from "semantic-ui-react";
import { AppStore, InsightStore } from "../stores";
import { useLocation } from "react-router";
import InsightBanner from "../components/insights/insight-banner";
import { useEffect } from "react";

interface Props {
  name: string;
  title: string;
  icon?: SemanticICONS;
  additionalCssClass?: string;
  noHeader?: boolean;
  titleSize?: "tiny" | "small" | "medium" | "large" | "huge" | "none";
  children: JSX.Element | JSX.Element[];
}

const Page = ({ name, title, additionalCssClass, children, titleSize }: Props) => {
  setPageInfo(name, title, additionalCssClass);

  useEffect(() => {
    void InsightStore.ensureLoaded();
  }, []);

  const pathNameEndsWithInsights = useLocation().pathname.endsWith("insights");

  let unreadInsightsSize = 0;
  if (AppStore.isLoggedIn) {
    const unreadInsights = InsightStore.unreadInsights;
    unreadInsightsSize = unreadInsights ? unreadInsights.length : 0;
  }

  return (
    <div className={"page" + (additionalCssClass ? " " + additionalCssClass : "")}>
      {unreadInsightsSize > 0 && !pathNameEndsWithInsights &&
        <InsightBanner />
      }
      <div className="page-content">
        {titleSize != "none" && <Header size={titleSize || "medium"}>{title}</Header>}
        {children}
      </div>
    </div>
  );
};

export default Page;
