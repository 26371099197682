import Select from "./select";
import { ProjectStore, TestSuiteStore } from "stores";
import { observer } from "mobx-react-lite";
import { useEffect, useInitialEffect } from "core/react-utils";
import { stringComparator } from "../../core/utils";

interface Props {
  value: number;
  onChange: (id: number) => any;
  projectId?: number;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
}

const TestSuiteSelect = observer(
  ({ onChange, value, placeholder, error, label, projectId }: Props) => {
    const testSuites = TestSuiteStore.active.filter((t) => !projectId || t.projectId == projectId);

    const suite = testSuites.find((e) => e.id == value) || null;

    useInitialEffect(() => {
      ProjectStore.loadAll();
      TestSuiteStore.loadAll();
    });

    useEffect(() => {
      if (value != null && suite == null) {
        onChange(null);
      }
    }, [projectId, value]);

    if (testSuites.length == 0) {
      return null;
    }

    const options = testSuites.sort((a, b) => stringComparator(a.name, b.name)).map(testSuite => {
      const projectName = testSuite.project?.name;
      const label = (testSuite.name === projectName) ? testSuite.name : `${testSuite.name} ${projectName}`;
      return { label: label, value: testSuite.name, projectName: projectName, testSuiteId: testSuite.id };
    });

    return (
      <Select
        className="test-suite-select"
        placeholder={placeholder}
        isSearchable={true}
        onChange={(option) => onChange(option?.testSuiteId)}
        autoDefault={!placeholder}
        isClearable={!!placeholder && !!value}
        value={suite && suite.project ? {
          label: (suite.name === suite.project.name) ? suite.name : `${suite.name} ${suite.project.name}`,
          value: suite.name,
          projectName: suite.project.name,
          testSuiteId: suite.id,
        } : null}
        options={options}
        formatOption={(option) =>
          projectId ? (
            option.value
          ) : (
            <div>
              {option.value}
              <div className="info">{option.projectName}</div>
            </div>
          )
        }
        valueField="testSuiteId"
        labelField="name"
        error={error}
        label={label}
      />
    );
  },
);

export default TestSuiteSelect;
