import { setPageInfo, useInitialEffect, useNavigation } from "core/react-utils";
import "./projects.scss";
import { observer } from "mobx-react-lite";
import { Button, Icon, Popup, SemanticCOLORS } from "semantic-ui-react";
import { AppStore, ModalStore, ProjectStore } from "stores";
import Page from "pages/page";
import { Icons } from "core/constants";
import SortableTable from "components/sortable-table";
import Project, { ProjectStats } from "models/project";
import { useHistory } from "react-router";
import RepoLink from "components/repo-link";
import { Link } from "react-router-dom";
import { organizationUrl, relativeLink } from "core/utils";
import ThreeDotMenu from "components/three-dot-menu";
import { useState } from "react";

const ProjectsPage = observer(() => {
  setPageInfo("projects", "Projects");
  const nav = useNavigation(relativeLink("projects/new"));
  const history = useHistory();

  useInitialEffect(() => {
    ProjectStore.loadAll();
  });

  return (
    <Page name="projects" title="Projects" icon={Icons.project}>
      <div className="page-actions">
        {AppStore.userIsAdmin && (
          <Button color="green" onClick={nav}>
            <Icon name="plus" />
            Add New Project
          </Button>
        )}
      </div>
      <SortableTable
        className="project-list"
        loading={!ProjectStore.loaded}
        loadingText="Loading Projects"
        headerColumns={[
          {
            className: "name",
            content: "Project Name",
            sortKey: "name",
            sortTransform: (s) => s.name.toLowerCase(),
          },
          {},
          {
            className: "tests",
            content: (
              <span>
                Tests{" "}
                <Popup
                  position="top center"
                  content="Number of unique tests run in the last 30 days."
                  trigger={<Icon name="question circle outline" />}
                />
              </span>
            ),
            sortKey: "stats.thisMonthUniqueTestsRun",
          },
          {
            className: "test-diff",
            content: (
              <span>
                MoM{" "}
                <Popup
                  position="top center"
                  content="Difference in unique tests run in the last 30 days compared to the previous 30 days."
                  trigger={<Icon name="question circle outline" />}
                />
              </span>
            ),
            sortKey: "stats.uniqueDiff",
          },
          {
            className: "deploy-frequency",
            content: (
              <span>
                Deploy Frequency{" "}
                <Popup
                  position="top center"
                  content="Number of deployments in the last 30 days."
                  trigger={<Icon name="question circle outline" />}
                />
              </span>
            ),
            sortKey: "stats.deploysInLastThirtyDays",
          },
          {
            className: "deploy",
            content: "Last Deployment",
          },
          { className: "actions", content: "", hide: !AppStore.userIsAdmin },
        ]}
        items={ProjectStore.active}
        renderRow={(p) => <ProjectRow key={p.id} project={p} push={history.push} />}
        defaultSortKey="name"
      />
    </Page>
  );
});

interface ProjectRowProps {
  project: Project;
  push: (path: string) => any;
}

async function deleteProject(project: Project) {
  ModalStore.confirmation.show(
    async (okClicked: boolean) => {
      if (okClicked) {
        await ProjectStore.delete(project.id);
      }
    },
    {
      message: `Are you sure you want to archive the ${project.name} project?`,
      okText: "Archive",
      okColor: "red",
    },
  );
}

const ProjectRow = observer(({ project, push }: ProjectRowProps) => {
  const [projectStats, setProjectStats] = useState<ProjectStats>();

  return (
    <tr>
      <td className="name">
        <Link to={relativeLink(`projects/${project.key}/test-suites`)}>{project.name}</Link>
      </td>
      <td>
        <Button color={"green" as SemanticCOLORS} onClick={async () => {
          const stats = await ProjectStore.loadProjectStats(project.id);
          setProjectStats(stats);
        }}>
          {projectStats ? "Refresh Stats" : "Load Stats"}
        </Button>
      </td>
      <td className="tests">
        {projectStats?.thisMonthUniqueTestsRun}
      </td>
      <td className="test-diff">
        <div className={`diff-value ${projectStats?.uniqueDiffType}`}>
          {projectStats?.uniqueDiffText}
        </div>
      </td>
      <td className="deploy-frequency">{projectStats?.deploysInLastThirtyDays}</td>
      <td className="deploy">
        {(projectStats?.lastDeployment?.build?.name || projectStats?.lastDeployment?.commit) && (
          <RepoLink
            hideProvierIcon={true}
            newWindow={true}
            project={project}
            gitRef={projectStats?.lastDeployment?.commit}
            displayText={projectStats?.lastDeployment?.build?.name}
          />
        )}
      </td>
      {AppStore.userIsAdmin && (
        <td className="actions">
          <ThreeDotMenu
            items={[
              {
                icon: Icons.edit,
                text: "Edit Settings",
                onClick: () => push(organizationUrl(`projects/${project.key}/settings`)),
              },
              { icon: Icons.delete, text: "Delete Project", onClick: () => deleteProject(project) },
            ]}
          />
        </td>
      )}
    </tr>
  );
});

export default ProjectsPage;
