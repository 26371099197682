import { endsWith, isEmpty, isFunction, isString, round, some, unionBy } from "lodash";
import { TestRun } from "models/test-run";
import Project from "models/project";
import ProjectForm from "models/forms/project-form";
import { User } from "../models/user";
import { API_URL } from "./constants";

export interface Entity {
  id: number;
}

export function toLowerWithDashes(value: string) {
  if (!value) return null;

  return value
    .replace(/[ \._]/g, "-")
    .replace(/[^A-Za-z0-9\\-]/g, "")
    .toLowerCase();
}

export function getUrlParam(name: string) {
  const url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function numberWithCommas(x) {
  if (x == null || x == undefined) return "0";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function avgDisplay(one: number, two: number) {
  if (one == 0 || two == 0) return "0";

  const avg = one / two;

  if (avg < 1) {
    return "< 1";
  }

  return numberWithCommas(Math.round(avg));
}

export function percentDisplay(one: number, two: number) {
  if (one == 0 || two == 0) return "0%";

  const percent = Math.round((one / two) * 1000) / 10;

  return percent + "%";
}

export interface Entity {
  id: number;
}

export function defaultIfEmpty(value: string, defaultValue: string) {
  return isEmpty(value) ? defaultValue : value;
}

export function isBlank(value: string) {
  return !value || isEmpty(value.trim());
}

export function isNotBlank(value: string) {
  return !isBlank(value);
}

export function mergeEntityLists<T extends Entity>(one: Array<T>, two: Array<T>): Array<T> {
  return unionBy([...two, ...one], "id");
}

export function buildRefUrl(project: Project | ProjectForm, testRunOrRef: TestRun | string) {
  const repo = buildRepoLink(project);

  if (!testRunOrRef || !repo) {
    return null;
  }

  const ref = isString(testRunOrRef) ? (testRunOrRef as string) : (testRunOrRef as TestRun).gitRef;

  if (!ref) {
    return null;
  }

  if (project.gitProvider == "GitHub") {
    return `${repo}/commit/${ref}`;
  }

  if (project.gitProvider == "BitBucket") {
    return `${repo}/commits/${ref}`;
  }

  return null;
}

export function buildRepoLink(project: Project | ProjectForm) {
  if (!project || !project.gitOwner || !project.gitRepo) {
    return null;
  }

  if (project.gitProvider == "GitHub") {
    return `https://github.com/${project.gitOwner}/${project.gitRepo}`;
  }

  if (project.gitProvider == "BitBucket") {
    return `https://bitbucket.org/${project.gitOwner}/${project.gitRepo}`;
  }

  return null;
}

export function setTitle(text: string) {
  window.document.title = `Testery - ${text}`;
}

export function capitalize(value: string) {
  return value.substring(0, 1).toUpperCase() + value.substring(1).toLowerCase();
}

export function capitalizeWords(value: string) {
  return value
    .split(" ")
    .map((w) => capitalize(w))
    .join(" ");
}

export function numberWithTh(n: number) {
  if (n > 13 || n < 11) {
    if (n % 10 == 1) return n + "st";
    if (n % 10 == 2) return n + "nd";
    if (n % 10 == 3) return n + "rd";
  }
  return n + "th";
}

export function findClosestElement(reference: any, selector: string) {
  let matchesFn;

  // find vendor prefix
  [
    "matches",
    "webkitMatchesSelector",
    "mozMatchesSelector",
    "msMatchesSelector",
    "oMatchesSelector",
  ].some(function(fn) {
    if (typeof document.body[fn] == "function") {
      matchesFn = fn;
      return true;
    }
    return false;
  });

  let parent;

  // traverse parents
  while (reference) {
    parent = reference.parentElement;
    if (parent && parent[matchesFn](selector)) {
      return parent;
    }
    reference = parent;
  }

  return null;
}

const cache = {};

export function delay(delayTime: number) {
  return new Promise(function(resolve) {
    setTimeout(resolve.bind(null), delayTime);
  });
}

export function userNameComparator(userA: User, userB: User): number {
  if (!userA.firstName)
    userA.firstName = "";
  if (!userA.lastName)
    userA.lastName = "";
  if (!userB.firstName)
    userB.firstName = "";
  if (!userB.lastName)
    userB.lastName = "";

  const userAFirst = userA.firstName.toLowerCase();
  const userBFirst = userB.firstName.toLowerCase();
  const userALast = userA.lastName.toLowerCase();
  const userBLast = userB.lastName.toLowerCase();

  let n: number;

  if ((userAFirst === "not" && userALast === "assigned") || (userBFirst === "not" && userBLast === "assigned"))
    n = -1;
  else if (userAFirst > userBFirst)
    n = 1;
  else if (userBFirst > userAFirst)
    n = -1;
  else if (userAFirst === userBFirst && userALast > userBLast)
    n = 1;
  else if (userAFirst === userBFirst && userBLast > userALast)
    n = -1;
  else
    n = 0;

  return n;
}

export function stringComparator(stringOne: string, stringTwo: string): number {
  const usableStringOne = stringOne.toLowerCase();
  const usableStringTwo = stringTwo.toLowerCase();

  let n: number;

  if (usableStringOne > usableStringTwo)
    n = 1;
  else if (usableStringTwo > usableStringOne)
    n = -1;
  else
    n = 0;

  return n;
}

export async function cachedLookup<T>(
  key: string,
  lookup: () => Promise<T>,
  cacheTimeInMinutes: number,
  failureRetryCount: number = -1,
): Promise<T> {
  const now = new Date().getTime();
  const ttl = cacheTimeInMinutes * 60 * 1000;

  if (!cache[key] || now > cache[key].expireTime) {
    cache[key] = {
      expireTime: now + ttl,
      value: await autoRetry(lookup, 2000, failureRetryCount),
    };
  }

  return cache[key].value;
}

export async function autoRetry<T>(
  action: () => Promise<T>,
  delayTime: number,
  retries: number = -1,
): Promise<T> {
  try {
    return await action();
  } catch (e) {
    if (retries == 0) {
      throw e;
    }
    await delay(delayTime);
    return autoRetry(action, delayTime, retries - 1);
  }
}

export interface RerunControl {
  continue: boolean;
}

export function autoRerun(action: () => any, freqencyInSeconds: number) {
  var keepRunning = true;

  const doCall = () => {
    try {
      if (keepRunning) {
        action();
      }
    } catch (e) {
      console.log(e);
    } finally {
      if (keepRunning) {
        setTimeout(doCall, freqencyInSeconds * 1000);
      }
    }
  };

  doCall();

  return () => {
    keepRunning = false;
  };
}

export function isField(object: any, key: string, prototype?: any): boolean {
  if (!prototype) {
    prototype = Object.getPrototypeOf(object);
  }

  if (isFunction(object[key])) return false;

  const desc = Object.getOwnPropertyDescriptor(prototype, key);
  const hasGetter = desc && typeof desc.get === "function";
  return !hasGetter;
}

const mimeTypesToSuffix = {
  "application/json": ".json",
};

export function downloadResultFile(testRunId: number, fileType: string) {
  const downloadUrl = `${this.urlBase}/test-runs/${testRunId}/file/${fileType}`;

  fetch(downloadUrl, { method: "GET", headers: this.getHeaders(true) })
    .then((resp) =>
      resp.blob().then((blob) => {
        const fileSuffix = mimeTypesToSuffix[resp.headers.get("Content-Type")] || "";
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${fileType}-${testRunId}${fileSuffix}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }),
    )
    .catch(() => alert("Failed to download file!"));
}

export function downloadBuildArtifact(projectId: number, buildId: number) {
  const downloadUrl = `${API_URL} + "/api/projects/${projectId}/builds/${buildId}/download`;

  fetch(downloadUrl, { method: "GET", headers: this.getHeaders(true) })
    .then((resp) =>
      resp.blob().then((blob) => {
        const fileSuffix = mimeTypesToSuffix[resp.headers.get("Content-Type")] || "";
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `build-${buildId}${fileSuffix}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }),
    )
    .catch(() => alert("Failed to download file!"));
}

export function relativeLink(link: string) {
  return endsWith(window.location.href, "/") ? "../" + link : link;
}

export function substringAfterLast(str: string, seperator: string) {
  return doSubstringAfter(str, seperator, (s1, s2) => s1.lastIndexOf(s2));
}

export function substringAfter(str: string, seperator: string) {
  return doSubstringAfter(str, seperator, (s1, s2) => s1.indexOf(s2));
}

export function substringBefore(str: string, seperator: string) {
  return str && str.includes(seperator) ? str.substring(0, str.indexOf(seperator)) : str;
}

export function organizationUrl(path?: string) {
  return path && path.length > 0
    ? `/${organizationFromUrl()}/${path}`
    : `/${organizationFromUrl()}`;
}

export function organizationFromUrl() {
  return substringBefore(window.location.pathname.substring(1), "/");
}

export function organizationPath() {
  return substringAfter(window.location.pathname.substring(1), "/");
}

function doSubstringAfter(
  str: string,
  seperator: string,
  indexer: (s1: string, s2: string) => number,
) {
  if (!str || str.length == 0 || !seperator || seperator.length == 0) return str;

  const index = indexer(str, seperator);

  if (index == -1) return str;

  const start = index + seperator.length;

  if (start >= str.length) return "";

  return str.substring(start);
}

export function nameInUse(items: { name: string; id: number }[], name: string) {
  return some(items, (i) => i.id != this.id && i.name == name);
}

export function keyInUse(items: { key: string; id: number }[], key: string) {
  return some(items, (i) => i.id != this.id && i.key == key);
}

export function spacesInCamelCase(s: string) {
  if (!s || s.length == 0) return s;

  return s.replace(/([A-Z]|[0-9]+)/g, " $1").trim();
}

export function camelCaseToTitleCase(s: string) {
  const stringBrokenApart = s.replace(/([A-Z])/g, " $1");
  return stringBrokenApart.charAt(0).toUpperCase() + stringBrokenApart.slice(1);
}

export function removeSpaces(s: string) {
  if (!s || s.length == 0) return s;

  return s.replace(/ /g, "");
}

export function sleep(millis: number) {
  return new Promise((resolve) => setTimeout(resolve, millis));
}

export function isJSON(str) {
  try {
    return (JSON.parse(str) && !!str);
  } catch (e) {
    return false;
  }
}

export function toCurrency(priceInPennies: number): string {
  return `$${round(priceInPennies / 100, 2).toFixed(2)}`;
}
