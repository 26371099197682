import { Button, Confirm } from "semantic-ui-react";
import "./release.scss";
import { ReleaseCriteriaTemplate } from "../../models/release";
import { useState } from "react";
import { ReleaseStore, ModalStore } from "../../stores";

interface Props {
  releaseCriteriaTemplate: ReleaseCriteriaTemplate;
}

const ReleaseCriteriaTemplateRow = (({ releaseCriteriaTemplate }: Props) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

  const deleteReleaseCriteriaTemplate = async () => {
    await ReleaseStore.archiveReleaseCriteriaTemplate(releaseCriteriaTemplate.id);
    await ReleaseStore.loadReleaseCriteriaTemplates();
    closeDeleteConfirm();
  };

  const openDeleteConfirm = () => {
    setDeleteConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setDeleteConfirmOpen(false);
  };

  return (
    <div className={"release-criteria-template"}>
      <Button icon={"pencil"} className={"edit-release-criteria-template"} primary={true} onClick={() => {
        ModalStore.releaseCriteriaTemplateModal.show(null, releaseCriteriaTemplate);
      }} />
      <Button style={{ marginRight: "10px" }} icon={"archive"} className={"archive-release-criteria-template"}
              negative={true} onClick={openDeleteConfirm} />
      <Confirm content={"Are you sure you'd like to delete this release criteria template?"} confirmButton={"Yes, delete it!"}
               cancelButton={"No, I want to keep it"} open={deleteConfirmOpen} onConfirm={deleteReleaseCriteriaTemplate}
               onCancel={closeDeleteConfirm} />
      {releaseCriteriaTemplate.text}
    </div>
  );
});

export default ReleaseCriteriaTemplateRow;
