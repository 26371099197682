import { map } from "lodash";
import { Chart } from "chart.js";

const charts = {};

export interface PieChartData {
  label: string;
  value: number;
  color: string;
}

function getChart(elementId: string, defaultInfo: any): Chart | null {
  let chart: Chart = charts[elementId];

  if (chart && !chart.canvas)
    return null;

  if (!chart || !chart.canvas.isConnected) {
    chart = charts[elementId] = new Chart(
      document.getElementById(elementId) as HTMLCanvasElement,
      defaultInfo,
    );
  }
  return chart;
}

const pieChartDefaults = {
  type: "doughnut",
  data: {
    datasets: [],
    labels: [],
  },
  options: {
    cutoutPercentage: 40,
    responsive: true,
    animation: {
      duration: 0,
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "right",
        labels: {
          usePointStyle: true,
        },
      },
    },
  },
};

export function displayPieChart(elementId: string, data: Array<PieChartData>) {
  const chart = getChart(elementId, { ...pieChartDefaults });

  if (!chart)
    return;

  chart.data = {
    datasets: [
      {
        data: map(data, (d) => d.value),
        backgroundColor: map(data, (d) => d.color),
      },
    ],
    labels: map(data, (d) => d.label),
  };

  chart.update();
}

export interface BarChartData {
  labels: string[];
  datasets: BarChartDataSet[];
}

export interface BarChartDataSet {
  data: number[];
  label: string;
  backgroundColor: string;
}

const stackedBarChartDefaults = {
  type: "bar",
  data: [],
  options: {
    scaleStartValue: 0,
    tooltips: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

export function displayStackedBarChart(
  elementId: string,
  labels: string[],
  datasets: BarChartDataSet[],
) {
  const chart = getChart(elementId, { ...stackedBarChartDefaults });

  if (!chart)
    return;

  chart.data = {
    datasets: datasets,
    labels: labels,
  };
  chart.update();
}

const barChartDefaults = {
  type: "bar",
  data: [],
  options: {
    scaleStartValue: 0,
    tooltips: {
      mode: "index",
      intersect: false,
    },
    legend: {
      display: false,
      position: "right",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

export function displayBarChart(elementId: string, labels: string[], dataset: BarChartDataSet) {
  const chart = getChart(elementId, { ...barChartDefaults });

  if (!chart)
    return;

  chart.data = {
    datasets: [dataset],
    labels: labels,
  };
  chart.update();
}
