import { useState, useEffect } from "react";
import Select from "./select";
import { useOnlyLatestRequest } from "core/react-utils";
import Project from "models/project";
import { autoRetry, stringComparator } from "core/utils";
import { ProjectStore } from "stores";

interface Props {
  project: Project;
  value: string;
  error?: boolean | string;
  label?: string;
  placeholder?: string;
  hideIfNoBuilds?: boolean;
  onChange: (v: string) => any;
}

const BuildSelect = ({
  project,
  onChange,
  value,
  error,
  label,
  placeholder,
  hideIfNoBuilds,
}: Props) => {
  const [options, setOptions] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  project = project || new Project();

  const allowCreate = true || !project.hasBuildArtifacts;

  const loadBuilds = useOnlyLatestRequest(
    () => {
      setOptions([]);
      setLoading(true);
      return autoRetry(() => ProjectStore.loadBuildNames(project.id), 2000);
    },
    (builds) => {
      setOptions(builds.sort((a, b) => stringComparator(a, b)));
      setLoading(false);
      if (!allowCreate) {
        onChange(builds[0]);
      }
    },
  );

  useEffect(() => {
    if (project.id) {
      loadBuilds();
    }
  }, [project.id]);

  if (hideIfNoBuilds && options.length == 0) return null;

  return (
    <Select
      className="build-select"
      placeholder={placeholder || ""}
      isLoading={loading}
      value={value}
      options={options}
      isSearchable={true}
      isClearable={!!value}
      onChange={onChange}
      isCreatable={allowCreate}
      createOptionText="Build"
      error={error}
      label={label}
    />
  );
};

export default BuildSelect;
