import { organizationUrl } from "core/utils";
import Project from "models/project";
import { Link } from "react-router-dom";

interface Props {
  project: Project;
}

export default function ProjectLink({ project }: Props) {
  if (!project) return <span />;

  return (
    <div className="project-link">
      <Link to={organizationUrl(`projects/${project.key}`)}>{project.name}</Link>
    </div>
  );
}
