import ProjectForm from "models/forms/project-form";
import Select from "./select";
import { CypressFrameworkVersion, TestFramework } from "models/project";
import YesNoSelect from "./yes-no-select";
import { observer } from "mobx-react-lite";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Form, Message } from "semantic-ui-react";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import FrameworkSelect from "./framework-select";
import FrameworkVersionSelect from "./framework-version-select";

const codeStyle = atomOneDark;

interface Props {
  form: ProjectForm;
}

const selenumNotice = (
  <h4>
    In order for Selenium tests to work correctly on Testery, you'll have to create your ChromeDriver instance with some
    arguments supplied by Testery. <br />
    <br />
    To do that you can use code like this to create your Browser instance:
  </h4>
);

const FrameworkInfo = observer(({ form }: Props) => {
  let configType = "package.json";
  let projectSpec = "package.json";
  let configDefault = "wdio.conf.js";
  let codeExample = null;
  let language = null;

  if (form.testingFramework == TestFramework.NightWatch) {
    configType = "NightWatch";
    configDefault = "nightwatch.conf.js";
  } else if (form.testingFramework == TestFramework.Cypress) {
    configType = "Cypress";
    configDefault = (!form.testingFrameworkVersion || form.testingFrameworkVersion === CypressFrameworkVersion.NineAndBelow) ? "cypress.json" : "cypress.config.js";
  } else if (form.testingFramework == TestFramework.CucumberJS) {
    configType = "Cucumber";
    configDefault = "cucumber.js";
    codeExample = cucumberJsCode;
    language = "javascript";
  } else if (form.testingFramework == TestFramework.WebDriverIO) {
    configType = "WebDriver";
    configDefault = "wdio.conf.js";
  } else if (form.testingFramework == TestFramework.Playwright) {
    configType = "Playwright";
    configDefault = "playwright.config.js";
  } else if (form.testingFramework == TestFramework.PyTest) {
    configType = "PyTest";
    projectSpec = "project";
    configDefault = "pytest.ini";
    codeExample = pyTestCode;
    language = "python";
  } else if (form.testingFramework == TestFramework.NUnit) {
    codeExample = nunitCode;
    language = "csharp";
  } else if (form.testingFramework == TestFramework.TestNG) {
    codeExample = javaCode;
    language = "java";
  }

  return (
    <div className="framework-info">
      <FrameworkSelect
        label="Testing Framework"
        error={form.getErrorText("testingFramework")}
        value={form.testingFramework}
        onChange={form.onChange("testingFramework")}
      />
      {form.testingFramework == TestFramework.TestNG && (
        <Select
          className="test-package-select"
          placeholder={"Enter the root packages containing tests..."}
          isCreatable={true}
          noDropdown={true}
          noDropdownIndicator={true}
          value={form.testPackages}
          options={form.testPackages}
          isMulti={true}
          onChangeMulti={form.onChange("testPackages")}
          error={form.getErrorText("testPackages")}
          label={"Java packages containing tests to run"}
        />
      )}
      {(form.testingFramework == TestFramework.Cypress) && (
        <FrameworkVersionSelect
          value={form.testingFrameworkVersion}
          label={"Testing Framework Version"}
          versions={Object.values(CypressFrameworkVersion)}
          onChange={form.onChange("testingFrameworkVersion")}
          error={form.getErrorText("testingFrameworkVersion")}
        />
      )}
      {form.testingFramework == TestFramework.PyTest && (
        <Select
          className="test-package-select"
          placeholder={"Enter test directories (if blank all directories will be searched)"}
          isCreatable={true}
          noDropdown={true}
          noDropdownIndicator={true}
          value={form.testPackages}
          options={form.testPackages}
          isMulti={true}
          onChangeMulti={form.onChange("testPackages")}
          error={form.getErrorText("testPackages")}
          label={"Project directories containing tests"}
        />
      )}
      {form.testingFramework == TestFramework.NUnit && (
        <div>
          <Select
            className="test-artifact-select"
            placeholder={"Enter the DLL files that contain tests you want to run and hit enter..."}
            isCreatable={true}
            noDropdown={true}
            noDropdownIndicator={true}
            value={form.testArtifacts}
            options={form.testArtifacts}
            isMulti={true}
            onChangeMulti={form.onChange("testArtifacts")}
            error={form.getErrorText("testArtifacts")}
            label={"Test Artifacts"}
          />
          <YesNoSelect
            label="Are you using .Net Core?"
            allowEmpty={true}
            value={form.usingDotnetCore}
            onChange={form.onChange("usingDotnetCore")}
            error={form.getErrorText("usingDotnetCore")}
          />
        </div>
      )}
      {codeExample && (
        <div>
          <YesNoSelect
            label="Are you using Selenium in your tests?"
            allowEmpty={true}
            value={form.usingSelenium}
            onChange={form.onChange("usingSelenium")}
            error={form.getErrorText("usingSelenium")}
          />
          {form.usingSelenium && (
            <div className="code-message">
              {selenumNotice}
              <SyntaxHighlighter style={codeStyle} language={language}>
                {codeExample}
              </SyntaxHighlighter>
            </div>
          )}
        </div>
      )}
      {form.testingFramework == TestFramework.Other && !form.collectOtherFramework && (
        <Message color="yellow">
          Didn't see your testing framework listed? If you'd like us to support another framework{" "}
          <a href="https://testery.io/contact" target="_blank">
            please reach out to our team today by clicking here!
          </a>
        </Message>
      )}
      {form.testingFramework == TestFramework.Other && form.collectOtherFramework && (
        <Form.Input
          label="Enter framework name"
          error={form.getErrorText("otherFramework")}
          type="text"
          value={form.otherFramework}
          onChange={form.onChange("otherFramework")}
        />
      )}
      <br />
      {form.testingFramework != TestFramework.Other &&
        form.testingFramework != TestFramework.NUnit &&
        form.testingFramework != TestFramework.TestNG && (
          <YesNoSelect
            label={`Is your ${projectSpec} in the root directory of your repo?`}
            allowEmpty={true}
            value={!form.usingWorkingDirectory}
            onChange={(v) => form.updateField("usingWorkingDirectory", !v)}
          />
        )}
      {form.usingWorkingDirectory && (
        <Form.Input
          label={`Directory containing your ${projectSpec} (relative to repo root)`}
          error={form.getErrorText("workingDirectory")}
          type="text"
          value={form.workingDirectory}
          onChange={form.onChange("workingDirectory")}
        />
      )}
      {(form.testingFramework == TestFramework.WebDriverIO || form.testingFramework == TestFramework.Cypress || form.testingFramework == TestFramework.Playwright || form.testingFramework == TestFramework.CucumberJS) && (
        <Form.Input
          label={
            `Location of your ${configType} config relative to your ` +
            (form.usingWorkingDirectory ? "package.json" : "project root")
          }
          error={form.getErrorText("configFile")}
          type="text"
          value={form.configFile}
          onChange={form.onChange("configFile")}
          placeholder={configDefault}
        />
      )}
    </div>
  );
});

const cucumberJsCode = `require("chromedriver");
const { Options } = require("selenium-webdriver/chrome");

const options = new Options();

if(process.env.IS_TESTERY == "true") {
  options.addArguments(process.env.TESTERY_CHROME_ARGS.split(";"));
}

const browser = new Builder().forBrowser("chrome").setChromeOptions(options).build();
`;

const nunitCode = `var options = new ChromeOptions();
if(Environment.GetEnvironmentVariable("IS_TESTERY") == "true") {
    options.AddArguments(Environment.GetEnvironmentVariable("TESTERY_CHROME_ARGS").Split(';'));
}
var browser = new ChromeDriver(options);`;

const pyTestCode = `import pytest
import os

@pytest.fixture
def chrome_options(chrome_options, pytestconfig):
    if os.environ.get('IS_TESTERY') == 'true':
      for arg in os.environ.get('TESTERY_CHROME_ARGS').split(';'):
        chrome_options.add_argument(arg)

    return chrome_options`;

const javaCode = `ChromeOptions options = new ChromeOptions();

if("true".equals(System.getenv("IS_TESTERY"))) {
    options.addArguments(System.getenv("TESTERY_CHROME_ARGS").split(";"))
}

return new ChromeDriver(options)`;

export default FrameworkInfo;
