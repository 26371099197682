import Select from "./select";
import { TestPlanStore } from "stores";
import { observer } from "mobx-react-lite";
import { useEffect, useInitialEffect } from "core/react-utils";
import { sortBy } from "lodash";

interface Props {
  value: number;
  onChange: (id: number) => any;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
}

const TestPlanSelect = observer(({ onChange, value, placeholder, error, label }: Props) => {
  const testPlans = sortBy(TestPlanStore.active, "name");

  const testPlan = testPlans.find((e) => e.id == value) || null;

  useInitialEffect(async () => {
    await TestPlanStore.ensureLoaded();
  });

  useEffect(() => {
    if (!testPlans.find((p) => p.id == value)) {
      if (placeholder) {
        onChange(null);
      } else {
        onChange(testPlans[0]?.id);
      }
    }
  }, [value]);

  if (testPlans.length == 0) {
    return null;
  }

  const options = testPlans.map(testPlan => {
    return { label: testPlan.name, value: testPlan.name, testPlanId: testPlan.id };
  });

  return (
    <Select
      className="test-plan-select"
      placeholder={placeholder}
      isSearchable={true}
      onChange={(s) => onChange(s?.testPlanId)}
      autoDefault={!placeholder}
      isClearable={!!placeholder && !!value}
      value={testPlan ? {
        label: testPlan.name,
        value: testPlan.name,
        testPlanId: testPlan.id,
      } : null
      }
      options={options}
      formatOption={(option) => option.label}
      valueField="value"
      labelField="name"
      error={error}
      label={label}
    />
  );
});

export default TestPlanSelect;
