import { EntityWithAccountId, EntityWithCreatedAt } from "./entity";

export class Release extends EntityWithAccountId {
  title: string;
  description?: string;
  createdByUserId: number;
  decision?: ReleaseDecision;
  decidedAt?: Date;
  decidedByUserId?: number;
  proposedReleaseDate?: string;
  actualReleaseDate?: string;
  archivedByUserId?: number;
  archivedAt?: Date;
  releaseCriteria: Array<ReleaseCriteria>;

  get isDecided(): boolean {
    return this.decision != null;
  }
}


export enum ReleaseDecision {
  GO = "Go",
  NO_GO = "No-Go"
}

export class ReleaseCriteria extends EntityWithCreatedAt {
  releaseId: number;
  releaseCriteriaTemplateId?: number;
  text: string;
  createdByUserId?: number;
  completedByUserId?: number;
  completedAt?: string;

  get isCompleted() {
    return this.completedAt != null;
  }
}

export class ReleaseCriteriaTemplate extends EntityWithAccountId {
  text: string;
  createdByUserId: number;
  lastUpdatedByUserId?: number;
}
