import Select from "components/form/select";
import { EmployeeStore } from "stores";
import { observer } from "mobx-react-lite";
import { orderBy } from "lodash";

interface Props {
  value: number;
  onChange: (id: number) => any;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
}

const AccountSelect = observer(({ onChange, value, placeholder, error, label }: Props) => (
  <Select
    className="account-select"
    placeholder={placeholder}
    isSearchable={false}
    onChange={(e) => onChange(e && e.id)}
    autoDefault={!placeholder}
    isClearable={!!placeholder && !!value}
    value={EmployeeStore.accounts.find((e) => e.id == value)}
    options={orderBy(EmployeeStore.accounts, "name")}
    valueField="id"
    formatOption={(o) => `${o.name} (${o.id})`}
    error={error}
    label={label}
  />
));

export default AccountSelect;
