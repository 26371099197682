import { useState } from "react";
import { setPageInfo, useEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import Page from "pages/page";
import Spinner from "components/spinner";
import { Grid } from "semantic-ui-react";
import { AppStore, SettingsStore } from "stores";
import "./setup.scss";
import PaymentStep from "./payment-step";
import { AccountSetupStep, AccountSetupStepType } from "../../models/account-setup";
import EnvironmentForm from "../../models/forms/environment-form";
import FinalStep from "./final-step";

const ExpressSetupPage = observer(() => {
  setPageInfo("setup", "SetupPage");

  const [setupStep, setSetupStep] = useState<AccountSetupStepType>(null);
  const [setupSteps, setSetupSteps] = useState<Array<AccountSetupStepType>>([]);
  const [initialized, setInitialized] = useState(false);

  function toTypes(types: Array<AccountSetupStep>) {
    return types.map(({ type }) => type);
  }

  async function loadSetupSteps() {
    setSetupSteps(toTypes(await SettingsStore.loadAccountSetupSteps()));
  }

  const next = async () => {
    await loadSetupSteps();
  };

  useEffect(async () => {
    handleStepSelection();
  }, [setupSteps]);

  useEffect(async () => {
    setInitialized(false);
    await SettingsStore.loadAuthorizations();
    await AppStore.loadAvailablePlans();

    await loadSetupSteps();

    const environments = await SettingsStore.loadEnvironments();
    if (environments.length === 0) {
      const environmentForm = new EnvironmentForm();
      environmentForm.key = "development";
      environmentForm.name = "Development";
      await SettingsStore.saveEnvironment(environmentForm);
    }

    setInitialized(true);
  }, [AppStore.selectedAccountId]);

  function handleStepSelection() {
    let selectedSetupStep: AccountSetupStepType;
    if (paymentStepComplete()) {
      selectedSetupStep = AccountSetupStepType.Final;
    } else {
      selectedSetupStep = AccountSetupStepType.Payment;
    }
    setSetupStep(selectedSetupStep);
  }

  function paymentStepComplete() {
    return AppStore.account?.currentAccountPlan != null;
  }

  if (!initialized) {
    return (
      <Page name="setup" title="Setup" noHeader titleSize="none">
        <Spinner size={22} />
      </Page>
    );
  }

  return (
    <Page name="setup" title="" noHeader={true}>
      <Grid columns={1}>
        <Grid.Column>
          {setupStep == AccountSetupStepType.Payment && <PaymentStep next={next} />}
          {setupStep == AccountSetupStepType.Final && <FinalStep />}
        </Grid.Column>
      </Grid>
    </Page>
  );
});

export default ExpressSetupPage;
