import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import TestPlanSuiteForm from "models/forms/test-plan-suite-form";
import TestRunForm from "models/forms/test-run-form";
import Project from "models/project";
import { Grid, Message, Radio } from "semantic-ui-react";
import { ProjectStore } from "stores";
import BranchSelect from "./branch-select";
import BuildSelect from "./build-select";
import CommitSelect from "./commit-select";

interface Props {
  form: TestRunForm | TestPlanSuiteForm;
  project: Project;
}

const TestRunVersionSelect = observer(({ form, project }: Props) => {
  const hasBuildArtifacts = project && project.hasBuildArtifacts;
  const hasRepo = project && project.hasRepo;
  const hasDeploy = ProjectStore.hasDeploy(form.projectId, form.environmentId);

  useInitialEffect(() => {
    ProjectStore.ensureLatestDeploysLoaded();
  });

  return (
    <div>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Radio
              checked={form.latestDeploy}
              onChange={(_, data) => form.updateField("latestDeploy", data.checked)}
              name="deployVersion"
              label="Latest Deployed Version"
            />
          </Grid.Column>
          <Grid.Column>
            <Radio
              checked={!form.latestDeploy}
              name="deployVersion"
              label="Specific Version"
              onChange={(_, data) => form.updateField("latestDeploy", !data.checked)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {form.latestDeploy && !hasDeploy && !form.isRunNow && (
        <Message info>
          <p>
            There are currently no deploys of this project to this environment in Testery. Once a
            deploy is created it will be used.
          </p>
        </Message>
      )}
      {form.latestDeploy && !hasDeploy && form.isRunNow && (
        <Message negative>
          <p>
            There are currently no deploys of this project to this environment in Testery, so you
            must select a specific version to run.
          </p>
        </Message>
      )}
      {!form.latestDeploy && (
        <Grid>
          {hasRepo && (
            <Grid.Row columns={2}>
              <Grid.Column>
                <BranchSelect
                  label="Branch"
                  value={form.branch}
                  onChange={form.onChange("branch")}
                  error={form.getErrorText("branch")}
                  provider={project && project.gitProvider}
                  owner={project && project.gitOwner}
                  repo={project && project.gitRepo}
                />
              </Grid.Column>
              <Grid.Column>
                <CommitSelect
                  placeholder="latest"
                  label="Commit"
                  project={project}
                  value={form.commit}
                  onChange={form.onChange("commit")}
                  error={form.getErrorText("commit")}
                  branch={form.branch}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          {hasBuildArtifacts && (
            <Grid.Row columns={2}>
              <Grid.Column>
                <BuildSelect
                  label="Build"
                  value={form.buildName}
                  onChange={form.onChange("buildName")}
                  project={project}
                  error={form.getErrorText("buildName")}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}
    </div>
  );
});

export default TestRunVersionSelect;
