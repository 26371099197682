import { type } from "core/serialization";
import { computed } from "mobx";

export class UserDetails {
  token: string;
  userId: number;
  email: string;
  roles: Map<number, RoleType>;
}

export class CurrentUserRole {
  id: number;
  accountId: number;
  accountName: string;
  userId: number;
  roleType: RoleType;
  notifyByEmail: boolean;
  notifyBySlack: boolean;
  slackMemberId: string;
}

export class CurrentUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  @type(CurrentUserRole)
  roles: CurrentUserRole[];
  tellAFriendRemaining: number;
}

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;

  @computed
  get fullName() {
    return `${this.firstName} ${this.lastName}`.trim();
  }
}

export class UserRole {
  id: number;
  accountId: number;
  @type(User)
  user: User;
  roleType: RoleType;
}

export class Invitation {
  id: number;
  createdAt: string;
  email: string;
  firstName: string;
  lastName: string;
  accountId: number;
  roleType: RoleType;
  accountName: string;

  @computed
  get fullName() {
    return `${this.firstName} ${this.lastName}`.trim();
  }
}

export enum RoleType {
  USER = "USER",
  ADMIN = "ADMIN",
}

export const RoleText = {
  ADMIN: "Administrator",
  USER: "User",
};

export enum FollowNotification {
  Email,
  Slack,
}

export class UserFollow {
  userId: number;
  testRunId: number;
  notifications: FollowNotification[] = [];
}
