import "./insight-banner.scss";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { organizationUrl } from "../../core/utils";

const InsightBanner = () => {

  return (
    <div className={"container"}>
      <Icon className={"wiggle"} name={"lightbulb"} />
      You have unread&nbsp;<Link to={organizationUrl("dashboard/insights")}>Insights</Link>&nbsp;to view.
    </div>
  );
};

export default InsightBanner;
