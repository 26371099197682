import { observer } from "mobx-react-lite";
import { TestRun } from "models/test-run";
import TestResultTimeline from "components/test-result-timeline";
import "./timeline-tab.scss";
import { TestRunStore } from "stores";

interface Props {
  testRun: TestRun;
}

const TimelineTab = observer(({ testRun }: Props) => {
  return (
    <div className="timeline-tab">
      {!TestRunStore.loadingTestRunResults && <TestResultTimeline testRun={testRun} />}
    </div>
  );
});

export default TimelineTab;
