import { ModalStore, TeamStore } from "stores";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import TeamInfoForm from "../../../models/forms/team-info-form";

const form = new TeamInfoForm();
const { teamInfo } = ModalStore;

teamInfo.onHidden = () => form.clearFormData();
teamInfo.beforeShown = () => {
  form.populate(teamInfo.state)
}

const TeamInfo = observer(() => {

  const saveTeam = async () => {
    if (teamInfo.state?.id)
      await TeamStore.updateTeam(form);
    else
      await TeamStore.createTeam(form);

    teamInfo.hide();
    form.clearFormData();
  };

  return (
    <Modal size="small" open={teamInfo.showing} id="new-team-modal">
      <Modal.Header>Team Information</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            label="Name*"
            value={form.name}
            onChange={form.onChange("name")}
            error={form.getErrorText("name")}
          />
          <Form.Input
            label="Description"
            value={form.description}
            onChange={form.onChange("description")}
            error={form.getErrorText("description")}
          />
          <Form.Input
            label="Email"
            type="email"
            value={form.email}
            onChange={form.onChange("email")}
            error={form.getErrorText("email")}
          />
          <Form.Input
            label="Slack Channel ID"
            value={form.slackChannel}
            onChange={form.onChange("slackChannel")}
            error={form.getErrorText("slackChannel")}
          />
          <Form.Input type={"hidden"} value={form.id} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={form.isSubmitting} onClick={() => teamInfo.hide()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button loading={form.isSubmitting} color="green" onClick={saveTeam}>
          <Icon name="save" />
          Save Team
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default TeamInfo;
