import Form from "./form";
import { observable, action } from "mobx";
import { EnvironmentVar } from "models/settings";

export default class EnvironmentVarForm extends Form {
  @observable key: string;
  @observable value: string;
  @observable encrypted: boolean;
  @observable file: boolean;
  @observable newVariable: boolean;

  constructor(data?: Partial<EnvironmentVarForm>) {
    super();
    Object.assign(this, data);
  }

  @action
  setDefaults() {
    this.value = "";
    this.key = "";
    this.encrypted = false;
    this.file = false;
    this.newVariable = false;
  }
}

export class WithEnvironmentVarsForm extends Form {
  @observable variables: EnvironmentVarForm[] = [];

  @action.bound
  addVariable() {
    this.variables.push(new EnvironmentVarForm({newVariable: true}));
  }

  @action.bound
  addFileVariable(name: string, content: string) {
    this.variables.push(new EnvironmentVarForm({file: true, encrypted: true, key: name, value: content, newVariable: true}));
  }

  @action
  deleteVariable(index: number) {
    this.variables.splice(index, 1);
  }

  @action
  setDefaults() {
    this.variables = [];
  }

  @action
  populateVariables(vars: EnvironmentVar[]) {
    this.variables = vars.map((v) => new EnvironmentVarForm(v));
  }
}
