import { type } from "core/serialization";
import { isBlank } from "core/utils";
import { computed, observable } from "mobx";
import { PipelineStore } from "stores";
import Project, { TestSuite } from "./project";

export class Alert {
  id: number;
  eventType: EventType;
  actionType: ActionType;
  criteria: string;
  destination: string;
  status: AlertStatus;
  testRunId: number;
}

export enum AlertStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum EventType {
  TESTRUN_PASS = "TESTRUN_PASS",
  TESTRUN_FAIL = "TESTRUN_FAIL",
  TESTRUN_COMPLETE = "TESTRUN_COMPLETE",
  TESTRUN_MATCHES = "TESTRUN_MATCHES",
  TESTRUN_FLAKY = "TESTRUN_FLAKY",
  TESTRUN_ANALYSIS_COMPLETE = "TESTRUN_ANALYSIS_COMPLETE",
}


export enum ActionType {
  TRIGGER_WEBHOOK = "TRIGGER_WEBHOOK",
  SEND_SLACK = "SEND_SLACK",
  SEND_MICROSOFT_TEAMS = "SEND_MICROSOFT_TEAMS",
  TRIGGER_OPSGENIE = "TRIGGER_OPSGENIE",
  SEND_EMAIL = "SEND_EMAIL"
}

export const eventTypeText = {
  TESTRUN_PASS: "Test run passes",
  TESTRUN_FAIL: "Test run fails",
  TESTRUN_COMPLETE: "Test run completes",
  TESTRUN_MATCHES: "Test run matches criteria",
  TESTRUN_FLAKY: "Test run completes and has flaky test",
  TESTRUN_ANALYSIS_COMPLETE: "Test run analysis completes",
};

export const actionTypesText = {
  TRIGGER_WEBHOOK: "Trigger webhook at",
  SEND_SLACK: "Send Slack message to channel",
  SEND_MICROSOFT_TEAMS: "Send Microsoft Teams message to Incoming Webhook URL",
  TRIGGER_OPSGENIE: "Send Opsgenie alert",
  SEND_EMAIL: "Send email to"
};

export class EnvironmentVar {
  key: string;
  value: string;
  encrypted: boolean;
  file: boolean;
}

export class Environment {
  id: number;
  pipelineStageId: number;
  archived: boolean;
  accountId: number;
  name: string;
  key: string;
  url: string;
  @type(EnvironmentVar)
  variables: EnvironmentVar[];

  @computed
  get pipelineStage() {
    return (
      PipelineStore.find(this.pipelineStageId) ||
      ({ name: "", priority: 999, testRunPriority: 10 } as PipelineStage)
    );
  }

  @computed
  get variablesWithoutFiles() {
    return this.variables && this.variables.filter(v => !v.file);
  }
}

export class AuditLog {
  time: string;
  message: string;

  constructor(data?: Partial<AuditLog>) {
    if (data) Object.assign(this, data);
  }
}

export class Authorization {
  id: number;
  accountId: number;
  provider: AuthorizationProvider;
  identifier: string;
  installationId: string;
  baseUrl?: string;

  get isSourceControlProvider() {
    return (
      this.provider == AuthorizationProvider.GitHub ||
      this.provider == AuthorizationProvider.BitBucket ||
      this.provider == AuthorizationProvider.Gitlab
    );
  }

  get needsIdentifier() {
    console.log("needsIdentifier")
    console.log(this.identifier)
    console.log(this.isSourceControlProvider)
    return isBlank(this.identifier) && this.isSourceControlProvider;
  }
}

export enum AuthorizationProvider {
  OpsGenie = "OpsGenie",
  GitHub = "GitHub",
  Jira = "Jira",
  BitBucket = "BitBucket",
  BitBucketServer = "BitBucketServer",
  Slack = "Slack",
  MicrosoftTeams = "MicrosoftTeams",
  Gitlab = "Gitlab",
}

export enum TestRunType {
  dotnet = "dotnet",
  dotnetcore = "dotnetcore",
  cucumberjs = "cucumberjs",
  java = "java",
  testng = "testng",
  ruby = "ruby",
  other = "other",
}

export class Organization {
  key: string;
  description: string;
  logoUrl: string;
}

export class Repo {
  provider: string;
  owner: string;
  repo: string;
  description: string;
}

export class Branch {
  name: string;
  commit: string;
}

export enum AuthReason {
  Login = "Login",
  Integration = "Integration",
  Setup = "Setup",
  SlackIntegration = "SlackIntegration",
  JiraIntegration = "JiraIntegration",
}

export class PipelineStage {
  id: number;
  name: string;
  testRunPriority: number = null;
  @observable priority: number;
  @type(EnvironmentVar)
  variables: EnvironmentVar[] = [];
  @observable windowsRunnerPoolIdrPoolId: number;
  @observable linuxRunnerPoolId: number;

  constructor(data?: Partial<PipelineStage>) {
    Object.assign(this, data);
  }
}

export class RunnerPool {
  id: number;
  name: string;
  runnerType: string;
  maxRunners: number;
  currentRunners: number;

  constructor(data?: Partial<RunnerPool>) {
    Object.assign(this, data);
  }
}

export class RunnerConfiguration {
  id: number;
  accountId: number;
  name: string;
  runnerType: string;
  chrome: string;
  nodejs: string;
  python?: string;
  key: string;
  isDefault: boolean;
  archived: boolean;

  constructor(data?: Partial<RunnerConfiguration>) {
    Object.assign(this, data);
  }

}

export enum TestDistributionRuleType {
  PROJECT = "PROJECT",
  TEST_NAME_INCLUDES = "TEST_NAME_INCLUDES", 
  TEST_FILE_INCLUDES =  "TEST_FILE_INCLUDES", 
  MOST_RECENT_SUITE = "MOST_RECENT_SUITE"
}

export enum TestDistributionRuleAssignedTo {
  UNIT = "UNIT", 
  INTEGRATION = "INTEGRATION", 
  END_TO_END = "END_TO_END",
  UNKNOWN = "UNKNOWN"
}


export class TestDistributionRule {
  id: number;
  orderToApply: number;
  ruleType: string;
  assignedTo: string;
  projectId: number;
  testSuiteId: number;
  includePatterns: string[];
  @type(Project)
  project: Project;
  @type(TestSuite)
  testSuite: TestSuite;

  constructor(data?: Partial<TestDistributionRule>) {
    Object.assign(this, data);
  }

  @computed
  get projectName() {
    return this.project ? this.project.name : "";
  }

  @computed
  get testSuiteName() {
    return this.testSuite ? this.testSuite.name : "";
  }
}


