import PopupMenu from "./popup-menu";
import { MenuItem } from "./popup-menu";

interface Props {
  items: MenuItem[];
  direction?: "left" | "right";
}

export default function ThreeDotMenu({ items, direction }: Props) {
  return items?.length > 0 ? (
    <PopupMenu icon="ellipsis vertical" items={items} direction={direction} />
  ) : null;
}
