import { RoleText, RoleType } from "models/user";
import Select from "./select";

interface Props {
  value: RoleType;
  onChange: (t: RoleType) => any;
  usePortal?: boolean;
  error?: boolean | string;
  label?: string;
}

const options = [
  { value: RoleType.USER, label: RoleText[RoleType.USER] },
  { value: RoleType.ADMIN, label: RoleText[RoleType.ADMIN] },
];

const RoleSelect = ({ onChange, value, usePortal, error, label }: Props) => (
  <Select
    className="role-select"
    onChange={(e) => onChange(e.value)}
    value={options.find((o) => o.value == value)}
    options={options}
    usePortal={usePortal}
    error={error}
    label={label}
  />
);

export default RoleSelect;
