import { removeSpaces, spacesInCamelCase } from "core/utils";
import { keys } from "lodash";
import { Timeframe } from "models/analytics";
import { ReactNode } from "react";
import { JsxElement } from "typescript";
import Select from "./select";

interface Props {
  value: Timeframe;
  onChange: (value: Timeframe) => any;
  error?: boolean | string;
  label?: string | JsxElement | ReactNode;
}

const TimeframeSelect = ({ onChange, value, error, label }: Props) => {
  return (
    <Select
      className="timeframe-select"
      onChange={(v) => onChange(Timeframe[removeSpaces(v)])}
      value={spacesInCamelCase(value)}
      options={keys(Timeframe).map(spacesInCamelCase)}
      error={error}
      label={label}
    />
  );
};

export default TimeframeSelect;
