import { observer } from "mobx-react-lite";
import { useInitialEffect } from "core/react-utils";
import { AnalyticsStore } from "stores";
import { Card } from "semantic-ui-react";
import { avgDisplay, numberWithCommas } from "core/utils";
import { displayStackedBarChart } from "core/charts";
import { TestsChartData } from "models/analytics";
import ChartTab from "./chart-tab";

const emptyData = new TestsChartData();

const TestsTab = observer(() => {
  const form = AnalyticsStore.testsChartData.options;
  const data = AnalyticsStore.testsChartData.data || emptyData;

  useInitialEffect(() => {
    AnalyticsStore.loadTestsChartData();
  });

  return (
    <ChartTab
      chartData={AnalyticsStore.testsChartData}
      drawChart={(id: string, labels: string[]) => {
        const counts = data.counts(form.frequency, form.dates);
        return displayStackedBarChart(id, labels, [
          {
            label: "Passed",
            backgroundColor: "#008000",
            data: counts.map((c) => c.passed),
          },
          {
            label: "Failed",
            backgroundColor: "#ed1d0a",
            data: counts.map((c) => c.failed),
          },
        ]);
      }}
      header={() => (
        <Card.Group centered>
          <Card>
            <Card.Content>
              <div className="name">Total Tests</div>
              <div className="value">{numberWithCommas(data.total)}</div>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <div className="name">Passed Tests</div>
              <div className="value">{numberWithCommas(data.passed)}</div>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <div className="name">Failed Tests</div>
              <div className="value">{numberWithCommas(data.failed)}</div>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <div className="name">Avg Tests Per Day</div>
              <div className="value">{avgDisplay(data.total, form.numberOfDays)}</div>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <div className="name">Unique Tests</div>
              <div className="value">{numberWithCommas(data.testCounts.uniqueTests)}</div>
            </Card.Content>
          </Card>
        </Card.Group>
      )}
    />
  );
});

export default TestsTab;
