import Select from "./select";
import { SettingsStore } from "stores";
import { observer } from "mobx-react-lite";
import { useEffect, useInitialEffect } from "core/react-utils";

interface Props {
  value: Array<number>;
  onChange: (ids: Array<number>) => any;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
  pipelineStageId?: number;
}

const EnvironmentMultiSelect = observer(
  ({ onChange, value, placeholder, error, label, pipelineStageId }: Props) => {
    const environments = pipelineStageId
      ? SettingsStore.activeEnvironments.filter((e) => e.pipelineStageId == pipelineStageId)
      : SettingsStore.activeEnvironments;

    const filteredEnvironments = value ? SettingsStore.environments.filter((e) => value.includes(e.id)) || null : null;

    useInitialEffect(() => {
      SettingsStore.ensureEnvironments();
    });

    useEffect(() => {
      if (pipelineStageId && !filteredEnvironments?.map(e => e.pipelineStageId).includes(pipelineStageId)) {
        onChange(null);
      }
    }, [pipelineStageId, value]);

    const options = environments.map(environment => {
      const label = (environment.name === environment.key) ? environment.name : `${environment.name} ${environment.key}`;
      return { label: label, value: environment.key, environmentName: environment.name, environmentId: environment.id };
    });

    let values = null;
    if (filteredEnvironments && filteredEnvironments.length > 0) {
      values = filteredEnvironments.map(env => {
        return {
          label: (env.name === env.key) ? env.name : `${env.name} ${env.key}`,
          value: env.key,
          environmentName: env.name,
          environmentId: env.id,
        };
      });
    }

    return (
      <Select
        className="environment-select"
        placeholder={placeholder}
        isSearchable={true}
        isMulti={true}
        onChangeMulti={(options) => onChange(options && options.map(o => o.environmentId))}
        autoDefault={!placeholder}
        isClearable={!!placeholder && !!value}
        value={values}
        options={options}
        formatOption={(option) =>
          option.environmentName == option.value ? (
            option.environmentName
          ) : (
            <div>
              {option.environmentName}
              <div className="info">{option.value}</div>
            </div>
          )
        }
        valueField="environmentId"
        labelField="name"
        error={error}
        label={label}
      />
    );
  },
);

export default EnvironmentMultiSelect;
