import Form from "./form";
import { observable, action } from "mobx";
import { notEmpty, validEmail } from "core/validation";

export default class TellAFriendForm extends Form {
  @observable email: string;
  @observable firstName: string;
  @observable lastName: string;
  @observable isSubmitted: boolean;

  validators = {
    email: [
      validEmail
    ],
    firstName: notEmpty,
    lastName: notEmpty,
  };

  @action.bound
  setDefaults() {
    this.email = "";
    this.firstName = "";
    this.lastName = "";
  }
}
