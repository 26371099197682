import { useEffect as useEffectReact, useState, useRef, DependencyList } from "react";
import { values, some } from "lodash";
import { useHistory } from "react-router";
import * as Stripe from "@stripe/stripe-js";
import { STRIPE_KEY } from "./constants";
import { autoRerun } from "./utils";

type UnloadEffect = () => void | undefined | Promise<void>;
type EffectCallback = () => void | Promise<void> | UnloadEffect;

export function useEffect(effect: EffectCallback, deps?: DependencyList) {
  useEffectReact(() => effect() as any, deps);
}

export function useInitialEffect(effect: EffectCallback) {
  return useEffect(effect, []);
}

export function useUnloadEffect(unloadEffect: UnloadEffect) {
  return useEffect(() => unloadEffect, []);
}

export function rerunWhileComponentLoaded(action: () => any, frequencyInSeconds: number) {
  useInitialEffect(() => autoRerun(action, frequencyInSeconds));
}

export interface Errors {
  message: string;
  fieldHasErrors: Record<string, boolean>;
}

export function useErrors() {
  return useState({ message: null, fieldHasErrors: {} } as Errors);
}

export async function submitWithErrorChecking(
  fieldHasErrors: Record<string, boolean>,
  setErrors: (e: Errors) => any,
  successAction: () => Promise<void>,
) {
  if (some(values(fieldHasErrors), (v) => v)) {
    setErrors({ message: null, fieldHasErrors });
  } else {
    try {
      await successAction();
    } catch (e) {
      setErrors({ message: "Request Failed", fieldHasErrors: {} });
    }
  }
}

export function clearErrors(fields: string[], errors: Errors, setErrors: (e: Errors) => any) {
  let errorCleared = false;

  fields.forEach((field) => {
    if (errors.fieldHasErrors[field]) {
      errors.fieldHasErrors[field] = false;
      errorCleared = true;
    }
  });

  if (errorCleared) setErrors(errors);
}

export function useRequestTracker() {
  const tracker = useRef(0);
  return {
    startRequest: () => (tracker.current = Math.random()),
    isLatestRequest: (num: number) => num == tracker.current,
  };
}

export function useOnlyLatestRequest<T>(request: () => Promise<T>, updater: (result: T) => any) {
  const { startRequest, isLatestRequest } = useRequestTracker();

  return async () => {
    const thisRun = startRequest();
    const result = await request();
    if (isLatestRequest(thisRun)) {
      updater(result);
    }
  };
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  useInitialEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  });

  return windowSize;
}

export function setPageInfo(name: string, title: string, additionalCssClass?: string) {
  const fullTitle = `Testery - ${title}`;
  const className = `${name}-page` + (additionalCssClass ? " " + additionalCssClass : "");
  const appElement = window.document.getElementById("app");

  if (appElement.className != className) appElement.className = className;
  if (window.document.title != fullTitle) window.document.title = fullTitle;
}

export function useNavigation(path: string) {
  const history = useHistory();
  return () => history.push(path);
}

let stripe;

export function loadStripe() {
  return stripe ? stripe : (stripe = Stripe.loadStripe(STRIPE_KEY));
}
