import { range } from "lodash";
import Select from "./select";

interface Props {
  min: number;
  max: number;
  value: number;
  menuPlacement?: "auto" | "top" | "bottom";
  error?: boolean | string;
  label?: string;
  onChange: (value: number) => any;
  emptyOption?: boolean;
}

const NumberSelect = ({
  onChange,
  value,
  min,
  max,
  menuPlacement,
  error,
  label,
  emptyOption,
}: Props) => {
  const validEmtpy = emptyOption && value == null;
  if (value > max && !validEmtpy) onChange(max);
  if (value < min && !validEmtpy) onChange(min);

  const options = range(min, max + 1).map((i) => i.toString());

  if (emptyOption) {
    options.unshift(" ");
  }

  return (
    <Select
      className="number-select"
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body}
      onChange={(v) => onChange(v == null || v == " " ? null : parseInt(v))}
      value={value == null ? "" : value.toString()}
      options={options}
      autoDefault={true}
      error={error}
      label={label}
    />
  );
};

export default NumberSelect;
