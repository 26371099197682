import { organizationUrl } from "core/utils";
import { Environment } from "models/settings";
import { Link } from "react-router-dom";

interface Props {
  environment: Environment;
}

export default function EnvironmentLink({ environment }: Props) {
  if (!environment) return <span />;

  return <Link to={organizationUrl(`environments/${environment.id}`)}>{environment.name}</Link>;
}
