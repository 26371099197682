import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { isArray, keys, map } from "lodash";
import { API_URL } from "./constants";
import { ValidationError } from "models/forms/form";
import { toType, toTypeList } from "./serialization";
import { AppStore } from "../stores";

const axiosInstance = axios.create({
  baseURL: API_URL + "/api",
  validateStatus: (status: number) => {
    return (status >= 200 && status < 300) || status == 400 || status == 403;
  },
});

export interface Response<T> {
  status: number;
  error?: string;
  data?: T;
  validationErrors?: ValidationError[];
}

export function mapValidationErrors(data: any) {
  if (isArray(data)) {
    return data;
  }
  return map(keys(data), (key) => {
    return { field: key, message: data[key] };
  });
}

function handleResponse<T, R>(response: AxiosResponse<T>, Type: new () => T): Promise<Response<R>> {
  if (response.status == 403) {
    return Promise.resolve({ status: 403, data: null } as Response<R>);
  }

  const result = { status: response.status, data: response.data };
  if (response.status == 400) {
    return Promise.reject({ status: 400, validationErrors: mapValidationErrors(result.data) });
  }

  if (response.status == 404) {
    return Promise.resolve({ ...result, data: null });
  }

  if (Type && result.data) {
    let data;

    if (Array.isArray(result.data)) {
      data = toTypeList(result.data as any, Type);
    } else {
      data = toType(result.data, Type);
    }
    return Promise.resolve({ ...result, data: data });
  }

  return Promise.resolve(result as any);
}

function handleError<T, R>(response: AxiosResponse<T>, returnsArray: boolean = false): Promise<Response<R>> {
  const parsedResponse = JSON.parse(JSON.stringify(response));
  const exemptCalls = [
    "/logout",
    "/insight",
    "/jira/project",
    "/test-run-info",
    "/flaky-test-info",
    "/test-info",
  ];
  let nonExempt = true;
  exemptCalls.forEach(call => {
    if (parsedResponse?.config?.url.includes(call))
      nonExempt = false;
  });
  if (nonExempt && parsedResponse.status == 401) {
    void AppStore.logout(false);
    window.location.href = "/login";
    return;
  }

  return Promise.resolve({ status: parsedResponse.status, data: returnsArray ? [] : {} } as Response<R>);
}

const API = {
  get: <T>(path: string, responseType?: new () => T): Promise<Response<T>> =>
    axiosInstance.get<T>(path, { retry: 0 } as AxiosRequestConfig).then((res) => handleResponse<T, T>(res, responseType))
      .catch(err => handleError(err)),

  getList: <T>(path: string, responseType?: new () => T): Promise<Response<T[]>> =>
    axiosInstance.get<T>(path).then((res) => handleResponse<T, T[]>(res, responseType))
      .catch(err => handleError(err, true)),

  post: <T>(path: string, data: any, responseType?: new () => T): Promise<Response<T>> =>
    axiosInstance.post<T>(path, data).then((res) => handleResponse<T, T>(res, responseType))
      .catch(err => handleError(err)),

  postMultipart: <T>(path: string, data: any, responseType?: new () => T): Promise<Response<T>> =>
    axiosInstance.post<T>(path, data, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => handleResponse<T, T>(res, responseType))
      .catch(err => handleError(err)),

  put: <T>(path: string, data: any, responseType?: new () => T): Promise<Response<T>> =>
    axiosInstance.put<T>(path, data).then((res) => handleResponse<T, T>(res, responseType))
      .catch(err => handleError(err)),

  patch: <T>(path: string, data: any, responseType?: new () => T): Promise<Response<T>> =>
    axiosInstance.patch<T>(path, data).then((res) => handleResponse<T, T>(res, responseType))
      .catch(err => handleError(err)),

  delete: <T>(path: string, responseType?: new () => T): Promise<Response<any>> =>
    axiosInstance.delete(path).then((res) => handleResponse<T, T>(res, responseType))
      .catch(err => handleError(err)),

  setToken: (token: string) => (axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`),

  setAccountId: (accountId: number) => (axiosInstance.defaults.headers.common["Account"] = accountId),
};

export default API;
