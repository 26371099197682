import { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { Insight } from "../../models/insight";
import { InsightStore } from "../../stores";
import "./insight-modal.scss";

interface Props {
  insight: Insight;
  read: boolean,
  onClose: (disableButtons: boolean) => any;
}

function InsightModal({ insight, read, onClose }: Props) {
  const [open, setOpen] = useState(false);

  function closeModal() {
    setOpen(false);
  }

  return (
    <Modal
      className={"insight-modal"}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button content={"View Insight"} icon={"eye"} positive={true} />}
    >
      <Modal.Header>{insight.title}</Modal.Header>
      <Modal.Content>
        <div dangerouslySetInnerHTML={{ __html: insight.content }} />
      </Modal.Content>
      <Modal.Actions>
        {read &&
          <Button onClick={closeModal}>Done</Button>
        }
        {!read &&
          <>
            <Button onClick={closeModal} icon={"envelope outline"} content={"Leave Unread"} />
            <Button onClick={async () => {
              onClose(true);
              closeModal();
              await InsightStore.markRead(insight.id);
              onClose(false);
            }} icon={"envelope open outline"} positive={true} content={"Mark Read"} />
          </>
        }
      </Modal.Actions>
    </Modal>
  );
}

export default InsightModal;
