import DurationDisplay from "components/duration-display";
import EnvironmentLink from "components/environment-link";
import TestRunProgress from "components/test-run-progress";
import { calculateDuration } from "core/date-utils";
import { buildRefUrl, organizationUrl } from "core/utils";
import { truncate } from "lodash";
import { observer } from "mobx-react-lite";
import { TestPlanRunSuite } from "models/test-plan";
import { Link } from "react-router-dom";
import { Icon, Label } from "semantic-ui-react";
import { TestPlanStore } from "stores";

interface Props {
  stageId: number;
  suite: TestPlanRunSuite;
  stageComplete: boolean;
}

const SuiteResults = observer(({ suite, stageId, stageComplete }: Props) => {
  let testSuite = TestPlanStore.findTestPlanSuiteByStageAndId(stageId, suite.testPlanSuiteId);
  if (!testSuite)
    testSuite = TestPlanStore.findTestPlanSuiteById(suite.testPlanSuiteId);
  const refLink = buildRefUrl(testSuite.suite.project, suite.testRun || suite.commit);
  const branch = suite.testRun?.branch || suite.branch;
  const gitRef = suite.testRun?.gitRef || suite.commit;
  const build = suite.testRun?.build || suite.build;

  return (
    <tr className="suite-result">
      <td className="no-bottom-border" colSpan={2}></td>
      <td className="name">
        <div>
          {suite.isRunning && <Icon name="circle notch" loading={true} />}
          {testSuite.suite.name}{" "}
          {stageComplete && suite.isNotRun && (
            <Label color="yellow" size="mini">
              Skipped
            </Label>
          )}
          {suite.isError && (
            <Label color="red" size="mini">
              Error
            </Label>
          )}
        </div>
        <div className="sub-info">{testSuite.suite.project.name}</div>
      </td>

      <td className="results">
        {suite.totalTestCount > 0 && (
          <Link to={organizationUrl(`test-runs/${suite.testRunId}`)}>
            <TestRunProgress
              pass={suite.passTestCount}
              fail={suite.failTestCount}
              total={suite.totalTestCount}
            />
          </Link>
        )}
        {suite.totalTestCount == 0 && suite.isError && (
          <div className="error-info">
            <div>Failed</div>
            <div className="sub-info">{suite.testRun.statusInfo}</div>
          </div>
        )}
      </td>
      <td className="info">
        <div>
          <div className="item">
            <div>Env:</div>
            <div>
              <EnvironmentLink environment={suite.environment} />
            </div>
          </div>
          {build && (
            <div className="item">
              <div>Build:</div>
              <div>{truncate(build.name, { length: 40 })}</div>
            </div>
          )}
          {branch && (
            <div className="item">
              <div>Branch:</div>
              <div>{truncate(branch, { length: 40 })}</div>
            </div>
          )}
          {refLink && (
            <div className="item">
              <div>Git Ref:</div>
              <div>
                <a className="github" target="_blank" href={refLink}>
                  {gitRef?.substr(0, 8)}
                </a>
              </div>
            </div>
          )}
        </div>
      </td>
      <td className="duration">
        {!suite.isNotRun && (
          <DurationDisplay
            duration={calculateDuration(suite.testRun.startTime, suite.testRun.endTime)}
          />
        )}
      </td>
      <td className="actions"></td>
    </tr>
  );
});

export default SuiteResults;
