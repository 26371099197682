import Spinner from "components/spinner";
import { useInitialEffect } from "core/react-utils";
import { findIndex } from "lodash";
import { observer } from "mobx-react-lite";
import Page from "pages/page";
import { useHistory, useParams } from "react-router";
import { Tab, TabProps } from "semantic-ui-react";
import { ProjectStore, SettingsStore } from "stores";
import DeploymentsTab from "./deployments-tab";
import BuildsTab from "./builds-tab";
import "./project.scss";
import TestSuitesTab from "./test-suites-tab";
import { organizationUrl, relativeLink } from "core/utils";
import SettingsTab from "./settings-tab";
import QuarantinedTestsTab from "./quarantined-tests-tab";

const ProjectPage = observer(() => {
  const { projectKey, tab } = useParams<{ projectKey: string; tab: string }>();
  const history = useHistory();

  useInitialEffect(() => {
    ProjectStore.ensureLoaded();
    SettingsStore.ensureEnvironments();
  });

  const project = ProjectStore.findByKey(projectKey);

  if (!project)
    return (
      <Page name="project" title="Project">
        <Spinner text="Loading Project" />
      </Page>
    );

  if (!tab) {
    history.replace(`${project.key}/test-suites`);
  }

  const tabPanes = [
    {
      menuItem: { key: "home", icon: "home", content: "" },
      render: () => <div></div>,
    },
    {
      menuItem: { key: "test-suites", icon: null, content: "Test Suites" },
      render: () => <TestSuitesTab key={1} project={project} />,
    },
  ];
  if (project.hasBuildArtifacts) {
    tabPanes.push({
      menuItem: { key: "builds", icon: null, content: "Builds" },
      render: () => <BuildsTab key={2} project={project} />,
    });
  }
  tabPanes.push(
    {
      menuItem: { key: "deployments", icon: null, content: "Deployments" },
      render: () => <DeploymentsTab key={3} project={project} />,
    },
  );
  tabPanes.push(
    {
      menuItem: { key: "settings", icon: null, content: "Settings" },
      render: () => <SettingsTab key={4} project={project} />,
    },
  );
  tabPanes.push(
    {
      menuItem: { key: "quarantined-tests", icon: null, content: "Quarantined Tests" },
      render: () => <QuarantinedTestsTab key={5} project={project} />,
    },
  );

  const tabIndex = findIndex(tabPanes, (i) => i.menuItem.key == tab);

  const navigate = (data: TabProps) => {
    const name = tabPanes[data.activeIndex].menuItem.key;
    history.push(name == "home" ? organizationUrl("projects") : relativeLink(name));
  };

  return (
    <Page name="project" title={project.name}>
      <Tab
        onTabChange={(_, data) => navigate(data)}
        activeIndex={tabIndex}
        menu={{ secondary: true, pointing: true }}
        panes={tabPanes}
      />
    </Page>
  );
});

export default ProjectPage;
