import TesteryTable from "../testery-table";
import { Insight } from "../../models/insight";
import DatetimeDisplay from "../datetime-display";
import { Button } from "semantic-ui-react";
import { InsightStore } from "../../stores";
import { useState } from "react";
import InsightModal from "./insight-modal";
import { capitalize } from "../../core/utils";

interface Props {
  insights: Array<Insight>;
  read: boolean;
  rerender: () => any;
}

export default function InsightTable({ insights, read, rerender }: Props) {
  const [updateButtonsDisabled, setUpdateButtonsDisabled] = useState(false);

  const insightTableData = [];

  insights.forEach(insight => {
    insightTableData.push([
      <DatetimeDisplay key={insight.id} datetime={insight.createdAt} />,
      insight.title,
      capitalize(insight.type),
      read ?
        <>
          <InsightModal read={read} insight={insight} onClose={setUpdateButtonsDisabled} />
          <Button content={"Mark Unread"} icon={"envelope outline"} disabled={updateButtonsDisabled}
                  loading={updateButtonsDisabled} onClick={async () => {
            setUpdateButtonsDisabled(true);
            await InsightStore.markUnread(insight.id);
            rerender();
            setUpdateButtonsDisabled(false);
          }} />
        </>
        :
        <>
          <InsightModal read={read} insight={insight} onClose={setUpdateButtonsDisabled} />
          <Button content={"Mark Read"} icon={"envelope open outline"} disabled={updateButtonsDisabled}
                  loading={updateButtonsDisabled} onClick={async () => {
            setUpdateButtonsDisabled(true);
            await InsightStore.markRead(insight.id);
            rerender();
            setUpdateButtonsDisabled(false);
          }} />
        </>,
    ]);
  });

  return (
    <div className={"insight-table"}>
      <TesteryTable tableHeaders={["Insight Date", "Title", "Type", ""]}
                    tableData={insightTableData}
      />
    </div>
  );
}
