import API from "core/api";
import { handleAuthReturn } from "core/auth0";
import * as Storage from "core/storage";
import { autoRetry, getUrlParam, organizationPath } from "core/utils";
import { action, observable, runInAction } from "mobx";
import { Account } from "models/account";
import LoginForm from "models/forms/login-form";
import NotificationForm from "models/forms/notification-form";
import PlanForm from "models/forms/plan-form";
import RegistrationForm from "models/forms/registration-form";
import UpdatePasswordForm from "models/forms/update-password-form";
import UserInfoForm from "models/forms/user-info-form";
import { CurrentUser, Invitation, RoleType, User, UserDetails, UserRole } from "models/user";
import { ModalStoreClass } from "./modal-store";
import { Plan } from "../models/plan";
import { PromoCode } from "../models/promo-code";
import { AccountCounts, AccountData } from "models/employee";
import { subMonths } from "date-fns";

export class AppStoreClass {
  private readonly ModalStore: ModalStoreClass;
  private readonly clearAccountData: () => any;
  @observable isLoggedIn: boolean = false;
  @observable authId: string;
  @observable user: CurrentUser;
  @observable account: Account;
  @observable selectedAccountId: number;
  @observable selectedAccountName: string;
  @observable userIsAdmin: boolean = false;
  @observable isLeftNavCollapsed = Storage.isLeftNavCollapsed();
  @observable navPath: string = "";
  @observable windowHeight: number;
  @observable availablePlans: Array<Plan>;
  @observable accountData = new AccountData();


  constructor(ModalStore: ModalStoreClass, clearAccountData: () => any) {
    this.ModalStore = ModalStore;
    this.clearAccountData = clearAccountData;

    const pushState = history.pushState;
    const updateNav = this.updateNavPathFromUrl;
    history.pushState = function() {
      pushState.apply(history, arguments);
      updateNav();
    };
    const replaceState = history.replaceState;
    history.replaceState = function() {
      replaceState.apply(history, arguments);
      updateNav();
    };
    window.addEventListener("popstate", updateNav);

    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  }

  get isTesteryAccount() {
    return this.account?.id == 1;
  }

  @action.bound
  updateHeight() {
    this.windowHeight = window.innerHeight;
  }

  @action.bound
  updateNavPathFromUrl() {
    const path = organizationPath();
    this.navPath = path.endsWith("/") ? path.slice(0, -1) : path;
  }

  @action.bound
  showFullLeftNav() {
    this.setLeftNavCollapsed(false);
  }

  @action.bound
  collapseLeftNav() {
    this.setLeftNavCollapsed(true);
  }

  @action
  setLeftNavCollapsed(value: boolean) {
    this.isLeftNavCollapsed = value;
    Storage.setLeftNavCollapsed(value);
  }

  @action
  async initialize() {
    this.updateNavPathFromUrl();
    Storage.setInviteToken(getUrlParam("token") || Storage.getInviteToken());
    Storage.setInviteEmail(getUrlParam("email") || Storage.getInviteEmail());
    Storage.setPromoCode(getUrlParam("promoCode") || Storage.getPromoCode());
    const location = window.location.pathname;

    if (location == "/accept" || location == "/password-reset") {
      this.logout();
    } else {
      const authToken = Storage.getUserKey();
      API.setToken(authToken);

      if (authToken) {
        try {
          await autoRetry(() => this.loadUser(), 1000, 20);
        } catch {
        }

        if (this.user) {
          runInAction(() => (this.isLoggedIn = true));
        } else {
          try {
            await this.logout();
          } catch {
          }
        }
      }
    }
  }

  @action
  async authReturn() {
    const authId = await handleAuthReturn();
    return runInAction(() => (this.authId = authId));
  }

  @action
  async loadUser() {
    const { data } = await API.get("/users/me", CurrentUser);
    runInAction(() => (this.user = data));
    if (!this.selectedAccountId && data.roles.length > 0) {
      if (data.roles.length == 1) {
        await this.selectAccount(data.roles[0].accountId);
      }
      let orgName: string = null;
      let path = window.location.pathname;
      if (path.length > 0) {
        path = path.substring(1);
        const slashIndex = path.indexOf("/");
        if (slashIndex > 0) {
          orgName = path.substring(0, slashIndex);
        }
      }
      const accountId = Storage.getAccountId();
      const pathRole = data.roles.find((r) => r.accountName == orgName);
      const accountRole = data.roles.find((r) => r.accountId == accountId);
      if (pathRole) {
        await this.selectAccount(pathRole.accountId);
      } else if (accountRole) {
        await this.selectAccount(accountId);
      } else if (data.roles.length > 0) {
        await this.selectAccount(data.roles[0].accountId);
      }
    }
  }

  async reloadUser() {
    const { data } = await API.get("/users/me", CurrentUser);
    return runInAction(() => (this.user = data));
  }

  updateNotificationSettings(form: NotificationForm) {
    return form.submit(async (data) => {
      const result = await API.post("users/me/notification-settings", data, CurrentUser);
      return runInAction(() => (this.user = result.data));
    });
  }

  getSelectedAccountRole() {
    return this.user.roles.find((r) => r.accountId == this.selectedAccountId);
  }

  @action
  async updatePassword(updatePasswordForm: UpdatePasswordForm) {
    return updatePasswordForm.submit((data) => API.post("/users/me/password", data, User));
  }

  @action
  async updateUserInfo(userInfoForm: UserInfoForm) {
    return await userInfoForm.submit(async (data) => {
      const result = await API.post("/users/me", data, User);
      userInfoForm.populate(result.data);
      return this.reloadUser();
    });
  }

  @action
  async logout(postLogoutToApi: boolean = true) {
    Storage.setUserKey(null);
    runInAction(() => (this.isLoggedIn = false));
    await this.clear();
    try {
      if (postLogoutToApi)
        await API.post("/logout", {});
    } catch {
    }
    API.setToken(null);
  }

  @action
  async login(loginForm: LoginForm) {
    await loginForm.submit(async (data) => {
      const result = await API.post("/login", data, UserDetails);
      const token = result.data.token;

      Storage.setSlackMemberId(null);

      if (token == "invalid") {
        throw "login-failed";
      } else {
        await this.postLogin(token);
      }
    });
  }

  async connectSlackUser() {
    await API.post("/slack-connect", { slackMemberId: Storage.getSlackMemberId() });

    Storage.setSlackMemberId(null);
  }

  async authLogin(authId: string) {
    const authData = { authId: authId, slackMemberId: null };

    const slackMemberId = Storage.getSlackMemberId();
    if (slackMemberId) authData.slackMemberId = slackMemberId;

    const result = await API.post("/auth-return", authData, UserDetails);

    if (slackMemberId) Storage.setSlackMemberId(null);

    const userDetails = result.data;
    await this.postLogin(userDetails.token);
  }

  async register(authId: string) {
    Storage.setFirstTimeSetup(true);
    let result = await API.post("/register", { authId }, UserDetails);
    const userDetails = result.data;
    await this.postLogin(userDetails.token);
  }

  async createOrganization(authId: string) {
    Storage.setFirstTimeSetup(true);
    await API.post("/account", { authId }, Account);
    await this.loadUser();
  }

  async authIntegration(authId: string) {
    await API.post("/auth-integration", { authId });
  }

  @action
  async postLogin(token: string) {
    API.setToken(token);
    Storage.setUserKey(token);
    await this.loadUser();
    if (Storage.getInviteToken() != null) {
      this.acceptInvitation();
    }
    runInAction(() => (this.isLoggedIn = !!this.user));
  }

  @action
  async selectAccount(accountId: number) {
    const role = this.user.roles.find((r) => r.accountId == accountId);
    Storage.setAccountId(accountId);
    API.setAccountId(accountId);
    this.selectedAccountId = role.accountId;
    this.selectedAccountName = role.accountName;
    this.userIsAdmin = role.roleType == RoleType.ADMIN;
    this.clearAccountData();
    return await this.loadAccount();
  }

  @action
  async loadAvailablePlans() {
    const results = await API.getList("/plan", Plan);
    runInAction(() => (this.availablePlans = results.data));
    return results.data;
  }

  @action
  async loadAccount() {
    const result = await API.get("/account", Account);
    runInAction(() => (this.account = result.data));
    return result.data;
  }

  @action
  async clear() {
    this.user = null;
    this.selectedAccountId = null;
    this.selectedAccountName = null;
    this.account = null;
    this.clearAccountData();
    Storage.setAccountId(null);
  }

  get accountSetup() {
    return this.account && this.account.setupComplete;
  }

  @action
  async updateAccountName(name: string) {
    const result = await API.post(`/account/change-name?name=${name}`, {}, Account);
    runInAction(() => (this.account = result.data));
    this.loadUser();
  }

  @action
  async updatePaymentInfo(planForm: PlanForm) {
    return await planForm.submit(async (data) => {
      const result = await API.post("/account/payment-info", data, Account);
      return runInAction(() => (this.account = result.data));
    }, true);
  }

  @action
  async finishSetup() {
    const result = await API.post("/account/setup-complete", {}, Account);
    return runInAction(() => (this.account = result.data));
  }

  @action
  async doUserEmailValidation() {
    const result = await API.post(
      `/verify-email?token=${encodeURIComponent(Storage.getInviteToken())}&email=${encodeURIComponent(getUrlParam("email"))}`,
      {},
      UserDetails,
    );
    if (result.data && result.data.token) {
      Storage.clearInviteData();
      await this.postLogin(result.data.token);
    }
  }

  @action
  async registerByEmail(form: RegistrationForm) {
    await form.submit(async (data) => {
      data.token = Storage.getInviteToken();
      data.promoCode = Storage.getPromoCode();
      const invite = data.token ? await this.loadInvitation(form.email) : null;
      const result = await API.post("/register-email", data, UserDetails);
      if (result.data && result.data.token) {
        Storage.clearInviteData();
        await this.postLogin(result.data.token);
        await this.selectAccount(invite.accountId);
      }
    });
  }

  @action
  async acceptInvitation() {
    const result = await API.post(
      `/users/me/accept?token=${encodeURIComponent(Storage.getInviteToken())}&email=${encodeURIComponent(Storage.getInviteEmail())}`,
      {},
      UserRole,
    );
    await this.reloadUser();
    await this.selectAccount(result.data.accountId);
    Storage.clearInviteData();
  }

  @action
  async loadInvitation(email?: string) {
    if (!Storage.getInviteToken()) return null;

    const emailToSend = email ? email : Storage.getInviteEmail();

    const result = await API.get(`/invitations?token=${encodeURIComponent(Storage.getInviteToken())}&email=${encodeURIComponent(emailToSend)}`, Invitation);

    return result.data;
  }

  @action
  async forgotPassword(form: LoginForm) {
    form.password = "blank";
    return form.submit(async ({ email }) => {
      await API.post("/forgot-password", { email });
    });
  }

  @action
  async passwordReset(onPasswordReset: () => any) {
    const token = getUrlParam("reset-token");

    if (token) {
      const result = await API.post("/password-reset", { token }, UserDetails);
      if (result.data) {
        await this.postLogin(result.data.token);
        this.ModalStore.updatePassword.show(onPasswordReset, { hideCancel: true });
      }
    }
  }

  @action
  async checkPromoCode(promoCode: string): Promise<PromoCode> {
    const response = await API.get(`/validate-promo-code?promoCode=${encodeURIComponent(promoCode)}`, PromoCode);
    return response.data;
  }

  async loadAccountCounts() {
    const result = await API.get(`/account/execution`, AccountData);
    runInAction(() => (this.accountData = result.data));
  }

  getThisMonthAccountCounts() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (
      this.accountData.counts.find((a) => a.year == year && a.month == month) || new AccountCounts()
    );
  }

  getLastMonthAccountCounts() {
    const date = subMonths(new Date(), 1);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (
      this.accountData.counts.find((a) => a.year == year && a.month == month) || new AccountCounts()
    );
  }
}
