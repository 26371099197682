import Page from "pages/page";
import { useHistory } from "react-router";
import { Tab, TabProps } from "semantic-ui-react";
import { Icons } from "core/constants";
import EnvironmentsTab from "./environments-tab";
import PipelineStagesTab from "./pipeline-stages-tab";
import RunnerPoolsTab from "./runner-pools-tab";
import "./environments.scss";
import { substringAfterLast } from "core/utils";
import { findIndex } from "lodash";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { organizationUrl } from "core/utils";


const EnvironmentsPage = () => {
  const history = useHistory();
  const tab = substringAfterLast(window.location.pathname, "/");

  const navigate = (data: TabProps) => {
    let path = ""
    if (data.activeIndex == 0) {
      path = "../environments"
    } else {
      path = organizationUrl("environments/" + panes[data.activeIndex].menuItem.key)
    }
    history.push(path);
  };

  const panes = [
    {
      menuItem: { key: "environments", icon: Icons.environment, content: "Environments" },
      render: () => <EnvironmentsTab />,
    },
    {
      menuItem: { key: "pipelines", icon: Icons.pipelines, content: "Pipeline Stages" },
      render: () => <PipelineStagesTab />,
    },
  ];

  if (tab == 'runner-pools' || useFeatureIsOn("runner-pools")) {
    panes.push(  {
      menuItem: { key: "runner-pools", icon: Icons.run, content: "Runner Pools" },
      render: () => <RunnerPoolsTab />,
    })
  }

  return (
    <Page name="environments" title="Environments" icon={Icons.environment}>
      <Tab
        onTabChange={(_, data) => navigate(data)}
        activeIndex={findIndex(panes, (i) => i.menuItem.key == tab)}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </Page>
  );
};

export default EnvironmentsPage;
