import { Chart } from "chart.js";
import { TestDistributionAnalysis } from "models/analytics";
import { useEffect } from "react";


interface Props {
    analysisData: TestDistributionAnalysis;
    
}

let chart : Chart | null = null;

export default function TestDistributionAnalysisChart({ analysisData } : Props) {
    useEffect(() => { 
        if (analysisData) {
          if (chart) {
            chart.destroy();
          }
          const ctx = (document.getElementById('testDistributionAnalysisChart') as HTMLCanvasElement).getContext('2d');
          chart = new Chart(ctx, {
            type: 'bar',
            data: {
              labels: ["Unit", "Integration", "E2E / Acceptance"].reverse(),
              datasets: [
                {
                  data: [analysisData?.unitTestCount, analysisData?.integrationTestCount, analysisData?.e2eTestCount].reverse(),
                  backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(255, 159, 64, 0.2)'
                                ],
                  barPercentage: 1.0,
                  categoryPercentage: 1.0
                  
                },
              ]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
              legend: {
                  display: false
              }},
                scales: {
                    x: {
                        stacked: true,
                        max: analysisData.max() * 1.1
                    },
                    y: {
                        stacked: true
                    }
                },
                indexAxis: 'y',
            }
        });
        
        }
      }, [analysisData]);

    return (
        <>
            <div style={{maxHeight: 320, maxWidth: 480}}>
                <canvas id="testDistributionAnalysisChart" width="400" height="400"></canvas>   
            </div>
        </>
    );
}
