import { observer } from "mobx-react-lite";
import { AppStore, ModalStore } from "stores";
import { Modal, Button, Icon } from "semantic-ui-react";
import NotificationSettings from "components/notification-settings";
import { saveNotificationSettings } from "components/notification-settings";

export function showNotificationSettingsIfNeeded(onClose: () => any = null) {
  const role = AppStore.getSelectedAccountRole();
  if (!role.notifyByEmail && !role.notifyBySlack) {
    ModalStore.notificationSettings.show(onClose);
    return true;
  }

  return false;
}

async function save() {
  const { notificationSettings } = ModalStore;

  await saveNotificationSettings();
  notificationSettings.hide();
}

const NotificationSettingsModal = observer(() => {
  const { notificationSettings } = ModalStore;

  return (
    <Modal open={notificationSettings.showing} size="mini">
      <Modal.Header>Follow Test run</Modal.Header>
      <Modal.Content>
        <NotificationSettings />
      </Modal.Content>
      <Modal.Actions>
        <Button size="small" onClick={() => notificationSettings.hide()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button size="small" onClick={save} color="green">
          <Icon name="check" />
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default NotificationSettingsModal;
