import { useInitialEffect, useNavigation } from "core/react-utils";
import "./triggers.scss";
import { observer } from "mobx-react-lite";
import { Button, Icon, Checkbox, Label } from "semantic-ui-react";
import {
  AppStore,
  ModalStore,
  ProjectStore,
  TriggerStore,
  SettingsStore,
  TestSuiteStore,
  PipelineStore,
  TestPlanStore,
} from "stores";
import Page from "pages/page";
import { Icons } from "core/constants";
import TestTrigger from "models/test-trigger";
import { useHistory } from "react-router";
import { buildRefUrl } from "core/utils";
import DatetimeDisplay from "components/datetime-display";
import SortableTable from "components/sortable-table";
import ProjectSelect from "components/form/project-select";
import EnvironmentSelect from "components/form/environment-select";
import { useState } from "react";
import TestFilters from "components/test-filters";
import ThreeDotMenu from "components/three-dot-menu";
import TestSuiteLink from "components/test-suite-link";
import ProjectLink from "components/project-link";
import EnvironmentLink from "components/environment-link";
import TestSuiteSelect from "components/form/test-suite-select";
import TestPlanLink from "components/test-plan-link";

interface TriggerRowProps {
  trigger: TestTrigger;
  push: (path: string) => any;
}

async function deleteTrigger(id: number) {
  ModalStore.confirmation.show(
    async (okClicked: boolean) => {
      if (okClicked) {
        await TriggerStore.delete(id);
      }
    },
    {
      message: `Are you sure you want to delete this trigger?`,
      okText: "Delete",
      okColor: "red",
    },
  );
}

async function runTrigger(trigger: TestTrigger, push: (path: string) => any) {
  const canRun = !trigger.latestDeploy || ProjectStore.hasDeploy(trigger.projectId, trigger.environmentId);

  const navigate = (id: number) => {
    if (!id) return;

    if (trigger.isTestPlanTrigger) {
      push(`test-plans/${trigger.testPlanId}/runs`);
    } else {
      push(`test-runs/${id}`);
    }
  };

  if (canRun) {
    if (!trigger.isTestPlanTrigger && trigger.environmentId == null) {
      ModalStore.pipelineEnvironment.show(
        async (result) => {
          if (result) {
            const id = await TriggerStore.runTriggerForEnvironment(trigger.id, result.environmentId);
            navigate(id);
          }
        },
        {
          pipelineStageId: trigger.pipelineStageId,
          environmentId: trigger.environmentId,
          saveText: "Run Trigger Now",
        },
      );
    } else {
      const id = await TriggerStore.runTrigger(trigger.id);
      navigate(id);
    }
  } else {
    ModalStore.confirmation.show(null, {
      message: (
        <h3>
          {`No Latest Deploy for ${trigger.project?.name} in ${trigger.environment?.name}.`}
          <br /> <br />
          Trigger cannot be run.
        </h3>
      ),
      hideCancel: true,
      size: "small",
    });
  }
}

const TriggerRow = observer(({ trigger, push }: TriggerRowProps) => {
  const isAdmin = AppStore.userIsAdmin;

  let deploys = <div>On deploy</div>;

  if (trigger.project && trigger.deployProjects.length > 0) {
    deploys = (
      <>
        <div>On deploy of</div>
        {trigger.deployProjects.map((p) => (
          <Label key={p.id} size="small" color="grey">
            {p.name}
          </Label>
        ))}
      </>
    );
  }

  let actions = [
    { icon: Icons.run, text: "Run Now", onClick: () => runTrigger(trigger, push) },
    {
      icon: Icons.edit,
      text: "Edit Trigger",
      onClick: () => push(`triggers/${trigger.id}`),
    },
    {
      icon: Icons.delete,
      text: "Delete Trigger",
      onClick: () => deleteTrigger(trigger.id),
    },
  ];

  if (!isAdmin) {
    actions = [actions[0]];
  }

  const toggleEnabled = () => isAdmin && TriggerStore.toggleEnabled(trigger);
  const refLink = buildRefUrl(trigger.project, trigger.commit);

  return (
    <tr>
      <td className="name">{trigger.name}</td>
      <td className="project">
        {!trigger.isTestPlanTrigger && <ProjectLink project={trigger.project} />}
        {!trigger.isTestPlanTrigger && trigger.testSuite && <TestSuiteLink testSuite={trigger.testSuite} />}
        {trigger.isTestPlanTrigger && <TestPlanLink testPlan={trigger.testPlan} />}
      </td>
      <td>
        {trigger.pipelineStage?.name}
        {trigger.pipelineStage && trigger.environment && " / "}
        <EnvironmentLink environment={trigger.environment} />
      </td>
      <td>
        <DatetimeDisplay datetime={trigger.lastRunAt} />
      </td>
      <td className="version">
        {trigger.latestDeploy && <span>Latest Deploy</span>}
        {trigger.build && !trigger.latestDeploy && (
          <div className="item">
            <div>Build:</div>
            <div>{trigger.build.name}</div>
          </div>
        )}
        {refLink && (
          <div className="item">
            <div>Git Ref:</div>
            <div>
              <a className="github" onClick={() => (window.location.href = refLink)}>
                {trigger.commit.substr(0, 8)}
              </a>
            </div>
          </div>
        )}
      </td>
      <td className="tags">
        <TestFilters
          includeTags={trigger.testSuite?.includeTags || trigger.includeTags}
          excludedTags={trigger.testSuite?.excludedTags || trigger.excludedTags}
          testFilters={trigger.testSuite?.testFilters || trigger.testFilters}
        />
      </td>
      <td className="trigger">{trigger.onDeploy ? deploys : trigger.cron}</td>
      <td>
        <Checkbox
          className="enabeled-toggle"
          readOnly={!isAdmin}
          checked={trigger.enabled}
          toggle
          onChange={toggleEnabled}
        />
      </td>
      <td className="actions">
        <ThreeDotMenu items={actions} />
      </td>
    </tr>
  );
});

const TriggersPage = observer(() => {
  const [environmnetId, setEnvironmentId] = useState(null as number);
  const [projectId, setProjectId] = useState(null as number);
  const [testSuiteId, setTestSuiteId] = useState(null as number);
  const nav = useNavigation("triggers/new");
  const history = useHistory();

  useInitialEffect(() => {
    TriggerStore.loadAll();
    ProjectStore.ensureLoaded();
    SettingsStore.ensureEnvironments();
    PipelineStore.ensureLoaded();
    ProjectStore.ensureLatestDeploysLoaded();
    TestSuiteStore.ensureLoaded();
    TestPlanStore.ensureLoaded();
  });

  const filter = (s: TestTrigger) =>
    (!environmnetId || s.environmentId == environmnetId) &&
    (!projectId || s.projectId == projectId) &&
    (!testSuiteId || s.testSuiteId == testSuiteId);

  return (
    <Page name="triggers" title="Test Triggers" icon={Icons.triggers}>
      <div className="page-actions">
        {AppStore.userIsAdmin && (
          <Button color="green" onClick={nav}>
            <Icon name="plus" />
            Add New Trigger
          </Button>
        )}
        <ProjectSelect placeholder="Any Project" value={projectId} onChange={setProjectId} />
        <TestSuiteSelect
          placeholder="Any Test Suite"
          projectId={projectId}
          value={testSuiteId}
          onChange={setTestSuiteId}
        />
        <EnvironmentSelect placeholder="Any Environment" value={environmnetId} onChange={setEnvironmentId} />
      </div>
      <SortableTable
        className="trigger-list"
        loading={!TriggerStore.loaded}
        loadingText="Loading Triggers"
        headerColumns={[
          {
            className: "name",
            content: "Name",
            sortKey: "name",
            sortTransform: (s) => s.name.toLowerCase(),
          },
          {
            className: "project",
            content: (
              <span>
                Project / Test Suite / <br />
                Test Plan
              </span>
            ),
            sortKey: "project.name",
            sortTransform: (s) =>
              s.testSuite?.name?.toLowerCase() || s.testPlan?.name?.toLowerCase() || s.project.name.toLowerCase(),
          },
          {
            className: "environment",
            content: "Pipeline Stage / Environment",
            sortKey: "environment.key",
            sortTransform: (s) => `${s.pipelineStage?.name?.toLocaleLowerCase()}/${s.environment?.name?.toLowerCase()}`,
          },
          { className: "lastRun", content: "Last Runtime", sortKey: "lastRunAt" },
          { className: "version", content: "Version" },
          { className: "tags", content: "Tags/Filters" },
          { className: "trigger", content: "Trigger" },
          {
            className: "enabled",
            content: "Enabled",
            sortKey: "enabled",
            sortTransform: (s) => !s.enabled,
          },
          { className: "actions", content: "" },
        ]}
        items={TriggerStore.active}
        renderRow={(s) => <TriggerRow key={s.id} trigger={s} push={history.push} />}
        filter={filter}
        defaultSortKey="lastRunAt"
        defaultDecending={true}
      />
    </Page>
  );
});

export default TriggersPage;
