import { EntityWithCreatedAt } from "./entity";

export class Insight extends EntityWithCreatedAt {
  content: string;
  title: string;
  type: InsightType;
}

export enum InsightType {
  ERROR = "ERROR", INFO = "INFO"
}

export class InsightResponse {
  unread: Array<Insight>;
  read: Array<Insight>;
}
