import { Authorization, Repo } from "models/settings";
import { action, observable } from "mobx";
import { TestRun } from "models/test-run";
import { TestSuite } from "models/project";
import { TestPlanStage, TestPlanSuite } from "models/test-plan";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import { WithEnvironmentVarsForm } from "models/forms/environment-var-form";
import { Team } from "../models/team";
import { ReleaseCriteriaTemplate } from "../models/release";
import JiraIssueForm from "../models/forms/jira-issue-form";
import { SemanticICONS } from "semantic-ui-react";

export class ModalState<T, R> {
  @observable showing: boolean = false;
  @observable state: T = {} as T;
  onClose: (result?: R) => any;
  onHidden: () => any;
  onShown: () => any;
  beforeShown: () => any;

  @action.bound
  show(onClose?: (result?: R) => any, state?: T) {
    this.state = state;
    if (this.beforeShown) this.beforeShown();
    this.onClose = onClose;
    this.showing = true;
    if (this.onShown) setTimeout(this.onShown, 50);
  }

  @action.bound
  hide(result?: R) {
    this.showing = false;
    if (this.onClose) {
      try {
        this.onClose(result);
      } finally {
        this.onClose = null;
      }
    }
    if (this.onHidden) {
      this.onHidden();
    }
  }
}

interface ConfirmationState {
  message: string | JSX.Element;
  okText?: string;
  okClick?: () => any;
  okColor?: SemanticCOLORS;
  okInverted?: boolean;
  okIcon?: SemanticICONS;
  hideOk?: boolean;
  cancelText?: string;
  cancelColor?: SemanticCOLORS;
  cancelInverted?: boolean;
  hideCancel?: boolean;
  hideButtonIcons?: boolean;
  header?: string | JSX.Element;
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
  helperText?: string;
  helperLinkText?: string;
  helperLinkHref?: string;
  helperStyle?: "hint" | "warning" | "danger";
}

interface PipelineEnvironmentState {
  saveText: string;
  pipelineStageId: number;
  environmentId: number;
}

interface UpdatePasswordState {
  hideCancel?: boolean;
}

export class ModalStoreClass {
  readonly confirmation = new ModalState<ConfirmationState, boolean>();
  readonly upsellModal = new ModalState<number, void>();
  readonly createJiraModal = new ModalState<JiraIssueForm, void>();
  readonly repoSelect = new ModalState<void, Repo>();
  readonly organizationSelect = new ModalState<Authorization, string>();
  readonly newTestRun = new ModalState<void, void>();
  readonly uploadTestRun = new ModalState<void, void>();
  readonly teamInfo = new ModalState<Team, void>();
  readonly updatePassword = new ModalState<UpdatePasswordState, void>();
  readonly runTestSuite = new ModalState<TestSuite, void>();
  readonly commitInfo = new ModalState<TestRun, void>();
  readonly notificationSettings = new ModalState<void, void>();
  readonly testPlanStageEdit = new ModalState<TestPlanStage, void>();
  readonly testPlanSuiteEdit = new ModalState<TestPlanSuite, void>();
  readonly environmentFileNew = new ModalState<WithEnvironmentVarsForm, void>();
  readonly releaseModal = new ModalState<void, void>();
  readonly releaseCriteriaTemplateModal = new ModalState<ReleaseCriteriaTemplate, void>();
  readonly pipelineEnvironment = new ModalState<
    PipelineEnvironmentState,
    PipelineEnvironmentState
  >();
}
