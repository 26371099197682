import Form from "./form";
import { action, observable } from "mobx";
import { notEmpty } from "core/validation";
import { ReleaseDecision } from "../release";

export default class ReleaseForm extends Form {
  @observable id: number;
  @observable title: string;
  @observable description?: string;
  @observable proposedReleaseDate?: string;
  @observable actualReleaseDate?: string;
  @observable decision?: ReleaseDecision;

  emptyToNull = ["description", "proposedReleaseDate", "actualReleaseDate", "decision"]

  validators = { title: notEmpty };

  @action
  setDefaults() {
    this.title = "";
    this.description = "";
    this.proposedReleaseDate = null;
    this.actualReleaseDate = null;
    this.decision = null;
  }
}

export class ReleaseCriteriaTemplateForm extends Form {
  @observable id?: number;
  @observable text: string;

  emptyToNull = ["text"]

  validators = { text: notEmpty };

  @action
  setDefaults() {
    this.text = "";
  }
}
