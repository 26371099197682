import { useState, useEffect } from "react";
import { isNotBlank, cachedLookup, stringComparator } from "core/utils";
import Select from "./select";
import { useOnlyLatestRequest } from "core/react-utils";
import { JsxElement } from "typescript";
import { ProjectStore } from "stores";

interface Props {
  provider: string;
  owner: string;
  repo: string;
  value: string;
  error?: boolean | string;
  label?: string | JsxElement;
  placeholder?: string;
  hideIfEmpty?: boolean;
  onChange: (v: string) => any;
}

const BranchSelect = ({
  provider,
  owner,
  repo,
  onChange,
  value,
  label,
  error,
  placeholder,
  hideIfEmpty,
}: Props) => {
  const [options, setOptions] = useState([] as string[]);
  const [loading, setLoading] = useState(false);

  const loadBranches = useOnlyLatestRequest(
    () => {
      setOptions([]);
      setLoading(true);
      const key = `${provider}/${owner}/${repo}`;
      const lookup = () => ProjectStore.loadBranches(provider, owner, repo);
      return cachedLookup(key, lookup, 15);
    },
    (branches) => {
      setOptions(branches.sort((a, b) => stringComparator(a.name, b.name)).map((b) => b.name));
      setLoading(false);
    },
  );

  useEffect(() => {
    setOptions([]);
    if (isNotBlank(repo)) {
      loadBranches();
    }
  }, [provider, owner, repo]);

  if (hideIfEmpty && options.length == 0) return null;

  return (
    <Select
      placeholder={placeholder}
      isClearable={!!value && !!placeholder}
      className="branch-select"
      isLoading={loading}
      value={value}
      options={options}
      isSearchable={true}
      onChange={onChange}
      label={label}
      error={error}
      isCreatable={true}
      createOptionText="Branch:"
    />
  );
};

export default BranchSelect;
