import { useEffect, useState } from "react";
import Select from "./select";
import { ProjectStore } from "stores";
import Project from "models/project";
import { some, chain } from "lodash";
import { stringComparator } from "../../core/utils";

interface Props {
  project: Project;
  value: string[];
  onChange: (v: string[]) => any;
  error?: boolean | string;
  label?: string;
}

const TestFiltersSelect = ({ project, onChange, value, error, label }: Props) => {
  const [tests, setTests] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null as string);
  project = project || new Project();

  const loadTests = async () => {
    try {
      if (!project.id) return;

      setLoading(true);
      setLoadingError(null);
      setTests([]);
      const projectTests = await ProjectStore.loadProjectTests(project.id);
      setTests(
        chain(projectTests.sort((a, b) => stringComparator(project.fullTestName(a), project.fullTestName(b))))
          .map((pj) => project.fullTestName(pj))
          .uniq()
          .sort()
          .value(),
      );
      setLoading(false);
      setLoadingError(null);
    } catch {
      setLoading(false);
      setLoadingError("Error loading tests");
    }
  };

  useEffect(() => {
    loadTests();
  }, [project.id]);

  const checkChanges = (values: string[]) => {
    const i = values.length - 1;
    const value = values[i];

    if (value.indexOf("Regex: ") != 0 && !some(tests, (s) => s == value)) {
      values[i] = "Regex: " + value;
    }

    return values;
  };

  return (
    <Select
      className="filter-select"
      placeholder={"Select tests or type regex and hit enter..."}
      isLoading={loading}
      isCreatable={true}
      value={value}
      options={tests}
      isMulti={true}
      onChangeMulti={(e) => {
        onChange(e && e.length > 0 ? checkChanges(e) : e);
      }}
      error={error || loadingError}
      label={label}
      createOptionText={"Regex:"}
    />
  );
};

export default TestFiltersSelect;
