import IconButton from "components/icon-button";
import PopupMenu, { PopupMenuItem } from "components/popup-menu";
import { Icons, RUNNING_LOCALLY } from "core/constants";
import { observer } from "mobx-react-lite";
import { RouterChildContext, useHistory } from "react-router";
import { Icon } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { AppStore, ModalStore } from "stores";
import "./left-nav.scss";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

interface NavItemProps {
  icon: SemanticICONS;
  text: string;
  path: string;
  subNav?: SubNavItemProps[];
  requiredTier?: number;
}

export const NavItem = observer(({ icon, text, path, subNav, requiredTier }: NavItemProps) => {
  const selected = AppStore.navPath == path || AppStore.navPath.startsWith(`${path}/`);
  const account = AppStore.account;
  const accountName = account.name;
  const history = useHistory();

  let nav: JSX.Element;
  let subNavItems: JSX.Element[] = null;
  const rootClicked = () => {
    if (requiredTier && !account.isLegacyOrQualifiedTier(requiredTier)) {
      ModalStore.upsellModal.show(null, requiredTier);
      return;
    }

    history.push(`/${accountName}/${path}`);
    window.scrollTo(0, 0);
  };

  const upsellIcon = <Icon name={"arrow circle up"}
                           style={{ fontSize: ".9em", color: "var(--color-orange)", marginBottom: "7px" }} />;

  if (AppStore.isLeftNavCollapsed) {
    let menuItems: PopupMenuItem[] = [{ text: text, onClick: rootClicked }];

    if (subNav && subNav.length > 0) {
      menuItems = [...menuItems, "divider", ...subNav];
    }

    nav = (
      <div className={"top-nav" + (selected ? " highlight" : "")}>
        <PopupMenu direction="right" icon={icon} items={menuItems} />
        {requiredTier && !AppStore.account.isLegacyOrQualifiedTier(requiredTier) && upsellIcon}
      </div>
    );
  } else if (subNav?.length && selected) {
    nav = (
      <div className="top-nav">
        <div><Icon name={icon} className={selected ? "highlight" : ""} /> {text}</div>
        {requiredTier && !AppStore.account.isLegacyOrQualifiedTier(requiredTier) && upsellIcon}
      </div>
    );
    subNavItems = subNav.map((s) =>
      <SubNavItem key={s.path} text={s.text} path={s.path} onClick={s.onClick} icon={s.icon} />);
  } else {
    nav = (
      <div className="top-nav">
        <div><Icon name={icon} className={selected ? "highlight" : ""} /> {text}</div>
        {requiredTier && !AppStore.account.isLegacyOrQualifiedTier(requiredTier) && upsellIcon}
      </div>
    );
  }

  return (
    <div className={"nav-item" + (selected ? " selected" : "")}>
      <div id={`${text.replace(" ", "-")}-tab`} onClick={rootClicked}
           className={AppStore.navPath == path ? "highlight" : ""}>
        {nav}
      </div>
      {subNavItems}
    </div>
  );
});

interface SubNavItemProps {
  text: string;
  path: string;
  onClick: () => any;
  icon: SemanticICONS;
}

const SubNavItem = observer(({ text, path, onClick, icon }: SubNavItemProps) => {
  const selected = AppStore.navPath == path || AppStore.navPath.startsWith(`${path}/`);

  return (
    <div id={`${text.replace(" ", "-").replace("/", "_")}-subtab`} onClick={onClick}
         className={"sub-nav" + (selected ? " highlight" : "")}>
      <Icon name={icon} style={{ fontSize: ".9em" }} /> {text}
    </div>
  );
});

function createSubNav(text: string, path: string, icon: SemanticICONS, history: RouterChildContext["router"]["history"]): SubNavItemProps {
  const accountName = AppStore.selectedAccountName;
  return {
    text,
    path,
    onClick: () => {
      history.push(`/${accountName}/${path}`);
      window.scrollTo(0, 0);
    },
    icon,
  };
}

const LeftNav = observer(() => {
  const history = useHistory();

  const subNav = [
    createSubNav("Users", "settings/users", "user", history),
    createSubNav("Teams", "settings/teams", "users", history),
  ];

  if (AppStore.userIsAdmin) {
    subNav.push(
      createSubNav("Integrations", "settings/integrations", "exchange", history),
      createSubNav("Payment", "settings/payment", "payment", history),
    );
  }
  const dashboardSubNav: Array<SubNavItemProps> = [];
  dashboardSubNav.push(createSubNav("Insights", "dashboard/insights", "lightbulb", history));
  dashboardSubNav.push(createSubNav("Analytics", "dashboard/analytics", "chart bar outline", history));
  dashboardSubNav.push(createSubNav("Release Readiness", "dashboard/release-readiness", "thumbs up", history));

  const environmentSubNav: Array<SubNavItemProps> = [createSubNav("Pipeline Stages", "environments/pipelines", Icons.pipelines, history)];
  if (useFeatureIsOn("runner-pools")) {
    environmentSubNav.push(createSubNav("Runner Pools", "environments/runner-pools", Icons.run, history));
  }

  return (
    <div className="left-nav">
      <div className="nav-menu">
        <NavItem
          icon={Icons.dashboard}
          text="Dashboard"
          path="dashboard"
          subNav={dashboardSubNav}
        />
        <NavItem icon={Icons.project} text="Projects" path="projects" />
        <NavItem icon={Icons.testPlan} text="Test Plans" path="test-plans" requiredTier={3} />
        {RUNNING_LOCALLY &&
          <NavItem icon={Icons.workflow} text="Quality Workflows" path="workflows" requiredTier={3} />}
        <NavItem icon={Icons.testRuns} text="Test Runs" path="test-runs" />
        <NavItem icon={Icons.triggers} text="Test Triggers" path="triggers" requiredTier={2} />
        <NavItem
          icon={Icons.environment}
          text="Environments"
          path="environments"
          subNav={environmentSubNav}
        />
        <NavItem icon={Icons.alert} text="Alerts" path="alerts" requiredTier={2} />
        <NavItem
          icon={Icons.settings}
          text="Settings"
          path="settings"
          subNav={subNav}
        />
      </div>
      <div className="collapse-button">
        <IconButton
          icon={AppStore.isLeftNavCollapsed ? "chevron circle right" : "chevron circle left"}
          onClick={() => AppStore.setLeftNavCollapsed(!AppStore.isLeftNavCollapsed)}
          marginRight={0}
        />
      </div>
    </div>
  );
});

export default LeftNav;
