import DatetimeDisplay from "components/datetime-display";
import IconButton from "components/icon-button";
import SortableTable from "components/sortable-table";
import { rerunWhileComponentLoaded, useInitialEffect } from "core/react-utils";
import { numberWithCommas } from "core/utils";
import { observer } from "mobx-react-lite";
import { TestRunnerAgent } from "models/employee";
import { Link } from "react-router-dom";
import { Button, Popup, Tab } from "semantic-ui-react";
import { EmployeeStore, ModalStore } from "stores";

const AgentsTab = observer(() => {
  useInitialEffect(() => {
    EmployeeStore.loadAgents();
  });

  rerunWhileComponentLoaded(() => EmployeeStore.refreshAgents(), 20);

  const stopAgent = async (agentId: number) => {
    ModalStore.confirmation.show(
      async (okClicked) => {
        if (okClicked) {
          await EmployeeStore.stopAgent(agentId);
        }
      },
      {
        message: "Are you sure you stop this agent?",
        okColor: "red",
        okText: "Stop Agent",
      },
    );
  };

  const replaceAgent = async (agent: TestRunnerAgent) => {
    ModalStore.confirmation.show(
      async (okClicked) => {
        if (okClicked) {
          await EmployeeStore.replaceAgent(agent);
        }
      },
      {
        message: "Are you sure you replace this agent?",
        okColor: "red",
        okText: "Replace Agent",
      },
    );
  };

  return (
    <Tab.Pane className="agents-tab">
      <Button color="green" onClick={() => EmployeeStore.loadAgents()}>
        Refresh Data
      </Button>
      <br />
      <SortableTable
        title="Agents"
        loadingText="Loading Agents"
        loading={EmployeeStore.loadingAgents}
        emptyMessage="No Agents Exist"
        headerColumns={[
          { content: "ID", sortKey: "id" },
          { content: "Created At", sortKey: "createdAt" },
          { content: "Version", sortKey: "version" },
          { content: "AMI ID", sortKey: "windowsAwsAmiId" },
          { content: "Server ID", sortKey: "serverId" },
          { content: "Cluster", sortKey: "cluster" },
          { content: "OS", sortKey: "os" },
          { content: "Status", sortKey: "status" },
          { content: "Capacity", sortKey: "capacity" },
          { content: "Current Runners", sortKey: "currentCount" },
          { content: "Replaced By", sortKey: "replacedById" },
          { content: "Last Run Completed", sortKey: "lastTestRunCompletedAt" },
          { content: "Last Heard From", sortKey: "updatedAt" },
          { content: "" },
        ]}
        items={EmployeeStore.agents}
        defaultSortKey="id"
        renderRow={(agent) => (
          <tr key={agent.id}>
            <td>
              <Link to={`/employee/agents/${agent.id}/logs`}>{agent.id}</Link>
            </td>
            <td>
              <DatetimeDisplay datetime={agent.createdAt} />
            </td>
            <td>{agent.shortVersion}</td>
            <td>{agent.windowsAwsAmiId}</td>
            <td>
              {agent.data ? (
                <Popup
                  position="right center"
                  wide={true}
                  trigger={<span>{agent.serverId}</span>}
                  content={
                    <div>
                      <div className="item">
                        <strong>Disk Space:</strong>{" "}
                        {numberWithCommas(Math.round(agent.data.availableDiskSpace / 1000000))} MB
                      </div>
                      <div className="item">
                        <strong>Runners Awaiting Cleanup:</strong> {agent.data.waitingForCleanUp}
                      </div>
                      {agent.data.currentRunners.length > 0 && (
                        <div className="item">
                          <strong>Runners:</strong>
                          {agent.data.currentRunners.map((id) => (
                            <div key={id}>{id}</div>
                          ))}
                        </div>
                      )}
                      {agent.data.processes.length > 0 && (
                        <div className="item">
                          <strong>Processes:</strong>
                          {agent.data.processes.map((id) => (
                            <div key={id}>{id}</div>
                          ))}
                        </div>
                      )}
                    </div>
                  }
                />
              ) : (
                agent.serverId
              )}
            </td>
            <td>{agent.cluster}</td>
            <td>{agent.os}</td>
            <td>{agent.status}</td>
            <td>{agent.capacity}</td>
            <td>{agent.nonExitedRunners.length}</td>
            <td>{agent.replacedById}</td>
            <td>
              <DatetimeDisplay datetime={agent.lastTestRunCompletedAt} showSecond={true} />
            </td>
            <td>
              <DatetimeDisplay datetime={agent.updatedAt} showSecond={true} />
            </td>
            <td>
              <IconButton
                label="Stop Agent"
                icon="stop circle outline"
                onClick={() => stopAgent(agent.id)}
              />
              {!agent.replacedById && (
                <IconButton
                  label="Replace Agent"
                  icon="repeat"
                  onClick={() => replaceAgent(agent)}
                />
              )}
            </td>
          </tr>
        )}
      />
    </Tab.Pane>
  );
});

export default AgentsTab;
