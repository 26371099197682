import { observer } from "mobx-react-lite";
import { useInitialEffect } from "core/react-utils";
import { AppStore } from "stores";
import { useState } from "react";
import { AccountCounts } from "models/employee";
import { Card } from "semantic-ui-react";
import { numberWithCommas } from "core/utils";
import { round } from "lodash";
import { useFeatureIsOn } from "@growthbook/growthbook-react";



const UsageTab = observer(() => {
  const [thisMonthCounts, setThisMonthCounts] = useState(new AccountCounts());
  const [lastMonthCounts, setLastMonthCounts] = useState(new AccountCounts());

  useInitialEffect(async () => {
    await AppStore.loadAccountCounts()
    setThisMonthCounts(AppStore.getThisMonthAccountCounts());
    setLastMonthCounts(AppStore.getLastMonthAccountCounts());

  });

  return (
    <>
            <Card.Group centered>
          <Card>
            <Card.Content>
              <div className="name">Testing Hours Month to Date</div>  
              {useFeatureIsOn("tem-quota-soft-limit") && (
                <div className="value">{numberWithCommas(round((thisMonthCounts.totalTestingMinutes / 60), 2))} / {AppStore.account.monthlyTestExecutionHoursLimit()}</div>
              )}
              {!useFeatureIsOn("tem-quota-soft-limit") && (
                <div className="value">{numberWithCommas(round((thisMonthCounts.totalTestingMinutes / 60), 2))}</div>
              )}
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <div className="name">Testing Hours Previous Month</div>
              {useFeatureIsOn("tem-quota-soft-limit") && (
                <div className="value">{numberWithCommas(round((lastMonthCounts.totalTestingMinutes / 60), 2))} / {AppStore.account.monthlyTestExecutionHoursLimit()}</div>
              )}
              {!useFeatureIsOn("tem-quota-soft-limit") && (
                <div className="value">{numberWithCommas(round((lastMonthCounts.totalTestingMinutes / 60), 2))}</div>
              )}
            </Card.Content>
          </Card>
          </Card.Group>
          
    </>
  );
});

export default UsageTab;

