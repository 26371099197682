import { User } from "./user";

export class Team {
  id: number;
  accountId: number;
  name: string;
  description?: string;
  email?: string;
  slackChannel?: string;
  users: Array<TeamUser>;
}

export class TeamUser {
  id: number;
  user: User;
  userId: number;
  teamId: number;
}
