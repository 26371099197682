import { useRef, useState } from "react";
import { Button, Form, Icon, Message } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { loadStripe, useInitialEffect } from "core/react-utils";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import PlanSelect from "components/form/plan-select";
import PlanForm from "models/forms/plan-form";
import { AppStore } from "stores";
import { capitalize } from "core/utils";
import "./payment-info.scss";
import * as Storage from "../../core/storage";
import { getSelectedPlanTier } from "../../core/storage";
import { Account } from "../../models/account";
import { PromoCode } from "../../models/promo-code";

interface Props {
  forSetup?: boolean;
  windowsOnly?: boolean;
  linuxOnly?: boolean;
  onSave?: () => any;
  onCancel?: () => any;
}

const planForm = new PlanForm();

const PaymentInfo = observer(({ onSave, forSetup, windowsOnly, linuxOnly }: Props) => {
    const [processing, setProcessing] = useState(false);
    const [changePayment, setChangePayment] = useState(false);
    const [newPlanTierSelection, setNewPlanTierSelection] = useState<number>(null);
    const [promoCodeSubmitting, setPromoCodeSubmitting] = useState<boolean>(false);
    const [invalidPromoCode, setInvalidPromoCode] = useState<boolean>(false);
    const [appliedPromoCode, setAppliedPromoCode] = useState<PromoCode>(null);
    const elements = useElements();
    const stripe = useStripe();
    const promoCodeRef = useRef<HTMLInputElement>();

    useInitialEffect(async () => {
      const signupPromoCode = Storage.getPromoCode();
      if (signupPromoCode) {
        promoCodeRef.current.value = signupPromoCode;
        await validatePromoCode();
      }
    });

    const getCardInfo = async (): Promise<boolean> => {
      planForm.clearCreditCardInfo();

      if (appliedPromoCode)
        planForm.updateField("promoCode", appliedPromoCode.customerFacingCode);

      if (appliedPromoCode && !appliedPromoCode.requiresCreditCard && appliedPromoCode.applicablePlanTiers.includes(planForm.linuxPlanTier))
        return true;

      if (!stripe || !elements) {
        planForm.addError("cardToken", "Could not communicate with credit card processor");
        return false;
      }

      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        planForm.addError("cardToken", error.message);
        return false;
      } else {
        planForm.updateField("cardToken", paymentMethod.id);
        planForm.updateField("cardBrand", paymentMethod.card.brand);
        planForm.updateField("cardExpireMonth", paymentMethod.card.exp_month);
        planForm.updateField("cardExpireYear", paymentMethod.card.exp_year);
        planForm.updateField("cardLastFour", paymentMethod.card.last4);
        return true;
      }
    };

    const setPlanTierSelection = (value: number) => {
      planForm.updateField("linuxPlanTier", value);
      setNewPlanTierSelection(value);
    };

    const account = AppStore.account;

    const save = async () => {
      try {
        planForm.clearErrors("linuxPlanTier");
        setProcessing(true);

        let response: Account;
        if (account.setupComplete && (!planForm.isPayingAccount || (account.cardLastFour && !changePayment))) {
          planForm.clearCreditCardInfo();
          response = await AppStore.updatePaymentInfo(planForm);
          setChangePayment(false);
          onSave && onSave();
        } else if (await getCardInfo()) {
          response = await AppStore.updatePaymentInfo(planForm);
          setChangePayment(false);
          onSave && onSave();
        }

        if (!response.id)
          planForm.addError("cardToken", "Invalid Credit Card");
      } finally {
        setProcessing(false);
      }
    };

    useInitialEffect(async () => {
      planForm.populate({
        linuxPlanTier: account?.currentPlan?.tier || getSelectedPlanTier(),
        windowsRunners: account.windowsRunners,
        monthly: account.monthlyBilling,
      });
      if (windowsOnly) {
        planForm.updateField("linuxPlanTier", null);
      }
    });

    async function validatePromoCode() {
      setPromoCodeSubmitting(true);
      const promoCode = promoCodeRef.current.value;
      const promoCodeResponse = await AppStore.checkPromoCode(promoCode);
      if (promoCodeResponse) {
        setInvalidPromoCode(false);
        setAppliedPromoCode(promoCodeResponse);
        promoCodeRef.current.value = promoCode.toUpperCase();
      } else {
        setInvalidPromoCode(true);
      }
      setPromoCodeSubmitting(false);
    }

    return (
      <Form>
        <PlanSelect
          form={planForm}
          account={account}
          windowsOnly={windowsOnly}
          linuxOnly={linuxOnly}
          newPlanTierSelection={newPlanTierSelection}
          setNewPlanTierSelection={setPlanTierSelection}
          promoCode={appliedPromoCode}
          setAppliedPromoCode={setAppliedPromoCode}
          planForm={planForm}
          changePayment={changePayment}
          validatePromoCode={validatePromoCode}
          promoCodeError={invalidPromoCode}
          promoCodeRef={promoCodeRef}
          promoCodeSubmitting={promoCodeSubmitting}
          forSetup={forSetup}
        />
        {account.currentPlan?.monthlyStripeSubscriptionId && planForm.isPayingAccount && account.cardLastFour && !changePayment && (
          <Message color="green">
            <h3>
              Payment Info: {capitalize(account.cardBrand)} *{account.cardLastFour}
            </h3>
            <Button size="small" color="grey" onClick={() => setChangePayment(true)}>
              <Icon name="edit" />
              Change Payment Info
            </Button>
          </Message>
        )}
        {forSetup ? (
          <div className="buttons">
            {/*{onCancel && (*/}
            {/*  <Button color="green" onClick={onCancel} disabled={processing}>*/}
            {/*    <Icon name={"chevron left"} />*/}
            {/*    Back*/}
            {/*  </Button>*/}
            {/*)}*/}
            <Button
              className={"submit-payment-button"}
              loading={processing}
              color="green"
              onClick={async () => {
                await planForm.validate();
                await save();
              }}
              disabled={!stripe || !elements}
            >
              Let's Get Started!
            </Button>
          </div>
        ) : (
          <div className="buttons" style={{ maxWidth: "800px" }}>
            {account.currentPlan.monthlyStripeSubscriptionId &&
              <Button loading={processing} color="green" onClick={save} disabled={!stripe || !elements}>
                Update
              </Button>
            }
          </div>
        )}
      </Form>
    );
  })
;

export const PlanInfo = ({ onSave, onCancel, forSetup, windowsOnly, linuxOnly }: Props) => {
  const [stripe, setStripe] = useState(null);

  useInitialEffect(() => {
    setStripe(loadStripe());
  });

  return (
    <div className="payment-info">
      <Elements stripe={stripe}>
        <PaymentInfo
          onSave={onSave}
          onCancel={onCancel}
          forSetup={forSetup}
          windowsOnly={windowsOnly}
          linuxOnly={linuxOnly}
        />
      </Elements>
    </div>
  );
};
