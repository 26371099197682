import Page from "pages/page";
import { observer } from "mobx-react-lite";
import { AppStore, ModalStore } from "../../stores";
import { Button, Icon, Tab, TabProps } from "semantic-ui-react";
import ReleasesTab from "./releases-tab";
import ReleaseCriteriaTemplateTab from "./release-criteria-template-tab";
import { findIndex } from "lodash";
import { useHistory, useParams } from "react-router";
import { useInitialEffect } from "../../core/react-utils";
import { useState } from "react";

const ReleaseReadinessPage = observer(() => {
  const { tab } = useParams<{ tab: string }>();
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  useInitialEffect(() => {
    if (!tab) {
      history.replace("release-readiness/releases");
    }
  });

  const tabPanes = [
    {
      menuItem: { key: "releases", icon: "clipboard", content: "Releases" },
      render: () => <ReleasesTab activeIndex={activeIndex} setActiveIndex={setActiveIndex} />,
    },
    {
      menuItem: { key: "release-criteria-template", icon: "list", content: "Criteria Templates" },
      render: () => <ReleaseCriteriaTemplateTab />,
    },
  ];

  const tabIndex = findIndex(tabPanes, (i) => i.menuItem.key == tab);

  const navigate = (data: TabProps) => {
    setActiveIndex(-1);
    const name = tabPanes[data.activeIndex].menuItem.key;
    history.push(name);
  };

  return (
    <Page name="release-readiness" title="Releases">
      <div className="page-actions">
        {AppStore.userIsAdmin && (
          <Button color="green" onClick={() => {
            const modal = ModalStore.releaseModal;
            const onClose = async () => {
              setActiveIndex(-1);
            };
            modal.show(onClose);
          }}>
            <Icon name="plus" />
            Add New Release
          </Button>
        )}
      </div>
      <Tab
        onTabChange={(_, data) => navigate(data)}
        activeIndex={tabIndex}
        menu={{ secondary: true, pointing: true }}
        panes={tabPanes}
      />
    </Page>
  );
});

export default ReleaseReadinessPage;
