import { setPageInfo } from "core/react-utils";
import { observer } from "mobx-react-lite";
import { Tab, Button, Icon, Grid } from "semantic-ui-react";
import { TestResult } from "../../models/test-run";
import { useState } from "react";
import { CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { AppStore, TestRunStore } from "stores";
import { runInAction } from "mobx";
import Spinner from "components/spinner";
import { useInitialEffect } from "core/react-utils";


interface Props {
  testResult: TestResult;
}

const ConsoleTab = observer(({ testResult }: Props) => {
  setPageInfo("testResult", "Troubleshooting");

  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  useInitialEffect(async () => {
    refreshAnalysis(testResult.testRunId, testResult.testRunTestId)
  })

  async function getSuggestion() {
    setLoading(true)
    await TestRunStore.createTestRunTestAnalysis(testResult.testRunId, testResult.testRunTestId)
    runInAction(() => {
      refreshAnalysis(testResult.testRunId, testResult.testRunTestId)
    })
  }

  async function refreshAnalysis(testRunId: number, testRunTestId: number) {
    const result = await TestRunStore.getSuggestion(testRunId, testRunTestId)
    if (result.analysisText != null && result.analysisText != "") {
      runInAction(() => {
        setText(result.formattedAnalysisText)
        setLoading(false)
      })
    } else { 
      if (result.id) {
        let pollDelay = 5 * 1000; // 5s
        setTimeout(() => refreshAnalysis(testRunId, testRunTestId), pollDelay);
      }
    }
  }
  
  return (
    <Tab.Pane>
      <div className="troubleshooting">
        {testResult.error && testResult.error.length > 0 && (
          <>
            <Grid celled>
              <Grid.Row>
                <Grid.Column width={3} only='computer'>
                  <CardMedia
                    sx={{ height: 150, width: 160, margin: "auto" }}
                    image="/img/tai-bot.png"
                    title="Tai"
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={13} className="analysisColumn" style={{textAlign:"left"}}>
                  {text && text.length > 0 && (
                    <CardContent>
                      <p style={{textAlign: "left", whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: text}}></p>
                    </CardContent>
                  )}
                  {text.length == 0 && !AppStore.account.canGetAiSuggestions()  && (
                    <>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        You've used all your suggestions for the month
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <a href="https://testery.com/contact" target="_blank">Contact Testery sales</a> to discuss adding more to your plan.
                      </Typography>
                    </CardContent>
                  </>
                  )}
                  {text.length == 0 && AppStore.account.canGetAiSuggestions() && (
                  <>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Let Testery Help You
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Need a little help? Tai can suggest ways to fix this issue.
                    </Typography>
                  </CardContent>
                  <CardActions>
                  <Button style={{margin: 'auto'}} onClick={getSuggestion} >
                    {loading && (
                      <><Spinner size={16} text="Analyzing Your Test Run" /></>
                    )}
                    {!loading && (
                      <><Icon name="chat" />Suggest A Fix</>
                    )}
                  </Button>
                </CardActions>
                </>
                )}
              </Grid.Column>
            </Grid.Row>
           </Grid>   
          </>
        )}
      </div>
    </Tab.Pane>
  );
});

export default ConsoleTab;
