import { Icon, Popup } from "semantic-ui-react";

export function VariablesInfoIcon() {
  return (
    <Popup
      hoverable={true}
      content={
        <span>
          A variable at the environment level will override a variable with the same name at the
          pipeline stage level
        </span>
      }
      trigger={<Icon name="question circle" />}
    />
  );
}
