import TestAdvancedOptions from "components/form/test-advanced-options";
import TestFilters from "components/form/test-filters";
import TestSuiteInfo from "components/form/test-suite-info";
import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import TestSuiteForm from "models/forms/test-suite-form";
import Project from "models/project";
import Page from "pages/page";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button, Form, Icon } from "semantic-ui-react";
import { TestSuiteStore, ProjectStore } from "stores";
import "./test-suite-edit.scss";

const form = new TestSuiteForm();

async function loadTestSuite(
  project: Project,
  testSuiteId: string,
  setTestSuiteLoaded: (v: boolean) => any,
) {
  await TestSuiteStore.ensureLoaded();
  form.clearFormData();

  if (testSuiteId) {
    const suite = TestSuiteStore.find(parseInt(testSuiteId));
    form.populateFromProject(project);
    form.populate({ defaultToParallelizeByFile: suite.parallelizeByFile, ...suite });
  } else {
    form.populateFromProject(project);
  }

  setTestSuiteLoaded(true);
}

async function saveTestSuite(projectId: number, push: (path: string) => any) {
  await TestSuiteStore.save(projectId, form);
  push("../test-suites");
}

const TestSuiteEditPage = observer(() => {
  const { testSuiteId, projectKey } = useParams<{ testSuiteId: string; projectKey: string }>();
  const [testSuiteLoaded, setTestSuiteLoaded] = useState(false);
  const history = useHistory();

  const isNew = !testSuiteId;
  const title = (isNew ? "New" : "Edit") + " Test Suite";
  const project = ProjectStore.findByKey(projectKey);

  useInitialEffect(async () => {
    await ProjectStore.ensureLoaded();
    loadTestSuite(ProjectStore.findByKey(projectKey), testSuiteId, setTestSuiteLoaded);
  });

  return (
    <Page title={title} name="test-suite-edit">
      {project && (
        <Form>
          <TestSuiteInfo form={form} />
          <TestFilters project={project} form={form} />
          <TestAdvancedOptions
            project={project}
            form={form}
            useAdvancedToggle={false}
            hidePriority={true}
          />
          <section className="form-actions">
            <Button type="button" onClick={() => history.goBack()}>
              <Icon name="times" />
              Cancel
            </Button>
            <Button
              onClick={() => saveTestSuite(project.id, history.push)}
              loading={!testSuiteLoaded || form.isSubmitting}
              color="green"
              type="submit"
            >
              <Icon name="check" />
              Save
            </Button>
          </section>
        </Form>
      )}
    </Page>
  );
});

export default TestSuiteEditPage;
