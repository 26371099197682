import { millisToMinutesAndSeconds } from "core/date-utils";

interface Props {
  duration: number;
}

const DurationDisplay = ({ duration }: Props) => {
  if (!duration || duration < 0) return null;

  return <span>{millisToMinutesAndSeconds(duration)}</span>;
};

export default DurationDisplay;
