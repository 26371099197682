import { observer } from "mobx-react-lite";
import { Button, Card, Form, Icon } from "semantic-ui-react";
import { AppStore, ModalStore } from "stores";
import UserInfoForm from "models/forms/user-info-form";
import { useInitialEffect } from "core/react-utils";
import NotificationSettings, { saveNotificationSettings } from "components/notification-settings";
import Page from "pages/page";
import "./profile.scss";

const nameForm = new UserInfoForm();

const ProfilePage = observer(() => {
  useInitialEffect(() => {
    nameForm.populate(AppStore.user);
  });

  const saveName = () => {
    AppStore.updateUserInfo(nameForm);
  };

  return (
    <Page title="Profile" name="profile">
      <div>
        <h3>
          <Icon name="envelope" /> Email: {AppStore.user.email}
        </h3>
        <Button color="green" inverted onClick={() => ModalStore.updatePassword.show()}>
          <Icon name="key" /> Update Password
        </Button>
        <br /> <br />
        <Card className="name">
          <Card.Content>
            <Card.Header>Name</Card.Header>
          </Card.Content>
          <Card.Content>
            <Form>
              <Form.Input
                label="First Name"
                value={nameForm.firstName}
                error={nameForm.getErrorText("firstName")}
                onChange={nameForm.onChange("firstName")}
              />
              <Form.Input
                label="Last Name"
                value={nameForm.lastName}
                error={nameForm.getErrorText("lastName")}
                onChange={nameForm.onChange("lastName")}
              />
              <Button color="green" onClick={saveName} loading={nameForm.isSubmitting}>
                <Icon name="check" /> Update Name
              </Button>
            </Form>
          </Card.Content>
        </Card>
        <Card>
          <Card.Content>
            <Card.Header>Notification Settings</Card.Header>
          </Card.Content>
          <Card.Content>
            <NotificationSettings />
            <Button color="green" onClick={saveNotificationSettings}>
              <Icon name="check" /> Update Settings
            </Button>
          </Card.Content>
        </Card>
      </div>
    </Page>
  );
});

export default ProfilePage;
