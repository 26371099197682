import { useState } from "react";
import { useInitialEffect } from "core/react-utils";
import { AppStore } from "stores";
import { Segment } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import Page from "pages/page";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import Spinner from "components/spinner";

const VerifyEmailPage = observer(() => {
  const [badToken, setBadToken] = useState(false);
  const history = useHistory();

  useInitialEffect(() => {
    AppStore.doUserEmailValidation().then(() => {
      if (AppStore.isLoggedIn) {
        history.replace("/");
      } else {
        setBadToken(true);
      }
    });
  });

  return (
    <Page name="login" title="Login" noHeader titleSize="none">
      {!badToken && <Spinner size={20} text="Verifying" />}
      {badToken && (
        <Segment placeholder style={{ width: "500px" }}>
          <h2>Your token is no longer valid!</h2>
          <br />
          <Button color="green" onClick={() => history.replace("/create-account")}>
            Create New Account
          </Button>
        </Segment>
      )}
    </Page>
  );
});

export default VerifyEmailPage;
