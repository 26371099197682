import { ReactNode } from "react";
import { JsxElement } from "typescript";
import Select from "./select";

interface Props {
  value: String;
  onChange: (value: String) => any;
  error?: boolean | string;
  label?: string | JsxElement | ReactNode;
  inline?: boolean;
}

const RunnerTypeSelect = ({
  onChange,
  value,
  error,
  label,
  inline,
}: Props) => {
  const yesValue = "WINDOWS";
  const noValue = "LINUX";

  return (
    <Select
      className="yes-no-select"
      onChange={onChange}
      value={value}
      options={[yesValue, noValue]}
      error={error}
      label={label}
      inline={inline}
    />
  );
};

export default RunnerTypeSelect;
