import { calculateDuration, parseServerDateTime } from "core/date-utils";
import { type } from "core/serialization";
import { isJSON, relativeLink } from "core/utils";
import { startOfDay, startOfHour, startOfMonth, startOfWeek } from "date-fns";
import { chain, sortBy, sumBy } from "lodash";
import { computed, observable } from "mobx";
import { SemanticCOLORS } from "semantic-ui-react";
import { ProjectStore, SettingsStore, TestRunStore, TestSuiteStore } from "stores";
import { Build, Deploy, ProjectTest } from "./project";
import { ReviewerAssignmentEntry, ReviewStatusChangeEntry } from "./review-data";
import { Environment, RunnerConfiguration } from "./settings";
import { Entity } from "./entity";

export enum TestRunStatus {
  QUEUED = "QUEUED",
  SUBMITTED = "SUBMITTED",
  SETUP = "SETUP",
  RUNNING = "RUNNING",
  TEAR_DOWN = "TEAR_DOWN",
  PASS = "PASS",
  FAIL = "FAIL",
  CANCELED = "CANCELED",
}

export const completedTestRunStatuses = [TestRunStatus.CANCELED, TestRunStatus.PASS, TestRunStatus.FAIL];

export const incompleteTestRunStatuses = [
  TestRunStatus.SUBMITTED,
  TestRunStatus.RUNNING,
  TestRunStatus.QUEUED,
  TestRunStatus.SETUP,
  TestRunStatus.TEAR_DOWN,
];

export const resultsTestRunsStatuses = [TestRunStatus.PASS, TestRunStatus.FAIL];

export type BooleanMap = { [index: number]: boolean };
export type FiltersMap = { [key: number]: TestStatus[] };
export type StringMap = { [key: number]: string };

export function isTestRunEnded(testRun: TestRun) {
  return completedTestRunStatuses.includes(testRun.status);
}

export class Message {
  id: number;
  createdAt: string;
  accountId: number;
  userId: number;
  testRunId: number;
  text: string;
}

export class TestRun {
  id: number;
  triggeredTestRunId: number;
  deployId: number;
  createdAt: string;
  updatedAt: string;
  copies: number;
  @observable
  status: TestRunStatus;
  startTime: string;
  endTime: string;
  @type(Build)
  build: Build;
  @type(Deploy)
  deploy: Deploy;
  projectId: number;
  environmentId: number;
  runnerConfigurationId: number;
  testSuiteId: number;
  testPlanSuiteId: number;
  testPlanRunId: number;
  branch: string;
  gitRef: string;
  statusInfo: string;
  totalCount: number;
  passCount: number;
  failCount: number;
  ignoredCount: number;
  totalTestingTime: number;
  maxRunners: number;
  includeTags: string[];
  excludedTags: string[];
  testFilters: string[];
  testTimeoutSeconds: number;
  timeoutMinutes: number;
  defaultToParallelizeByFile: boolean;
  retryFailedTests: boolean;
  reviewerUserId?: number;
  reviewerAssignmentEntries: Array<ReviewerAssignmentEntry> = [];
  reviewerStatusChangeEntries: Array<ReviewStatusChangeEntry> = [];
  recordVideo?: boolean;
  testRunners: Array<TestRunnerData> = [];
  playwrightProject?: string;
  ignoreQuarantine: boolean;
  quarantineTotalCount: number;
  quarantinePassCount: number;
  quarantineFailCount: number;
  quarantineIgnoredCount: number;
  quarantineTotalTestingTime: number;

  constructor(data?: Partial<TestRun>) {
    if (data) Object.assign(this, data);
  }

  get environment(): Environment | null {
    return SettingsStore.findEnvironment(this.environmentId);
  }

  get environmentName(): string | null {
    return this.environment?.name;
  }

  get runnerConfiguration(): RunnerConfiguration | null {
    return SettingsStore.findRunnerConfiguration(this.runnerConfigurationId);
  }

  get runnerConfigurationName(): string | null {
    return this.runnerConfiguration?.key;
  }

  @computed
  get testSuite() {
    return TestSuiteStore.find(this.testSuiteId);
  }

  get testSuiteName() {
    return this.testSuite?.name;
  }

  @computed
  get startTimeAsDate() {
    return parseServerDateTime(this.startTime);
  }

  @computed
  get createdAtAsDate() {
    return parseServerDateTime(this.createdAt);
  }

  @computed
  get startDay() {
    return startOfDay(this.startTimeAsDate);
  }

  @computed
  get startWeek() {
    return startOfWeek(this.startTimeAsDate);
  }

  @computed
  get startHour() {
    return startOfHour(this.startTimeAsDate);
  }

  @computed
  get startMonth() {
    return startOfMonth(this.startTimeAsDate);
  }

  @computed
  get project() {
    return ProjectStore.find(this.projectId);
  }

  @computed
  get notCompleted() {
    return incompleteTestRunStatuses.includes(this.status);
  }

  @computed
  get hasResults() {
    return resultsTestRunsStatuses.includes(this.status);
  }

  get currentReviewerAssignmentEntry(): ReviewerAssignmentEntry | null {
    if (this.reviewerAssignmentEntries.length === 0) return null;

    return this.reviewerAssignmentEntries[0];
  }

  get currentReviewerStatusChangeEntry(): ReviewStatusChangeEntry | null {
    if (this.reviewerStatusChangeEntries.length === 0) return null;

    return this.reviewerStatusChangeEntries[0];
  }

  get nonExitedRunners(): Array<TestRunnerData> {
    if (!this.testRunners || this.testRunners.length === 0)
      return [];

    return this.testRunners.filter(testRunner => testRunner.status != TestRunnerStatus.Exited);
  }

  @computed
  get statusDisplay() {
    return this.status.toString().replace("_", " ");
  }
}

export class TestRunHistory {
  id: number;
  userName: string;
  userEmail: string;
  timeStamp: string;
  updatedColumn: string;
  updatedValue: string;

  constructor(data?: Partial<TestRunHistory>) {
    if (data) Object.assign(this, data);
  }
}

export class TestRunLog {
  runnerSetupLog: string;
  testListGenerationLog: string;
  ecsTaskArn: string;

  constructor(data?: Partial<TestRunLog>) {
    if (data) Object.assign(this, data);
  }
}

export class TestRunnerData {
  testRunId: number;
  accountId: number;
  testsRun: number;
  status: TestRunnerStatus;
  currentTest?: string;
  currentTestStartTime?: string;
  lastStatusChangeTime?: string;

  constructor(data?: Partial<TestRunnerData>) {
    if (data) Object.assign(this, data);
  }
}

export class TestCounts {
  totalCount: number;
  passCount: number;
  failCount: number;
  ignoredCount: number;
  pendingCount: number;
  runningCount: number;
  notRunCount: number;
}

export function getCounts(testRun: TestRun): Promise<TestCounts> {
  if (!testRun) return new Promise(resolve => resolve({
    totalCount: 0,
    passCount: 0,
    failCount: 0,
    ignoredCount: 0,
    pendingCount: 0,
    runningCount: 0,
    notRunCount: 0,
  }));

  const testRunTests = TestRunStore.testRunTests[testRun.id];
  if (testRun.notCompleted || !testRunTests || testRunTests.find(test => notCompleteTestStatuses.includes(test.status))) {
    return TestRunStore.loadTestRunTests(testRun.id, false, true).then(() => {
      return getTestCounts(testRun);
    });
  } else {
    return new Promise(resolve => resolve(getTestCounts(testRun)));
  }
}

function getTestCounts(testRun: TestRun): TestCounts {
  const { totalCount, passCount, failCount, ignoredCount } = testRun;
  const runningCount = TestRunStore.testRunTests[testRun.id].filter(test => test.phase == TestPhase.TEST && test.status == TestStatus.RUNNING).length;
  const notRunCount = TestRunStore.testRunTests[testRun.id].filter(test => test.phase == TestPhase.TEST && test.status == TestStatus.NOT_RUN).length;
  const pendingCount = totalCount - failCount - passCount - ignoredCount - runningCount - notRunCount;

  return {
    totalCount: Math.max(totalCount - ignoredCount, 0),
    passCount: Math.max(passCount, 0),
    failCount: Math.max(failCount, 0),
    ignoredCount: Math.max(ignoredCount, 0),
    pendingCount: Math.max(0, pendingCount),
    runningCount: Math.max(0, runningCount),
    notRunCount: Math.max(0, notRunCount),
  };
}

export enum TestStatus {
  PASS = "PASS",
  FAIL = "FAIL",
  PENDING = "PENDING",
  IGNORED = "IGNORED",
  RUNNING = "RUNNING",
  NOT_RUN = "NOT_RUN",
  FLAKY = "FLAKY",
}

export const notCompleteTestStatuses = [
  TestStatus.PENDING,
  TestStatus.RUNNING
]

export enum TestPhase {
  SETUP = "SETUP",
  TEST = "TEST",
  TEAR_DOWN = "TEAR_DOWN",
}

export const StatusColors = {
  PASS: "#21ba45" as SemanticCOLORS,
  FAIL: "#df3f3f" as SemanticCOLORS,
  PENDING: "#767676" as SemanticCOLORS,
  IGNORED: "#eadc14" as SemanticCOLORS,
  RUNNING: "#0064ff" as SemanticCOLORS,
};

export class TestRunTest {
  id: number;
  parentId: number;
  groupParentId: number;
  testRunId: number;
  testRunnerId: number;
  runnerUuid: string;
  @type(ProjectTest)
  projectTest: ProjectTest;
  status: TestStatus;
  phase: TestPhase;
  createdAt: string;
  updatedAt: string;
  startTime: string;
  endTime: string;
  duration: number;
  flaky = false;
  @observable
  selected = false;
  reviewerUserId?: number;
  reviewStatusId?: number;
  reviewerAssignmentEntries: Array<ReviewerAssignmentEntry> = [];
  reviewerStatusChangeEntries: Array<ReviewStatusChangeEntry> = [];
  testRunAnalysisRootCauseId: number;
  testRunTestTrackedIssues: Array<TestRunTestTrackedIssue> = [];
  quarantined: boolean;

  constructor(data?: Partial<TestRunTest>) {
    if (data) {
      Object.assign(this, data);
    }
  }

  @computed
  get updatedAtAsDate() {
    return parseServerDateTime(this.updatedAt);
  }

  @computed
  get calculatedDuration() {
    return calculateDuration(this.startTime, this.endTime);
  }

  @computed
  get statusDisplay() {
    return this.status.toString().replace("_", " ");
  }

  @computed
  get link() {
    return relativeLink(`${this.testRunId}/tests/${this.id}`);
  }

  @computed
  get groupParentLink() {
    return relativeLink(`${this.testRunId}/tests/${this.groupParentId}`);
  }

  @computed
  get notCompleted() {
    return this.status == TestStatus.PENDING || this.status == TestStatus.RUNNING;
  }

  @computed
  get hasResults() {
    return this.status == TestStatus.FAIL || this.status == TestStatus.PASS || this.status == TestStatus.RUNNING || this.status == TestStatus.NOT_RUN;
  }

  get currentReviewerAssignmentEntry(): ReviewerAssignmentEntry | null {
    if (this.reviewerAssignmentEntries.length === 0) return null;

    return this.reviewerAssignmentEntries[0];
  }

  get currentReviewerStatusChangeEntry(): ReviewStatusChangeEntry | null {
    if (this.reviewerStatusChangeEntries.length === 0) return null;

    return this.reviewerStatusChangeEntries[0];
  }

  get isTestPhase() {
    return this.phase != TestPhase.SETUP && this.phase != TestPhase.TEAR_DOWN;
  }
}

export class TestRunTestTrackedIssue extends Entity {
  testRunTestId: number;
  testRunAnalysisRootCauseId?: number;
  authorizationId: number;
  issueId: string;
  issueKey: string;

  get keyForAnalysisTab(): string {
    return `${this.testRunAnalysisRootCauseId}-${this.issueKey}`;
  }
}

export class Counts {
  ignored = 0;
  pass = 0;
  fail = 0;
  flaky = 0;
  pending = 0;
  running = 0;
  notRun = 0;

  quarantineIgnored = 0;
  quarantinePass = 0;
  quarantineFail = 0;
  quarantineFlaky = 0;
  quarantinePending = 0;
  quarantineRunning = 0;
  quarantineNotRun = 0;

  constructor(counts?: Partial<Counts>) {
    if (counts) {
      Object.assign(this, counts);
    }
  }

  get total() {
    return this.pass + this.fail + this.pending + this.running + this.notRun + this.ignored;
  }

  get totalQuarantine() {
    return this.quarantinePass + this.quarantineFail + this.quarantinePending + this.quarantineRunning + this.quarantineNotRun + this.quarantineIgnored;
  }

  get totalWithoutIgnored() {
    return this.pass + this.fail + this.pending + this.running + this.notRun;
  }

  get totalNotPassFile() {
    return this.totalWithoutIgnored - this.pass - this.fail;
  }

  get totalQuarantineWithoutIgnored() {
    return this.quarantinePass + this.quarantineFail + this.quarantinePending + this.quarantineRunning + this.quarantineNotRun;
  }

  get totalQuarantineNotPassFile() {
    return this.totalQuarantineWithoutIgnored - this.quarantinePass - this.quarantineFail;
  }

  checkMaxRunning(testRun: TestRun, files: TestFile[]) {
    if (testRun && testRun.defaultToParallelizeByFile) {
      const oldRunning = this.running;
      this.running = files.filter((f) => f.status == TestStatus.RUNNING).length;
      this.pending += oldRunning - this.running;
    }

    return this;
  }

  increment(status: TestStatus) {
    if (status == TestStatus.FAIL) this.fail++;
    else if (status == TestStatus.PASS) this.pass++;
    else if (status == TestStatus.IGNORED) this.ignored++;
    else if (status == TestStatus.NOT_RUN) this.notRun++;
    else if (status == TestStatus.PENDING) this.pending++;
    else if (status == TestStatus.RUNNING) this.running++;

    return this;
  }

  combine(counts: Counts) {
    this.ignored += counts.ignored;
    this.pass += counts.pass;
    this.fail += counts.fail;
    this.flaky += counts.flaky;
    this.pending += counts.pending;
    this.running += counts.running;
    this.notRun += counts.notRun;

    this.quarantineIgnored += counts.quarantineIgnored;
    this.quarantinePass += counts.quarantinePass;
    this.quarantineFail += counts.quarantineFail;
    this.quarantineFlaky += counts.quarantineFlaky;
    this.quarantinePending += counts.quarantinePending;
    this.quarantineRunning += counts.quarantineRunning;
    this.quarantineNotRun += counts.quarantineNotRun;

    return this;
  }
}

export function groupTestRunTestsByFile(testRunId: number, tests: TestRunTest[]) {
  return chain(tests)
    .groupBy((t) => t.projectTest.testFileName)
    .mapValues((v) => new TestFile(testRunId, v[0].projectTest.testFileName, v))
    .values()
    .sortBy((v) => v.name)
    .value();
}

export class TestFile {
  testRunId: number;
  name: string;
  tests: TestRunTest[];
  id: number;

  constructor(testRunId: number, name: string, tests: TestRunTest[]) {
    this.name = name;
    this.tests = tests;
    this.testRunId = testRunId;
    this.id = tests[0].id;
  }

  @computed
  get latestTests() {
    return chain(this.tests)
      .groupBy((t) => t.parentId)
      .mapValues((r) => sortBy(r, "id").reverse()[0])
      .values()
      .sortBy(["startTime", "projectTest.name"])
      .value();
  }

  @computed
  get hasSelectedTest() {
    return !!this.tests.find((t) => t.selected);
  }

  @computed
  get counts() {
    const byStatus = this.testsByStatus;
    return new Counts({
      ignored: byStatus[TestStatus.IGNORED].filter(trt => !trt.quarantined).length,
      pass: byStatus[TestStatus.PASS].filter(trt => !trt.quarantined).length,
      fail: byStatus[TestStatus.FAIL].filter(trt => !trt.quarantined).length,
      flaky: this.latestTests.filter((trt) => trt.flaky && !trt.quarantined).length,
      quarantineIgnored: byStatus[TestStatus.IGNORED].filter(trt => trt.quarantined).length,
      quarantinePass: byStatus[TestStatus.PASS].filter(trt => trt.quarantined).length,
      quarantineFail: byStatus[TestStatus.FAIL].filter(trt => trt.quarantined).length,
      quarantineFlaky: this.latestTests.filter((trt) => trt.flaky && trt.quarantined).length,
      pending: byStatus[TestStatus.PENDING].length,
      running: byStatus[TestStatus.RUNNING].length,
      notRun: byStatus[TestStatus.NOT_RUN].length,
    });
  }

  @computed
  get latestStartTime() {
    const max = chain(this.latestTests)
      .maxBy((t) => t.startTime)
      .value();
    return max && max.startTime;
  }

  @computed
  get testsByStatus() {
    const byStatus = chain(this.latestTests)
      .groupBy((t) => t.status)
      .value();

    return {
      [TestStatus.FAIL]: byStatus[TestStatus.FAIL] || [],
      [TestStatus.PASS]: byStatus[TestStatus.PASS] || [],
      [TestStatus.PENDING]: byStatus[TestStatus.PENDING] || [],
      [TestStatus.IGNORED]: byStatus[TestStatus.IGNORED] || [],
      [TestStatus.NOT_RUN]: byStatus[TestStatus.NOT_RUN] || [],
      [TestStatus.RUNNING]: byStatus[TestStatus.RUNNING] || [],
    };
  }

  @computed
  get status() {
    if (this.testsByStatus[TestStatus.RUNNING].length > 0) return TestStatus.RUNNING;
    if (this.testsByStatus[TestStatus.PENDING].length > 0) return TestStatus.PENDING;

    const ignoredCount = this.testsByStatus[TestStatus.IGNORED].length;

    if (ignoredCount == this.latestTests.length) return TestStatus.IGNORED;

    if (this.testsByStatus[TestStatus.PASS].length == this.latestTests.length - ignoredCount) return TestStatus.PASS;

    if (this.testsByStatus[TestStatus.NOT_RUN].length == this.latestTests.length) return TestStatus.NOT_RUN;

    return TestStatus.FAIL;
  }

  @computed
  get statusDisplay() {
    return this.status.toString().replace("_", " ");
  }

  @computed
  get duration() {
    if (this.status == TestStatus.NOT_RUN) return 0;

    const itemWithGroupParentId = this.latestTests.find((t) => t.groupParentId);

    if (itemWithGroupParentId) {
      return (
        itemWithGroupParentId.calculatedDuration +
        sumBy(this.latestTests, (t) => (t.status != TestStatus.RUNNING ? t.duration : 0))
      );
    }

    return sumBy(this.latestTests, (t) => (t.status == TestStatus.RUNNING ? t.calculatedDuration : t.duration));
  }
}

export class TestResult {
  testRunTestId: number;
  testRunTestReviewerUserId: number;
  testRunTestReviewStatusId: number;
  parentId: number;
  runnerUuid: string;
  key: string;
  testRunId: number;
  file: string;
  name: string;
  filter: string;
  status: TestStatus;
  output: string;
  startTime: string;
  endTime: string;
  duration: number;
  error: string;
  stackTrace: string;
  screenShots: string[];
  videos: string[];
  processOutput: string;
  flaky: boolean;
  testRun: TestRun;
  testRunTest?: TestRunTest;
}

export class TestRunTestAnalysis {
  id: number;
  testRunId: number;
  testRunTestId: number;
  analysisText: string;
  name: string;
  testRunAnalysisRootCauseId: number;

  @computed
  get formattedAnalysisText() {
    return TestRunTestAnalysis.formatAnalysisText(this.analysisText);
  }

  static formatAnalysisText(analysisText: string) {
    if (isJSON(analysisText)) {
      var analysis = JSON.parse(analysisText);
      var formattedText = `
        <div class="relevantTesteryDoc">I've taken a look at your error output, and I think this article from the Testery Docs should help: <a href="${analysis.relevantTesteryDoc.url}">${analysis.relevantTesteryDoc.title}</a></div>
        In addition to our Testery Help Docs, I've got some code suggestions that may help you.  Please remember, these are AI generated code examples, you'll need to ensure that they are appropriate for your codebase.
        <div class="suggestions">
        `;
      analysis.suggestions.forEach((suggestion, index) => {
        formattedText = formattedText + `
          <h3>Suggestion ${index + 1}</h3>
          <div>${suggestion.text}</div>
          <code>${suggestion.sourceCode}</code>
        `;
      });
      formattedText = formattedText + "</div>";
      return formattedText;
    } else {
      return analysisText.replace(/\\n/g, "\n").replace(/```(.*?)```/gms, "<code>$1</code>").replace(/Title: (.*?)\nURL: (.*?)\n/gm, "<a href=\"$2\">$1</a><br />");
    }
  }
}

export class TestRunAnalysis {
  testRunId: number;
  analysisText: string;
  status: string;
  testRunTestAnalysis: TestRunTestAnalysis[];
  summary: string;
  testRunAnalysisRootCauses: TestRunAnalysisRootCause[];
}

export class TestRunAnalysisRootCause {
  id: number;
  name: string;
  description: string;
  affectedTestRunTestAnalysis: TestRunTestAnalysis[];

  static formatAnalysisText(rootCause: TestRunAnalysisRootCause) {
    if (rootCause.affectedTestRunTestAnalysis.length == 0) return "";
    return TestRunTestAnalysis.formatAnalysisText(rootCause.affectedTestRunTestAnalysis[0].analysisText);
  }
}

export class RerunTestRequest {
  testIds?: Array<string>;
  rerunTestRun?: boolean;
  rerunFailedTests?: boolean;
}

export enum TestRunnerStatus {
  Created = "Created",
  Idle = "Idle",
  Running = "Running",
  Exited = "Exited",
}

export class TestRunner {
  id: number;
  uuid: string;
  testRunnerAgentId: number;
  testRunId: number;
  taskArn: string;
  testsRun: number;
  status: TestRunnerStatus;
  currentTest: string;
  currentTestStartTime: string;
  lastStatusChangeTime: string;
}

export class LogMessage {
  time: string;
  message: string;
}

export class TestItem {
  projectTestId: number;
  testRunTestId: number;
  name: string;
  testFilter: string;
  fileFilter: string;
  ignored: boolean;
  parentId: number;
}

export class TestInfo {
  file: string;
  name: string;
  filter: string;
  @type(TestItem)
  testItems: TestItem[];
}

export class TestListResult {
  @type(TestInfo)
  infos: TestInfo[];
  @type(TestInfo)
  unfilteredInfos: TestInfo[];
  filters: string[];
  output: string;
  error: string;
  runnerUuid: string;
}
