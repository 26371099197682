import { observer } from "mobx-react-lite";
import { Counts, TestRun } from "models/test-run";
import { Card } from "semantic-ui-react";
import DatetimeDisplay from "../../components/datetime-display";
import DurationDisplay from "../../components/duration-display";
import { calculateDuration } from "../../core/date-utils";
import "./details-tab.scss";
import "./test-run-overview.scss";
import { useEffect } from "react";
import { displayPieChart } from "../../core/charts";

interface Props {
  testRun: TestRun;
  counts: Counts;
}

const DetailsTab = observer(({ testRun, counts }: Props) => {

  const duration = calculateDuration(testRun.startTime, testRun.endTime);

  useEffect(() => {
    testRun && testRun.quarantineTotalCount > 0 &&
    displayPieChart("quarantineResultsChart", [
      { value: counts.quarantinePass, label: "Passing", color: "#3fdf3f" },
      { value: counts.quarantineFail, label: "Failing", color: "#df3f3f" },
      { value: counts.totalQuarantineNotPassFile, label: "Other", color: "#cccccc" },
    ]);
  }, [counts.quarantinePass, counts.quarantineFail, counts.totalQuarantineNotPassFile]);

  return (
    <div className={"details-container"}>
      <Card className="summary-item summary-timing">
        <Card.Content>
          <Card.Header>Timing</Card.Header>
          <div className="value">
            <div>
              Start time: <DatetimeDisplay datetime={testRun.startTime} />
            </div>
            <div>
              End time: <DatetimeDisplay datetime={testRun.endTime} />
            </div>
            <div>
              Duration: <DurationDisplay duration={duration} />
            </div>
            {testRun.totalTestingTime > 0 && (
              <div>
                Total Test Execution Time: <DurationDisplay duration={testRun.totalTestingTime} />
              </div>
            )}
          </div>
        </Card.Content>
      </Card>
      {testRun.quarantineTotalCount > 0 && (
        <Card className={"summary-item"}>
          <Card.Content>
            <Card.Header>
              Quarantine Stats
            </Card.Header>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="report-summary-charts">
                <canvas id="quarantineResultsChart" className="report-summary-chart"></canvas>
              </div>
              <div className="value totals">
                <table>
                  <tbody>
                  <tr className="total">
                    <td className="number">{counts.totalQuarantine}</td>
                    <td>Tests</td>
                  </tr>
                  <tr>
                    <td className="number">{counts.quarantinePass}</td>
                    <td>Passing</td>
                  </tr>
                  <tr>
                    <td className="number">{counts.quarantineFail}</td>
                    <td>Failing</td>
                  </tr>
                  {counts.quarantinePending > 0 && (
                    <tr>
                      <td className="number">{counts.quarantinePending}</td>
                      <td>Pending</td>
                    </tr>
                  )}
                  {counts.quarantineRunning > 0 && (
                    <tr>
                      <td className="number">{counts.quarantineRunning}</td>
                      <td>Running</td>
                    </tr>
                  )}
                  {counts.quarantineNotRun > 0 && (
                    <tr>
                      <td className="number">{counts.quarantineNotRun}</td>
                      <td>Not Run</td>
                    </tr>
                  )}
                  {counts.quarantineIgnored > 0 && (
                    <tr>
                      <td className="number">{counts.quarantineIgnored}</td>
                      <td>Ignored</td>
                    </tr>
                  )}
                  {counts.quarantineFlaky > 0 && (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <hr />
                        </td>
                      </tr>
                      <tr>
                        <td className="number">{counts.quarantineFlaky}</td>
                        <td>Flaky</td>
                      </tr>
                    </>
                  )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="value">
              {testRun.totalTestingTime > 0 && (
                <div>
                  Quarantine Test Execution Time: <DurationDisplay duration={testRun.quarantineTotalTestingTime} />
                </div>
              )}
            </div>
          </Card.Content>
        </Card>
      )}
    </div>
  );
});

export default DetailsTab;
