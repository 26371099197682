import { observer } from "mobx-react-lite";
import { BooleanMap, TestFile, TestRun, TestRunStatus, TestRunTest, TestStatus } from "models/test-run";
import { AppStore, ProjectStore, TestRunStore } from "stores";
import { Button, Checkbox, Icon, Input, Pagination } from "semantic-ui-react";
import TestMultiStatusSelect from "../../components/form/test-multi-status-select";
import Spinner from "../../components/spinner";
import TestResultRow from "../../components/test-result-row";
import Project from "../../models/project";
import { chain } from "lodash";
import { useState } from "react";

interface Props {
  testRun: TestRun;
  refresh: (testRunId: number) => any;
  testFiles: Array<TestFile>;
  running: boolean;
  rootCauseId: number;
}

const TestResultsTab = observer(({ testRun, refresh, testFiles, running, rootCauseId }: Props) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedForRerun, setSelectedForRerun] = useState<Array<TestRunTest>>([]);
  const [showQuarantinedTests, setShowQuarantinedTests] = useState<boolean>(false);

  function toggleRerunOption(testRunTest: TestRunTest, rerun: boolean) {
    if (rerun && !selectedForRerun.includes(testRunTest))
      setSelectedForRerun([...selectedForRerun, testRunTest]);
    else if (!rerun)
      setSelectedForRerun(selectedForRerun.filter(trt => trt.id !== testRunTest.id));
  }

  const results = chain(testFiles).sortBy(["latestStartTime", "name"]).groupBy("status").value();

  const hasFailedTests = !!results[TestStatus.FAIL];
  const allowRerun = hasFailedTests && !running;
  const loadingResults = TestRunStore.loadingTestRunResults;

  const testResults = [
    ...(results[TestStatus.RUNNING] || []),
    ...(results[TestStatus.NOT_RUN] || []),
    ...(results[TestStatus.FAIL] || []),
    ...(results[TestStatus.IGNORED] || []),
    ...(results[TestStatus.PASS] || []),
    ...(results[TestStatus.PENDING] || []),
  ];

  const filteredStatuses = TestRunStore.getStatusFilters(testRun.id);

  const matchesFilter = (testRunTest: TestRunTest) =>
    testRunTest.selected ||
    ((filteredStatuses.length == 0 ||
        !!filteredStatuses.find((s) => s == testRunTest.status) ||
        (filteredStatuses.find((s) => s == TestStatus.FLAKY) && testRunTest.flaky)) &&
      (!testRunTest.quarantined || showQuarantinedTests == testRunTest.quarantined) &&
      (search == "" || testRunTest.projectTest.matches(search.toLocaleLowerCase())) &&
      (rootCauseId == 0 || testRunTest.testRunAnalysisRootCauseId == rootCauseId));

  let filteredFiles = testResults.filter((file) => file.latestTests.some((t) => matchesFilter(t)));

  let filteredCount = 0;
  testFiles.forEach((f) =>
    f.latestTests.forEach((t) => {
      if (matchesFilter(t)) {
        filteredCount++;
      }
    }),
  );

  const openItems = TestRunStore.getOpenItems(testRun.id);
  const aboveResultCutoff = filteredCount > 200;
  const openDefault = !aboveResultCutoff;

  let pagination: JSX.Element = null;
  const pageSize = 20;

  if (!testRun) return null;

  const project = ProjectStore.find(testRun.projectId) || new Project();

  const rerunTests = async (testResults: Array<TestRunTest>) => {
    const currentlyRunning =
      testRun.status == TestRunStatus.SUBMITTED || testRun.status == TestRunStatus.SETUP || testRun.status == TestRunStatus.RUNNING || testRun.status == TestRunStatus.TEAR_DOWN;
    await TestRunStore.rerunTests(testResults);
    if (!currentlyRunning) {
      refresh(testRun.id);
    }
  };

  const rerunFailedTests = async () => {
    const currentlyRunning =
      testRun.status == TestRunStatus.SUBMITTED || testRun.status == TestRunStatus.SETUP || testRun.status == TestRunStatus.RUNNING || testRun.status == TestRunStatus.TEAR_DOWN;
    await TestRunStore.rerunFailedTests(testRun.id);
    if (!currentlyRunning) {
      refresh(testRun.id);
    }
  };

  const rerunSelectedTests = async () => {
    const currentlyRunning =
      testRun.status == TestRunStatus.SUBMITTED || testRun.status == TestRunStatus.SETUP || testRun.status == TestRunStatus.RUNNING || testRun.status == TestRunStatus.TEAR_DOWN;
    await TestRunStore.rerunTests(selectedForRerun);
    if (!currentlyRunning) {
      refresh(testRun.id);
    }
  };

  if (aboveResultCutoff && filteredFiles.length > pageSize) {
    pagination = (
      <Pagination
        totalPages={Math.ceil(filteredFiles.length / pageSize)}
        lastItem={false}
        firstItem={false}
        defaultActivePage={1}
        onPageChange={(_, a) => {
          setPage(a.activePage as number);
        }}
      />
    );
    filteredFiles = filteredFiles.filter(
      (_, i) => i >= pageSize * (page - 1) && i < pageSize * page,
    );
  }

  const isOpen = (i: number) => (openItems[i] == undefined ? openDefault : openItems[i]);

  const resultRows = filteredFiles.map((file) => (
    <TestResultRow
      filter={matchesFilter}
      testRun={testRun}
      key={file.id}
      open={isOpen(file.id)}
      toggleOpen={() =>
        TestRunStore.updateOpenItems(testRun.id, {
          ...openItems,
          [file.id]: !isOpen(file.id),
        })
      }
      testFile={file}
      rerunTests={rerunTests}
      project={project}
      testTimeoutSeconds={testRun.testTimeoutSeconds}
      toggleRerunOption={toggleRerunOption}
      refresh={refresh}
    />
  ));

  const toggleCollapse = (open: boolean) => {
    const data = {} as BooleanMap;
    filteredFiles.forEach((f) => {
      data[f.id] = open;
    });
    TestRunStore.updateOpenItems(testRun.id, data);
  };

  return (
    <div className="test-results-tab">
      {!loadingResults && (
        <header>
          <div className="title">
            <h2>Test Results</h2>
            <div className="count">
              {filteredCount}
              {filteredStatuses.length == 0 ? "" : " Filtered"} Test Cases
            </div>
            <Button inverted color="grey" size="mini" onClick={() => toggleCollapse(false)}>
              <Icon name="minus" /> Collapse All
            </Button>
            <Button inverted color="grey" size="mini" onClick={() => toggleCollapse(true)}>
              <Icon name="plus" /> Expand All
            </Button>
            <Input
              icon={{
                name: search == "" ? "search" : "close",
                link: true,
                onClick: () => setSearch(""),
              }}
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <br />
            {pagination}
          </div>
          <div className="actions">
            <Checkbox checked={showQuarantinedTests} label={"Show Quarantined Tests"}
                      onChange={() => setShowQuarantinedTests(!showQuarantinedTests)} />
            <TestMultiStatusSelect
              placeholder="Any Status"
              value={filteredStatuses}
              onChange={(filters) => TestRunStore.updateStatusFilters(testRun.id, filters)}
            />
            {AppStore.account.isLegacyOrTierTwoPlusPlan() && (
              <>
                {allowRerun && (
                  <Button color="green" onClick={rerunFailedTests}>
                    <Icon name="redo" />
                    Rerun Failed Tests
                  </Button>
                )}
                <Button color="green" onClick={rerunSelectedTests} disabled={selectedForRerun.length === 0}>
                  <Icon name="redo" />
                  Rerun Selected Tests
                </Button>
              </>
            )}
          </div>
        </header>
      )}
      {!loadingResults && (
        <table id="test-run-result-table" className="report-test-results-list table">
          <tbody>{resultRows}</tbody>
        </table>
      )}
      {loadingResults && <Spinner text="Loading Test Results" />}
    </div>
  );
});

export default TestResultsTab;
