import ShowAdvanced from "components/show-advanced";
import { observer } from "mobx-react-lite";
import TestRunForm from "models/forms/test-run-form";
import TestSuiteForm from "models/forms/test-suite-form";
import Project from "models/project";
import { Grid } from "semantic-ui-react";
import NumberInput from "./number-input";
import NumberSelect from "./number-select";
import ParallelizationSelect from "./parallelization-select";
import YesNoSelect from "./yes-no-select";

interface Props {
  project: Project;
  form: TestRunForm | TestSuiteForm;
  useAdvancedToggle?: boolean;
  hidePriority?: boolean;
}

const TestAdvancedOptions = observer(
  ({ project, form, useAdvancedToggle, hidePriority }: Props) => {
    const content = (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <NumberSelect
              label="Number of Parallel Tests"
              min={1}
              max={form.maxRunnersAllowed}
              value={form.maxRunners}
              onChange={form.onChange("maxRunners")}
              error={form.getErrorText("maxRunners")}
            />
          </Grid.Column>
          <Grid.Column>
            {project?.allowParallelizationOption && (
              <ParallelizationSelect
                label="Parallelization Strategy"
                allowEmpty={true}
                paralelizeByFile={form.defaultToParallelizeByFile}
                onChange={form.onChange("defaultToParallelizeByFile")}
                error={form.getErrorText("defaultToParallelizeByFile")}
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <NumberInput
              min={1}
              max={1440}
              label="Test Run Timeout (minutes)"
              value={form.timeoutMinutes}
              onChange={form.onChange("timeoutMinutes")}
              error={form.getErrorText("timeoutMinutes")}
            />
          </Grid.Column>
          <Grid.Column>
            <NumberInput
              min={1}
              max={10800}
              label="Test Timeout (seconds)"
              value={form.testTimeoutSeconds}
              onChange={form.onChange("testTimeoutSeconds")}
              error={form.getErrorText("testTimeoutSeconds")}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          {!hidePriority && (
            <Grid.Column>
              <NumberSelect
                min={1}
                max={10}
                label="Priority"
                value={form.priority}
                onChange={form.onChange("priority")}
                error={form.getErrorText("priority")}
              />
            </Grid.Column>
          )}
          <Grid.Column>
            <YesNoSelect
              label="Retry Failed Tests"
              value={form.retryFailedTests}
              onChange={form.onChange("retryFailedTests")}
              error={form.getErrorText("retryFailedTests")}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    return useAdvancedToggle ? <ShowAdvanced>{content}</ShowAdvanced> : content;
  },
);

export default TestAdvancedOptions;
