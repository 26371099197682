import { MenuItem, Select } from "@mui/material";
import { UserStore } from "../../stores";

interface Props {
  usersAlreadyOnTeam: Array<number>;
  onSelect: (userId: number) => any;
}

const UserSelect = ({ usersAlreadyOnTeam, onSelect }: Props) => {
  const users = UserStore.usersForDropdown;
  const options = users.map(user => {
    if (usersAlreadyOnTeam.includes(user.id))
      return;

    return <MenuItem key={user.id} value={user.id}>{user.fullName}</MenuItem>;
  });

  return (
    <>
      <Select style={{ background: "#FFF", textAlign: "left", width: "200px" }}
              displayEmpty={true}
              onChange={e => onSelect(Number(e.target.value))}
              value={""}>
        <MenuItem key={""} value={""}><em>Add User to Team</em></MenuItem>
        {options}
      </Select>
    </>
  );
};

export default UserSelect;
