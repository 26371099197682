import { observable, action } from "mobx";
import { notEmpty } from "core/validation";
import Form from "./form";

export default class PipelineEnvironmentForm extends Form {
  @observable pipelineStageId: number;
  @observable environmentId: number;

  validators = { pipelineStageId: notEmpty, environmentId: notEmpty };

  @action
  setDefaults() {
    super.setDefaults();
    this.pipelineStageId = null;
    this.environmentId = null;
  }

  @action
  populateValues(pipelineStageId: number, environmentId: number) {
    this.pipelineStageId = pipelineStageId;
    this.environmentId = environmentId;
  }
}
