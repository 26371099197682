import { TestRun, TestStatus } from "models/test-run";
import { useState, useEffect, useRef, Fragment } from "react";
import { parseJSON } from "date-fns";
import { TestRunStore } from "stores";
import { keys, minBy, chain, maxBy } from "lodash";
import "./test-result-timeline.scss";
import { Stage, Layer, Rect, Text } from "react-konva";
import { useWindowSize } from "core/react-utils";
import { millisToMinutesAndSeconds } from "core/date-utils";
import DatetimeDisplay from "components/datetime-display";
import { observer } from "mobx-react-lite";
import Konva from "konva";

interface Props {
  testRun: TestRun;
}

const passColor = "#21ba45";
const passRerunColor = "#a8ebb8";
const failColor = "#db2828";
const failRerunColor = "#f07c7c";
const newFileColor = "#f0ad4e";
const highlightColor = "#3f8bee";

const TestResultTimeline = observer(({ testRun }: Props) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const [hovered, setHovered] = useState(null as any);
  const [popupWidth, setPopupWidth] = useState(0);
  const [width, setWidth] = useState(0);
  const windowSize = useWindowSize();

  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current, windowSize]);

  if (TestRunStore.loadingTestRunResults) return null;

  const testRunTests = TestRunStore.getTestRunTests(testRun.id).filter(
    (t) =>
      (t.status == TestStatus.PASS || t.status == TestStatus.FAIL) &&
      (!testRun.endTime || t.endTime < testRun.endTime),
  );
  const now = new Date();
  const convertTime = (time: string) => (time ? parseJSON(time) : now).getTime();
  const startTime = convertTime((minBy(testRunTests, "startTime") || testRun).startTime);
  const endTime = convertTime((maxBy(testRunTests, "endTime") || testRun).endTime);
  const totalDuration = endTime - startTime;

  const itemHeight = 28;
  const topOffset = 3;
  const rowHeight = itemHeight + 5;
  let index = -1;
  const drawableWidth = width ? width - 10 : 0;
  const leftOffset = 3;

  const items = chain(testRunTests)
    .orderBy("startTime")
    .groupBy((t) => t.testRunnerId || t.runnerUuid)
    .mapValues((tests) => {
      var lastFile = "";
      index++;
      const y = index * rowHeight + topOffset;

      return tests
        .map((t) => {
          const thisStart = convertTime(t.startTime);
          const thisEnd = convertTime(t.endTime);
          const thisDuration = thisEnd - thisStart;
          const offset = (thisStart - startTime) / totalDuration;
          const percentage = thisDuration / totalDuration;
          const rerun = t.id != t.parentId;
          const newFile =
            t.projectTest.fileFilter != lastFile && testRun.defaultToParallelizeByFile;
          lastFile = t.projectTest.fileFilter;
          const x = offset * drawableWidth + leftOffset;

          let color = passColor;

          if (t.status == TestStatus.FAIL) {
            color = rerun ? failRerunColor : failColor;
          } else if (rerun) {
            color = passRerunColor;
          }

          const name = (rerun ? "Rerun: " : "") + t.projectTest.name;
          const display = `${name} (${millisToMinutesAndSeconds(t.duration)})`;

          return {
            id: t.id,
            parentId: t.parentId,
            newFile,
            name,
            display,
            startTime: t.startTime,
            endTime: t.endTime,
            start: thisStart,
            end: thisEnd,
            duration: thisDuration,
            offset: offset,
            width: percentage,
            status: t.status,
            rerun,
            color,
            x,
            y,
            isHighlighted: t.selected,
          };
        })
        .filter((t) => t.end <= endTime);
    })
    .value();

  const click = (item) => {
    TestRunStore.updateSelectedParentId(testRun.id, item.isHighlighted ? null : item.parentId);
  };

  const doubleClick = (item) => {
    TestRunStore.updateSelectedParentId(testRun.id, item.parentId);
    window.setTimeout(() => {
      document
        .getElementById(`test-run-test-${item.id}`)
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }, 100);
  };

  const hover = (item) => {
    if (item) {
      setPopupWidth(
        new Konva.Text({
          text: item.display,
          fontSize: 14,
          fontStyle: "bold",
        }).getTextWidth() + 12,
      );
      setHovered(item);
    } else {
      setHovered(null);
      setPopupWidth(0);
    }
  };

  const rows = keys(items);
  const highlightBorderWidth = 2;

  const times: Date[] = [new Date(startTime)];
  let time = startTime;
  const gaps = Math.round(width / 140);
  const timeWidth = 100 / gaps;
  const increment = Math.ceil((endTime - startTime) / gaps);

  while (time < endTime) {
    time += increment;
    times.push(new Date(time));
  }

  let hoverX = 0;
  let hoverY = 0;

  if (popupWidth > 0 && hovered) {
    hoverY = hovered.y - itemHeight < 5 ? hovered.y + itemHeight + 5 : hovered.y - itemHeight - 5;
    hoverX = hovered.x + popupWidth > width ? width - popupWidth : hovered.x;
  }

  return (
    <div className="test-result-timeline" ref={ref}>
      {times.length > 2 &&
        times.map((t, i) => (
          <div
            key={i}
            style={{ width: (i == 0 || i == gaps ? timeWidth / 2 : timeWidth) + "%" }}
            className={`time-ledger${i == 0 ? " first" : ""}${i == gaps ? " last" : ""}`}
          >
            <DatetimeDisplay
              datetime={t.toJSON()}
              hideDate={true}
              showSecond={true}
              displayFormat="HH:mm:ss"
            />
            <div className="line">|</div>
          </div>
        ))}
      <Stage width={width} height={rows.length * rowHeight + topOffset + 30}>
        <Layer>
          {rows.map((key) =>
            items[key]
              .map((t) => {
                const adjustedWidth = drawableWidth * t.width || 3;
                const x = t.isHighlighted ? t.x + highlightBorderWidth : t.x;
                const width = t.isHighlighted
                  ? Math.max(adjustedWidth - highlightBorderWidth * 2, 3)
                  : adjustedWidth;
                const y = t.isHighlighted ? t.y + highlightBorderWidth : t.y;
                const height = t.isHighlighted ? itemHeight - highlightBorderWidth * 2 : itemHeight;

                return (
                  <Fragment key={t.id}>
                    {t.newFile && (
                      <Rect y={t.y} x={t.x - 3} width={3} height={itemHeight} fill={newFileColor} />
                    )}
                    {t.isHighlighted && (
                      <Rect
                        onClick={() => click(t)}
                        x={t.x}
                        y={t.y}
                        width={adjustedWidth}
                        height={itemHeight}
                        fill={highlightColor}
                        cornerRadius={5}
                        shadowBlur={5}
                      />
                    )}
                    <Rect
                      onMouseEnter={() => hover(t)}
                      onMouseOut={() => hover(null)}
                      onClick={() => click(t)}
                      onDblClick={() => doubleClick(t)}
                      x={x}
                      y={y}
                      width={width}
                      height={height}
                      fill={t.color}
                      cornerRadius={5}
                    />
                  </Fragment>
                );
              })
              .reverse(),
          )}
          {hovered && popupWidth > 0 && (
            <Fragment>
              <Rect
                x={hoverX}
                y={hoverY}
                width={popupWidth}
                height={itemHeight}
                fill="#ddd"
                cornerRadius={5}
              />
              <Text
                align="center"
                onClick={() => click(null)}
                x={hoverX}
                y={hoverY + 8}
                width={popupWidth}
                fontSize={14}
                fontStyle="bold"
                text={hovered.display}
              />
            </Fragment>
          )}
        </Layer>
      </Stage>
    </div>
  );
});

export default TestResultTimeline;
