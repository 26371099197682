import { ItemStoreList } from "./item-store";
import API from "core/api";
import { action, computed, observable, runInAction } from "mobx";
import { AuditLog, PipelineStage } from "models/settings";
import { orderBy } from "lodash";
import PipelineStageForm from "models/forms/pipeline-stage-form";

export class PipelineStoreClass extends ItemStoreList<PipelineStage> {
  @observable pipelineStageAuditLogItems: Array<AuditLog>;
  @observable pipelineStageAuditLogLoaded = false;
  
  @computed
  get sortedItems() {
    return orderBy(this.items, ["priority", "name"]);
  }

  async load(id: number) {
    const { data } = await API.get(`/pipeline-stages/${id}`, PipelineStage);
    return this.add(data);
  }

  @action
  async doLoadAll() {
    const { data } = await API.getList("/pipeline-stages", PipelineStage);
    return data;
  }

  @action
  async delete(id: number) {
    await API.delete(`/pipeline-stages/${id}`);
    super.remove(id);
  }

  @action
  async save(form: PipelineStageForm, forceSubmit?: boolean) {
    return form.submit(async (data) => {
      if (data.id) {
        const result = await API.patch(`/pipeline-stages/${data.id}`, data, PipelineStage);
        return this.update(result.data);
      } else {
        const result = await API.post("/pipeline-stages", data, PipelineStage);
        return this.add(result.data);
      }
    }, forceSubmit);
  }

  @action
  updatePriority(stageId: number, newPriority: number) {
    const stage = this.find(stageId);
    const stagesByPriority = this.sortedItems.filter((s) => s.id != stageId);

    stagesByPriority.splice(newPriority, 0, stage);

    stagesByPriority.forEach((s, i) => {
      if (s.priority != i) {
        s.priority = i;
        this.save(new PipelineStageForm(s), true);
      }
    });
  }

  @action
  async loadPipelineStageAuditLog(pipelineStageId: number) {
    const result = await API.getList(`pipeline-stages/${pipelineStageId}/auditTrail`, AuditLog);
    return runInAction(() => {
      this.pipelineStageAuditLogLoaded = true;
      this.pipelineStageAuditLogItems = result.data;
    });
  }
}
