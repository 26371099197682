import { observer } from "mobx-react-lite";
import { ModalStore } from "stores";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import "./environment-file-new.scss";
import EnvironmentVarForm from "models/forms/environment-var-form";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { useState } from "react";

let form = new EnvironmentVarForm();
const { environmentFileNew } = ModalStore;

async function saveFile() {
  environmentFileNew.state.addFileVariable(form.key.trim() === "" ? "Blank File Name" : form.key, form.value);
  environmentFileNew.hide();
}

environmentFileNew.onHidden = form.clearFormData;

const EnvironmentFileNew = observer(() => {
  const [language, setLanguage] = useState<string>("plaintext");
  const [keyError, setKeyError] = useState(false);

  return (
    <Modal open={environmentFileNew.showing} size="small">
      <Modal.Header>New File</Modal.Header>
      <Modal.Content>
        <Form className="environment-file-new">
          <Form.Input
            id={"environment-file-name"}
            label="File Name"
            placeholder={"File Name"}
            value={form.key}
            onChange={(e) => {
              const value = e.target.value.trim();
              setKeyError(value === "")
              if (keyError)
                return;
              form.updateField("key", value);
            }}
            error={keyError}
          />
          <Form.Select
            label={"File/Code Language (only for code styling below)"}
            onChange={(_e, data) => setLanguage(data.value as string)}
            value={language}
            options={[ // Supported languages can be found here: https://uiwjs.github.io/react-textarea-code-editor/
              { key: "c", value: "c", text: "C" },
              { key: "coffeescript", value: "coffeescript", text: "CoffeeScript" },
              { key: "csharp", value: "csharp", text: "C#" },
              { key: "css", value: "css", text: "CSS" },
              { key: "go", value: "go", text: "GO" },
              { key: "html", value: "html", text: "HTML" },
              { key: "java", value: "java", text: "Java" },
              { key: "js", value: "js", text: "JavaScript" },
              { key: "json", value: "json", text: "JSON" },
              { key: "jsx", value: "jsx", text: "JSX" },
              { key: "kotlin", value: "kotlin", text: "Kotlin" },
              { key: "php", value: "php", text: "PHP" },
              { key: "plaintext", value: "plaintext", text: "Plain Text" },
              { key: "powershell", value: "powershell", text: "PowerShell" },
              { key: "python", value: "python", text: "Python" },
              { key: "tsx", value: "tsx", text: "TSX" },
              { key: "typescript", value: "typescript", text: "TypeScript" },
              { key: "python", value: "python", text: "Python" },
              { key: "xml", value: "xml", text: "XML" },
              { key: "yaml", value: "yaml", text: "YAML" },
            ]} />
          <div className={"field"}>
            <label>File Contents</label>
            <CodeEditor
              onChange={form.onChange("value")}
              placeholder={"File Contents"}
              language={language}
              padding={15}
              style={{
                fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                border: "1px solid rgba(34,36,38,.15)",
                borderRadius: ".28571429rem",
                height: "300px",
                overflowY: "scroll",
                background: "#FFF",
                resize: "none"
              }} />
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={() => environmentFileNew.hide()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button
          onClick={async() => {
            // @ts-ignore
            if (document.getElementById("environment-file-name").value.trim() === "") {
              setKeyError(true);
              return;
            }

            await saveFile();

            const environmentFileTableContainer = document.getElementById("environment-file-table-container");
            environmentFileTableContainer.scrollTop = environmentFileTableContainer.scrollHeight;
            window.scrollTo(0, document.body.scrollHeight);
          }}
          loading={form.isSubmitting} color="green" type="submit">
          <Icon name="check" />
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default EnvironmentFileNew;
