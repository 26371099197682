import API from "core/api";
import { action, observable, runInAction } from "mobx";
import { TestRunHistory, TestRunLog } from "models/test-run";

export class TestRunHistoryStoreClass {
  @observable historyItems: Array<TestRunHistory>;
  @observable historyLoaded = false;
  @observable logItems: Array<TestRunLog>;
  @observable logsLoaded = false;

  @action
  async clear() {
    this.historyItems = new Array<TestRunHistory>();
    this.historyLoaded = false;
    this.logItems = new Array<TestRunLog>();
    this.logsLoaded = false;
  }

  @action.bound
  async loadHistory(testRunId: number) {
    const result = await API.getList(`test-runs/${testRunId}/history`, TestRunHistory);

    return runInAction(() => {
      this.historyLoaded = true;
      this.historyItems = result.data;
    });
  }

  @action.bound
  async loadLogs(testRunId: number) {
    const result = await API.getList(`test-runs/${testRunId}/logs`, TestRunLog);

    return runInAction(() => {
      this.logsLoaded = true;
      this.logItems = result.data;
    });
  }

  @action.bound
  async ensureLoaded(testRunId: number) {
    if (this.historyLoaded)
      return;

    await this.loadHistory(testRunId);
  }
}
