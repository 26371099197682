import { useInitialEffect, useUnloadEffect, setPageInfo } from "core/react-utils";
import { useParams, useHistory } from "react-router";
import { Button, Form, Icon } from "semantic-ui-react";
import { ProjectStore, TestDistributionAnalysisRuleStore } from "stores";
import Page from "pages/page";
import { Icons } from "core/constants";
import { observer } from "mobx-react-lite";
import TestDistributionAnalysisRuleForm from "models/forms/test-distribution-analysis-rule-form";
import NumberSelect from "components/form/number-select";
import TestDistributionRuleTypeSelect from "components/form/test-distribution-rule-type-select";
import TestDistributionRuleAssignedToSelect from "components/form/test-distribution-rule-assigned-to-select";
import ProjectSelect from "components/form/project-select";
import TestSuiteSelect from "components/form/test-suite-select";
import { organizationUrl } from "core/utils";
import TestFiltersSelect from "components/form/test-filters-select";

const form = new TestDistributionAnalysisRuleForm();
let project: any = null;

async function loadRunnerPool(id: string) {
  await TestDistributionAnalysisRuleStore.loadTestDistributionRules();
  if (id) {
    form.populateFromRule(TestDistributionAnalysisRuleStore.find(parseInt(id)));
  } 
  if (form.projectId) {
    project = await ProjectStore.load(form.projectId);
  }
}

async function saveRule(push: (path: string) => any) {
  await TestDistributionAnalysisRuleStore.saveRule(form);
  push(organizationUrl("settings/test-distribution-settings"));
}

const TestDistributionAnalysisRuleEditPage = observer(() => {
  const { ruleId } = useParams<{ ruleId: string }>();
  const history = useHistory();
  const title = (ruleId ? "Edit" : "New") + " Test Distribution Analysis Rule";

  setPageInfo("runner-pool-edit", title);

  useInitialEffect(() => {
    loadRunnerPool(ruleId);
  });

  useUnloadEffect(() => form.clearFormData());

  return (
    <Page name="runner-pools-edit" title={title} icon={Icons.pipelines}>
      <Form onSubmit={() => null}>
        <NumberSelect
          label="Priority"
          value={form.orderToApply}
          onChange={form.onChange("orderToApply")}
          min={0}
          max={30}
        />
        <TestDistributionRuleTypeSelect
            label="Rule Type"
            value={form.ruleType}
            onChange={form.onChange("ruleType")}
            error={form.getErrorText("ruleType")}
        />
        <TestDistributionRuleAssignedToSelect
            label="Distribution Type Assigned"
            value={form.assignedTo}
            onChange={form.onChange("assignedTo")}
            error={form.getErrorText("assignedTo")}
            />
        <ProjectSelect
          label="Project"
          value={form.projectId}
          onChange={form.onChange("projectId")}
          error={form.getErrorText("projectId")}
          allowOtherFramework={true}
        />
        <TestSuiteSelect
          label="Test Suite"
          projectId={form.projectId}
          value={form.testSuiteId}
          onChange={form.onChange("testSuiteId")}
          error={form.getErrorText("testSuiteId")}
          placeholder="Select Test Suite"
        />
         <TestFiltersSelect
          label="Test Filters"
          project={project}
          value={form.includePatterns}
          onChange={form.onChange("includePatterns")}
          error={form.getErrorText("includePatterns")}
        />
      </Form>
      <section className="form-actions">
        <Button onClick={() => history.goBack()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button color="green" onClick={() => saveRule(history.push)}>
          <Icon name="check" />
          Save
        </Button>
      </section>
    </Page>
  );
});

export default TestDistributionAnalysisRuleEditPage;

