import { ItemStoreList } from "./item-store";
import API from "core/api";
import { action } from "mobx";
import RunnerPoolForm from "models/forms/runner-pool-form";
import { RunnerPool } from "models/settings";

export class RunnerPoolStoreClass extends ItemStoreList<RunnerPool> {

  async load(id: number) {
    const { data } = await API.get(`/runner-pools/${id}`, RunnerPool);
    return this.add(data);
  }

  @action
  async doLoadAll() {
    const { data } = await API.getList("/runner-pools", RunnerPool);
    return data;
  }

  @action
  async delete(id: number) {
    await API.delete(`/runner-pools/${id}`);
    super.remove(id);
  }

  @action
  async save(form: RunnerPoolForm, forceSubmit?: boolean) {
    return form.submit(async (data) => {
      if (data.id) {
        const result = await API.patch(`/runner-pools/${data.id}`, data, RunnerPool);
        return this.update(result.data);
      } else {
        const result = await API.post("/runner-pools", data, RunnerPool);
        return this.add(result.data);
      }
    }, forceSubmit);
  }
}
