import { observable, action } from "mobx";
import { notEmpty, valueSelected } from "core/validation";
import { PipelineStage } from "models/settings";
import { WithEnvironmentVarsForm } from "./environment-var-form";

export default class PipelineStageForm extends WithEnvironmentVarsForm {
  @observable id: number;
  @observable name: string;
  @observable priority: number;
  @observable testRunPriority: number;
  @observable windowsRunnerPoolId: number;
  @observable linuxRunnerPoolId: number;

  constructor(stage?: PipelineStage) {
    super();

    if (stage) {
      this.populateFromPipelineStage(stage);
    }
  }

  validators = { name: notEmpty, priority: valueSelected, testRunPriority: valueSelected };

  @action
  setDefaults() {
    super.setDefaults();
    this.id = null;
    this.name = "";
    this.priority = 1;
    this.testRunPriority = 1;
  }

  @action
  populateFromPipelineStage(stage: PipelineStage) {
    this.populate(stage);
    this.populateVariables(stage.variables);
  }
}
