import { parseServerDateTime } from "core/date-utils";
import { computed } from "mobx";

export abstract class Entity {
  id: number;

  assign(data: { [P in keyof this]?: this[P] }) {
    Object.assign(this, data);

    return this;
  }
}

export abstract class EntityWithCreatedAt extends Entity {
  createdAt: string;

  @computed
  get createdAtDate() {
    return parseServerDateTime(this.createdAt);
  }
}

export abstract class EntityWithDates extends Entity {
  createdAt: string;
  updatedAt: string;

  @computed
  get createdAtDate() {
    return parseServerDateTime(this.createdAt);
  }

  @computed
  get updatedAtDate() {
    return parseServerDateTime(this.updatedAt);
  }
}

export abstract class EntityWithAccountId extends EntityWithDates {
  accountId: number;
}
