import Select from "./select";
import { TestRunStore } from "stores";
import { observer } from "mobx-react-lite";
import { RefObject } from "react";
import { useEffect, useInitialEffect } from "core/react-utils";

interface Props {
  value: number;
  onChange: (id: number) => any;
  placeholder?: string;
  itemRef?: RefObject<HTMLElement>;
  error?: boolean | string;
  label?: string;
  projectId: number;
  environmentId: number;
  branch: string;
}

const TestRunSelect = observer(
  ({
     value,
     onChange,
     placeholder,
     itemRef,
     error,
     label,
     projectId,
     environmentId,
     branch
   }: Props) => {
    useInitialEffect(async () => {
      TestRunStore.pageSize = 100
      await TestRunStore.ensureLoaded();
    });

    let testruns = TestRunStore.eligibleForComparison;

    useEffect(async () => {
      if (projectId) {
        TestRunStore.setFilterProject(projectId);
      }
      if (environmentId) {
        TestRunStore.setFilterEnvironment([environmentId])
      }
      if (branch) {
        TestRunStore.setFilterBranch([branch])
      }
      await TestRunStore.loadTestRuns()

      if (!testruns.find((p) => p.id == value)) {
        if (placeholder) {
          onChange(null);
        } else {
          onChange(testruns[0]?.id);
        }
      }
    }, [value, projectId, environmentId, branch]);

    const options = testruns.map(run => {
      const label = `${run.project.name} - ${run.createdAt}`;
      return { label: label, value: run.id, projectName: run.project.name, testRunId: run.id };
    });
    const selectedTestRun = TestRunStore.getTestRun(value);
    return (
      <Select
        className="testrun-select"
        placeholder={placeholder || ""}
        value={selectedTestRun ? {
          label: `${selectedTestRun.project.name} - ${selectedTestRun.createdAt}`,
          value: selectedTestRun.id,
          projectName: selectedTestRun.project.name,
          testRunId: selectedTestRun.id,
        } : null
      }
        isClearable={!!placeholder && !!value}
        isSearchable={true}
        onChange={(option) => onChange(option && option.testRunId)}
        options={options}
        valueField="testRunId"
        formatOption={(option) =>
            option.label
        }
        autoDefault={!placeholder}
        itemRef={itemRef}
        error={error}
        label={label}
      />
    );
  },
);

export default TestRunSelect;
