import { orderBy } from "lodash";
import { observer } from "mobx-react-lite";
import { TestPlan } from "models/test-plan";
import { Card } from "semantic-ui-react";
import StageDraggable from "./stage-draggable";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { TestPlanStore } from "stores";
import "./test-plan.scss";
import { substringAfterLast } from "core/utils";

interface Props {
  testPlan: TestPlan;
}

function dragEnd(data: DropResult) {
  if (data.reason === "DROP" && data.destination && data.destination.droppableId) {
    substringAfterLast;
    const id = parseInt(substringAfterLast(data.draggableId, "-"));
    const newIndex = data.destination.index;

    if (data.type === "stage") {
      TestPlanStore.saveTestPlanStageOrder(id, newIndex);
    } else {
      const sourceStageId = parseInt(substringAfterLast(data.source.droppableId, "-"));
      const destinationStageId = parseInt(substringAfterLast(data.destination.droppableId, "-"));
      TestPlanStore.saveTestPlanSuiteOrder(sourceStageId, id, newIndex, destinationStageId);
    }
  }
}

const Stages = observer(({ testPlan }: Props) => {
  // we need this so it will rerender the stage list when it gets changed
  testPlan.activeStages;

  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Card.Group className="stages">
        <Droppable droppableId="-1" type="stage">
          {(provided) => (
            <div className="drop-container" ref={provided.innerRef}>
              {orderBy(testPlan.activeStages, "order").map((s, index) => (
                <StageDraggable key={s.id} stage={s} index={index} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Card.Group>
    </DragDropContext>
  );
});

export default Stages;
