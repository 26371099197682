import Form from "./form";
import { observable, action } from "mobx";
import { notEmpty } from "core/validation";

export default class UserInfoForm extends Form {
  @observable firstName: string;
  @observable lastName: string;

  validators = {
    firstName: notEmpty,
    lastName: notEmpty,
  };

  @action.bound
  setDefaults() {
    this.firstName = "";
    this.lastName = "";
  }
}
