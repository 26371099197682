import Spinner from "components/spinner";
import { useInitialEffect } from "core/react-utils";
import { delay, getUrlParam } from "core/utils";
import { observer } from "mobx-react-lite";
import Page from "pages/page";
import { useState } from "react";
import { useHistory } from "react-router";
import { SettingsStore, AppStore } from "stores";
import { popAuthInfo } from "core/storage";
import { Button } from "semantic-ui-react";
import { AuthorizationProvider } from "models/settings";

async function checkForAuthorizations(
  rerunCount: number,
  setError: (e: boolean) => any,
  navigate: () => any,
) {
  const auth = (await SettingsStore.loadAuthorizations()).find(
    (a) => a.provider == AuthorizationProvider.GitHub,
  );

  if (auth) {
    navigate();
  } else if (rerunCount == 0) {
    setError(true);
  } else {
    await delay(1000);
    checkForAuthorizations(rerunCount - 1, setError, navigate);
  }
}

const GitHubPage = observer(() => {
  const installationId = getUrlParam("installation_id");
  const [error, setError] = useState(false);
  const history = useHistory();
  const navigate = () => history.push(popAuthInfo()?.url || "/");

  useInitialEffect(async () => {
    if (installationId) {
      await SettingsStore.addGitHubInstallation(parseInt(installationId));
      checkForAuthorizations(20, setError, navigate);
    } else {
      history.push(`${AppStore.account.name}/settings/integrations`);
    }
  });

  return (
    <Page title="GitHub Authorization" name="github" titleSize="none">
      {!error && <Spinner text="Finishing Authorization" size={24} />}
      {error && (
        <div>
          <h3>Authorization Not Completed</h3>
          <p>
            If you are not a GitHub admin in your organization you will have to wait for admin
            approval before this can be fully authorized.
          </p>
          <Button color="green" onClick={navigate}>
            Continue
          </Button>
        </div>
      )}
    </Page>
  );
});

export default GitHubPage;
