import { ReleaseStore, ModalStore } from "stores";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { ReleaseCriteriaTemplateForm } from "../../../models/forms/release-form";

const { releaseCriteriaTemplateModal } = ModalStore;
const form = new ReleaseCriteriaTemplateForm();

releaseCriteriaTemplateModal.onHidden = () => {
  form.clearFormData();
};
releaseCriteriaTemplateModal.beforeShown = () => {
  form.populate(releaseCriteriaTemplateModal.state);
};

const save = async () => {
  await form.submit(async () => {
    if (form.id && form.id != 0) {
      await ReleaseStore.updateReleaseCriteriaTemplate(form);
    } else {
      await ReleaseStore.createReleaseCriteriaTemplate({ text: form.text });
    }
    await ReleaseStore.loadReleaseCriteriaTemplates();
    releaseCriteriaTemplateModal.hide();
  }, true);
};

const ReleaseCriteriaTemplateModal = observer(() => {
  return (
    <Modal size="small" open={releaseCriteriaTemplateModal.showing} id="release-criteria-template-modal">
      <Modal.Header>Release Criteria Template</Modal.Header>
      <Modal.Content>
        <Form.Input placeholder={"Criteria Template Text"}
                    value={form.text}
                    onChange={form.onChange("text")}
                    error={form.getErrorText("text")} />
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={form.isSubmitting} onClick={() => releaseCriteriaTemplateModal.hide()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button loading={form.isSubmitting} color="green" onClick={save}>
          <Icon name="save" />
          Save Criteria Template
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default ReleaseCriteriaTemplateModal;
