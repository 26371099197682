import IconButton from "components/icon-button";
import { Icons } from "core/constants";
import { observer } from "mobx-react-lite";
import { TestPlanSuite } from "models/test-plan";
import { Card, Icon } from "semantic-ui-react";
import { ModalStore, TestPlanStore } from "stores";

async function deleteSuite(suite: TestPlanSuite) {
  ModalStore.confirmation.show(
    async (okClicked: boolean) => {
      if (okClicked) {
        await TestPlanStore.deleteTestPlanSuite(suite.testPlanStageId, suite.id);
      }
    },
    {
      message: `Are you sure you want to delete this suite?`,
      okText: "Delete",
      okColor: "red",
    },
  );
}

interface Props {
  suite: TestPlanSuite;
}

const Suite = observer(({ suite }: Props) => (
  <Card className="suite">
    <Card.Content>
      <div className="right">
        <div className="details">
          <div>Env: {suite.environmentDisplay }</div>
          {suite.branch && <div>Branch: {suite.branch}</div>}
          <div>Git Ref: {suite.commit ? suite.commit.substring(0, 10) : "(latest commit)"}</div>
          {suite.buildId && <div>Build: {suite.build?.name}</div>}
        </div>
        <div>
          <IconButton icon={Icons.delete} onClick={() => deleteSuite(suite)} />
        </div>
      </div>
      <div className="left">
        <div className="name">
          <Icon name="bars" />
          {suite.suite?.name} ({suite.suite?.project?.name})
        </div>
      </div>
    </Card.Content>
  </Card>
));

export default Suite;
