import Select from "./select";
import { UserStore } from "../../stores";
import { userNameComparator } from "../../core/utils";

interface Props {
  value: number[];
  onChange: (id: number[]) => any;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
}

const TestRunReviewerSelect = ({ onChange, value, placeholder, error, label }: Props) => {
  const users = UserStore.usersForReviewFilter;
  const convertToString = (id: number) => users.find(u => u.id === id).fullName;
  const convertFromString = (s: string) => users.find(user => user.fullName === s).id;

  return (
    <Select
      className="test-run-reviewer-select"
      placeholder={placeholder || ""}
      isSearchable={true}
      onChangeMulti={(values) => onChange(values ? values.map(convertFromString) : null)}
      value={value ? value.map(convertToString) : null}
      isClearable={!!value && value.length > 0}
      options={users.sort((a, b) => userNameComparator(a, b)).map(user => user.fullName)}
      error={error}
      label={label}
      isMulti
    />
  );
};

export default TestRunReviewerSelect;
