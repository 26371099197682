import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { ReleaseStore } from "../../stores";
import { Release, ReleaseCriteria } from "../../models/release";

interface Props {
  release: Release;
  releaseCriteria: ReleaseCriteria;
  index: number;
}

const ReleaseCriteriaRow = (({ release, releaseCriteria, index }: Props) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [displayedReleaseCriteria, setDisplayedReleaseCriteria] = useState<ReleaseCriteria>(releaseCriteria);

  const updateItem = async () => {
    if (saving || release.isDecided)
      return;

    setSaving(true);
    setDisplayedReleaseCriteria(await ReleaseStore.toggleReleaseCriteria(releaseCriteria));
    setSaving(false);
    NotificationManager.success("Successfully updated release criteria!", null, 2000);
  };

  return (
    <div style={{display: "flex"}}>
      <div style={{paddingTop: "5px", paddingRight: "5px"}}>
      <input id={`release-criteria-${index}`} type={"checkbox"} disabled={saving || release.isDecided}
             defaultChecked={displayedReleaseCriteria.isCompleted} onClick={updateItem} />
        </div>
      <label htmlFor={`release-criteria-${index}`}>{displayedReleaseCriteria.text}</label>
    </div>
  );
});

export default ReleaseCriteriaRow;
