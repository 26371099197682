import { organizationUrl } from "core/utils";
import { TestSuite } from "models/project";
import { Link } from "react-router-dom";

interface Props {
  testSuite: TestSuite;
}

export default function TestSuiteLink({ testSuite }: Props) {
  if (!testSuite || !testSuite.project) return <span />;

  return (
    <div className="test-suite-link">
      <Link to={organizationUrl(`projects/${testSuite.project.key}/test-suites`)}>
        {testSuite.name}
      </Link>
    </div>
  );
}
