import { useUnloadEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import LoginForm from "models/forms/login-form";
import Page from "pages/page";
import { useState } from "react";
import { useHistory } from "react-router";
import { Button, Form, Segment } from "semantic-ui-react";
import { AppStore } from "stores";
import "./forgot-password.scss";

const form = new LoginForm();

const ForgotPassword = observer(() => {
  const [done, setDone] = useState(false);
  useUnloadEffect(() => form.clearFormData());
  const history = useHistory();

  const submit = async () => {
    await AppStore.forgotPassword(form);
    setDone(true);
  };

  return (
    <Page name="forgot-password" title="Forgot Password" noHeader titleSize="huge">
      <Segment placeholder>
        {done && (
          <div>
            <h3>Check your email for the link</h3>
            <br />
            <Button onClick={history.goBack}>Back to Login</Button>
          </div>
        )}
        {!done && (
          <div>
            <h3>
              Enter the email address on your account and we'll email you a link to reset your
              password
            </h3>
            <br />
            <Form>
              <Form.Input
                autoFocus
                placeholder="Email Address"
                value={form.email}
                onChange={form.onChange("email")}
                error={form.getErrorText("email")}
              />
              <div className="buttons">
                <Button color="green" loading={form.isSubmitting} onClick={submit}>
                  Submit
                </Button>
                <Button onClick={() => history.push("/login")}>Cancel</Button>
              </div>
            </Form>
          </div>
        )}
      </Segment>
    </Page>
  );
});

export default ForgotPassword;
