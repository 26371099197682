import Form from "./form";
import { action, observable } from "mobx";
import { notEmpty } from "core/validation";

export default class JiraIssueForm extends Form {
  @observable projectId: string;
  @observable projectName: string;
  @observable issueTypeId: string;
  @observable issueTypeName: string;
  @observable issuePriorityId: string;
  @observable issuePriorityName: string;
  @observable issueUserId: string;
  @observable issueUserName: string;
  @observable summary: string;
  @observable description?: string;
  @observable rootCauseId?: number;
  @observable testRunId: number;
  @observable testRunTestIds: Array<number>;

  validators = {
    projectId: [notEmpty],
    issueTypeId: [notEmpty],
    summary: [notEmpty],
    testRunTestIds: [notEmpty],
  };

  constructor() {
    super();
  }

  @action
  setDefaults() {
    this.projectId = null;
    this.issueTypeId = null;
    this.summary = null;
    this.description = null;
    this.rootCauseId = null;
    this.testRunId = null;
    this.testRunTestIds = [];
  }
}
