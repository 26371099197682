import { useEffect, useRef } from "react";
import { WidgetInstance } from "friendly-challenge";

interface Props {
  successCallback,
  errCallback
}

export const FriendlyCaptcha = ({ successCallback, errCallback }: Props) => {
  const container = useRef();
  const widget = useRef<WidgetInstance>();

  const doneCallback = (solution) => {
    successCallback(solution);
  };

  const errorCallback = (err) => {
    errCallback(err);
  };

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: "auto",
        doneCallback: doneCallback,
        errorCallback: errorCallback,
      });
    }

    return () => {
      if (widget.current != undefined) widget.current.reset();
    };
  }, [container]);

  return (
    <div ref={container} className="frc-captcha" data-sitekey="FCMU92SA30FNCTPK" />
  );
};
