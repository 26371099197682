import { ReactNode } from "react";
import { JsxElement } from "typescript";
import Select from "./select";

interface Props {
  value: boolean;
  onChange: (value: boolean) => any;
  error?: boolean | string;
  label?: string | JsxElement | ReactNode;
  inline?: boolean;
  allowEmpty?: boolean;
  yesText?: string;
  noText?: string;
}

const YesNoSelect = ({
  onChange,
  value,
  error,
  label,
  inline,
  allowEmpty,
  yesText,
  noText,
}: Props) => {
  const yesValue = yesText || "Yes";
  const noValue = noText || "No";

  return (
    <Select
      className="yes-no-select"
      onChange={(e) => onChange(e == yesValue)}
      value={value ? yesValue : value == null && allowEmpty ? null : noValue}
      options={[yesValue, noValue]}
      error={error}
      label={label}
      inline={inline}
    />
  );
};

export default YesNoSelect;
