import Select from "./select";
import { RunnerPoolStore } from "stores";
import { observer } from "mobx-react-lite";
import { stringComparator } from "../../core/utils";

interface Props {
  value: number;
  onChange: (id: number) => any;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
  runnerType?: string;
}

const RunnerPoolSelect = observer(
  ({ onChange, value, placeholder, error, label, runnerType }: Props) => {
    const pools = [...RunnerPoolStore.items].filter((p) => p.runnerType == runnerType)

    const options = pools.sort((a, b) => stringComparator(a.name, b.name)).map(pipelineStage => {
      return { label: pipelineStage.name, value: pipelineStage.name, runnerPoolId: pipelineStage.id };
    });

    const pipelineStage = pools.find((e) => e.id == value);
    if (pools.length == 0) {
      return null;
    }
    
    return (
      RunnerPoolStore.hasItems && (
        <Select
          className="runner-pools-select"
          placeholder={placeholder}
          isSearchable={true}
          onChange={(option) => onChange(option && option.runnerPoolId)}
          autoDefault={!placeholder}
          isClearable={!!placeholder && !!value}
          value={pipelineStage ? {
            label: pipelineStage.name,
            value: pipelineStage.name,
            runnerPoolId: pipelineStage.id,
          } : null
          }
          options={options}
          formatOption={(option) => option.label}
          valueField="runnerPoolId"
          labelField="name"
          error={error}
          label={label}
        />
      )
    );
  },
);

export default RunnerPoolSelect;
