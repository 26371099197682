import { cookies, local, options, session } from "brownies";
import { AuthReason } from "models/settings";

cookies[options] = {
  expires: 0,
  domain: window.location.host,
  secure: !window.location.host.includes("localhost") && !window.location.host.includes("127.0.0.1"),
  httpOnly: !window.location.host.includes("localhost") && !window.location.host.includes("127.0.0.1"),
};

export function setUserKey(userKey: string) {
  if (!userKey) {
    delete local.login;
    delete cookies.isLoggedIn;
  } else {
    local.login = userKey;
    cookies.isLoggedIn = true;
  }
}

export function getUserKey(): string {
  return cookies.isLoggedIn ? local.login : null;
}

export function setAccountId(accountId: number) {
  local.accountId = accountId;
}

export function getAccountId() {
  return local.accountId;
}

export function setSlackMemberId(slackMemberId: string | null) {
  local.slackMemberId = slackMemberId;
}

export function getSlackMemberId() {
  return local.slackMemberId;
}

export function setAuthReturnUrl(url: string, reason: AuthReason) {
  session.authReturnUrl = url;
  session.authReason = reason && reason.toString();
}

export function setAuthInfo(url: string, reason: AuthReason) {
  session.authReturnUrl = url;
  session.authReason = reason && reason.toString();
}

export function getAuthReason() {
  return AuthReason[session.authReason];
}

export function popAuthInfo() {
  const url = session.authReturnUrl;
  const reason = getAuthReason();
  setAuthInfo(null, null);
  return { url, reason };
}

export function getSelectedPlanTier(): number {
  return Number(local.planTier);
}

export function setSelectedPlanTier(plan: string) {
  local.planTier = plan;
}

export function popSelectedPlanTier(): number {
  const planTier = local.planTier;
  delete local.planTier;
  return planTier;
}

export function getLoginProvider() {
  return session.loginProvider;
}

export function setLoginProvider(provider: string) {
  session.loginProvider = provider;
}

export function setInviteToken(token: string) {
  session.inviteToken = token;
}

export function setInviteEmail(email: string) {
  session.inviteEmail = email;
}

export function clearInviteData() {
  session.inviteToken = null;
  session.inviteEmail = null;
}

export function getInviteToken(): string {
  return session.inviteToken;
}

export function setPromoCode(promoCode?: string) {
  return session.promoCode = promoCode;
}

export function deletePromoCode() {
  delete session.promoCode;
}

export function getPromoCode(): string {
  return session.promoCode;
}

export function getInviteEmail(): string {
  return session.inviteEmail;
}

export function setFirstTimeSetup(value: boolean) {
  session.firstTimeSetup = value;
}

export function isFrameworkSelectedInSetup() {
  return !!session.frameworkSelectedInSetup;
}

export function setFrameworkSelectedInSetup(value: boolean) {
  session.frameworkSelectedInSetup = value;
}

export function isFirstTimeSetup() {
  return !!session.firstTimeSetup;
}

export const testCodeRepoOption = "In a repository";
export const testCodeUploadOption = "I will upload my test code";
export const testCodeNoCodeOption = "I don’t have test code yet";

export function getTestCodeOption(): string {
  return session.testCodeOption;
}

export function setTestCodeOption(value: string) {
  session.testCodeOption = value;
}

export function isLeftNavCollapsed() {
  return !!local.leftNavCollapsed;
}

export function setLeftNavCollapsed(value: boolean) {
  local.leftNavCollapsed = value;
}

export function setTestRunsTabFilters(params: string) {
  local.testRunsTabFilters = params;
}

export function getTestRunsTabFilters(): string {
  return local.testRunsTabFilters;
}

export function clearTestRunsTabFilters() {
  delete local.testRunsTabFilters;
}
