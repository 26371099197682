import { useState } from "react";
import { useInitialEffect } from "core/react-utils";
import { AppStore } from "stores";
import { Divider, Grid, Segment } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import Page from "pages/page";
import { observer } from "mobx-react-lite";
import Spinner from "components/spinner";
import { Invitation } from "models/user";
import { useHistory } from "react-router";

const AcceptPage = observer(() => {
  const [badToken, setBadToken] = useState(false);
  const [invitation, setInvitation] = useState(null as Invitation);
  const history = useHistory();

  useInitialEffect(() => {
    AppStore.loadInvitation().then((invite) => {
      if (invite) {
        setInvitation(invite);
      } else {
        setBadToken(true);
      }
    });
  });

  return (
    <Page name="login" title="Login" noHeader={true} titleSize="none">
      {badToken && (
        <Segment placeholder style={{ width: "800px" }}>
          <h2>
            Your token is no longer valid!
            <br />
            <br />
            Please ask to be invited to the organization again.
          </h2>
        </Segment>
      )}
      {!invitation && !badToken && <Spinner size={20} />}
      {invitation && (
        <div>
          <h2>To accept your invitation to {invitation.accountName}</h2>
          <Segment placeholder style={{ width: "800px" }}>
            <Grid columns={2} relaxed="very" stackable>
              <Grid.Column verticalAlign="middle">
                <Button
                  size="large"
                  style={{ maxWidth: "300px" }}
                  color="green"
                  onClick={() => history.push("login")}
                >
                  Log into an Existing Account
                </Button>
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Button color="green" onClick={() => history.push("create-account")} size="large">
                  Create a New Account
                </Button>
              </Grid.Column>
            </Grid>
            <Divider vertical content="or" />
          </Segment>
        </div>
      )}
    </Page>
  );
});

export default AcceptPage;
