import { useState } from "react";
import { useParams } from "react-router";
import { useInitialEffect } from "core/react-utils";
import { TestRunStore } from "stores";
import { TestResult, TestStatus } from "models/test-run";
import Page from "pages/page";
import TestResultDisplay from "components/test-result";

let doRefresh = false;

async function load(
  testRunId: number,
  testRunTestId: number,
  setTestResult: (result: TestResult) => any,
) {
  const result = await TestRunStore.getTestResult(testRunId, testRunTestId);
  setTestResult(result);
  return result;
}

async function refresh(
  testRunId: number,
  testRunTestId: number,
  setTestResult: (result: TestResult) => any,
) {
  const result = await load(testRunId, testRunTestId, setTestResult);

  let spinner = document.getElementById("loading-spinner");
  if (spinner) {
    spinner.scrollIntoView();
  } else {
    window.scrollTo(0, 0);
  }

  if (!doRefresh) return;

  if (result.status == TestStatus.RUNNING) {
    let pollDelay = 5 * 1000; // 5s
    setTimeout(() => refresh(testRunId, testRunTestId, setTestResult), pollDelay);
  }
}

const TestResultPage = () => {
  const { testRunId, testId } = useParams<{ testRunId: string; testId: string }>();
  const testRunTestId = parseInt(testId);
  const testRunIdn = parseInt(testRunId);
  const [testResult, setTestResult] = useState(null as TestResult);

  useInitialEffect(() => {
    load(testRunIdn, testRunTestId, setTestResult).then((result) => {
      if (result.status == TestStatus.RUNNING) {
        doRefresh = true;
        refresh(testRunIdn, testRunTestId, setTestResult);
      }
    });

    return () => {
      doRefresh = false;
    };
  });

  return (
    <Page name="test-results" title="Test Results" titleSize="none">
      <TestResultDisplay testResult={testResult} />
    </Page>
  );
};

export default TestResultPage;
