import { observer } from "mobx-react-lite";
import { ModalStore, TestPlanStore } from "stores";
import { Modal, Button, Icon, Form, Grid } from "semantic-ui-react";
import "./test-plan-stage-edit.scss";
import TestPlanStageExecutionTypeSelect from "components/form/test-plan-stage-execution-type-select";
import YesNoSelect from "components/form/yes-no-select";
import TestPlanStageForm from "models/forms/test-plan-stage-form";

const form = new TestPlanStageForm();
const { testPlanStageEdit } = ModalStore;

async function saveTestPlanStage() {
  if (!form.id) {
    const testPlan = TestPlanStore.find(form.testPlanId);
    form.updateField("order", testPlan.activeStages.length);
  }
  await TestPlanStore.saveTestPlanStage(form);
  testPlanStageEdit.hide();
}

testPlanStageEdit.onHidden = form.clearFormData;
testPlanStageEdit.beforeShown = () => {
  form.populate(testPlanStageEdit.state);
};

const TestPlanStageEdit = observer(() => {
  return (
    <Modal open={testPlanStageEdit.showing} size="small">
      <Modal.Header>{testPlanStageEdit.state.id ? "Edit" : "New"} Test Plan Stage</Modal.Header>
      <Modal.Content>
        <Form className="test-plan-stage-edit">
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Form.Input
                  label="Stage Name"
                  value={form.name}
                  onChange={form.onChange("name")}
                  error={form.getErrorText("name")}
                />
                <TestPlanStageExecutionTypeSelect
                  label="Execution Type"
                  value={form.executionType}
                  onChange={form.onChange("executionType")}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.TextArea
                  label="Description"
                  value={form.description}
                  onChange={form.onChange("description")}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <YesNoSelect
                  label="Always Run This Stage"
                  value={form.alwaysRun}
                  onChange={form.onChange("alwaysRun")}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={() => testPlanStageEdit.hide()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button onClick={saveTestPlanStage} loading={form.isSubmitting} color="green" type="submit">
          <Icon name="check" />
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default TestPlanStageEdit;
