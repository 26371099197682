import { Form } from "semantic-ui-react";

interface Props {
  min: number;
  max: number;
  value: number;
  error?: boolean | string;
  label?: string;
  onChange: (value: number) => any;
  onBlur?: () => any;
}

const NumberInput = ({ onChange, onBlur, value, min, max, error, label }: Props) => {
  if (value > max) onChange(max);
  if (value < min) onChange(min);

  const maxChars = max.toString().length;

  return (
    <Form.Input
      className={`number-input input-${maxChars}-chars`}
      onChange={(v) => onChange(parseInt(v.target.value.replace(/\D/g, "")))}
      value={value ? value.toString() : ""}
      error={error}
      label={label}
      onBlur={onBlur}
    />
  );
};

export default NumberInput;
