import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import Project, { ProjectTest } from "models/project";
import { useState } from "react";
import { ProjectStore } from "stores";
import TesteryTable from "../../components/testery-table";
import { Button, Icon } from "semantic-ui-react";

interface Props {
  project: Project;
}

const QuarantinedTestsTab = observer(({ project }: Props) => {
  const [quarantinedTests, setQuarantinedTests] = useState<Array<ProjectTest>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useInitialEffect(async () => {
    await loadQuarantinedTests();
  });

  async function loadQuarantinedTests() {
    setQuarantinedTests(await ProjectStore.loadQuarantinedTests(project.id));
    setLoading(false);
  }

  async function handleDequarantine(projectTestId: number) {
    setLoading(true);
    await ProjectStore.dequarantineTest(project.id, projectTestId);
    await loadQuarantinedTests();
  }

  const actionButtons = [];
  quarantinedTests.forEach(projectTest => actionButtons.push(
    <Button onClick={async () => await handleDequarantine(projectTest.id)} primary={true} loading={loading}
            disabled={loading}><Icon name={"first aid"} />
      Remove Quarantine
    </Button>));

  if (quarantinedTests.length == 0)
    return <div>No tests are currently quarantined.</div>

  return (
    <div className="quarantined-tests-tab">
      <TesteryTable tableHeaders={["Test File", "Test Name", "Quarantined At"]}
                    objectFieldsToUse={["file", "name", "quarantinedAt"]}
                    actionsColumn={actionButtons} dateTimeColumns={[2]}
                    tableData={quarantinedTests} />
    </div>
  );
});

export default QuarantinedTestsTab;
