import { observer } from "mobx-react-lite";
import { useInitialEffect } from "core/react-utils";
import { ProjectStore, SettingsStore, PipelineStore } from "stores";
import TimeframeSelect from "components/form/timeframe-select";
import TimeframeFrequencySelect from "components/form/timeframe-frequency-select";
import ProjectSelect from "components/form/project-select";
import EnvironmentSelect from "components/form/environment-select";
import BranchSelect from "components/form/branch-select";
import { format } from "date-fns";
import Spinner from "components/spinner";
import { ChartData } from "stores/analytics-store";
import { useEffect, useState } from "react";
import PipelineStageSelect from "components/form/pipeline-stage-select";
import TestRunSelect from "components/form/testrun-select";

interface Props {
  drawChart: (elementId: string, labels: string[]) => any;
  header: () => JSX.Element;
  chartData: ChartData<any>;
  filterList?: Array<String>;
  testRunComparison?: boolean;
}

const ChartTab = observer(({ drawChart, header, chartData, filterList }: Props) => {
  const [showData, setShowData] = useState(false);
  const form = chartData.options;
  const filters = filterList || ["timeframe", "frequency", "pipeline", "environment", "project", "branch"]

  useInitialEffect(() => {
    SettingsStore.ensureEnvironments();
    PipelineStore.ensureLoaded();
    ProjectStore.ensureLoaded();
  });

  useEffect(() => {
    const dates = form.dates;
    drawChart(
      "analytics-test-runs-chart-canvas",
      dates.map((d) => format(d, form.isHourly ? "h a" : form.isMonthly ? "MMMM" : "MM/dd/yy")),
    );
    setShowData(chartData.loaded);
  }, [chartData.loaded, chartData.data, form.frequency]);

  const project = ProjectStore.find(form.projectId);

  return (
    <div id="test-runs-tab">
      <div className="list-filters no-offset">
        {filters.indexOf("timeframe") >= 0 &&
        <TimeframeSelect value={form.timeframe} onChange={(f) => form.updateTimeframe(f)} />
        }
        {filters.indexOf("frequency")>= 0 &&
        <TimeframeFrequencySelect
          value={form.frequency}
          onChange={form.onChange("frequency")}
          hideHourly={!form.allowHourly}
        />
        }
        {filters.indexOf("pipeline") >= 0 &&
        <PipelineStageSelect
          placeholder="Any Pipeline Stage"
          value={form.pipelineStageId}
          onChange={form.onChange("pipelineStageId")}
        />
        }
        {filters.indexOf("environment") >= 0 &&
        <EnvironmentSelect
          placeholder="Any Environment"
          value={form.environmentId}
          onChange={form.onChange("environmentId")}
          pipelineStageId={form.pipelineStageId}
        />
        }
        {filters.indexOf("project") >= 0 &&
        <ProjectSelect
          placeholder="Any Project"
          value={form.projectId}
          onChange={form.onChange("projectId")}
        />
        }
        {(filters.indexOf("branch") >= 0 && project) && (
          <BranchSelect
            hideIfEmpty
            placeholder="Any Branch"
            provider={project.gitProvider}
            owner={project.gitOwner}
            repo={project.gitRepo}
            value={form.branch}
            onChange={form.onChange("branch")}
          />
        )}
         {filters.indexOf("testRuns") >= 0 &&
          <div>
            <TestRunSelect
              placeholder="Test Run 1"
              value={form.testRunOneId}
              onChange={form.onChange("testRunOneId")}
              projectId={form.projectId}
              environmentId={form.environmentId}
              branch={form.branch}
            />
            <TestRunSelect
              placeholder="Test Run 2"
              value={form.testRunTwoId}
              onChange={form.onChange("testRunTwoId")}
              projectId={form.projectId}
              environmentId={form.environmentId}
              branch={form.branch}
            />
          </div>
        }
      </div>
      <br />
      {!showData && (
        <div className="loading">
          <Spinner size={30} />
        </div>
      )}
      <div className={`${showData ? "" : "hide"}`}>
        {header()}
        <div className="chart">
          <canvas id="analytics-test-runs-chart-canvas"></canvas>
        </div>
      </div>
    </div>
  );
});

export default ChartTab;
