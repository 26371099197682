import Select from "./select";
import { TestRunStatus } from "models/test-run";

interface Props {
  value: TestRunStatus[];
  onChange: (value: TestRunStatus[]) => any;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
}

const TestRunMultiStatusSelect = ({ onChange, value, placeholder, error, label }: Props) => {
  const convertToString = (s: TestRunStatus) => s.toString().replace("_", " ");
  const convertFromString = (s: string) => TestRunStatus[s.replace(" ", "_")];

  return (
    <Select
      className="test-run-multi-status-select"
      placeholder={placeholder || ""}
      onChangeMulti={(v) => onChange(v ? v.map(convertFromString) : null)}
      value={value ? value.map(convertToString) : null}
      isClearable={!!value && value.length > 0}
      options={Object.keys(TestRunStatus).map(convertToString)}
      error={error}
      label={label}
      isMulti
    />
  );
};

export default TestRunMultiStatusSelect;
