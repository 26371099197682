import Select from "./select";

interface Props {
  paralelizeByFile: boolean;
  onChange: (value: boolean) => any;
  error?: boolean | string;
  label?: string;
  inline?: boolean;
  allowEmpty?: boolean;
}

const byFile = "Parallelize by file/feature";
const byTest = "Parallelize by test/scenario";

const ParallelizationSelect = ({
  onChange,
  paralelizeByFile,
  error,
  label,
  inline,
  allowEmpty,
}: Props) => (
  <Select
    className="parallelization-select"
    onChange={(e) => onChange(e === null ? null : e == byFile)}
    value={paralelizeByFile ? byFile : paralelizeByFile == null && allowEmpty ? null : byTest}
    options={[byFile, byTest]}
    error={error}
    label={label}
    inline={inline}
    isClearable={allowEmpty}
  />
);

export default ParallelizationSelect;
