import { createRef, ReactNode, RefObject, useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { isArray, isString, toString } from "lodash";
import { findClosestElement } from "core/utils";
import { useInitialEffect } from "core/react-utils";
import { Label } from "semantic-ui-react";
import { JsxElement } from "typescript";

interface Props<ObjectType> {
  options: ObjectType[];
  value: ObjectType | ObjectType[];
  onChange?: (value: ObjectType) => any;
  onChangeMulti?: (value: ObjectType[]) => any;
  label?: string | JsxElement | ReactNode;
  error?: string | boolean;
  inline?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  isSearchable?: boolean;
  isLoading?: boolean;
  menuPortalTarget?: any;
  menuPlacement?: "auto" | "top" | "bottom";
  usePortal?: boolean;
  isClearable?: boolean;
  isCreatable?: boolean;
  noDropdownIndicator?: boolean;
  noDropdown?: boolean;
  valueField?: string;
  labelField?: string;
  createOptionText?: string;
  autoDefault?: boolean;
  className?: string;
  formatOption?: (value: ObjectType) => ReactNode | string;
  itemRef?: RefObject<HTMLElement>;
  disabled?: boolean;
}

function Select<ObjectType>({
  options,
  value,
  onChange,
  label,
  error,
  inline,
  onChangeMulti,
  placeholder,
  isMulti,
  isSearchable,
  isLoading,
  menuPlacement,
  usePortal,
  isClearable,
  isCreatable,
  noDropdown,
  noDropdownIndicator,
  formatOption,
  valueField,
  labelField,
  createOptionText,
  autoDefault,
  className,
  itemRef,
  disabled
}: Props<ObjectType>) {
  const ref = itemRef || createRef();
  const [portal, setPortal] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (ref.current) {
      const modalPortal = findClosestElement(
        ReactDOM.findDOMNode(ref.current as any),
        ".ReactModalPortal",
      );
      if (modalPortal) {
        setPortal(modalPortal);
      }
    }
  }, [ref]);

  useInitialEffect(() => {
    if (usePortal) {
      setPortal(document.body);
    }
  });

  const defaultFormatter = (v: ObjectType) => toString(v[labelField || "label"]);
  const valueResolver = (v: ObjectType) => toString(v[valueField || "value"]);
  const handleInputChange = (value: string) => setInputValue(value);

  let components = {};

  if (noDropdownIndicator) {
    components["DropdownIndicator"] = null;
  }

  const Component = isCreatable ? CreatableSelect : ReactSelect;
  const isStringOptions = options && options.length > 0 && options[0] && isString(options[0]);

  let convertedOptions: any[] = options;

  if (options && options.length > 0 && options[0]) {
    if (isStringOptions) {
      convertedOptions = options.map((o) => {
        return { label: o, value: o };
      });
    } else {
      if (!labelField && !formatOption && !options[0]["label"])
        console.error(
          "You must specify a labelField for your option object or give a formatOption function",
        );
      if (!valueField && !options[0]["value"])
        console.error("You must specify a valueField for your option object");
    }
  }

  const ensureValueOptions = (v) =>
    isArray(v) ? (!v ? [] : v.map(ensureValueOption)) : ensureValueOption(v);
  const ensureValueOption = (v) =>
    isString(v) ? (({ label: v, value: v } as any) as ObjectType) : (v as ObjectType);

  const addItem = (inputValue: string) => {
    if (!inputValue || inputValue == "") return;
    const optionValue = (inputValue as any) as ObjectType;

    setInputValue("");

    if (isMulti) {
      onChangeMulti && onChangeMulti([...(value as ObjectType[]), optionValue]);
    } else {
      onChange && onChange(optionValue);
    }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "Enter":
      case "Tab":
        event.stopPropagation();
        event.preventDefault();
        addItem(inputValue);
    }
  };

  const convertChangeValue = isStringOptions || isCreatable;

  const handleChange = (e) => {
    if (isMulti && onChangeMulti) {
      onChangeMulti((convertChangeValue ? e && e.map((v) => v.value) : e) || []);
    } else if (onChange) {
      onChange(convertChangeValue ? e && e.value : e);
    }
  };

  useInitialEffect(() => {
    if (autoDefault && !isMulti && options && options.length > 0) {
      const optionValue = value && valueResolver(ensureValueOption(value));
      if (!optionValue || !convertedOptions.find((o) => valueResolver(o) == optionValue)) {
        options.length > 0 && onChange(options[0]);
      }
    }
  });

  const onBlur = () => {
    if (isCreatable) {
      addItem(inputValue);
    }
  };

  const fullClassName = `react-select${className ? " " + className : ""}`;

  let field: ReactSelect;

  if (isLoading) {
    field = (
      <ReactSelect
        key="select-loading"
        className={fullClassName}
        isLoading={true}
        inputValue=""
        placeholder="Loading..."
        components={components}
        menuIsOpen={noDropdown ? false : undefined}
      />
    );
  } else {
    field = (
      <Component
        isDisabled={disabled ? disabled : false}
        key="select-loaded"
        ref={ref}
        className={"react-select"}
        menuPosition={portal ? "fixed" : "absolute"}
        placeholder={placeholder}
        value={ensureValueOptions(value)}
        isSearchable={isCreatable || !!isSearchable}
        menuPortalTarget={portal}
        menuPlacement={menuPlacement || "auto"}
        isClearable={isClearable}
        onChange={handleChange}
        onInputChange={isCreatable && handleInputChange}
        onKeyDown={isCreatable && noDropdown && handleKeyDown}
        inputValue={isCreatable && noDropdown && inputValue}
        isMulti={isMulti}
        components={components}
        options={convertedOptions}
        menuIsOpen={noDropdown ? false : undefined}
        formatOptionLabel={formatOption || defaultFormatter}
        getOptionValue={valueResolver}
        formatCreateLabel={(v) => `${createOptionText || "Add"} "${v}"`}
        onBlur={onBlur}
      />
    );
  }

  return (
    <div
      className={
        "field " +
        (className ? " " + className : "") +
        (error ? " error" : "") +
        (inline ? " inline" : "")
      }
    >
      {label && <label>{label}</label>}
      {field}
      {error && isString(error) && (
        <Label pointing prompt>
          {error}
        </Label>
      )}
    </div>
  );
}

export default Select;
