import Form from "./form";
import { action, observable } from "mobx";
import { validUrl } from "core/validation";

export default class MicrosoftTeamsForm extends Form {
  @observable webhookUrl: string;
  @observable showing: boolean;

  validators = {
    webhookUrl: { validator: validUrl, message: "Invalid URL" },
  };

  @action
  setDefaults() {
    this.webhookUrl = "";
    this.showing = false;
  }
}
