import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { observer } from "mobx-react-lite";
import { PipelineStage } from "models/settings";
import { Card, CardContent, Icon } from "semantic-ui-react";
import { ModalStore, PipelineStore } from "stores";
import "./pipeline-stages.scss";
import { useHistory } from "react-router";
import ThreeDotMenu from "components/three-dot-menu";
import { Icons } from "core/constants";
import { organizationUrl } from "core/utils";




interface StageProps {
  stage: PipelineStage;
  index: number;
  pushHistory: (h: string) => any;
}

function dragEnd(data: DropResult) {
  if (data.reason === "DROP" && data.destination && data.destination.droppableId) {
    const stageId = parseInt(data.draggableId);
    const newIndex = data.destination.index;

    PipelineStore.updatePriority(stageId, newIndex);
  }
}

function deleteStage(stage: PipelineStage) {
  ModalStore.confirmation.show(
    async (okClicked: boolean) => {
      if (okClicked) {
        await PipelineStore.delete(stage.id);
      }
    },
    {
      message: `Are you sure you want to delete the pipeline stage ${stage.name}?`,
      okText: "Delete",
      okColor: "red",
    },
  );
}

const PipelineStageRow = observer(({ stage, index, pushHistory }: StageProps) => (
  <Draggable draggableId={stage.id.toString()} index={index}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={provided.draggableProps.style}
      >
        <Card className="pipeline-stage">
          <CardContent>
            <div className="bars">
              <Icon name="bars" />
            </div>
            <div className="name">{stage.name}</div>
            <div className="priority">{stage.testRunPriority}</div>
            <div className="actions">
              <ThreeDotMenu
                items={[
                  {
                    icon: Icons.edit,
                    text: "Edit Stage",
                    onClick: () => pushHistory(organizationUrl(`environments/pipelines/${stage.id}`)),
                  },
                  { icon: Icons.delete, text: "Delete Stage", onClick: () => deleteStage(stage) },
                  { icon: Icons.stethoscope, text: "View Audit Log", onClick: () => pushHistory(organizationUrl(`environments/pipelines/${stage.id}/audit-log`)),}
                ]}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    )}
  </Draggable>
));

const PipelineStageList = observer(() => {
  const history = useHistory();

  return (
    <div>
      {PipelineStore.sortedItems.map((p, index) => (
        <PipelineStageRow key={p.id} stage={p} index={index} pushHistory={history.push} />
      ))}
    </div>
  );
});

const PipelineStages = observer(() => (
  <div className="pipeline-stages">
    <DragDropContext onDragEnd={dragEnd}>
      <div className="pipeline-stage-header">
        <div className="bars"></div>
        <div className="name">Stage Name</div>
        <div className="priority">Test Execution Priority</div>
        <div className="actions"></div>
      </div>
      <Droppable droppableId="1" type="group">
        {(provided) => (
          <div ref={provided.innerRef}>
            <PipelineStageList />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </div>
));

export default PipelineStages;
