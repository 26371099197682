import { ReleaseStore, ModalStore } from "stores";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import ReleaseForm from "../../../models/forms/release-form";
import { Release } from "../../../models/release";

const releaseForm = new ReleaseForm();

ModalStore.releaseModal.onShown = () => {
  releaseForm.clearFormData();
};

const NewReleaseModal = observer(() => {
  const { releaseModal } = ModalStore;

  const save = async () => {
    await releaseForm.submit(async (data) => {
      await ReleaseStore.createRelease({
        title: data.title,
        description: data.description,
        proposedReleaseDate: data.proposedReleaseDate,
      } as Release);
      await ReleaseStore.loadReleases(1);
      releaseModal.hide();
    }, true);
  };

  return (
    <Modal size="small" open={releaseModal.showing} id="new-release-modal">
      <Modal.Header>New Release</Modal.Header>
      <Modal.Content>
        <Form.Input placeholder={"Release Title"}
                    onChange={releaseForm.onChange("title")}
                    error={releaseForm.getErrorText("title")} />
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={releaseForm.isSubmitting} onClick={() => releaseModal.hide()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button loading={releaseForm.isSubmitting} color="green" onClick={save}>
          <Icon name="save" />
          Create Release
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default NewReleaseModal;
