import { setPageInfo } from "core/react-utils";
import { observer } from "mobx-react-lite";
import { Button, Icon, Tab } from "semantic-ui-react";
import { TestResult } from "../../models/test-run";
import { AppStore } from "../../stores";
import { useHistory } from "react-router";
import API from "../../core/api";
import { FeatureName } from "../../models/feature";
import "./videos-tab.scss";

interface Props {
  testResult: TestResult;
}

const VideosTab = observer(({ testResult }: Props) => {
  setPageInfo("testResult", "Videos");
  const history = useHistory();
  const account = AppStore.account;

  if (!account.hasFeature(FeatureName.SCREEN_RECORDING)) {
    return (
      <Tab.Pane>
        <div style={{ maxWidth: "500px", textAlign: "center" }}>
          <div style={{ marginBottom: "15px" }}>
            This feature is only available on paid plans. Upgrade to start watching your tests run!
          </div>
          <Button primary={true} onClick={async () => {
            await API.post("/sales-video-notification", {});
            history.push(`/${account.name}/settings/payment`);
          }}>
            View Plans & Pricing
          </Button>
        </div>
      </Tab.Pane>
    );
  }

  if (testResult.videos.length === 0) {
    return (<Tab.Pane>No videos for this test</Tab.Pane>);
  }

  return (
    <Tab.Pane>
      <div style={{ position: "relative" }}>
        {testResult.videos && testResult.videos.length > 0 && (
          <div>
            {testResult.videos.map((url, i) => (
              <div key={i} style={{ maxWidth: "750px" }}>
                <video style={{ maxWidth: "100%", height: "auto" }} controls>
                  <source src={url} type={"video/webm"} />
                </video>
              </div>
            ))}
          </div>
        )}
        {testResult.videos && testResult.videos.length > 0 && (
          <a className={"download-video-link"} href={testResult.videos[0]} download={true}><Icon
            name={"download"} /> Download Video</a>
        )}
      </div>
    </Tab.Pane>
  );
});

export default VideosTab;
