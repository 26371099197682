import { TestPlanStage } from "models/test-plan";
import { Draggable } from "react-beautiful-dnd";
import Stage from "./stage";

interface Props {
  stage: TestPlanStage;
  index: number;
}

const StageDraggable = ({ stage, index }: Props) => (
  <Draggable draggableId={`stage-${stage.id}`} index={index} type="stage">
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={provided.draggableProps.style}
      >
        <Stage stage={stage} />
      </div>
    )}
  </Draggable>
);

export default StageDraggable;
