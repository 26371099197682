import Project from "models/project";
import Select from "./select";
import { union } from "lodash";
import ProjectForm from "models/forms/project-form";
import { stringComparator } from "../../core/utils";

interface Props {
  project: Project | ProjectForm;
  value: string[];
  hideOptions?: boolean;
  onChange: (v: string[]) => any;
  error?: boolean | string;
  label?: string;
}

const TagSelect = ({ project, onChange, value, hideOptions, error, label }: Props) => {
  const tags = (project && project.testTags) || [];

  return (
    <Select
      className="tag-select"
      placeholder={hideOptions ? "Type a tag name and hit enter..." : "Select tag or type tag and hit enter..."}
      isCreatable={true}
      noDropdown={hideOptions}
      noDropdownIndicator={hideOptions}
      value={value}
      options={union(tags.sort((a, b) => stringComparator(a, b)), value)}
      isMulti={true}
      onChangeMulti={onChange}
      error={error}
      label={label}
    />
  );
};

export default TagSelect;
