import { useState } from "react";
import { Card } from "semantic-ui-react";

interface Props {
  closedText?: string;
  openText?: string;
  defaultOpen?: boolean;
  children: any;
}

const ShowAdvanced = ({ closedText, openText, defaultOpen, children }: Props) => {
  const [open, setOpen] = useState(defaultOpen === true);

  if (!open) {
    return (
      <div className="show-advanced">
        <a onClick={() => setOpen(true)}>{closedText || "Show Advanced Options"}</a>
      </div>
    );
  }

  return (
    <Card className="show-advanced" style={{ width: "100%" }}>
      <Card.Content>
        <a className="text" onClick={() => setOpen(false)}>
          {openText || "Hide Advanced Options"}
        </a>
        <div style={{ marginBottom: "20px" }} />
        {children}
      </Card.Content>
    </Card>
  );
};

export default ShowAdvanced;
