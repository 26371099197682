import { EmployeeStore } from "stores";
import "./employee.scss";
import { Button, Icon, Input, Tab } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import AccountSelect from "./account-select";
import DatePicker from "react-datepicker";
import DatetimeDisplay from "components/datetime-display";
import NumberInput from "components/form/number-input";
import Select from "components/form/select";
import SortableTable from "components/sortable-table";

const PerformanceTab = observer(() => {
  return (
    <Tab.Pane className="performance-tab">
      <div className="list-filters">
        <DatePicker
          isClearable
          selected={EmployeeStore.dateFilter}
          onChange={EmployeeStore.updateDateFilter}
          placeholderText="Any Date"
        />
        <AccountSelect
          value={EmployeeStore.accountFilter}
          onChange={EmployeeStore.updateAccountFilter}
          placeholder="All Accounts"
        />
        <Select
          className="monitor-type-select"
          placeholder="Any Monitor Type"
          value={EmployeeStore.monitorTypeFilter}
          onChange={EmployeeStore.updateMonitorTypeFilter}
          options={["Web", "Background", "Job", "MessageHandler"]}
          isClearable={!!EmployeeStore.monitorTypeFilter}
        />
        <Input
          className="name-filter"
          value={EmployeeStore.nameFilter}
          onChange={(e) => EmployeeStore.updateNameFilter(e.target.value)}
          onBlur={EmployeeStore.loadMonitorData}
          placeholder="Any Name"
        />
      </div>
      <div className="more-filters">
        <Button color="green" onClick={() => EmployeeStore.loadMonitorData()}>
          Refresh Data
        </Button>
        <NumberInput
          label="Minimum Duration"
          min={0}
          max={10000000}
          value={EmployeeStore.durationFilter}
          onChange={EmployeeStore.updateDurationFilter}
          onBlur={EmployeeStore.loadMonitorData}
        />
        <NumberInput
          label="Minimum DB Time"
          min={0}
          max={10000000}
          value={EmployeeStore.dbTimeFilter}
          onChange={EmployeeStore.updateDbTimeFilter}
          onBlur={EmployeeStore.loadMonitorData}
        />
      </div>
      <SortableTable
        headerColumns={[
          { content: "Time", sortKey: "startTime" },
          { content: "Type", sortKey: "monitorType" },
          { content: "Name", sortKey: "name" },
          { content: "Path", sortKey: "extra.path" },
          { content: "Duration", sortKey: "duration" },
          { content: "DB Requests", sortKey: "dbRequests" },
          { content: "DB Time", sortKey: "dbTime" },
          { content: "Account ID", sortKey: "extra.accountId" },
          { content: "User ID", sortKey: "extra.userId" },
          { content: "CLI", sortKey: "extra.CLI" },
        ]}
        items={EmployeeStore.monitorData}
        loadingText="Loading Performance Data"
        loading={EmployeeStore.monitorLoading}
        emptyMessage="No Performance Data Found"
        renderRow={(d) => (
          <tr key={d.start + d.name + d.duration}>
            <td>
              <DatetimeDisplay datetime={d.startTime} showSecond={true} />
            </td>
            <td>{d.monitorType}</td>
            <td>{d.name}</td>
            <td>{d.extra.path}</td>
            <td>{d.duration}</td>
            <td>{d.dbRequests}</td>
            <td>{d.dbTime}</td>
            <td>{d.extra.accountId}</td>
            <td>{d.extra.userId}</td>
            <td>{d.extra.CLI ? <Icon name="check" /> : null}</td>
          </tr>
        )}
        defaultSortKey="startTime"
        defaultDecending={true}
      />
    </Tab.Pane>
  );
});

export default PerformanceTab;
