import { action, observable, runInAction } from "mobx";
import API, { Response } from "core/api";
import { ReviewerRequest, ReviewStatus, ReviewStatusRequest } from "../models/review-data";

export class ReviewStoreClass {
  @observable reviewStatuses: Array<ReviewStatus> = [];
  @observable reviewStatusesLoaded = false;

  @action
  clear() {
    this.reviewStatusesLoaded = false;
    this.reviewStatuses = [];
  }

  @action
  async loadReviewStatuses(): Promise<Array<ReviewStatus>> {
    const { data } = await API.getList("/review/statuses", ReviewStatus);
    runInAction(() => {
      this.reviewStatusesLoaded = true;
      this.reviewStatuses = data;
    })
    return data;
  }

  @action
  async updateAssignedReviewer(reviewRequest: ReviewerRequest): Promise<Response<any>> {
    return await API.put("/review/user", reviewRequest);
  }

  @action
  async updateReviewStatus(reviewRequest: ReviewStatusRequest): Promise<Response<any>> {
    return await API.put("/review/status", reviewRequest);
  }

  getReviewStatusesForSelect(): Array<ReviewStatus> {
    return this.reviewStatuses;
  }
}
