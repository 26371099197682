import Form from "./form";
import { action, observable } from "mobx";
import { lengthAtLeast, notEmpty, validEmail } from "core/validation";
import { IS_PROD } from "../../core/constants";

export default class RegistrationForm extends Form {
  @observable firstName: string;
  @observable lastName: string;
  @observable email: string;
  @observable password: string;
  @observable passwordRepeat: string;
  @observable token: string;
  @observable captchaSolution: string;
  @observable promoCode?: String;

  validators = {
    firstName: notEmpty,
    lastName: notEmpty,
    email: validEmail,
    password: { validator: lengthAtLeast(8), message: "Password must be at least 8 characters" },
    passwordRepeat: {
      validator: (value: string) => {
        return this.password == value;
      },
      message: "Passwords must match",
    }
  };

  constructor() {
    super();

    if (IS_PROD)
      this.validators["captchaSolution"] = { validator: notEmpty, message: "Captcha has failed or has not yet completed." }
  }

  ignoreOnPostFields = ["passwordRepeat"];

  @action.bound
  setDefaults() {
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.password = "";
    this.passwordRepeat = "";
    this.token = null;
  }
}
