import DatetimeDisplay from "components/datetime-display";
import SortableTable from "components/sortable-table";
import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import Project, { Build } from "models/project";
import { useState } from "react";
import { BuildStore, ProjectStore } from "stores";
import { Button, Icon } from "semantic-ui-react";
import { useHistory } from "react-router";
import { relativeLink } from "core/utils";
import IconButton from "components/icon-button";
import { Dialog, DialogActions, DialogContent } from "@mui/material";



interface Props {
  project: Project;
}

const BuildsTab = observer(({ project }: Props) => {
  const history = useHistory();
  const [builds, setBuilds] = useState(null as Build[]);

  useInitialEffect(async () => {
    setBuilds(await ProjectStore.loadBuilds(project.id));
  });

  let headerColumns = [
    {
      className: "date",
      content: "Date",
      sortKey: "updatedAt",
    },
    {
      className: "build",
      content: "name",
      sortKey: "name",
    },
    {
      className: "branch",
      content: "Branch",
      sortKey: "branch",
    },
    {
      className: "download",
      content: "",
      sortKey: "downloadUrl"
    }
  ];

  const edit = (id: string | number) => history.push(relativeLink(`builds/${id}`));

  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  const [saveFileLink, setSaveFileLink] = useState("");

  const openDownloadDialog = () => {
    setOpen(true);
  };

  return (
    <>
    <Dialog open={open}>
        <DialogContent dividers>
          <p><a href={saveFileLink}>Click here to download your build</a></p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
    <div className="builds-tab">
      <Button color="green" onClick={() => edit("new")}>
        <Icon name="plus" />
        Add Build
      </Button>
      <br />
      <SortableTable
        className="deploys"
        loading={builds == null}
        headerColumns={headerColumns}
        items={builds}
        defaultSortKey="updatedAt"
        defaultDecending={true}
        renderRow={(d) => (
          <tr key={d.id}>
            <td className="date">
              <DatetimeDisplay datetime={d.updatedAt} />
            </td>
            <td className="name">{d.name}</td>            
            <td className="branch">{d.branch}</td>
            <td className="download">
              <IconButton
                icon={"download"}
              onClick={async () => {
                await BuildStore.download(d.projectId, d.id).then((res) => {
                    setSaveFileLink(res['url']);
                    openDownloadDialog()
                  });
              }}
              />
            </td>
          </tr>
        )}
      />
    </div>
    </>
  );
});

export default BuildsTab;
