import { EntityWithCreatedAt } from "./entity";

export class PromoCode extends EntityWithCreatedAt {
  customerFacingCode: string;
  applicablePlanTiers: Array<number>;
  valid: boolean;
  percentOff?: number;
  amountOff?: number;
  duration: PromoCodeDuration;
  durationInMonths: number;
  requiresCreditCard: boolean;
}

export enum PromoCodeDuration {
  forever = "forever",
  once = "once",
  repeating = "repeating"
}
