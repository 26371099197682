import Form from "./form";
import { Repo } from "models/settings";
import { action, observable, reaction } from "mobx";
import { defaultIfEmpty, isBlank, isNotBlank, toLowerWithDashes } from "core/utils";
import { emptySelectMessage, keyNotInUse, nameNotInUse, notEmpty, valueSelected } from "core/validation";
import { some } from "lodash";
import { ProjectStore } from "stores";
import Project, { compiledFrameworks, TestFramework, TestingFrameworkVersion } from "models/project";

export default class ProjectForm extends Form {
  @observable id: number;
  @observable name: string;
  @observable description: string;
  @observable key: string;
  @observable gitProvider: string;
  @observable gitOwner: string;
  @observable gitRepo: string;
  @observable configFile: string;
  @observable defaultBranch: string;
  @observable testingFramework: TestFramework;
  @observable testingFrameworkVersion?: TestingFrameworkVersion;
  @observable usingWorkingDirectory: boolean;
  @observable workingDirectory: string;
  @observable collectOtherFramework: boolean;
  @observable otherFramework: string;
  @observable usingSelenium: boolean;
  @observable usingDotnetCore: boolean;
  @observable retryFailedTests: boolean;
  @observable defaultToParallelizeByFile: boolean;
  @observable testTags: string[];
  @observable testArtifacts: string[];
  @observable testPackages: string[];
  @observable testTimeoutSeconds: number;
  @observable testRunTimeoutMinutes: number;
  @observable hasTests: boolean;
  @observable screenshotNamesInOutput: boolean;
  @observable defaultMaxRunners: number;
  @observable recordVideo: boolean;
  @observable cancelOlderTestRunsIfEquivalent: boolean;
  @observable quarantineFlakyTests: boolean;

  emptyToNull = ["configFile"];

  validators = {
    name: [notEmpty, nameNotInUse(this, () => ProjectStore.active)],
    key: [notEmpty, keyNotInUse(this, () => ProjectStore.items)],
    testingFramework: { validator: notEmpty, message: "Select a testing framework" },
    testingFrameworkVersion: {
      validator: (value: string) => this.testingFramework !== TestFramework.Cypress || isNotBlank(value),
      message: "Select a testing framework version",
    },
    defaultBranch: {
      validator: () => !this.hasTests || isBlank(this.gitRepo) || isNotBlank(this.defaultBranch),
      message: "You must select a default branch",
    },
    otherFramework: {
      validator: (v: boolean) =>
        this.testingFramework != TestFramework.Other ||
        !this.collectOtherFramework ||
        valueSelected(v),
      message: "Enter the name of your testing framework",
    },
    usingSelenium: {
      validator: (v: boolean) =>
        (this.testingFramework != TestFramework.NUnit &&
          this.testingFramework != TestFramework.CucumberJS &&
          this.testingFramework != TestFramework.TestNG) ||
        valueSelected(v),
      message: emptySelectMessage,
    },
    usingDotnetCore: {
      validator: (v: boolean) => this.testingFramework != TestFramework.NUnit || valueSelected(v),
      message: emptySelectMessage,
    },
    testArtifacts: [
      {
        validator: (v: string[]) => this.testingFramework != TestFramework.NUnit || v.length > 0,
        message: "You must specify at least one DLL containing tests to run",
      },
      {
        validator: (v: string[]) => {
          return (
            this.testingFramework != TestFramework.NUnit ||
            !some(v, (n) => !n.toLowerCase().endsWith(".dll"))
          );
        },
        message: "Invalid DLL name, the file should end with .dll",
      },
    ],
    testPackages: [
      {
        validator: (v: string[]) => this.testingFramework != TestFramework.TestNG || v.length > 0,
        message: "You must specify at least one package containing tests to run",
      },
      {
        validator: (v: string[]) => {
          return this.testingFramework != TestFramework.TestNG || !some(v, (n) => n.endsWith("."));
        },
        message: "Invalid package name",
      },
    ],
  };

  constructor() {
    super();

    reaction(
      () => this.usingWorkingDirectory,
      (usingWorkingDirectory) => {
        if (!usingWorkingDirectory) {
          this.updateField("workingDirectory", null);
        }
      },
    );
    reaction(
      () => this.hasTests,
      (hasTests) => {
        this.updateField("testingFramework", hasTests ? null : TestFramework.Other);
      },
    );
  }

  @action
  setDefaults() {
    this.id = null;
    this.name = "";
    this.description = "";
    this.key = "";
    this.gitProvider = "";
    this.gitOwner = "";
    this.gitRepo = "";
    this.defaultBranch = null;
    this.usingWorkingDirectory = false;
    this.defaultToParallelizeByFile = true;
    this.workingDirectory = null;
    this.testingFramework = null;
    this.usingSelenium = null;
    this.usingDotnetCore = null;
    this.retryFailedTests = false;
    this.testTimeoutSeconds = 900;
    this.testRunTimeoutMinutes = 180;
    this.hasTests = true;
    this.testTags = [];
    this.testArtifacts = [];
    this.testPackages = [];
    this.screenshotNamesInOutput = false;
    this.defaultMaxRunners = null;
    this.recordVideo = true;
    this.quarantineFlakyTests = false;
  }

  @action
  populateFromProject(project: Project) {
    this.populate(project);
    this.usingWorkingDirectory = project.workingDirectory != null;
  }

  @action
  clearFrameworkInfo() {
    this.testingFramework = null;
    this.usingSelenium = null;
    this.usingWorkingDirectory = false;
    this.workingDirectory = null;
    this.usingDotnetCore = null;
    this.testTags = [];
    this.testArtifacts = [];
    this.testPackages = [];
  }

  @action
  populateFromRepo(repo: Repo, force?: boolean) {
    this.updateField("gitProvider", repo.provider);
    this.updateField("gitOwner", repo.owner);
    this.updateField("gitRepo", repo.repo);
    this.updateField("name", defaultIfEmpty(force ? null : this.name, repo.repo));
    this.updateField("gitRepo", repo.repo);
    this.updateField("key", defaultIfEmpty(force ? null : this.key, toLowerWithDashes(repo.repo)));
    this.updateField(
      "description",
      defaultIfEmpty(force ? null : this.description, repo.description),
    );
    this.updateField("defaultBranch", null);
  }

  @action
  clearRepo() {
    this.updateField("gitProvider", "");
    this.updateField("gitOwner", "");
    this.updateField("gitRepo", "");
    this.updateField("defaultBranch", null);
  }

  get isCompiledFramework() {
    return compiledFrameworks.some((f) => f == this.testingFramework);
  }
}
