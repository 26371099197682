import Page from "pages/page";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import { useInitialEffect } from "core/react-utils";
import { InsightStore } from "../../stores";
import { useReducer } from "react";
import InsightTable from "../../components/insights/insight-table";

const InsightsPage = observer(() => {
  const history = useHistory();
  const [, rerender] = useReducer(x => x + 1, 0);

  useInitialEffect(() => {
    InsightStore.loadInsights();
    history.replace("insights");
  });

  const readInsights = InsightStore.readInsights;
  const unreadInsights = InsightStore.unreadInsights;

  if (readInsights.length === 0 && unreadInsights.length === 0)
    return <Page name={"insights"} title={"Insights"}><h3>No Insights Available At This Time</h3></Page>;

  return (
    <Page name="insights" title="Insights">
      {unreadInsights.length > 0 &&
        <>
          <h4>Unread</h4>
          <InsightTable read={false} rerender={rerender} insights={unreadInsights} />
        </>
      }
      {unreadInsights.length === 0 &&
        <h3>No Unread Insights Available</h3>
      }

      {readInsights.length > 0 &&
        <>
          <h4>Read</h4>
          <InsightTable read={true} rerender={rerender} insights={readInsights} />
        </>
      }
      {readInsights.length === 0 &&
        <h3>No Read Insights Available</h3>
      }
    </Page>
  );
});

export default InsightsPage;
