import { reduce, sumBy } from "lodash";
import { computed } from "mobx";
import { TestRun, TestStatus } from "./test-run";
import { isSameDay, isSameHour, isSameMonth, isSameWeek } from "date-fns";
import { type } from "core/serialization";
import { parseServerDateTime } from "core/date-utils";
import { ProjectTest } from "./project";

export enum Timeframe {
  Today = "Today",
  Yesterday = "Yesterday",
  Last7Days = "Last7Days",
  Last30Days = "Last30Days",
  Last60Days = "Last60Days",
  Last90Days = "Last90Days",
}

export enum TimeframeFrequency {
  Hourly = "Hourly",
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}

export class GroupedTestRuns {
  testRuns: TestRun[] = [];
  date: Date;
}

export class TestRunCountHour {
  time: string;
  passed: number;
  failed: number;
  canceled: number;
  totalTestingTime: number;
  total: number;

  @computed
  get timeAsDate() {
    return parseServerDateTime(this.time);
  }
}

export type StatusTotal = {
  failed: number;
  passed: number;
  canceled: number;
};

export class StatusChartData {
  @type(TestRunCountHour)
  countsByHour: TestRunCountHour[] = [];
  passed = 0;
  failed = 0;
  canceled = 0;
  total = 0;
  testRunMinutes = 0;

  counts(frequency: TimeframeFrequency, dates: Date[]): StatusTotal[] {
    let check = isSameDay;

    if (frequency == TimeframeFrequency.Hourly) {
      check = isSameHour;
    } else if (frequency == TimeframeFrequency.Weekly) {
      check = isSameWeek;
    } else if (frequency == TimeframeFrequency.Monthly) {
      check = isSameMonth;
    }

    return dates.map((d) =>
      reduce(
        this.countsByHour.filter((t) => check(t.timeAsDate, d)),
        (totals, current) => {
          totals.passed += current.passed;
          totals.failed += current.failed;
          totals.canceled += current.canceled;
          return totals;
        },
        { passed: 0, failed: 0, canceled: 0 } as StatusTotal,
      ),
    );
  }
}

export class FlakyTestHour {
  time: string;
  count: number;

  @computed
  get timeAsDate() {
    return parseServerDateTime(this.time);
  }
}

export class TestCounts {
  totalTests? = null;
  testPassCount? = null;
  testFailCount? = null;
  uniqueTests = 0;
  totalTestRuns = 0;
}

export class TestDistributionAnalysis {
  unitTestCount = 0;
  integrationTestCount = 0;
  e2eTestCount = 0;
  unknownTestCount = 0;
  startTime: string;
  endTime: string;

  max() {
    return Math.max(
      this.unitTestCount,
      this.integrationTestCount,
      this.e2eTestCount
    );
  }

  @computed
  get startDate() {
    return parseServerDateTime(this.startTime);
  }

  @computed
  get endDate() {
    return parseServerDateTime(this.endTime);
  }

  @computed
  get label() {
    return this.startDate?.toLocaleDateString('en-US') + " - " + this.endDate?.toLocaleDateString('en-US');
  }
}

export class FlakyChartData {
  @type(TestCounts)
  allTests = new TestCounts();
  @type(TestCounts)
  flakyTests = new TestCounts();
  @type(FlakyTestHour)
  countsByHour: FlakyTestHour[] = [];
  projectTestsData: Array<Map<number, ProjectTest>> = [];

  counts(frequency: TimeframeFrequency, dates: Date[]) {
    let check = isSameDay;

    if (frequency == TimeframeFrequency.Hourly) {
      check = isSameHour;
    } else if (frequency == TimeframeFrequency.Weekly) {
      check = isSameWeek;
    } else if (frequency == TimeframeFrequency.Monthly) {
      check = isSameMonth;
    }

    return dates.map((d) =>
      sumBy(
        this.countsByHour.filter((t) => check(t.timeAsDate, d)),
        (t) => t.count,
      ),
    );
  }
}

export class TestRunComparisonChartData {
  @type(TestCounts)
  runOne = new TestCounts();
  @type(TestCounts)
  runTwo = new TestCounts();
}

export class TqlData {
  @type(String)
  tqlResult = new String();
}

export class TestRunTestComparisonData {
  projectId: number;
  projectTestId: number;
  status: TestStatus;
  duration: number;
  testRunId: number;
  name: string;
  id: number;
}

export class TestRunTestComparisonChartData {
  runOneTests = new TqlResult<TestRunTestComparisonData>();
  runTwoTests = new TqlResult<TestRunTestComparisonData>();
}

export class TestResultCountHour {
  time: string;
  passed: number;
  failed: number;
  totalTestingTime: number;

  @computed
  get timeAsDate() {
    return parseServerDateTime(this.time);
  }
}

export class TestsChartData {
  @type(TestCounts)
  testCounts = new TestCounts();
  @type(TestResultCountHour)
  countsByHour: TestResultCountHour[] = [];
  passed: number;
  failed: number;
  total: number;
  totalTestingMinutes: number;

  counts(frequency: TimeframeFrequency, dates: Date[]): StatusTotal[] {
    let check = isSameDay;

    if (frequency == TimeframeFrequency.Hourly) {
      check = isSameHour;
    } else if (frequency == TimeframeFrequency.Weekly) {
      check = isSameWeek;
    } else if (frequency == TimeframeFrequency.Monthly) {
      check = isSameMonth;
    }

    return dates.map((d) =>
      reduce(
        this.countsByHour.filter((t) => check(t.timeAsDate, d)),
        (totals, current) => {
          totals.passed += current.passed;
          totals.failed += current.failed;
          return totals;
        },
        { passed: 0, failed: 0, canceled: 0 } as StatusTotal,
      ),
    );
  }
}

export class TqlResult<T> {
  results: Array<T>;
}
