import TestPlanSettings from "components/form/test-plan-settings";
import { observer } from "mobx-react-lite";
import Page from "pages/page";

const TestPlanNewPage = observer(() => {
  return (
    <Page title="New Test Plan" name="test-plan-new">
      <TestPlanSettings />
    </Page>
  );
});

export default TestPlanNewPage;
