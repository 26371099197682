import { Icon } from "semantic-ui-react";

interface Props {
  text?: string;
  size?: number;
  ellipsis?: boolean;
  noText?: boolean;
}

const Spinner = ({ text, size, ellipsis, noText }: Props) => (
  <span style={{ fontWeight: "bold", fontSize: `${size || 16}px` }}>
    <Icon name="circle notch" loading />
    {noText ? "" : text || "Loading"}
    {ellipsis === false || noText ? "" : "..."}
  </span>
);

export default Spinner;
