import { Link, useHistory } from "react-router-dom";
import { AppStore, UserStore } from "stores";
import { observer } from "mobx-react-lite";
import { Dropdown, Icon } from "semantic-ui-react";
import { organizationUrl } from "core/utils";
import { orderBy } from "lodash";
import { CurrentUserRole } from "models/user";

async function logout() {
  await AppStore.logout();
  window.location.href = "/login";
}

function postLogin(history: any) {
  const { user } = AppStore;


  const selectRole = async (role: CurrentUserRole) => {
    await UserStore.resetUsersForSelect();
    const account = await AppStore.selectAccount(role.accountId);
    if (account.setupComplete) {
      history.push(`/${role.accountName}/dashboard`);
    } else {
      history.push(`/${role.accountName}`);
    }
  };

  const showProfile = () => {
    history.push(organizationUrl("profile"));
  };

  return (
    <header className="site">
      <div className="identity">
        <Link to={organizationUrl()}>
          <h1>
            <img src="/img/testery-logo-white.png" alt="Testery logo" />
          </h1>
        </Link>
      </div>
      <div className="user-menu">
        <Dropdown scrolling icon="user circle outline">
          <Dropdown.Menu direction="left">
            <Dropdown.Item onClick={showProfile}>
              <Icon name="user" />
              Profile
            </Dropdown.Item>
            {user.roles.length > 1 && (
              <>
                <Dropdown.Divider />
                {orderBy(user.roles, "accountName").map((role) => (
                  <Dropdown.Item
                    className={role.accountName == AppStore.selectedAccountName ? "active" : ""}
                    onClick={() => selectRole(role)}
                    key={role.id}
                  >
                    {role.accountName}
                  </Dropdown.Item>
                ))}
              </>
            )}
            <Dropdown.Divider />
            <Dropdown.Item onClick={logout}>
              <Icon name="log out" />
              Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
}

function preLogin() {
  return (
    <header className="site">
      <div className="identity">
        <Link to={"/"}>
          <h1>
            <img src="/img/testery-logo-white.png" alt="Testery logo" />
          </h1>
        </Link>
      </div>
      <div className="links"></div>
      <div className="auth">
        <div className="user-info"></div>
      </div>
    </header>
  );
}

const Header = observer(() => {
  const history = useHistory();
  return (AppStore.isLoggedIn ? postLogin(history) : preLogin());
});

export default Header;
