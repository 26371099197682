import Select from "./select";
import { PipelineStore } from "stores";
import { observer } from "mobx-react-lite";
import { PipelineStage } from "models/settings";
import { stringComparator } from "../../core/utils";

interface Props {
  value: number;
  onChange: (id: number) => any;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
  allowNoStage?: boolean;
}

const PipelineStageSelect = observer(
  ({ onChange, value, placeholder, error, label, allowNoStage }: Props) => {
    const pipelineStages = [...PipelineStore.sortedItems];

    if (allowNoStage) {
      pipelineStages.push(new PipelineStage({ name: "No Stage", priority: 1000, id: -1 }));
    }

    const options = pipelineStages.sort((a, b) => stringComparator(a.name, b.name)).map(pipelineStage => {
      return { label: pipelineStage.name, value: pipelineStage.name, pipelineStageId: pipelineStage.id };
    });

    const pipelineStage = pipelineStages.find((e) => e.id == value);

    return (
      PipelineStore.hasItems && (
        <Select
          className="pipeline-stage-select"
          placeholder={placeholder}
          isSearchable={true}
          onChange={(option) => onChange(option && option.pipelineStageId)}
          autoDefault={!placeholder}
          isClearable={!!placeholder && !!value}
          value={pipelineStage ? {
            label: pipelineStage.name,
            value: pipelineStage.name,
            pipelineStageId: pipelineStage.id,
          } : null
          }
          options={options}
          formatOption={(option) => option.label}
          valueField="pipelineStageId"
          labelField="name"
          error={error}
          label={label}
        />
      )
    );
  },
);

export default PipelineStageSelect;
