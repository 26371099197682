export class ReviewerAssignmentEntry {
  id: number;
  createdAt: string;
  testRunId?: number;
  testRunTestId?: number;
  assignorUserId: number;
  assigneeUserId: number;

  constructor(data?: Partial<ReviewerAssignmentEntry>) {
    if (data) Object.assign(this, data);
  }
}

export class ReviewStatusChangeEntry {
  id: number;
  createdAt: string;
  testRunId?: number;
  testRunTestId?: number;
  reviewStatusId?: number;

  constructor(data?: Partial<ReviewStatusChangeEntry>) {
    if (data) Object.assign(this, data);
  }
}

export class ReviewStatus {
  id: number;
  createdAt: string;
  updatedAt?: string;
  accountId: number;
  order: number;
  name: string;
  custom: boolean;
  deactivated: boolean;

  constructor(data?: Partial<ReviewStatus>) {
    if (data) Object.assign(this, data);
  }
}

export enum ReviewType {
  TEST_RUN = "TEST_RUN",
  TEST_RUN_TEST = "TEST_RUN_TEST"
}

export class ReviewerRequest {
  reviewType: ReviewType;
  testRunId: number;
  testRunTestId?: number;
  assigneeUserId?: number;

  constructor(data?: Partial<ReviewerRequest>) {
    if (data) Object.assign(this, data);
  }
}

export class ReviewStatusRequest {
  reviewType: ReviewType;
  testRunId: number;
  testRunTestId?: number;
  reviewStatusId?: number;

  constructor(data?: Partial<ReviewStatusRequest>) {
    if (data) Object.assign(this, data);
  }
}
