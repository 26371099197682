import Form from "./form";
import { action, observable, reaction } from "mobx";
import { getSelectedPlanTier } from "../../core/storage";
import { AppStore } from "../../stores";

export default class PlanForm extends Form {
  @observable linuxPlanTier?: number;
  @observable cardToken: string;
  @observable cardLastFour: string;
  @observable cardBrand: string;
  @observable cardExpireMonth: number;
  @observable cardExpireYear: number;
  @observable monthly: boolean;
  @observable promoCode?: string;

  validators = {};

  constructor() {
    super();

    reaction(
      () => AppStore.account?.setupComplete,
      () => {
        if (!AppStore.account?.setupComplete) {
          this.validators["linuxPlanTier"] = [
            {
              validator: () => !this.noPlanSelected,
              message: "You must select a plan",
            },
          ];
        }
      },
    );

    reaction(
      () => this.linuxPlanTier,
      () => {
        this.clearErrors("linuxPlanTier");
      },
    );
  }

  get isPayingAccount() {
    return AppStore.account.currentAccountPlan != null;
  }

  get noPlanSelected() {
    return !this.linuxPlanTier;
  }

  @action
  setDefaults() {
    this.linuxPlanTier = getSelectedPlanTier() || null;
    this.monthly = true;
    this.clearCreditCardInfo();
  }

  @action
  clearCreditCardInfo() {
    this.clearErrors("cardToken");
    this.cardToken = null;
    this.cardBrand = null;
    this.cardExpireMonth = null;
    this.cardExpireYear = null;
    this.cardLastFour = null;
  }
}
