import { observer } from "mobx-react-lite";
import { RunnerPool } from "models/settings";
import { Icon } from "semantic-ui-react";
import { ModalStore, RunnerPoolStore } from "stores";
import "./runner-pools.scss";
import IconButton from "components/icon-button";
import SortableTable from "components/sortable-table";
import { Link } from "react-router-dom";


function deletePool(pool: RunnerPool) {
  ModalStore.confirmation.show(
    async (okClicked: boolean) => {
      if (okClicked) {
        await RunnerPoolStore.delete(pool.id);
      }
    },
    {
      message: `Are you sure you want to delete the runner pool ${pool.name}?`,
      okText: "Delete",
      okColor: "red",
    },
  );
}

const RunnerPools = observer(() => {
  return (
    <div className="pipeline-stages">
    <div>
      <SortableTable
        className={"environment-table"}
        loading={!RunnerPoolStore.loaded}
        loadingText="Loading Runner Pools"
        headerColumns={[
          {
            className: "name",
            content: "Name",
            sortKey: "name",
            sortTransform: (pool) => pool.name.toLowerCase(),
          },
          {
            className: "maxRunners",
            content: "Runners",
            sortKey: "maxRunners",
          },
          {
            className: "runnerType",
            content: "Runner Type",
            sortKey: "runnerType",
          },
          {
            className: "utilization",
            content: "Current Utilization",

          },
          {
            className: "actions",
            content: "",

          },
        ]}
        renderRow={(pool: RunnerPool) => (
          <tr
            className="runnerPool"
            key={pool.id}
          >
            <td><div className={"name text-overflow"}>{pool.name}</div></td>
            <td><div className={"name text-overflow"}>{pool.maxRunners}</div></td>
            <td><div className={"name text-overflow"}>{pool.runnerType}</div></td>
            <td><div className={"name text-overflow"}>{Math.min(Math.round((pool.currentRunners / pool.maxRunners) * 100), 100)}%</div></td>
            <td>
              <div className="actions">
                <Link to={`runner-pools/${pool.id}`}>              
                  <Icon name="edit" />
                </Link>
                <IconButton icon="trash" onClick={() => deletePool(pool)} />
              </div>
            </td>
          </tr>
        )}
        items={RunnerPoolStore.items}
        defaultSortKey="name"
        defaultDecending={false}
      />
      </div>
    </div>
  )
});

export default RunnerPools;
