import Spinner from "components/spinner";
import { useInitialEffect } from "core/react-utils";
import { organizationUrl, relativeLink } from "core/utils";
import { findIndex } from "lodash";
import { observer } from "mobx-react-lite";
import Page from "pages/page";
import { useHistory, useParams } from "react-router";
import { Tab, TabProps } from "semantic-ui-react";
import { WorkflowStore } from "stores";
import SettingsTab from "./settings-tab";
import StepsTab from "./steps-tab";

const WorkflowPage = observer(() => {
  const { workflowId, tab } = useParams<{ workflowId: string; tab: string }>();
  const history = useHistory();
  const workflowdIdInt = workflowId ? parseInt(workflowId) : 0;

  useInitialEffect(() => {
    WorkflowStore.ensureLoaded();
  });

  const workflow = WorkflowStore.find(workflowdIdInt);

  if (!workflow)
    return (
      <Page name="workflow" title="Quality Workflow">
        <Spinner text="Loading Workflow" />
      </Page>
    );

  if (!tab) {
    history.replace(`${workflow.id}/steps`);
  }

  const tabPanes = [
    {
      menuItem: { key: "home", icon: "home", content: "" },
      render: () => <div></div>,
    },
    {
      menuItem: { key: "steps", icon: null, content: "Steps" },
      render: () => <StepsTab key={1} workflow={workflow} />,
    },
    {
      menuItem: { key: "settings", icon: null, content: "Settings" },
      render: () => <SettingsTab key={2} workflow={workflow} />,
    },
  ];

  const tabIndex = findIndex(tabPanes, (i) => i.menuItem.key == tab);

  const navigate = (data: TabProps) => {
    const name = tabPanes[data.activeIndex].menuItem.key;
    history.push(name == "home" ? organizationUrl("workflows") : relativeLink(name));
  };

  return (
    <Page name="workflow" title={workflow.name}>
      <Tab
        onTabChange={(_, data) => navigate(data)}
        activeIndex={tabIndex}
        menu={{ secondary: true, pointing: true }}
        panes={tabPanes}
      />
    </Page>
  );
});

export default WorkflowPage;
