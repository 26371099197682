import RoleSelect from "components/form/role-select";
import IconButton from "components/icon-button";
import SortableTable from "components/sortable-table";
import { observer } from "mobx-react-lite";
import { CurrentUserRole, RoleType } from "models/user";
import { useState } from "react";
import { Button, Card, CardContent, CardHeader, Form, Icon, Tab } from "semantic-ui-react";
import { EmployeeStore, ModalStore, AppStore } from "stores";
import AccountSelect from "./account-select";

const RolesTab = observer(() => {
  const [accountId, setAccountId] = useState(null as number);
  const [role, setRole] = useState(RoleType.USER);
  const [addingRole, setAddingRole] = useState(false);

  const addRole = async () => {
    if (accountId) {
      setAddingRole(true);
      await EmployeeStore.addRole(accountId, role);
      setAddingRole(false);
    }
  };

  const deleteRole = async (role: CurrentUserRole) => {
    ModalStore.confirmation.show(
      async (okClicked) => {
        if (okClicked) {
          await EmployeeStore.deleteRole(role.id);
        }
      },
      {
        message: `Are you sure you want to delete the ${role.accountName} role?`,
        okColor: "red",
        okText: "Delete Role",
      },
    );
  };

  return (
    <Tab.Pane className="roles-tab">
      <Card className="add-role-form">
        <CardContent>
          <CardHeader>Add New Role</CardHeader>
        </CardContent>
        <CardContent>
          <Form>
            <AccountSelect
              label="Account"
              placeholder="Select Account..."
              value={accountId}
              onChange={setAccountId}
            />
            <RoleSelect label="Role" value={role} onChange={setRole} />
            <Button color="green" onClick={addRole} loading={addingRole}>
              <Icon name="plus" />
              Add Role
            </Button>
          </Form>
        </CardContent>
      </Card>
      <br />
      <SortableTable
        title="Your Roles"
        emptyMessage="No Roles Found"
        headerColumns={[
          { content: "ID", sortKey: "id" },
          { content: "Account", sortKey: "accountName" },
          { content: "Role", sortKey: "roleType" },
          { content: "" },
        ]}
        items={AppStore.user.roles}
        defaultSortKey="id"
        renderRow={(role) => (
          <tr key={role.id}>
            <td>{role.id}</td>
            <td>{role.accountName}</td>
            <td>{role.roleType}</td>
            <td>
              <IconButton
                label="Delete Role"
                icon="trash"
                onClick={() => deleteRole(role)}
              />
            </td>
          </tr>
        )}
      />
    </Tab.Pane>
  );
});

export default RolesTab;
