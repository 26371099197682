import { useInitialEffect, setPageInfo } from "core/react-utils";
import { ProjectStore, SettingsStore, UserStore } from "stores";
import Page from "pages/page";
import { Icons } from "core/constants";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { Badge, Box } from "@mui/joy";
import { organizationUrl } from "core/utils";
import { Check } from "@mui/icons-material";
import { chain } from "lodash";



interface GetStartedTaskProps {
  completed: boolean;
  headerText: string;
  detailText: string;
  actionPath: string;
  learnMorePath: string;
}

const GetStartedTask = ({ completed, headerText, detailText, actionPath, learnMorePath  }: GetStartedTaskProps) => {

  return (
    <Card
        data-resizable
        size="lg"
        sx={{
          textAlign: 'center',
          alignItems: 'center',
          overflow: 'auto',
          '--icon-size': '100px',
        }}
      >
      <CardOverflow variant="solid"
        style={{background: 'linear-gradient(90deg, #5fc953 0%, #339900 100%)'}}
      >
        <AspectRatio
          variant="solid"
          color="primary"
          ratio="1"
          sx={{
            m: 'auto',
            transform: 'translateY(50%)',
            borderRadius: '50%',
            width: 'var(--icon-size)',
            boxShadow: 'sm',
            bgcolor: 'background.surface',
            position: 'relative',
          }}
        >
          <div>
            <FolderIcon color="warning" sx={{ fontSize: '4rem' }} />
          </div>
        </AspectRatio>
      </CardOverflow>
      <Typography level="title-lg" sx={{ mt: 'calc(var(--icon-size) / 2)' }}>
      {completed && (
      <Badge
        color="success"
        size="lg"
        variant="outlined"
        badgeContent={<Check />}
        badgeInset="-10%" 
      >
       {headerText}
      </Badge>      
      )}
      {!completed && (
        <>       {headerText}
</>
      )}

      </Typography>
      <CardContent sx={{ maxWidth: '40ch' }}>
          {detailText}
      </CardContent>
        {!completed && (
                <CardActions
                orientation="vertical"
                buttonFlex={1}
                sx={{
                  '--Button-radius': '40px',
                  width: 'clamp(min(100%, 160px), 50%, min(100%, 200px))',
                }}
              >
          <a href={actionPath}>
            <Button variant="solid" color="warning">
              Get Started
            </Button>
            </a>
            <a href={learnMorePath}>
            <Button variant="plain" color="neutral">
              Learn More
            </Button>
          </a>
          </CardActions>
        )}
        </Card>
  );
};


const GetStartedPage = observer(() => {
  const [tasks, setTasks] = useState([] as GetStartedTaskProps[]);

  useInitialEffect(async () => {
    await ProjectStore.ensureLoaded();
    await SettingsStore.ensureEnvironments();
    await SettingsStore.ensureAlerts();
    await UserStore.ensureUserRoles();
    tasks.push({completed: ProjectStore.items.length > 0, headerText: "Setup Your First Project", detailText: "Projects enable you run tests across multiple repositories and even testing frameworks.", actionPath: organizationUrl("projects"), learnMorePath: "https://docs.testery.io/get-to-know-testery/projects"});
    tasks.push({completed: SettingsStore.environments.length > 0, headerText: "Configure Your Environment", detailText: "Testery supports running the same tests or different subsets of tests against different environments.", actionPath: organizationUrl("environments"), learnMorePath: "https://docs.testery.io/get-to-know-testery/set-up-your-test-environments"});
    tasks.push({completed: UserStore.userRoles.length > 1, headerText: "Add Your Team", detailText: "Invite your team to collaborate on your test runs.", actionPath: organizationUrl("settings/users"), learnMorePath: "https://docs.testery.io/get-started/getting-started-guide/invite-your-team"});
    tasks.push({completed: SettingsStore.alerts.length > 0, headerText: "Configure Alerts", detailText: "Set up Slack or Teams to get alerts on your test runs.", actionPath: organizationUrl("settings/users"), learnMorePath: "https://docs.testery.io/get-started/getting-started-guide/invite-your-team"});
    setTasks(chain(tasks).sortBy("completed").value());
  });

  const title = "Get Started";

  setPageInfo("get-started", title);
  return (
    <>
    <Page name="get-started" title={title} icon={Icons.pipelines}>
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
        gap: 4,
      }}
    >
      {tasks.map((task, index) => {
        return (
        <GetStartedTask 
          key={index}
          completed={task.completed}
          headerText={task.headerText}
          detailText={task.detailText}
          actionPath={task.actionPath}
          learnMorePath={task.learnMorePath}
        />
      )})}
    </Box>
    </Page>
    </>
  )
});

export default GetStartedPage;
