import { action, observable, reaction } from "mobx";
import {
  FlakyChartData, TestRunTestComparisonChartData,
  TestRunTestComparisonData,
  StatusChartData,
  TestRunComparisonChartData,
  TestsChartData,
  TqlData, TqlResult, TestDistributionAnalysis,
  Timeframe,
} from "models/analytics";
import ChartOptionsForm from "models/forms/chart-options-form";
import Api from "core/api";
import { lightFormat, startOfDay, subDays } from "date-fns";

export class ChartData<T> {
  readonly options = new ChartOptionsForm();
  @observable data: T;
  @observable loaded = false;
  @observable loading = false;

  @action
  clear() {
    this.data = null;
    this.loaded = false;
    this.loading = false;
    this.options.clearFormData();
  }

  @action
  update(data: T) {
    this.loaded = true;
    this.loading = false;
    this.data = data;
  }

  @action
  startLoading(clear: boolean = false) {
    if (clear) {
      this.data = null;
      this.loaded = false;
    }
    this.loading = true;
  }
}

export class AnalyticsStoreClass {
  readonly statusChartData = new ChartData<StatusChartData>();
  readonly flakyChartData = new ChartData<FlakyChartData>();
  readonly testsChartData = new ChartData<TestsChartData>();
  readonly testRunComparisonChartData = new ChartData<TestRunComparisonChartData>();
  readonly slowerTestsComparisonChartData = new ChartData<TestRunTestComparisonChartData>();
  readonly tqlData = new ChartData<TqlData>();


  constructor() {
    reaction(
      () => [
        this.statusChartData.options.projectId,
        this.statusChartData.options.environmentId,
        this.statusChartData.options.pipelineStageId,
        this.statusChartData.options.timeframe,
        this.statusChartData.options.branch,
      ],
      () => this.loadStatusChartData(),
    );
    reaction(
      () => [
        this.flakyChartData.options.projectId,
        this.flakyChartData.options.environmentId,
        this.flakyChartData.options.pipelineStageId,
        this.flakyChartData.options.timeframe,
        this.flakyChartData.options.branch,
      ],
      () => this.loadFlakyChartData(),
    );
    reaction(
      () => [
      ],
      () => this.loadTestDistributionAnalysisData(),
    )
    reaction(
      () => [
        this.testsChartData.options.projectId,
        this.testsChartData.options.environmentId,
        this.testsChartData.options.pipelineStageId,
        this.testsChartData.options.timeframe,
        this.testsChartData.options.branch,
      ],
      () => this.loadTestsChartData(),
    );
    reaction(
      () => [
        this.testRunComparisonChartData.options.projectId,
        this.testRunComparisonChartData.options.environmentId,
        this.testRunComparisonChartData.options.pipelineStageId,
        this.testRunComparisonChartData.options.timeframe,
        this.testRunComparisonChartData.options.branch,
        this.testRunComparisonChartData.options.testRunOneId,
        this.testRunComparisonChartData.options.testRunTwoId,
      ],
      () => this.loadTestRunComparisonChartData(),
    );
  }

  @action
  clear() {
    this.statusChartData.clear();
    this.flakyChartData.clear();
    this.testsChartData.clear();
    this.testRunComparisonChartData.clear();
  }

  async loadStatusChartData() {
    this.statusChartData.startLoading(true);

    var url = "/test-runs/test-run-info?" + this.statusChartData.options.buildUrlParams();

    const result = await Api.get(url, StatusChartData);

    return this.statusChartData.update(result.data);
  }

  async loadTestDistributionAnalysisData() {

    var url = "/test-distribution-analysis";

    const result = await Api.getList(url, TestDistributionAnalysis);

    return result.data;
  }

  @action
  async runAnalysis(timeframe: Timeframe) {
    let startTime = new Date();
    switch(timeframe) {
      case Timeframe.Today:
        startTime = startOfDay(new Date());
        break;
      case Timeframe.Yesterday:
        startTime = subDays(startOfDay(new Date()), 1);
        break;
      case Timeframe.Last7Days:
        startTime = subDays(startOfDay(new Date()), 7);
        break;
      case Timeframe.Last30Days:
        startTime = subDays(startOfDay(new Date()), 30);
        break;
      case Timeframe.Last60Days:
        startTime = subDays(startOfDay(new Date()), 60);
        break;
      case Timeframe.Last90Days:
        startTime = subDays(startOfDay(new Date()), 90);
        break;
    }
    await Api.post("/test-distribution-analysis", {"startTime": lightFormat(startTime, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS")});
  }


  async loadFlakyChartData() {
    this.flakyChartData.startLoading(true);

    var url = "/test-runs/flaky-test-info?" + this.flakyChartData.options.buildUrlParams();

    const result = await Api.get(url, FlakyChartData);

    return this.flakyChartData.update(result.data);
  }

  async loadTestsChartData() {
    this.testsChartData.startLoading(true);

    var url = "/test-runs/test-info?" + this.testsChartData.options.buildUrlParams();

    const result = await Api.get(url, TestsChartData);

    return this.testsChartData.update(result.data);
  }

  async loadTestRunComparisonChartData() {
    const t = new TestRunComparisonChartData();
    const url = "/tql";

    if (this.testRunComparisonChartData.options.testRunOneId) {
      const resultRunOne = await Api.post(url, { tql: `select TOTAL_TESTS, TEST_PASS_COUNT, TEST_FAIL_COUNT from TEST_RUN where ID=${this.testRunComparisonChartData.options.testRunOneId}` }, TqlResult<TestsChartData>);
      t.runOne.totalTests = resultRunOne.data.results[0]["totalTests"];
      t.runOne.testPassCount = resultRunOne.data.results[0]["testPassCount"];
      t.runOne.testFailCount = resultRunOne.data.results[0]["testFailCount"];
    }

    if (this.testRunComparisonChartData.options.testRunTwoId) {
      const resultRunTwo = await Api.post(url, { tql: `select TOTAL_TESTS, TEST_PASS_COUNT, TEST_FAIL_COUNT from TEST_RUN where ID=${this.testRunComparisonChartData.options.testRunTwoId}` }, TqlResult<TestsChartData>);
      t.runTwo.totalTests = resultRunTwo.data.results[0]["totalTests"];
      t.runTwo.testPassCount = resultRunTwo.data.results[0]["testPassCount"];
      t.runTwo.testFailCount = resultRunTwo.data.results[0]["testFailCount"];
    }

    return this.testRunComparisonChartData.update(t);
  }

  async loadSlowerTestsData() {
    const t = new TestRunTestComparisonChartData();
    const url = "/tql";

    if (this.testRunComparisonChartData.options.testRunOneId) {
      const resultRunOne = await Api.post(url, { tql: `select ID, STATUS, NAME, PROJECT_ID, DURATION, PROJECT_TEST_ID, TEST_RUN_ID from TEST_RUN_TEST where TEST_RUN_ID=${this.testRunComparisonChartData.options.testRunOneId}` }, TqlResult<TestRunTestComparisonData>);
      t.runOneTests = resultRunOne.data
    }

    if (this.testRunComparisonChartData.options.testRunTwoId) {
      const resultRunTwo = await Api.post(url, { tql: `select ID, STATUS, NAME, PROJECT_ID, DURATION, PROJECT_TEST_ID, TEST_RUN_ID from TEST_RUN_TEST where TEST_RUN_ID=${this.testRunComparisonChartData.options.testRunTwoId}` }, TqlResult<TestRunTestComparisonData>);
      t.runTwoTests = resultRunTwo.data
    }

    this.slowerTestsComparisonChartData.update(t);
  }
}
