import API from "core/api";
import { join, keyBy, orderBy } from "lodash";
import { action, observable, runInAction } from "mobx";
import { Account } from "models/account";
import {
  AccountCounts,
  AccountData,
  HeaderData,
  MonitorData,
  Performance,
  ScheduledJob,
  TestRunInfo,
  TestRunnerAgent,
  TestRunnerAgentStatus,
} from "models/employee";
import { subMonths } from "date-fns";
import {
  LogMessage,
  TestListResult,
  TestResult,
  TestRun,
  TestRunner,
  TestRunStatus,
  TestRunTest,
} from "models/test-run";
import { isNotBlank } from "core/utils";
import { RoleType, UserRole } from "models/user";
import { AppStoreClass } from "./app-store";
import { formatForMillisOffset } from "../core/date-utils";

export class EmployeeStoreClass {
  private readonly AppStore: AppStoreClass;
  @observable initialized = false;
  @observable isEmployee = false;
  @observable headerData = new HeaderData();
  @observable accounts = [] as Account[];
  @observable dateFilter: Date = null;
  @observable accountFilter: number = null;
  @observable statusFilter: TestRunStatus[] = [];
  @observable testRuns: TestRunInfo[] = [];
  @observable testRunsLoading = false;
  @observable monitorLoading = false;
  @observable monitorData: MonitorData[] = [];
  @observable performanceLoading: false;
  @observable performanceData: Performance[] = [];
  @observable nameFilter: string = null;
  @observable monitorTypeFilter: string = null;
  @observable cliFilter: boolean = null;
  @observable durationFilter: number = 0;
  @observable dbTimeFilter: number = 0;
  @observable currentTab: string = null;
  @observable accountData = new AccountData();
  @observable agents: TestRunnerAgent[] = [];
  @observable loadingAgents = false;

  constructor(AppStore: AppStoreClass) {
    this.AppStore = AppStore;
  }

  getFilteredAccount() {
    return this.accounts.find((a) => a.id == this.accountFilter);
  }

  @action
  async init(tab?: string) {
    if (!this.initialized) {
      this.initialized = true;
      this.statusFilter = [TestRunStatus.QUEUED, TestRunStatus.SUBMITTED, TestRunStatus.RUNNING, TestRunStatus.SETUP, TestRunStatus.TEAR_DOWN];
      await this.checkEmployee();
      if (this.isEmployee) {
        this.loadHeaderData();
        this.loadAccounts();
      } else {
        window.location.href = "/";
      }
      this.updateTab(tab || "test-run-search");
    }
    return this.isEmployee;
  }

  @action
  updateTab(tab: string) {
    this.currentTab = tab;
    this.reloadData();
  }

  async checkEmployee() {
    try {
      const result = await API.get("/employee");
      runInAction(() => {
        this.isEmployee = (result.data as any).employee == true;
      });
    } catch {
      runInAction(() => (this.isEmployee = false));
    }
  }

  async loadAccounts() {
    const result = await API.getList("/employee/accounts", Account);
    return runInAction(() => (this.accounts = orderBy(result.data, "id")));
  }

  async loadHeaderData() {
    const result = await API.get("/employee/header-data", HeaderData);
    return runInAction(() => (this.headerData = result.data));
  }

  @action.bound
  async refreshHeader() {
    this.loadHeaderData();
    if (this.accountFilter) {
      this.loadAccountCounts(this.accountFilter);
    }
  }
  
  async loadAccountCounts(accountId: number) {
    if (accountId) {
      const result = await API.get(`/employee/accounts/${accountId}/execution`, AccountData);
      runInAction(() => (this.accountData = result.data));
    } else {
      this.accountData = new AccountData();
    }
  }

  getThisMonthAccountCounts() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (
      this.accountData.counts.find((a) => a.year == year && a.month == month) || new AccountCounts()
    );
  }

  getLastMonthAccountCounts() {
    const date = subMonths(new Date(), 1);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (
      this.accountData.counts.find((a) => a.year == year && a.month == month) || new AccountCounts()
    );
  }

  @action.bound
  updateAccountFilter(accountId: number) {
    this.accountFilter = accountId;
    this.loadAccountCounts(accountId);
    this.reloadData();
  }

  @action.bound
  updateStatusFilter(statues: TestRunStatus[]) {
    this.statusFilter = statues;
    this.reloadData();
  }

  @action.bound
  updateDateFilter(date: Date) {
    this.dateFilter = date;
    this.reloadData();
  }

  reloadData() {
    if (this.currentTab == "performance") {
      this.loadMonitorData();
    } else {
      this.searchForTestRuns();
    }
  }

  @action
  async searchForTestRuns() {
    this.testRunsLoading = true;
    let url = "/employee/test-runs?page=0&limit=40";

    if (this.accountFilter != null) url += "&accountId=" + this.accountFilter;
    if (this.statusFilter.length > 0) url += "&status=" + join(this.statusFilter, ",");
    if (this.dateFilter) url += `&date=${formatForMillisOffset(this.dateFilter)}`;

    const result = await API.getList(url, TestRunInfo);
    return runInAction(() => {
      this.testRunsLoading = false;
      return (this.testRuns = result.data);
    });
  }

  @action.bound
  updateNameFilter(name: string) {
    this.nameFilter = name;
  }

  @action.bound
  updateCliFilter(value: boolean) {
    this.cliFilter = value;
    this.reloadData();
  }

  @action.bound
  updateMonitorTypeFilter(value: string) {
    this.monitorTypeFilter = value;
    this.reloadData();
  }

  @action.bound
  updateDurationFilter(amount: number) {
    this.durationFilter = amount;
  }

  @action.bound
  updateDbTimeFilter(amount: number) {
    this.dbTimeFilter = amount;
  }

  @action.bound
  async loadMonitorData() {
    this.monitorLoading = true;
    let url = "/employee/performance-logs?a=b";

    if (this.accountFilter != null) url += "&accountId=" + this.accountFilter;
    if (isNotBlank(this.monitorTypeFilter)) url += "&type=" + this.monitorTypeFilter;
    if (this.dateFilter) url += `&date=${formatForMillisOffset(this.dateFilter)}`;
    if (this.durationFilter) url += `&durationMin=${this.durationFilter}`;
    if (this.dbTimeFilter) url += `&dbTimeMin=${this.dbTimeFilter}`;
    if (isNotBlank(this.nameFilter)) url += `&name=${this.nameFilter}`;
    if (this.cliFilter != null) url += `&isCommandLine=${this.cliFilter}`;

    const result = await API.getList(url, MonitorData);

    return runInAction(() => {
      this.monitorLoading = false;
      return (this.monitorData = result.data);
    });
  }

  async loadAccountCustomerId(accountId: number) {
    const result = await API.get(`/employee/accounts/${accountId}/customer-id`);
    return result.data as string;
  }

  @action
  async loadAgents() {
    this.loadingAgents = true;
    await this.refreshAgents();
    return runInAction(() => {
      this.loadingAgents = false;
    });
  }

  @action
  async refreshAgents() {
    const result = await API.getList(`/employee/agents`, TestRunnerAgent);
    return runInAction(() => {
      return (this.agents = result.data);
    });
  }

  @action
  stopAgent(agentId: number) {
    API.delete(`/employee/agents/${agentId}`);
    this.agents = this.agents.filter((a) => a.id != agentId);
  }

  @action
  replaceAgent(agent: TestRunnerAgent) {
    agent.status = TestRunnerAgentStatus.Deprecated;
    API.post(`/employee/agents/${agent.id}/replace`, {}).then(() => this.refreshAgents());
  }

  async loadTestRun(id: number) {
    return (await API.get(`/employee/test-runs/${id}`, TestRun)).data;
  }

  async loadTestListResult(id: number) {
    return (await API.get(`/employee/test-runs/${id}/test-list-result`, TestListResult)).data;
  }

  async loadTestRunTests(id: number) {
    return (await API.getList(`/employee/test-runs/${id}/tests`, TestRunTest)).data;
  }

  async loadTestRunners(id: number): Promise<{ [id: number]: TestRunner }> {
    const result = await API.getList(`/employee/test-runs/${id}/runners`, TestRunner);
    return keyBy(result.data, "id");
  }

  async stopTestRunner(testRunner: TestRunner) {
    const result = await API.post(
      `/employee/test-runs/${testRunner.testRunId}/runners/${testRunner.id}/stop`,
      {},
      TestRunner,
    );
    return result.data;
  }

  async replaceTestRunner(testRunner: TestRunner) {
    await API.post(
      `/employee/test-runs/${testRunner.testRunId}/runners/${testRunner.id}/replace`,
      {},
    );
  }

  async loadTestResult(testRunId: number, testRunTestId: number) {
    return (await API.get(`/employee/test-runs/${testRunId}/tests/${testRunTestId}`, TestResult))
      .data;
  }

  async loadRunnerLogs(runnerUuid: string) {
    return (await API.getList(`/employee/runners/${runnerUuid}/logs`, LogMessage)).data;
  }

  async loadAgentLogs(agentId: number) {
    return (await API.getList(`/employee/agents/${agentId}/logs`, LogMessage)).data;
  }

  async loadTestRunLogs(testRunId: number) {
    return (await API.getList(`/employee/test-runs/${testRunId}/logs`, LogMessage)).data;
  }

  async loadJobs() {
    return (await API.getList("/employee/jobs", ScheduledJob)).data;
  }

  @action
  async toggleJobEnabled(job: ScheduledJob) {
    job.enabled = !job.enabled;
    API.post(`/employee/jobs/${job.id}/toggle-enabled`, {}, ScheduledJob);
  }

  @action
  async runJob(job: ScheduledJob) {
    await API.post(`/employee/jobs/${job.id}/run`, {}, ScheduledJob);
  }

  @action
  async addRole(accountId: number, role: RoleType) {
    await API.post("/employee/roles", { accountId, role }, UserRole);
    await this.AppStore.reloadUser();
  }

  @action
  async deleteRole(id: number) {
    await API.delete(`/employee/roles/${id}`);
    await this.AppStore.reloadUser();
  }
}
