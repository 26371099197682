import DurationDisplay from "components/duration-display";
import IconButton from "components/icon-button";
import TestRunProgress from "components/test-run-progress";
import { observer } from "mobx-react-lite";
import { TestPlanRunStage } from "models/test-plan";
import { Icon, Label } from "semantic-ui-react";
import { TestPlanStore } from "stores";
import SuiteResults from "./suite-results";

interface Props {
  stage: TestPlanRunStage;
  stageComplete: boolean;
}

const StageResults = observer(({ stage, stageComplete }: Props) => (
  <>
    <tr className="stage-result">
      <td className="no-bottom-border" colSpan={2}></td>
      <td className="name">
        {stage.isRunning && <Icon name="circle notch" loading={true} />}
        {stage.name}{" "}
        {stageComplete && stage.isSkipped && (
          <Label color="yellow" size="mini">
            Skipped
          </Label>
        )}
        {stage.isError && (
          <Label color="red" size="mini">
            Error
          </Label>
        )}
      </td>
      <td className="results">
        {stage.totalTestCount > 0 && !stage.isOpen && (
          <TestRunProgress
            pass={stage.passTestCount}
            fail={stage.failTestCount}
            total={stage.totalTestCount}
          />
        )}
      </td>
      <td className="info">&nbsp;</td>
      <td className="duration">
        <DurationDisplay duration={stage.duration} />
      </td>
      <td className="actions">
        <IconButton
          icon={stage.isOpen ? "chevron up" : "chevron down"}
          onClick={() => TestPlanStore.toggleTestPlanRunStageOpen(stage.id)}
        />
      </td>
    </tr>
    {stage.isOpen &&
      stage.suites.map((s) => (
        <SuiteResults
          key={s.id}
          stageComplete={stageComplete}
          suite={s}
          stageId={stage.testPlanStageId}
        />
      ))}
  </>
));

export default StageResults;
