import IconButton from "components/icon-button";
import { Icons } from "core/constants";
import { observer } from "mobx-react-lite";
import { TestPlanStage } from "models/test-plan";
import { Card, Icon } from "semantic-ui-react";
import { ModalStore, TestPlanStore } from "stores";
import Suites from "./suites";

async function deleteStage(stage: TestPlanStage) {
  ModalStore.confirmation.show(
    async (okClicked: boolean) => {
      if (okClicked) {
        await TestPlanStore.deleteTestPlanStage(stage.id);
      }
    },
    {
      message: `Are you sure you want to delete the stage ${stage.name}?`,
      okText: "Delete",
      okColor: "red",
    },
  );
}

interface Props {
  stage: TestPlanStage;
}

const Stage = observer(({ stage }: Props) => (
  <Card className="stage">
    <Card.Content>
      <div className="right">
        <div className="details">
          <div className="execution-type">{stage.executionType}</div>
          {stage.alwaysRun && (
            <div className="always-run">
              <Icon name="check" />
              Always Run
            </div>
          )}
        </div>
        <div>
          <IconButton
            icon={Icons.edit}
            onClick={() => ModalStore.testPlanStageEdit.show(null, stage)}
          />
          <IconButton icon={Icons.delete} onClick={() => deleteStage(stage)} />
        </div>
      </div>
      <div className="left">
        <div className="name">
          <Icon name="bars" />
          {stage.name}
        </div>
        <div className="description">{stage.description}</div>
        <br />
        <Suites stage={stage} />
      </div>
    </Card.Content>
  </Card>
));

export default Stage;
