import { useState } from "react";
import { SemanticICONS, SemanticCOLORS, Icon, Popup } from "semantic-ui-react";

interface Props {
  icon: SemanticICONS;
  color?: SemanticCOLORS;
  label?: string;
  onClick?: () => any;
  marginRight?: number;
}

const IconButton = ({ icon, label, color, onClick, marginRight }: Props) => {
  const [running, setRunning] = useState(false);

  const doClick = async () => {
    if (!onClick || running) return;

    setRunning(true);
    try {
      await onClick();
    } finally {
      setRunning(false);
    }
  };

  const content = (
    <span
      style={{ marginRight: marginRight != null ? marginRight : 5 }}
      className="icon-button"
      onClick={doClick}
    >
      <Icon
        color={color}
        aria-label={label}
        name={running ? "circle notch" : icon}
        loading={running}
      />
    </span>
  );

  const popup = !!label && !running;

  return popup ? <Popup basic size="mini" content={label} trigger={content} /> : content;
};

export default IconButton;
