import { useState, useEffect } from "react";
import Project from "models/project";
import { isBlank, cachedLookup } from "core/utils";
import Select from "./select";
import { useOnlyLatestRequest } from "core/react-utils";
import { ProjectStore } from "stores";

interface Props {
  project: Project;
  branch: string;
  value: string;
  error?: boolean | string;
  label?: string;
  placeholder?: string;
  onChange: (v: string) => any;
}

const CommitSelect = ({ project, branch, value, onChange, error, label, placeholder }: Props) => {
  const [options, setOptions] = useState([] as string[]);
  const [loading, setLoading] = useState(false);

  const loadCommits = useOnlyLatestRequest(
    async () => {
      if (!project || !project.id || isBlank(branch)) return [];
      setOptions([]);
      setLoading(true);
      const key = `commits/${project.id}/${branch}`;
      const lookup = () => ProjectStore.loadCommits(project.id, branch);
      return cachedLookup(key, lookup, 5);
    },
    (commits) => {
      setOptions(commits);
      setLoading(false);
    },
  );

  useEffect(() => {
    loadCommits();
  }, [project, branch]);

  return (
    <Select
      placeholder={placeholder}
      className="commit-select"
      isLoading={loading}
      isSearchable={true}
      value={value}
      isClearable={!!value && !!placeholder}
      options={options}
      onChange={onChange}
      error={error}
      label={label}
      isCreatable={true}
      createOptionText="Commit:"
    />
  );
};

export default CommitSelect;
