import { setPageInfo, useInitialEffect, useUnloadEffect } from "core/react-utils";
import { useHistory, useParams } from "react-router";
import "./environment-edit.scss";
import { Button, Form, Icon, Popup } from "semantic-ui-react";
import { AppStore, ModalStore, PipelineStore, SettingsStore } from "stores";
import Page from "pages/page";
import { Icons } from "core/constants";
import PipelineStageSelect from "components/form/pipeline-stage-select";
import EnvironmentForm from "models/forms/environment-form";
import { observer } from "mobx-react-lite";
import KeyInput from "components/form/key-input";
import EnvironmentVariables from "components/form/environment-variables";
import { WithEnvironmentVarsForm } from "models/forms/environment-var-form";
import { useState } from "react";

const form = new EnvironmentForm();

async function loadEnvironment(environmentIdParam: string) {
  if (environmentIdParam) {
    await SettingsStore.ensureEnvironments();
    form.populateFromEnvironment(SettingsStore.findEnvironment(parseInt(environmentIdParam)));
  }
}

async function deleteEnvironment(push: (path: string) => any) {
  ModalStore.confirmation.show(
    async (okClicked: boolean) => {
      if (okClicked) {
        await SettingsStore.deleteEnvironment(form.id);
        push("../environments");
      }
    },
    {
      message: `Are you sure you want to delete the ${form.key} environment?`,
      okText: "Delete",
      okColor: "red",
      okIcon: "trash",
    },
  );
}

async function gotoAuditLog(push: (path: string) => any) {
  push(`../environments/${form.id}/audit-log`);
}

async function saveEnvironment(push: (path: string) => any) {
  let header;
  let message;
  for (const variable of form.variables) {
    if ((variable.key != "" && variable.value != "") || (variable.encrypted && !variable.newVariable))
      continue;

    if (variable.file) {
      header = "Environment File Empty";
      message = "Looks like you have at least one file with empty contents. Please delete these files (highlighted with red text).";
    } else {
      header = "Variable Values Empty";
      message = "Looks like you have at least one variable with an empty Name and/or Value. Please fill in all variable fields or delete the variables that aren't needed.";
    }

    ModalStore.confirmation.show(() => {
      },
      {
        header: header,
        message: message,
        hideCancel: true,
        size: "small",
      });

    return;
  }

  const success = await SettingsStore.saveEnvironment(form);
  if (!success) {
    header = "Environment too large";
    message = "Looks like you're environment is too large. Please remove some variables/files and try again. Otherwise, you can click the \"Save\" button again to save anyway. If you do this, your test runs using this environment may fail.";

    ModalStore.confirmation.show(() => {
      },
      {
        header: header,
        message: message,
        hideCancel: true,
        size: "small",
      });

    return;
  }

  push("../environments");
}

const EnvironmentsEditPage = observer(() => {
  const { environmentId } = useParams<{ environmentId: string }>();
  const history = useHistory();
  const title = (environmentId ? "Edit" : "New") + " Environment";
  const [disableButtons, setDisableButtons] = useState(false);

  setPageInfo("environment-edit", title);

  useInitialEffect(() => {
    PipelineStore.ensureLoaded();
    SettingsStore.ensureEnvironments();
    loadEnvironment(environmentId);
  });

  useUnloadEffect(() => form.clearFormData());

  return (
    <Page name="environment-edit" title={title} icon={Icons.environment}>
      <section className="form-actions">
        <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "560px" }}>
          <div>
            <Button onClick={() => gotoAuditLog(history.push)}>
              <Icon name="stethoscope" /> View Audit Log
            </Button>
          </div>
        </div>
      </section>
      <Form onSubmit={() => null}>
        <Form.Group widths={"equal"}>
          <Form.Input
            label="Name"
            type="text"
            name="Name"
            value={form.name}
            error={form.getErrorText("name")}
            onChange={form.onChange("name")}
          />
          <KeyInput
            label="Key"
            value={form.key}
            onChange={form.onChange("key")}
            error={form.getErrorText("key")}
          />
        </Form.Group>
        <Form.Group widths={"equal"}>
          <Form.Input
            label="Environment URL"
            type="text"
            name="Environment URL"
            value={form.url}
            onChange={form.onChange("url")}
            error={form.getErrorText("url")}
          />
        </Form.Group>
        <Form.Group widths={"equal"}>
          <PipelineStageSelect
            label="Pipeline Stage"
            placeholder="Select..."
            value={form.pipelineStageId}
            onChange={form.onChange("pipelineStageId")}
          />
          <Form.Input
            label="Maximum Parallel Test Runs"
            type="text"
            name="Maximum Parallel Test Runs"
            value={form.maximumParallelTestRuns}
            onChange={form.onChange("maximumParallelTestRuns")}
            error={form.getErrorText("maximumParallelTestRuns")}
          />
          <Popup
            position="bottom center"
            content="Max number of test runs that can use this environment at once. Test runs submitted going over this limit will be queued. Enter '0' for no limit."
            trigger={<Icon name="question circle outline" />}
          />
        </Form.Group>
        <br />
        <EnvironmentVariables form={form as WithEnvironmentVarsForm} />
      </Form>
      <section className="form-actions">
        <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "560px" }}>
          <div>
            <Button disabled={disableButtons} onClick={() => history.goBack()}>
              <Icon name="times" />
              Cancel
            </Button>
            <Button disabled={disableButtons || !AppStore.userIsAdmin} color="green" onClick={async () => {
              setDisableButtons(true);
              try {
                await saveEnvironment(history.push);
              } finally {
                setDisableButtons(false);
              }
            }}>
              <Icon name="check" />
              Save
            </Button>
          </div>
          {form.id && (
            <Button disabled={disableButtons || !AppStore.userIsAdmin} color="red"
                    onClick={() => deleteEnvironment(history.push)}>
              <Icon name="trash" />
              Delete
            </Button>
          )}
        </div>

      </section>

    </Page>
  );
});

export default EnvironmentsEditPage;
