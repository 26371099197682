import { useInitialEffect } from "core/react-utils";
import { useHistory, useParams } from "react-router";
import { EmployeeStore, ModalStore } from "stores";
import Page from "pages/page";
import "./employee.scss";
import { Button, Card, CardContent, CardHeader, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import Spinner from "components/spinner";
import {
  Counts,
  groupTestRunTestsByFile,
  TestRun,
  TestRunner,
  TestRunnerStatus,
  TestRunTest,
} from "models/test-run";
import DatetimeDisplay from "components/datetime-display";
import "./test-run.scss";
import DurationDisplay from "components/duration-display";
import { Link } from "react-router-dom";
import { calculateDuration } from "core/date-utils";
import SortableTable from "components/sortable-table";
import { values } from "lodash";
import { substringAfterLast } from "core/utils";
import IconButton from "components/icon-button";
import { useState } from "react";

const EmployeeTestRunPage = observer(() => {
  const { testRunId } = useParams<{ testRunId: string }>();
  const [testRun, setTestRun] = useState(null as TestRun);
  const [testRunTests, setTestRunTests] = useState(null as TestRunTest[]);
  const [testRunners, setTestRunners] = useState(null as { [id: number]: TestRunner });
  const history = useHistory();

  const refresh = async () => {
    const id = parseInt(testRunId);
    setTestRun(await EmployeeStore.loadTestRun(id));
    setTestRunTests(await EmployeeStore.loadTestRunTests(id));
    setTestRunners(await EmployeeStore.loadTestRunners(id));
  };

  useInitialEffect(() => {
    EmployeeStore.init().then(refresh);
  });

  const counts = new Counts();
  if (testRun && testRunTests) {
    const testFiles = groupTestRunTestsByFile(testRun.id, testRunTests);
    testFiles.forEach((f) => counts.combine(f.counts));
    counts.checkMaxRunning(testRun, testFiles);
  }

  const stopRunner = async (testRunner: TestRunner) => {
    ModalStore.confirmation.show(
      async (okClicked) => {
        if (okClicked) {
          await EmployeeStore.stopTestRunner(testRunner);
          setTestRunners(await EmployeeStore.loadTestRunners(testRun.id));
        }
      },
      {
        message: "Are you sure you stop this test runner?",
        okColor: "red",
        okText: "Stop Test Runner",
      },
    );
  };

  const replaceRunner = async (testRunner: TestRunner) => {
    ModalStore.confirmation.show(
      async (okClicked) => {
        if (okClicked) {
          await EmployeeStore.replaceTestRunner(testRunner);
          setTestRunners(await EmployeeStore.loadTestRunners(testRun.id));
        }
      },
      {
        message: "Are you sure you replace this test runner?",
        okColor: "red",
        okText: "Replace Test Runner",
      },
    );
  };

  return (
    <Page name="employee-test-run" title="Employee Test Run">
      <div>
        <Button onClick={history.goBack}>
          <Icon name="chevron left" />
          Back
        </Button>
      </div>
      <div>
        <Button color="green" onClick={refresh}>
          Refresh Data
        </Button>
      </div>
      {!testRun && <Spinner size={24} text="Loading Test Run" />}
      {testRun && (
        <div>
          <Card.Group>
            <Card>
              <CardContent>
                <CardHeader>Test Run</CardHeader>
                <hr />
                <div className="item">ID: {testRun.id}</div>
                <div className="item">Status: {testRun.status}</div>
                {testRun.statusInfo && <div className="item">Message: {testRun.statusInfo}</div>}
                <div className="item">
                  Created At: <DatetimeDisplay datetime={testRun.createdAt} />
                </div>
                <div className="item">
                  Started At: <DatetimeDisplay datetime={testRun.startTime} />
                </div>
                <div className="item">
                  Ended At: <DatetimeDisplay datetime={testRun.endTime} />
                </div>
                <div className="item">
                  Duration:{" "}
                  <DurationDisplay
                    duration={calculateDuration(testRun.startTime, testRun.endTime)}
                  />
                </div>
                <div className="item">
                  Total Execution Time: <DurationDisplay duration={testRun.totalTestingTime} />
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <CardHeader>Test Run Info</CardHeader>
                <hr />
                <div className="item">Environment ID: {testRun.environmentId}</div>
                <div className="item">Project ID: {testRun.projectId}</div>
                <div className="item">Branch: {testRun.branch}</div>
                <div className="item">Ref: {testRun.gitRef?.substr(0, 8)}</div>
                <div className="item">Build: {testRun.build?.name}</div>
                <div className="item">Deploy: {testRun.deployId}</div>
                <div className="item">Copies: {testRun.copies}</div>
                <div className="item">Test Timeout Seconds: {testRun.testTimeoutSeconds}</div>
                <div className="item">Test Run Timeout Minutes: {testRun.timeoutMinutes}</div>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <CardHeader>Test Info</CardHeader>
                <hr />
                <div className="item">Pass: {counts.pass}</div>
                <div className="item">Fail: {counts.fail}</div>
                {counts.ignored > 0 && <div className="item">Ignored: {counts.ignored}</div>}
                {counts.running > 0 && <div className="item">Running: {counts.running}</div>}
                {counts.pending > 0 && <div className="item">Pending: {counts.pending}</div>}
                {counts.notRun > 0 && <div className="item">Not Run: {counts.notRun}</div>}
                <div className="item">Total: {counts.total}</div>
              </CardContent>
            </Card>
          </Card.Group>
          <div>
            <Button
              color="green"
              onClick={() => history.push(`/employee/test-runs/${testRunId}/logs`)}
            >
              View Test Run Logs
            </Button>
            <Button
              color="green"
              onClick={() => history.push(`/employee/test-runs/${testRunId}/test-list-result`)}
            >
              View Test List Result
            </Button>
          </div>
          <br />
          <SortableTable
            loading={testRunTests != null && testRunners == null}
            title="Test Runners"
            headerColumns={[
              { content: "ID", sortKey: "id" },
              { content: "UUID", sortKey: "uuid" },
              { content: "Status", sortKey: "status" },
              { content: "Tests Run", sortKey: "testsRun" },
              { content: "Agent ID", sortKey: "testRunnerAgentId" },
              { content: "Task", sortKey: "taskArn" },
              { content: "Test Start Time", sortKey: "currentTestStartTime" },
              { content: "Current Test", sortKey: "currentTest" },
              { content: "" },
            ]}
            items={values(testRunners)}
            defaultSortKey="id"
            renderRow={(runner) => (
              <tr key={runner.id}>
                <td>{runner.id}</td>
                <td>
                  <Link to={`/employee/test-runs/${testRun.id}/runners/${runner.uuid}`}>
                    {runner.uuid.substring(0, 8)}
                  </Link>
                </td>
                <td>{runner.status}</td>
                <td>{runner.testsRun}</td>
                <td>{runner.testRunnerAgentId}</td>
                <td>{substringAfterLast(runner.taskArn, "/")}</td>
                <td>
                  <DatetimeDisplay
                    showSecond={true}
                    datetime={runner.currentTestStartTime}
                    hideDate={true}
                  />
                </td>
                <td>{runner.status == TestRunnerStatus.Running ? runner.currentTest : null}</td>
                <td>
                  {runner.status != TestRunnerStatus.Exited && (
                    <>
                      <IconButton
                        icon="stop circle outline"
                        label="Stop Runner"
                        onClick={() => stopRunner(runner)}
                      />
                      <IconButton
                        icon="repeat"
                        label="Replace Runner"
                        onClick={() => replaceRunner(runner)}
                      />
                    </>
                  )}
                </td>
              </tr>
            )}
          />
          <br />
          <SortableTable
            loading={testRunTests == null}
            title="Test Results"
            headerColumns={[
              { content: "ID", sortKey: "id" },
              { content: "Project Test", sortKey: "projectTest.id" },
              { content: "Status", sortKey: "status" },
              { content: "File", sortKey: "projectTest.file" },
              { content: "Name", sortKey: "projectTest.name" },
              { content: "Start Time", sortKey: "startTime" },
              { content: "Duration", sortKey: "calculatedDuration" },
              { content: "Runner", sortKey: "testRunnerId" },
            ]}
            items={testRunTests}
            defaultSortKey="projectTest.id"
            renderRow={(trt) => (
              <tr key={trt.id}>
                <td>
                  <Link to={`/employee/test-runs/${trt.testRunId}/tests/${trt.id}`}>{trt.id}</Link>
                </td>
                <td>{trt.projectTest.id}</td>
                <td>{trt.status}</td>
                <td>{trt.projectTest.file}</td>
                <td className="name">{trt.projectTest.name}</td>
                <td>
                  <DatetimeDisplay datetime={trt.startTime} hideDate={true} showSecond={true} />
                </td>
                <td>
                  <DurationDisplay duration={trt.duration || trt.calculatedDuration} />
                </td>
                <td>
                  {testRunners && testRunners[trt.testRunnerId] && (
                    <Link
                      to={`/employee/test-runs/${trt.testRunId}/runners/${
                        testRunners[trt.testRunnerId].uuid
                      }`}
                    >
                      {testRunners[trt.testRunnerId].uuid.substring(0, 8)}
                    </Link>
                  )}
                </td>
              </tr>
            )}
          />
        </div>
      )}
    </Page>
  );
});

export default EmployeeTestRunPage;
