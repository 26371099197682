import { TestFramework } from "models/project";
import Select from "./select";
import { ProjectStore } from "stores";
import { observer } from "mobx-react-lite";
import { RefObject } from "react";
import { useEffect, useInitialEffect } from "core/react-utils";

interface Props {
  value: number;
  onChange: (id: number) => any;
  placeholder?: string;
  itemRef?: RefObject<HTMLElement>;
  error?: boolean | string;
  label?: string;
  allowOtherFramework?: boolean;
  onlyWithDeploys?: boolean;
  onlyWithTests?: boolean;
}

const ProjectSelect = observer(
  ({
     value,
     onChange,
     placeholder,
     itemRef,
     error,
     label,
     allowOtherFramework,
     onlyWithDeploys,
     onlyWithTests,
   }: Props) => {
    useInitialEffect(() => {
      ProjectStore.ensureLoaded();
    });
    let projects = ProjectStore.active;

    if (onlyWithDeploys) {
      projects = projects.filter((p) => p.hasDeploy);
    }

    if (!allowOtherFramework) {
      projects = projects.filter((p) => p.testingFramework != TestFramework.Other);
    }

    if (onlyWithTests) {
      projects = projects.filter((p) => p.hasTests);
    }

    useEffect(() => {
      if (!projects.find((p) => p.id == value)) {
        if (placeholder) {
          onChange(null);
        } else {
          onChange(projects[0]?.id);
        }
      }
    }, [value]);

    const options = projects.map(project => {
      const label = (project.name === project.key) ? project.name : `${project.name} ${project.key}`;
      return { label: label, value: project.key, projectName: project.name, projectId: project.id };
    });

    const selectedProject = ProjectStore.find(value);

    return (
      <Select
        className="project-select"
        placeholder={placeholder || ""}
        value={selectedProject ? {
          label: (selectedProject.name === selectedProject.key) ? selectedProject.name : `${selectedProject.name} ${selectedProject.key}`,
          value: selectedProject.key,
          projectName: selectedProject.name,
          projectId: selectedProject.id,
        } : null
      }
        isClearable={!!placeholder && !!value}
        isSearchable={true}
        onChange={(option) => onChange(option && option.projectId)}
        options={options}
        valueField="projectId"
        formatOption={(option) =>
          option.value != option.label ? (
            <div>
              {option.projectName}
              <div className="info">Key: {option.value}</div>
            </div>
          ) : (
            option.label
          )
        }
        autoDefault={!placeholder}
        itemRef={itemRef}
        error={error}
        label={label}
      />
    );
  },
);

export default ProjectSelect;
