import { observer } from "mobx-react-lite";
import { Workflow } from "models/workflow";
import { useCallback, useState } from "react";
import ReactFlow, { applyEdgeChanges, applyNodeChanges, Edge, Node } from "react-flow-renderer";
import { AppStore } from "stores";

const initialNodes: Node[] = [
  {
    id: "1",
    type: "input",
    data: { label: "Input Node" },
    position: { x: 250, y: 25 },
  },

  {
    id: "2",
    // you can also pass a React component as a label
    data: { label: <div>Default Node</div> },
    position: { x: 100, y: 125 },
  },
  {
    id: "3",
    type: "output",
    data: { label: "Output Node" },
    position: { x: 250, y: 250 },
  },
];

const initialEdges: Edge[] = [
  { id: "e1-2", source: "1", target: "2" },
  { id: "e2-3", source: "2", target: "3", animated: true },
];

interface Props {
  workflow: Workflow;
}

const Steps = observer(({ workflow: _workflow }: Props) => {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), [setNodes]);
  const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), [setEdges]);

  return (
    <div style={{ width: "100%", height: AppStore.windowHeight - 160 + "px" }}>
      <ReactFlow nodes={nodes} edges={edges} onNodesChange={onNodesChange} onEdgesChange={onEdgesChange} fitView />
    </div>
  );
});

export default Steps;
