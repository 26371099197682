import { AuthorizationProvider, Authorization, AuthReason } from "models/settings";
import "./git-provider-select.scss";
import { SettingsStore } from "stores";
import * as Storage from "core/storage";
import { observer } from "mobx-react-lite";
import { Button, Icon } from "semantic-ui-react";
import { repositoryAuthorize } from "core/auth0";

interface Props {
  value: Authorization;
  onChange: (t: Authorization) => any;
}

const GitProviderSelect = observer(({ onChange, value }: Props) => {
  const auths = SettingsStore.authorizations;
  const gitHub = auths.find((a) => a.provider == AuthorizationProvider.GitHub);
  const bitBucket = auths.find((a) => a.provider == AuthorizationProvider.BitBucket);
  const bitBucketServer = auths.find((a) => a.provider == AuthorizationProvider.BitBucketServer);
  const gitlab = auths.find((a) => a.provider == AuthorizationProvider.Gitlab);

  function doAuth(provider: string) {
    Storage.setAuthInfo(window.location.pathname, AuthReason.Integration);
    repositoryAuthorize(provider);
  }

  if (!gitHub && !gitlab && !bitBucket && !bitBucketServer) {
    return SettingsStore.authorizationsLoaded ? (
      <div>
        <h3>You must authorize a Git provider before you can add a repository</h3>
        <Button inverted color="green" onClick={() => doAuth("github")}>
          <Icon name="github" />
          Authorize GitHub
        </Button>
        <Button inverted color="green" onClick={() => doAuth("bitbucket")}>
          <Icon name="bitbucket" />
          Authorize BitBucket
        </Button>
        <Button inverted color="green" onClick={() => doAuth("Gitlab")}>
          <Icon name="git" />
          Authorize Gitlab
        </Button>
      </div>
    ) : null;
  }

  if (!value) onChange((value = gitlab || gitHub || bitBucket || bitBucketServer));

  return (
    <div className="git-provider-select">
      {gitHub && (
        <div
          onClick={() => onChange(gitHub)}
          className={`provider ${value.id == gitHub.id ? "active" : ""}`}
        >
          <Icon name="github" /> GitHub
        </div>
      )}
      {bitBucket && (
        <div
          onClick={() => onChange(bitBucket)}
          className={`provider ${value.id == bitBucket.id ? "active" : ""}`}
        >
          <Icon name="bitbucket" /> BitBucket
        </div>
      )}
      {bitBucketServer && (
        <div
          onClick={() => onChange(bitBucketServer)}
          className={`provider ${value.id == bitBucketServer.id ? "active" : ""}`}
        >
          <Icon name="bitbucket" /> BitBucket Server
        </div>
      )}
      {gitlab && (
        <div
          onClick={() => onChange(gitlab)}
          className={`provider ${value.id == gitlab.id ? "active" : ""}`}
        >
          <Icon name="git" /> Gitlab
        </div>
      )}
    </div>
  );
});

export default GitProviderSelect;
