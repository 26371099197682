import Form from "./form";
import { observable, action } from "mobx";
import { notEmpty, validEmail } from "core/validation";
import { RoleType } from "models/user";
import { UserStore } from "stores";

export default class UserInvitationForm extends Form {
  @observable email: string;
  @observable firstName: string;
  @observable lastName: string;
  @observable roleType: RoleType;

  validators = {
    email: [
      validEmail,
      {
        validator: (value: string) => {
          return !UserStore.isEmailInUse(value.toLowerCase());
        },
        message: "Email already used",
      },
    ],
    firstName: notEmpty,
    lastName: notEmpty,
    roleType: notEmpty,
  };

  @action.bound
  setDefaults() {
    this.email = "";
    this.firstName = "";
    this.lastName = "";
    this.roleType = RoleType.USER;
  }
}
