import { observer } from "mobx-react-lite";
import "./setup.scss";
import { AppStore, SettingsStore } from "../../stores";
import { useInitialEffect } from "../../core/react-utils";
import { AccountSetupStep, AccountSetupStepType } from "../../models/account-setup";
import Spinner from "components/spinner";

const FinalStep = observer(() => {

  useInitialEffect(async () => {
    await SettingsStore.createAccountSetupStep(new AccountSetupStep(AccountSetupStepType.Final));
    await AppStore.loadAccount();
    window.location.href = `/${AppStore.selectedAccountName}/get-started`;  
  });

  return (
    <div className={"final"}>
      <h3 id={"setup-complete-success-text"}>Welcome to Testery, we're setting up your account right now.</h3>
      <Spinner size={16} text="Finalizing account setup..." />
    </div>
  );
});

export default FinalStep;
