import Stages from "components/test-plan/stages";
import { observer } from "mobx-react-lite";
import { TestPlan, TestPlanStage } from "models/test-plan";
import { useHistory } from "react-router";
import { Button, Icon } from "semantic-ui-react";
import { ModalStore, TestPlanStore } from "stores";

interface Props {
  testPlan: TestPlan;
}

const StagesTab = observer(({ testPlan }: Props) => {
  const history = useHistory();

  return (
    <div className="stages-tab">
      <div className="page-actions">
        <Button
          color="green"
          onClick={() =>
            ModalStore.testPlanStageEdit.show(
              null,
              new TestPlanStage().assign({ testPlanId: testPlan.id }),
            )
          }
        >
          <Icon name="plus" />
          Add New Stage
        </Button>
        {testPlan.hasTestSuites && (
          <Button
            color="green"
            inverted
            onClick={async () => {
              await TestPlanStore.runTestPlan(testPlan.id);
              history.push("runs");
            }}
            disabled={testPlan.hasDynamicEnvironments}
          >
            <Icon name="play" />
            Run Test Plan
          </Button>
        )}
      </div>
      <Stages testPlan={testPlan} />
    </div>
  );
});

export default StagesTab;
