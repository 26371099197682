import { useInitialEffect, setPageInfo } from "core/react-utils";
import { observer } from "mobx-react-lite";
import { Button, Icon, Tab } from "semantic-ui-react";
import { AppStore, PipelineStore } from "stores";
import PipelineStages from "components/pipeline-stages";
import { useHistory } from "react-router";

const PipelineStagesTab = observer(() => {
  setPageInfo("environments", "Pipeline Stages");
  const history = useHistory();

  useInitialEffect(() => {
    PipelineStore.loadAll();
  });

  return (
    <Tab.Pane className="pipeline-stages-tab">
      <div>
        Pipeline stages are the stages in your devops deployment pipeline. These stages will be
        shown in the order you specify in the Environment Dashboard to give you insight and
        visibility into what is happening in each stage. (Example: Dev, Test, Staging, Production,
        etc).
      </div>
      <div className="page-actions">
        {AppStore.userIsAdmin && (
          <Button color="green" onClick={() => history.push("pipelines/new")}>
            <Icon name="plus" />
            Add New Pipeline Stage
          </Button>
        )}
      </div>
      <PipelineStages />
    </Tab.Pane>
  );
});

export default PipelineStagesTab;
