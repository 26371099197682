import { observer } from "mobx-react-lite";
import { useInitialEffect } from "core/react-utils";
import { AnalyticsStore } from "stores";
import { Card, Header } from "semantic-ui-react";
import { TestRunTestComparisonChartData, TestRunComparisonChartData } from "models/analytics";
import ChartTab from "./chart-tab";
import TestRunProgress from "../../components/test-run-progress";
import SlowerTestsComparison from "../../components/slower-tests-comparison";
import NewTestFailuresComparison from "../../components/new-test-failures-comparison";

const emptyTestRunComparisonChartData = new TestRunComparisonChartData();
const emptySlowerTestsComparisonChartData = new TestRunTestComparisonChartData();

const TestRunComparisonTab = observer(() => {
  const form = AnalyticsStore.testRunComparisonChartData.options;
  const testRunComparisonChartData = AnalyticsStore.testRunComparisonChartData.data || emptyTestRunComparisonChartData;
  const slowerTestsComparisonData = AnalyticsStore.slowerTestsComparisonChartData.data || emptySlowerTestsComparisonChartData;

  const testRunOneTestCounts = testRunComparisonChartData.runOne;
  const testRunTwoTestCounts = testRunComparisonChartData.runTwo;

  const handleQueryParams = async () => {
    const params = new URLSearchParams(window.location.search);
    const testRunIdsParam = params.get("test_run_ids") || "";
    const test_run_ids = testRunIdsParam.split(",");
    if (test_run_ids.length > 0) {
      form.updateTestRunOneId(parseInt(test_run_ids[0]));
    }
    if (test_run_ids.length > 1) {
      form.updateTestRunTwoId(parseInt(test_run_ids[1]));
    }
  };

  useInitialEffect(async () => {
    await handleQueryParams();
    await AnalyticsStore.loadTestRunComparisonChartData();
    await AnalyticsStore.loadSlowerTestsData();
  });

  return (
    <ChartTab
      filterList={[]}
      chartData={AnalyticsStore.testRunComparisonChartData}
      drawChart={() => {
      }}
      header={() => (
        <div>
          <Header>
            Test Run Comparison Report
          </Header>
          <Card.Group centered>
            <Card>
              <Card.Content>
                <div className="value">Add Test Run Title Here</div>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <div className="value">Add Test Run Title Here</div>
              </Card.Content>
            </Card>
          </ Card.Group>
          <div className="ui grid">
            <div className="eight wide column">
              <Header>
                Total Tests Run
              </Header>
              <div className="ui placeholder segment">
                <div className="ui two column stackable center aligned grid">
                  <div className="ui vertical divider top"></div>
                  <div className="middle aligned row">
                    <div className="column">
                      <Card>
                        <Card.Content>
                          <div className="name">
                            Test Run 1
                          </div>
                          <div className="value">{testRunOneTestCounts.totalTests}</div>
                        </Card.Content>
                      </Card>
                    </div>
                    <div className="column">
                      <Card>
                        <Card.Content>
                          <div className="name">
                            Test Run 2
                          </div>
                          <div className="value tqlResults">{testRunTwoTestCounts.totalTests}</div>
                        </Card.Content>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="eight wide column">
              <Header>
                Pass/Fail Counts
              </Header>
              <div className="ui placeholder segment">
                <div className="ui two column stackable center aligned grid">
                  <div className="ui vertical divider"></div>
                  <div className="middle aligned row">
                    <div className="column">
                      <Card>
                        <Card.Content>
                          <div className="name">
                            Test Run 1
                          </div>
                          <TestRunProgress pass={testRunOneTestCounts.testPassCount}
                                           fail={testRunOneTestCounts.testFailCount}
                                           total={testRunOneTestCounts.totalTests} />
                        </Card.Content>
                      </Card>
                    </div>
                    <div className="column">
                      <Card>
                        <Card.Content>
                          <div className="name">
                            Test Run 2
                          </div>
                          <TestRunProgress pass={testRunTwoTestCounts.testPassCount}
                                           fail={testRunTwoTestCounts.testFailCount}
                                           total={testRunTwoTestCounts.totalTests} />
                        </Card.Content>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="eight wide column">
              <Header>
                Slower Tests (Top 10)
              </Header>
              <div className="ui placeholder segment">
                <div className="middle aligned row">
                  <div className="column">
                    <Card style={{width: "100%"}}>
                      <Card.Content>
                        <SlowerTestsComparison data={slowerTestsComparisonData} />
                      </Card.Content>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
            <div className="eight wide column">
              <Header>
                New Test Failures
              </Header>
              <div className="ui placeholder segment">
                <div className="middle aligned row">
                  <div className="column">
                    <Card style={{width: "100%"}}>
                      <Card.Content>
                        <NewTestFailuresComparison data={slowerTestsComparisonData} />
                      </Card.Content>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    />);

});

export default TestRunComparisonTab;
