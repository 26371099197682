import RepoSelect from "./repo-select";
import NewTestRun from "./new-test-run";
import OrganizationSelect from "./organization-select";
import Confirmation from "./confirmation";
import UpdatePassword from "./update-password";
import CommitInfo from "./commit-info";
import NotificationSettings from "./notification-settings";
import RunTestSuite from "./run-test-suite";
import TestPlanStageEdit from "./test-plan-stage-edit";
import TestPlanSuiteEdit from "./test-plan-suite-edit";
import EnvironmentFileNew from "./environment-file-new";
import PipelineEnvironment from "./pipeline-environment";
import TeamInfo from "./team-info";
import ReleaseCriteriaTemplateModal from "./release/release-criteria-template-modal";
import NewReleaseModal from "./release/new-release-modal";
import UploadTestRun from "./upload-test-run";
import UpsellModal from "./upsell";
import JiraModal from "./jira";

export default () => (
  <span>
    <RepoSelect />
    <NewTestRun />
    <UploadTestRun />
    <TeamInfo />
    <OrganizationSelect />
    <Confirmation />
    <UpdatePassword />
    <CommitInfo />
    <NotificationSettings />
    <RunTestSuite />
    <TestPlanStageEdit />
    <TestPlanSuiteEdit />
    <EnvironmentFileNew />
    <NewReleaseModal />
    <ReleaseCriteriaTemplateModal />
    <PipelineEnvironment />
    <UpsellModal />
    <JiraModal />
  </span>
);
