import { observer } from "mobx-react-lite";
import Spinner from "components/spinner";
import { rerunWhileComponentLoaded } from "core/react-utils";
import { orderBy } from "lodash";
import { TestPlanStore } from "stores";
import "./test-plan-restuls.scss";
import ResultRow from "./result-row";
import { Button, Icon } from "semantic-ui-react";


interface Props {
  testPlanId: number;
}

const TestPlanResults = observer(({testPlanId}: Props) => {
  rerunWhileComponentLoaded(() => TestPlanStore.loadTestPlanRuns(0, testPlanId), 20);

  return (
    <div className="test-plan-results">
      {!TestPlanStore.testPlanRuns && <Spinner text="Loading Test Plan Runs" />}
      {TestPlanStore.testPlanRuns?.length === 0 && <h3>No Test Plan Runs Found</h3>}
      {TestPlanStore.testPlanRuns?.length > 0 && (
          <>
          <table className="table test-plan-runs" key={1}>
            <thead>
              <tr>
                <th className="date">Date</th>
                <th className="status">Status</th>
                <th className="results" colSpan={3}>
                  Results
                </th>
                <th className="duration">Duration</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody>
              {orderBy(TestPlanStore.testPlanRuns, ["createdAt", "id"], "desc").map((run) => (
                <ResultRow key={run.id} run={run} />
              ))}
            </tbody>
          </table>
          {!TestPlanStore.lastTestPlanRunsPageLoaded && (
           <Button size="tiny" color="green" onClick={async () => {
              TestPlanStore.loadNextTestPlanRunsPage(testPlanId)
          }}>
            <Icon name="plus" />
            Load Older Runs
          </Button>
          )}
        </>
      )}
    </div>
  );
});

export default TestPlanResults;
