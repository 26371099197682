import { useInitialEffect, useUnloadEffect, setPageInfo } from "core/react-utils";
import { useParams, useHistory } from "react-router";
import "./pipeline-stage-edit.scss";
import { Button, Form, Icon } from "semantic-ui-react";
import { PipelineStore, RunnerPoolStore } from "stores";
import Page from "pages/page";
import { Icons } from "core/constants";
import { observer } from "mobx-react-lite";
import PipelineStageForm from "models/forms/pipeline-stage-form";
import NumberSelect from "components/form/number-select";
import EnvironmentVariables from "components/form/environment-variables";
import { WithEnvironmentVarsForm } from "models/forms/environment-var-form";
import RunnerPoolSelect from "components/form/runner-pool-select";

const form = new PipelineStageForm();

async function loadPipelineStage(id: string) {
  await PipelineStore.ensureLoaded();
  if (id) {
    form.populateFromPipelineStage(PipelineStore.find(parseInt(id)));
  } else {
    form.priority = PipelineStore.items.length;
  }
}

async function savePipelineStage(push: (path: string) => any) {
  await PipelineStore.save(form);
  push("../pipelines");
}

const PipelineStageEditPage = observer(() => {
  const { pipelineStageId } = useParams<{ pipelineStageId: string }>();
  const history = useHistory();
  const title = (pipelineStageId ? "Edit" : "New") + " Pipeline Stage";

  setPageInfo("pipeline-stage-edit", title);

  useInitialEffect(() => {
    PipelineStore.ensureLoaded();
    RunnerPoolStore.ensureLoaded();
    loadPipelineStage(pipelineStageId);
  });

  useUnloadEffect(() => form.clearFormData());

  return (
    <Page name="pipeline-stage-edit" title={title} icon={Icons.pipelines}>
      <Form onSubmit={() => null}>
        <Form.Input
          label="Name"
          type="text"
          name="Name"
          placeholder="Pipeline stage (Develop, Staging, Production, etc)"
          value={form.name}
          error={form.getErrorText("name")}
          onChange={form.onChange("name")}
          autoFocus
        />
        <NumberSelect
          label="Test Execution Priority"
          value={form.testRunPriority}
          onChange={form.onChange("testRunPriority")}
          min={1}
          max={10}
        />
        <EnvironmentVariables form={form as WithEnvironmentVarsForm} />
        <RunnerPoolSelect
              label="Windows Runner Pool"
              value={form.windowsRunnerPoolId}
              onChange={form.onChange("windowsRunnerPoolId")}
              error={form.getErrorText("windowsRunnerPoolId")}
              runnerType="WINDOWS"
              placeholder="Select a runner pool"
            />

        <RunnerPoolSelect
              label="Linux Runner Pool"
              value={form.linuxRunnerPoolId}
              onChange={form.onChange("linuxRunnerPoolId")}
              error={form.getErrorText("linuxRunnerPoolId")}
              runnerType="LINUX"
              placeholder="Select a runner pool"
            />
      </Form>
      <section className="form-actions">
        <Button onClick={() => history.goBack()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button color="green" onClick={() => savePipelineStage(history.push)}>
          <Icon name="check" />
          Save
        </Button>
      </section>
    </Page>
  );
});

export default PipelineStageEditPage;
