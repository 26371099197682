import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useInitialEffect } from "core/react-utils";
import { EmployeeStore } from "stores";
import { LogMessage } from "models/test-run";
import Page from "pages/page";
import { Button, Icon } from "semantic-ui-react";
import "./test-result.scss";
import Spinner from "components/spinner";
import "./runner-logs.scss";
import { format, parseISO } from "date-fns";

const EmployeeRunnerLogsPage = () => {
  const { runnerId, testRunId, agentId } = useParams<{
    runnerId: string;
    testRunId: string;
    agentId: string;
  }>();
  const history = useHistory();
  const [logs, setLogs] = useState(null as LogMessage[]);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    if (runnerId) {
      setLogs(await EmployeeStore.loadRunnerLogs(runnerId));
    } else if (testRunId) {
      setLogs(await EmployeeStore.loadTestRunLogs(parseInt(testRunId)));
    } else if (agentId) {
      setLogs(await EmployeeStore.loadAgentLogs(parseInt(agentId)));
    }
    setLoading(false);
  };

  useInitialEffect(() => {
    EmployeeStore.init().then(loadData);
  });

  let output = "";

  if (logs) {
    logs.forEach((l) => {
      output += format(parseISO(l.time + "+0000"), "HH:mm:ss.SSS") + " - " + l.message + "\n";
    });
  }

  return (
    <Page name="employee-runner-logs" title={`Employee Runner ${runnerId}`}>
      <Button onClick={history.goBack}>
        <Icon name="chevron left" />
        Back
      </Button>
      <Button color="green" onClick={() => window.scrollTo(0, document.body.scrollHeight)}>
        Scroll To Bottom
      </Button>
      {!logs && <Spinner />}
      {logs && logs.length == 0 && <h3>No logs to load</h3>}
      {logs && logs.length > 0 && (
        <div>
          <h3>Log Messages on {format(parseISO(logs[0].time + "+0000"), "MMM dd, yyyy")}</h3>
          <pre>{output}</pre>
        </div>
      )}
      <Button color="green" onClick={loadData} loading={loading}>
        Refresh Data
      </Button>
      <Button onClick={history.goBack}>
        <Icon name="chevron left" />
        Back
      </Button>
    </Page>
  );
};

export default EmployeeRunnerLogsPage;
