import Form from "./form";
import { action, observable } from "mobx";
import { notEmpty } from "core/validation";
import { SettingsStore } from "stores";

export default class TestRunUploadForm extends Form {
  @observable id: number;
  @observable projectId: number;
  @observable environmentId: number;
  @observable pipelineStageId: number;
  @observable commit: string;
  @observable branch: string;
  @observable file: File;

  validators = {
    projectId: notEmpty,
    environmentId: {
      validator: (environmentId: number) => {
        return environmentId != null;
      },
      message: "You must select an environment",
    },

  };


  @action
  setDefaults() {
    this.branch = "master";
    const env = SettingsStore.activeEnvironments[0];
    this.environmentId = env && env.id;
  }
}
