import Select from "./select";
import { TestDistributionRuleType } from "models/settings";

interface Props {
  value: TestDistributionRuleType;
  label?: string;
  error?: string;
  onChange: (v: TestDistributionRuleType) => any;
  hideOther?: boolean;
}

const TestDistributionRuleTypeSelect = ({ value, label, error, onChange, hideOther }: Props) => {
  const options = Object.keys(TestDistributionRuleType);

  if (hideOther) {
    options.pop();
  }

  return (
    <Select
      error={error}
      label={label}
      className="test-distribution-rule-type-select"
      value={value}
      options={options}
      onChange={(v) => onChange(TestDistributionRuleType[v] || null)}
    />
  );
};

export default TestDistributionRuleTypeSelect;
