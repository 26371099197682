import { observer } from "mobx-react-lite";
import { TestRun } from "models/test-run";
import "./history-tab.scss";
import { TestRunHistoryStore } from "stores";
import SortableTable from "components/sortable-table";
import { useInitialEffect } from "core/react-utils";
import DatetimeDisplay from "components/datetime-display";


interface Props {
  testRun: TestRun;
}

const HistoryTab = observer(({ testRun }: Props) => {

  useInitialEffect(async () => {
    await TestRunHistoryStore.loadHistory(testRun.id)
  })

  const historyItems = TestRunHistoryStore.historyItems;

  return (
    <div className="history-tab">
      <SortableTable
        className="trigger-list"
        loading={!TestRunHistoryStore.historyLoaded}
        loadingText="Loading Test Run History"
        headerColumns={[
          { className: "timeStamp", content: "Time", sortKey: "timeStamp" },
          {
            className: "userName",
            content: "Change User Name",
            sortKey: "userName",
          },
          {
            className: "userEmail",
            content: "Change User Email",
            sortKey: "userEmail",
          },
          {
            className: "updatedColumn",
            content: "Updated Field",
            sortKey: "updatedColumn",
          },
          {
            className: "updatedValue",
            content: "New Value",
            sortKey: "newValue",
          }
        ]}
        items={historyItems}
        renderRow={(historyItem) => (
          <tr key={historyItem.id}>
            <td><DatetimeDisplay datetime={historyItem.timeStamp} /></td>
            <td>{historyItem.userName ? historyItem.userName : "N/A"}</td>
            <td>{historyItem.userEmail ? historyItem.userEmail : "N/A"}</td>
            <td>{historyItem.updatedColumn}</td>
            <td>{historyItem.updatedValue}</td>
          </tr>
        )} 
        // filter={filter}
        defaultSortKey="timeStamp"
        defaultDecending={true}
      />
    </div>
  );
});

export default HistoryTab;
