import Select from "./select";
import { TestStatus } from "models/test-run";

interface Props {
  value: TestStatus[];
  onChange: (value: TestStatus[]) => any;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
}

const TestMultiStatusSelect = ({ onChange, value, placeholder, error, label }: Props) => {
  const convertToString = (s: TestStatus) => s.toString().replace("_", " ");
  const convertFromString = (s: string) => TestStatus[s.replace(" ", "_")];

  return (
    <Select
      className="test-multi-status-select"
      placeholder={placeholder || ""}
      onChangeMulti={(v) => onChange(v ? v.map(convertFromString) : null)}
      value={value.map(convertToString)}
      isClearable={!!value && value.length > 0}
      options={Object.keys(TestStatus).map(convertToString)}
      error={error}
      label={label}
      isMulti
    />
  );
};

export default TestMultiStatusSelect;
