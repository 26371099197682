import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import Page from "pages/page";
import { Button, Header, Segment } from "semantic-ui-react";
import { AppStore, UserStore } from "stores";
import { Form, Icon } from "semantic-ui-react";

import "./tell-a-friend.scss";
import TellAFriendForm from "models/forms/tell-a-friend-form";
import { Alert } from "@mui/material";

const tellAFriendForm = new TellAFriendForm();

const TellAFriendPage = observer(() => {

  useInitialEffect(async () => 
  {
    tellAFriendForm.clearFormData();
    await AppStore.loadUser()
  });

  const sendRequest = async () => {
    await UserStore.tellAFriend(tellAFriendForm);
    await AppStore.loadUser();
    tellAFriendForm.setDefaults();
  };

  return (
    <Page name="tell-a-friend" title="Invite a Friend" titleSize="none">
      <Header size="large">Invite a friend to join the Playwright Beta on Testery</Header>
      <Segment>
      <Alert variant="outlined" severity="info">
        <p>You have {AppStore.user.tellAFriendRemaining} invitations remaining.</p>
      </Alert>
          <Form>
              <Form.Input
                label="Email"
                type="email"
                value={tellAFriendForm.email}
                onChange={tellAFriendForm.onChange("email")}
                error={tellAFriendForm.getErrorText("email")}
              />
              <Form.Input
                label="First Name"
                value={tellAFriendForm.firstName}
                onChange={tellAFriendForm.onChange("firstName")}
                error={tellAFriendForm.getErrorText("firstName")}
              />
              <Form.Input
                label="Last Name"
                value={tellAFriendForm.lastName}
                onChange={tellAFriendForm.onChange("lastName")}
                error={tellAFriendForm.getErrorText("lastName")}
              />
              <Button color="green" onClick={sendRequest} loading={tellAFriendForm.isSubmitting}>
                <Icon name="plus" />
                Invite to Testery
              </Button>
              <br />
              {tellAFriendForm.isSubmitted && (
              <Alert variant="outlined" severity="info">
                  <p>Thank you!  We'll get them signed up for the Testery Playwright Beta right away.</p>
              </Alert>
              )}
            </Form>
      </Segment>
    </Page>
  );
});

export default TellAFriendPage;
