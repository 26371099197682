import { useHistory } from "react-router-dom";
import { useInitialEffect, useNavigation } from "core/react-utils";
import "./alerts.scss";
import { eventTypeText, actionTypesText, Alert } from "models/settings";
import Page from "pages/page";
import { AppStore, ModalStore, SettingsStore } from "stores";
import { observer } from "mobx-react-lite";
import { Button, Icon } from "semantic-ui-react";
import { Icons } from "core/constants";
import SortableTable from "components/sortable-table";
import ThreeDotMenu from "components/three-dot-menu";

const alertText = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

async function deleteAlert(alert: Alert) {
  ModalStore.confirmation.show(
    async (okClicked: boolean) => {
      if (okClicked) {
        await SettingsStore.deleteAlert(alert.id);
      }
    },
    {
      message: `Are you sure you want to delete this alert?`,
      okText: "Delete",
      okColor: "red",
    },
  );
}

const AlertsPage = observer(() => {
  useInitialEffect(() => {
    SettingsStore.loadAlerts();
  });
  const history = useHistory();

  return (
    <Page name="alerts" title="Alerts" icon={Icons.alert}>
      <div className="page-actions">
        {AppStore.userIsAdmin && (
          <Button color="green" onClick={useNavigation("alerts/new")}>
            <Icon name="plus" />
            Add New Alert
          </Button>
        )}
      </div>
      <SortableTable
        loading={!SettingsStore.alertsLoaded}
        loadingText="Loading Alerts"
        className="alerts"
        headerColumns={[
          { content: "Event", sortKey: "eventType" },
          { content: "Criteria", sortKey: "criteria" },
          { content: "Action", sortKey: "actionType" },
          { content: "Destination", sortKey: "destination" },
          {
            className: "status",
            content: "Status",
            sortKey: "status",
            sortTransform: (a) => a.status + "-" + a.id,
          },
          {
            className: "actions",
            content: "",
          },
        ]}
        items={SettingsStore.alerts.filter(
          (a) => {
            return !a.testRunId && eventTypeText[a.eventType] && actionTypesText[a.actionType]}
        )}
        renderRow={(alert) => (
          <tr className="alert" key={alert.id}>
            <td>
              When <b>{eventTypeText[alert.eventType]}</b>
            </td>
            <td className="alert-destination">{alert.criteria}</td>
            <td>
              Then <b>{actionTypesText[alert.actionType]}</b>
            </td>
            <td className="alert-destination">{alert.destination}</td>
            <td className="alert-status">{alertText[alert.status]}</td>
            <td className="actions">
              {AppStore.userIsAdmin && (
                <ThreeDotMenu
                  items={[
                    {
                      icon: Icons.edit,
                      text: "Edit Alert",
                      onClick: () => history.push(`alerts/${alert.id}`),
                    },
                    { icon: Icons.delete, text: "Delete Alert", onClick: () => deleteAlert(alert) },
                  ]}
                />
              )}
            </td>
          </tr>
        )}
        defaultSortKey="status"
        defaultDecending={false}
      />
    </Page>
  );
});

export default AlertsPage;
