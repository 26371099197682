import Form from "./form";
import { observable, action } from "mobx";
import { notEmpty, validEmail } from "core/validation";

export default class LoginForm extends Form {
  @observable email: string;
  @observable password: string;
  @observable slackMemberId: string;

  validators = { email: validEmail, password: notEmpty };

  @action
  setDefaults() {
    this.email = "";
    this.password = "";
    this.slackMemberId = "";
  }
}
