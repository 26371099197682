import { observer } from "mobx-react-lite";
import TestRunForm from "models/forms/test-run-form";
import TestSuiteForm from "models/forms/test-suite-form";
import Project, { TestFramework } from "models/project";
import { Form, Grid } from "semantic-ui-react";
import TagSelect from "./tag-select";
import TestFiltersSelect from "./test-filters-select";

interface Props {
  project: Project;
  form: TestRunForm | TestSuiteForm;
}

const TestFilters = observer(({ project, form }: Props) => (
  <Grid>
    <Grid.Row columns={2}>
      <Grid.Column>
        <TagSelect
          label="Included Tags"
          project={project}
          value={form.includeTags}
          onChange={form.onChange("includeTags")}
          error={form.getErrorText("includeTags")}
        />
      </Grid.Column>
      <Grid.Column>
        <TagSelect
          label="Excluded Tags"
          project={project}
          value={form.excludedTags}
          onChange={form.onChange("excludedTags")}
          error={form.getErrorText("excludedTags")}
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns={1}>
      <Grid.Column>
        <TestFiltersSelect
          label="Test Filters"
          project={project}
          value={form.testFilters}
          onChange={form.onChange("testFilters")}
          error={form.getErrorText("testFilters")}
        />
      </Grid.Column>
    </Grid.Row>
    {project.testingFramework == TestFramework.Playwright && (
      <Grid.Row>
        <Grid.Column>
          <Form.Input
            label={"Playwright Project To Run (leave blank to run all projects)"}
            placeholder={"Playwright project"}
            value={form.playwrightProject}
            onChange={form.onChange("playwrightProject")}
          />
        </Grid.Column>
      </Grid.Row>
    )}
  </Grid>
));

export default TestFilters;
