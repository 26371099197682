import { observer } from "mobx-react-lite";
import { useInitialEffect } from "core/react-utils";
import { AnalyticsStore, TestDistributionAnalysisRuleStore } from "stores";
import { TestDistributionAnalysis, Timeframe } from "models/analytics";
import { useState } from "react";
import TestDistributionAnalysisChart from "./charts/test-distribution-analysis-chart";
import TestDistributionAnalysisSelect from "components/form/test-distribution-analysis-select";
import { Button, Form, Grid } from "semantic-ui-react";
import TimeframeSelect from "components/form/timeframe-select";
import { TestDistributionRule } from "models/settings";
import { organizationUrl } from "core/utils";
import { useHistory } from "react-router-dom";

interface TestDistributionRequestProps {
  buttonText: string;
}

const TestDistributionRequest = ({ buttonText  }: TestDistributionRequestProps) => {
  const [selectedTimeframe, setSelectedTimeframe] = useState(Timeframe.Last30Days);


  return (
    <Form key={Math.random()}>
        <Grid columns={9}>
          <Grid.Row>
            <Grid.Column>
            <Button type="button" primary={true} onClick={() => { AnalyticsStore.runAnalysis(selectedTimeframe)}}>
              {buttonText}
            </Button>   
            </Grid.Column>
            <Grid.Column>
              <TimeframeSelect value={selectedTimeframe} onChange={(f) => setSelectedTimeframe(f)} />
              </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
  );
};


const TestDistributionTab = observer(() => {
  const [analysis, setAnalysis] = useState(null as TestDistributionAnalysis | null);
  const [analysisList, setAnalysisList] = useState([] as TestDistributionAnalysis[]);
  const [rules, setRules] = useState([] as TestDistributionRule[]);
  const history = useHistory();


  useInitialEffect(async () => {
    let data = await AnalyticsStore.loadTestDistributionAnalysisData();
    setAnalysisList(data);
    setAnalysis(data[0]);
    let rules = await TestDistributionAnalysisRuleStore.loadTestDistributionRules();
    setRules(rules);
  });

  return (
    <>
      {analysisList.length === 0 && rules.length === 0 && (
      <>
        <h3>Understand and manage your automated test mix with Testery.</h3>
        <p>Help your team develop a healthy test pyramid by adding unit, integration and system tests.</p>
        <img src="/img/empty-states/empty-state-test-distribution.png" width="480px" />
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              <Button type="button" primary={true} onClick={() => { history.push(organizationUrl(`settings/test-distribution-settings`)) }}>
                Setup Test Distribution Analysis
              </Button>  
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
  )}
      {analysisList.length === 0 && rules.length > 0 && (
        <>
        <h3>Understand and manage your automated test mix with Testery.</h3>
        <p>Help your team develop a healthy test pyramid by adding unit, integration and system tests.</p>
        <img src="/img/empty-states/empty-state-test-distribution.png" width="480px" />
        <TestDistributionRequest buttonText="Start Analysis"/>
        </>
      )}
      {analysisList.length > 0 && (
        <>
        <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <TestDistributionAnalysisSelect analysisList={analysisList} selectedAnalysis={analysis} onChange={setAnalysis} />
          </Grid.Column>
        </Grid.Row>
        </Grid>
        <TestDistributionAnalysisChart analysisData={analysis} />
        <TestDistributionRequest buttonText="Run New Analysis"/>
        </>
       )}
   
    </>
  );
});

export default TestDistributionTab;

