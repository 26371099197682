import { CSSProperties } from "react";

interface Props {
  label: string;
  labelBackgroundColor: string;
  labelTextColor: string;
  value: string;
  valueBackgroundColor: string;
  valueTextColor: string;
  secondaryValue?: string;
  secondaryValueBackgroundColor?: string;
  secondaryValueTextColor?: string;
}

const CapsuleDisplay =
  ({
     label,
     labelBackgroundColor,
     labelTextColor,
     value,
     valueBackgroundColor,
     valueTextColor,
     secondaryValue,
     secondaryValueBackgroundColor,
     secondaryValueTextColor,
   }: Props) => {
    const styling = {
      textTransform: "uppercase",
      padding: "5px 10px",
      letterSpacing: ".1rem",
      fontWeight: "bold",
    } as CSSProperties;

    let secondaryContainer = null;

    if (secondaryValue) {
      secondaryContainer = (
        <div style={{
          ...styling,
          background: secondaryValueBackgroundColor,
          color: secondaryValueTextColor,
          letterSpacing: "normal",
          fontSize: "0.9em",
          padding: 0,
          textAlign: "center",
        }}>
          {secondaryValue}
        </div>
      );
    }

    return (
      <div style={{ border: "none", fontSize: "0.7em", margin: "10px" }}>
        <div style={{ display: "flex" }}>
          <div style={{
            ...styling,
            background: labelBackgroundColor,
            color: labelTextColor,
          }}>{label}</div>
          <div style={{
            ...styling,
            background: valueBackgroundColor,
            color: valueTextColor,
          }}>{value}</div>
        </div>
        {secondaryContainer}
      </div>
    );
  };

export default CapsuleDisplay;
