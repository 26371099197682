import Select from "./select";
import { TestingFrameworkVersion } from "../../models/project";

interface Props {
  value: TestingFrameworkVersion;
  label?: string;
  error?: string;
  onChange: (v: string) => any;
  versions: Array<TestingFrameworkVersion>;
}

const FrameworkVersionSelect = ({ value, label, error, onChange, versions }: Props) => {
  return (
    <Select
      error={error}
      label={label}
      className="framework-select"
      value={value}
      options={versions}
      onChange={(v) => onChange(v)}
    />
  );
};

export default FrameworkVersionSelect;
