import { AppStoreClass } from "./app-store";
import { UserStoreClass } from "./user-store";
import { SettingsStoreClass } from "./settings-store";
import { TestRunStoreClass } from "./test-run-store";
import { ModalStoreClass } from "./modal-store";
import { ProjectStoreClass } from "./project-store";
import { TriggerStoreClass } from "./trigger-store";
import { PipelineStoreClass } from "./pipeline-store";
import { DashboardStoreClass } from "./dashboard-store";
import { EmployeeStoreClass } from "./employee-store";
import { AnalyticsStoreClass } from "./analytics-store";
import { TestSuiteStoreClass } from "./test-suite-store";
import { TestPlanStoreClass } from "./test-plan-store";
import { ReviewStoreClass } from "./review-store";
import { WorkflowStoreClass } from "./workflow-store";
import { TeamStoreClass } from "./team-store";
import { InsightStoreClass } from "./insight-store";
import { RunnerPoolStoreClass } from "./runner-pool-store";
import { ReleaseStoreClass } from "./release-store";
import {TestRunHistoryStoreClass} from "./test-run-history-store";
import { BuildStoreClass } from "./build-store";
import { TrackedIssueStoreClass } from "./tracked-issue-store";
import { TestDistributionAnalysisRuleStoreClass } from "./test-distribution-analysis-store";

export const ModalStore = new ModalStoreClass();

export const AppStore = new AppStoreClass(ModalStore, clearAccountData);

export const EmployeeStore = new EmployeeStoreClass(AppStore);

export const UserStore = new UserStoreClass();

export const TeamStore = new TeamStoreClass();

export const TrackedIssueStore = new TrackedIssueStoreClass();

export const ReviewStore = new ReviewStoreClass();

export const SettingsStore = new SettingsStoreClass(ModalStore);

export const TestDistributionAnalysisRuleStore = new TestDistributionAnalysisRuleStoreClass();

export const ProjectStore = new ProjectStoreClass();

export const BuildStore = new BuildStoreClass();

export const TestRunStore = new TestRunStoreClass(AppStore, ProjectStore, SettingsStore);

export const TestRunHistoryStore = new TestRunHistoryStoreClass();

export const TriggerStore = new TriggerStoreClass(TestRunStore);

export const PipelineStore = new PipelineStoreClass();

export const RunnerPoolStore = new RunnerPoolStoreClass();

export const DashboardStore = new DashboardStoreClass(SettingsStore);

export const InsightStore = new InsightStoreClass();

export const ReleaseStore = new ReleaseStoreClass();

export const AnalyticsStore = new AnalyticsStoreClass();

export const TestSuiteStore = new TestSuiteStoreClass(TestRunStore);

export const TestPlanStore = new TestPlanStoreClass();

export const WorkflowStore = new WorkflowStoreClass();

const storesToClear = [
  BuildStore,
  UserStore,
  SettingsStore,
  ProjectStore,
  TestRunStore,
  TriggerStore,
  PipelineStore,
  RunnerPoolStore,
  DashboardStore,
  AnalyticsStore,
  TestSuiteStore,
  TestPlanStore,
  ReviewStore,
  WorkflowStore,
  InsightStore,
  ReleaseStore,
  TrackedIssueStore
];

function clearAccountData() {
  storesToClear.forEach((s) => s.clear());
}
