import { useInitialEffect, setPageInfo, useNavigation } from "core/react-utils";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { sortBy } from "lodash";
import { Button, Icon, Popup, Tab } from "semantic-ui-react";
import { AppStore, PipelineStore, SettingsStore } from "stores";
import PipelineStageSelect from "components/form/pipeline-stage-select";
import SortableTable from "components/sortable-table";
import { useState } from "react";
import { VariablesInfoIcon } from "components/variables-info-icon";
import { Environment } from "models/settings";

const EnvironmentsTab = observer(() => {
  const [stageId, setStageId] = useState(null as number);
  setPageInfo("environments", "Environments");

  useInitialEffect(() => {
    SettingsStore.loadEnvironments();
    PipelineStore.ensureLoaded();
  });

  const history = useHistory();
  const filter = (e) =>
    !stageId || e.pipelineStageId == stageId || (stageId == -1 && !e.pipelineStageId);

  return (
    <Tab.Pane className="environments-tab">
      <div className="page-actions">
        {AppStore.userIsAdmin && (
          <Button color="green" onClick={useNavigation("environments/new")}>
            <Icon name="plus" />
            Add New Environment
          </Button>
        )}
        <PipelineStageSelect
          value={stageId}
          onChange={setStageId}
          placeholder="Any Pipeline Stage"
          allowNoStage={true}
        />
      </div>
      <SortableTable
        className={"environment-table"}
        loading={!SettingsStore.environmentsLoaded}
        loadingText="Loading Environments"
        headerColumns={[
          {
            className: "pipeline-stage",
            content: "Stage",
            sortKey: "stage",
            sortTransform: (env) =>
              (
                env.pipelineStage.priority +
                "-" +
                env.pipelineStage.name +
                "|" +
                env.name
              ).toLowerCase(),
          },
          {
            className: "name",
            content: "Name",
            sortKey: "name",
            sortTransform: (env) => env.name.toLowerCase(),
          },
          {
            className: "key",
            content: "Key",
            sortKey: "key",
            sortTransform: (env) => env.key.toLowerCase(),
          },
          {
            className: "variables",
            content: (
              <div>
                Variables <VariablesInfoIcon />
              </div>
            ),
          },
        ]}
        renderRow={(env: Environment) => (
          <tr
            className="environment"
            key={env.id}
            onClick={() => AppStore.userIsAdmin && history.push(`environments/${env.id}`)}
          >
            <td className="pipeline-stage">{env.pipelineStage.name}</td>
            <td><div className={"name text-overflow"}>{env.name}</div></td>
            <td><div className={"key text-overflow"}>{env.key}</div></td>
            <td className="variables">
              <div className={"variable-container"}>
                {sortBy(env.variables, ["file", "encrypted", "key"]).map((v) => (
                  <Popup
                    key={Math.random()}
                    position={"top center"}
                    content={
                      <div>
                        <div style={{textTransform: "uppercase", fontSize: "0.8em", color: "#505050"}}>{v.key}</div>
                        <div>{v.encrypted ? "****" : v.value}</div>
                      </div>
                    }
                    trigger={
                      <div className={"key-pair"}>
                        <div className={"icon"}><Icon name={v.file ? "file" : "code"} /></div>
                        <div className={"key"}>{v.key}</div>
                      </div>
                    }
                  />
                ))}
              </div>
            </td>
          </tr>
        )}
        items={SettingsStore.activeEnvironments}
        filter={filter}
        defaultSortKey="stage"
        defaultDecending={false}
      />
    </Tab.Pane>
  );
});

export default EnvironmentsTab;
