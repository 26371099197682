import "./testery-table.scss";
import { Table, TableProps } from "semantic-ui-react";
import { camelCaseToTitleCase } from "../../core/utils";
import DatetimeDisplay from "../datetime-display";

interface Props {
  tableProps?: TableProps,
  objectFieldsToUse?: Array<string>,
  tableHeaders?: Array<string>,
  tableData: Array<object>,
  actionsColumn?: Array<any>,
  actionsColumnHeader?: string,
  removeBoxShadow?: boolean,
  dateColumns?: Array<number>,
  dateTimeColumns?: Array<number>
}

const headerStyle = {
  background: "#FFF",
  fontWeight: 300,
};

const TesteryTable =
  ({
     tableProps,
     objectFieldsToUse,
     tableHeaders,
     tableData,
     actionsColumn,
     actionsColumnHeader,
     removeBoxShadow,
     dateColumns,
     dateTimeColumns,
   }: Props) => {

    const headers = [];
    if (tableHeaders && tableHeaders.length > 0) {
      tableHeaders.forEach(header => {
        headers.push(<Table.HeaderCell style={headerStyle}
                                       key={`header-${Math.random()}`}>{header}</Table.HeaderCell>);
      });
    } else if (objectFieldsToUse) {
      objectFieldsToUse.forEach(field => {
        headers.push(<Table.HeaderCell style={headerStyle}
                                       key={`header-${Math.random()}`}>{camelCaseToTitleCase(field)}</Table.HeaderCell>);
      });
    } else if (tableData && tableData.length > 0) {
      Object.keys(tableData[0]).forEach(key =>
        headers.push(<Table.HeaderCell style={headerStyle}
                                       key={`header-${Math.random()}`}>{camelCaseToTitleCase(key)}</Table.HeaderCell>),
      );
    }

    if (actionsColumn)
      headers.push(<Table.HeaderCell style={headerStyle}
                                     key={`action-header-${Math.random()}`}>{actionsColumnHeader}</Table.HeaderCell>);

    const bodyRows = [];
    tableData.forEach((object, index) => {
      const cells = [];

      let columnIndex = 0;
      let displayValue;

      if (objectFieldsToUse) {
        objectFieldsToUse.forEach(field => {
          displayValue = convertData(object[field], columnIndex);
          cells.push(<Table.Cell key={`field-${Math.random()}`}>{displayValue}</Table.Cell>);
          columnIndex++;
        });
      } else {
        Object.values(object).forEach(value => {
          displayValue = convertData(value, columnIndex);
          cells.push(<Table.Cell key={`field-${Math.random()}`}>{displayValue}</Table.Cell>);
          columnIndex++;
        });
      }

      if (actionsColumn) {
        cells.push(<Table.Cell key={`field-${Math.random()}`}>{actionsColumn[index]}</Table.Cell>);
        columnIndex++;
      }
      bodyRows.push(<Table.Row key={`row-${Math.random()}`}>{cells}</Table.Row>);
    });

    function convertData(rawValue: string, columnIndex: number) {
      let displayValue: any = rawValue;
      if (dateColumns && dateColumns.includes(columnIndex))
        displayValue = <DatetimeDisplay key={`date-${Math.random()}`} datetime={displayValue} dateOnly={true} />;
      else if (dateTimeColumns && dateTimeColumns.includes(columnIndex))
        displayValue = <DatetimeDisplay key={`date0-time-${Math.random()}`} datetime={displayValue} />;

      return displayValue;
    }

    return (
      <div style={{
        overflow: "auto",
        boxShadow: removeBoxShadow ? "none" : "rgba(0, 0, 0, 0.2) 0 0 1px -2px, rgba(0, 0, 0, 0.14) 0px 0 2px 0px, rgba(0, 0, 0, 0.12) 0 0 5px 0",
      }}>

        <Table {...tableProps} className={"testery-table"}
               style={{ tableLayout: "fixed", borderRadius: 0, border: "none" }}>

          <Table.Header>
            <Table.Row>
              {headers}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {bodyRows}
          </Table.Body>

          <Table.Footer>

          </Table.Footer>

        </Table>

      </div>
    );
  };

export default TesteryTable;
