import { observer } from "mobx-react-lite";
import { Button, Form, Grid } from "semantic-ui-react";
import ReleaseForm from "../../models/forms/release-form";
import DatePicker from "react-datepicker";
import { toUTCDate } from "../../core/date-utils";
import { ReleaseDecision } from "../../models/release";
import ReleaseCriteriaRow from "./release-criteria-row";
import Select from "../form/select";

interface Props {
  form: ReleaseForm;
  releaseCriteria: Array<typeof ReleaseCriteriaRow>;
  submitForm: (form: ReleaseForm) => {};
}

const ReleaseInfo = observer(({ form, releaseCriteria, submitForm }: Props) => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={4} style={{ display: "flex", flexDirection: "column" }}>
        {releaseCriteria}
      </Grid.Column>
      <Grid.Column width={3} style={{ display: "flex", flexDirection: "column" }}>
        Proposed Release Date <DatePicker id={"release-proposed-release-date"}
                                          selected={form.proposedReleaseDate ? toUTCDate(form.proposedReleaseDate) : null}
                                          onChange={form.onChange("proposedReleaseDate")}
                                          isClearable={true}
      />
        Actual Release Date: <DatePicker id={"release-actual-release-date"}
                                         selected={form.actualReleaseDate ? toUTCDate(form.actualReleaseDate) : null}
                                         onChange={form.onChange("actualReleaseDate")}
                                         isClearable={true}
      />
        <Select placeholder={"Undetermined"}
                label={"Release Decision"}
                value={form.decision ? { label: ReleaseDecision[form.decision], value: form.decision } : null}
                isClearable={true}
                onChange={option => {
                  form.updateField("decision", option ? option.value : null);
                }}
                options={Object.keys(ReleaseDecision).map(key => ({ label: ReleaseDecision[key], value: key }))}
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <Form.Input label={"Title"} value={form.title} onChange={form.onChange("title")} />
        <Form.TextArea
          id={"release-description"}
          style={{ resize: "none" }}
          label="Description"
          value={form.description}
          onChange={form.onChange("description")}
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Button style={{ margin: "0 15px" }} onClick={_e => submitForm(form)} content={"Save Release"} icon={"save"}
              primary={true} />
    </Grid.Row>
  </Grid>
));

export default ReleaseInfo;
