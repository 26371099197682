import { action, observable, runInAction } from "mobx";
import API from "core/api";
import { Team } from "../models/team";
import TeamInfoForm from "../models/forms/team-info-form";

export class TeamStoreClass {
  @observable teams: Team[] = [];
  @observable teamsLoaded = false;

  @action
  clear() {
    this.teamsLoaded = false;
    this.teams = [];
  }

  @action
  async loadTeams() {
    const { data } = await API.getList("/team", Team);
    runInAction(() => {
      this.teamsLoaded = true;
      this.teams = data;
    });
    return data;
  }

  @action
  async createTeam(form: TeamInfoForm) {
    return form.submit(async (data) => {
      await API.post("/team", data);
      await this.loadTeams();
    });
  }

  @action
  async updateTeam(form: TeamInfoForm) {
    return form.submit(async (data) => {
      await API.put(`/team/${data.id}`, data);
      await this.loadTeams();
    });
  }

  @action
  async deleteTeam(teamId: number) {
    await API.delete(`/team/${teamId}`);
    await this.loadTeams();
  }

  @action
  async addUserToTeam(userId: number, teamId: number) {
    await API.post(`/team/${teamId}/user/${userId}`, null);
    await this.loadTeams();
  }

  @action
  async deleteUserFromTeam(userId: number, teamId: number) {
    await API.delete(`/team/${teamId}/user/${userId}`);
    await this.loadTeams();
  }
}
