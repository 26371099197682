import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useInitialEffect } from "core/react-utils";
import { EmployeeStore } from "stores";
import { TestResult } from "models/test-run";
import Page from "pages/page";
import TestResultDisplay from "components/test-result";
import { Button, Icon } from "semantic-ui-react";
import "./test-result.scss";

const EmployeeTestResultPage = () => {
  const { testRunId, testId } = useParams<{ testRunId: string; testId: string }>();
  const history = useHistory();
  const testRunTestId = parseInt(testId);
  const testRunIdn = parseInt(testRunId);
  const [testResult, setTestResult] = useState(null as TestResult);

  useInitialEffect(() => {
    EmployeeStore.init().then(async () => {
      setTestResult(await EmployeeStore.loadTestResult(testRunIdn, testRunTestId));
    });
  });

  return (
    <Page name="employee-test-result" title="Employee Test Result">
      <Button onClick={history.goBack}>
        <Icon name="chevron left" />
        Back
      </Button>
      <TestResultDisplay testResult={testResult} />
    </Page>
  );
};

export default EmployeeTestResultPage;
