import { observer } from "mobx-react-lite";
import { ModalStore, TestSuiteStore, ProjectStore } from "stores";
import { Modal, Button, Icon, Card } from "semantic-ui-react";
import TestRunForm from "models/forms/test-run-form";
import EnvironmentSelect from "components/form/environment-select";
import TestRunVersionSelect from "components/form/test-run-version-select";
import "./run-test-suite.scss";
import { useHistory } from "react-router";

const form = new TestRunForm();
const { runTestSuite } = ModalStore;

runTestSuite.onHidden = form.clearFormData;
runTestSuite.onShown = () => {
  form.updateField("projectId", runTestSuite.state.projectId);
  form.updateField("testSuiteId", runTestSuite.state.id);
};

const RunTestSuite = observer(() => {
  const history = useHistory();

  const run = async () => {
    const testRun = await TestSuiteStore.run(form);
    runTestSuite.hide();
    history.push(`../../test-runs/${testRun.id}`);
  };

  const project = ProjectStore.find(runTestSuite.state.projectId);

  return (
    <Modal open={runTestSuite.showing} size="small">
      <Modal.Header>Run Test Suite</Modal.Header>
      <Modal.Content>
        <div className="run-test-suite">
          <EnvironmentSelect
            value={form.environmentId}
            onChange={form.onChange("environmentId")}
            label="Environment"
          />
          <Card>
            <Card.Content>
              <TestRunVersionSelect project={project} form={form} />
            </Card.Content>
          </Card>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button size="small" onClick={() => runTestSuite.hide()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button size="small" onClick={run} color="green" loading={form.isSubmitting}>
          <Icon name="check" />
          Run Test Suite
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default RunTestSuite;
