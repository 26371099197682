import Form from "./form";
import { observable, action } from "mobx";
import { notEmpty } from "core/validation";
import { ProjectStore } from "stores";

export default class TestPlanSuiteForm extends Form {
  @observable projectId: number;
  @observable testPlanStageId: number;
  @observable id: number;
  @observable testSuiteId: number;
  @observable environmentId: number;
  @observable useDynamicEnvironment: boolean;
  @observable dynamicEnvironment: string;
  @observable latestDeploy: boolean;
  @observable branch: string;
  @observable commit: string;
  @observable buildName: string;
  @observable order: number;
  isRunNow = false;

  validators = {
    projectId: notEmpty,
    testSuiteId: notEmpty,
    environmentId: {
      validator: (environmentId: number) => {
        return this.useDynamicEnvironment || environmentId > 0
      },
      message: "You must select an environment"
    },
    dynamicEnvironment:{
      validator: (dynamicEnvironment: string) => {
        return !this.useDynamicEnvironment || notEmpty(dynamicEnvironment)
      },
      message: "You must select an environment"
    },
    buildName: {
      validator: (buildName: string) => {
        const project = ProjectStore.find(this.projectId);
        return this.latestDeploy || !project || !project.hasBuildArtifacts || notEmpty(buildName);
      },
      message: "You must select a build",
    },
    branch: {
      validator: (branch: string) => {
        const project = ProjectStore.find(this.projectId);
        return this.latestDeploy || project.hasBuildArtifacts || notEmpty(branch);
      },
      message: "You must select a branch",
    },
  };

  @action
  setDefaults() {
    this.projectId = null;
    this.testSuiteId = null;
    this.environmentId = null;
    this.latestDeploy = false;
    this.branch = null;
    this.commit = null;
    this.buildName = null;
    this.isRunNow = false;
    this.useDynamicEnvironment = false;
    this.dynamicEnvironment = "";
  }
}
