import { orderBy } from "lodash";
import { observer } from "mobx-react-lite";
import { TestPlanStage, TestPlanSuite } from "models/test-plan";
import { Button, Card, Icon } from "semantic-ui-react";
import { Droppable } from "react-beautiful-dnd";
import { ModalStore } from "stores";
import SuiteDraggable from "./suite-draggable";

interface Props {
  stage: TestPlanStage;
}

const Suites = observer(({ stage }: Props) => {
  // we need this so it will rerender the suite list when it gets changed
  stage.activeSuites;

  return (
    <Card.Group className="suites">
      <div>
        <Button
          color="grey"
          inverted
          size="mini"
          onClick={() =>
            ModalStore.testPlanSuiteEdit.show(
              null,
              new TestPlanSuite().assign({ testPlanStageId: stage.id }),
            )
          }
        >
          <Icon name="plus" />
          Add Test Suite
        </Button>
      </div>
      <Droppable droppableId={`stage-drop-${stage.id}`} type="suite">
        {(provided) => (
          <div className="drop-container" ref={provided.innerRef}>
            {orderBy(stage.activeSuites, "order").map((s, index) => (
              <SuiteDraggable key={s.id} suite={s} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Card.Group>
  );
});

export default Suites;
