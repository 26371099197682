import { useState } from "react";
import * as Storage from "core/storage";
import { useInitialEffect } from "core/react-utils";
import { AppStore } from "stores";
import { Button, Form, Grid, GridColumn, GridRow, Segment } from "semantic-ui-react";
import Page from "pages/page";
import { observer } from "mobx-react-lite";
import RegistrationForm from "models/forms/registration-form";
import { useHistory } from "react-router";
import { getUrlParam } from "core/utils";
import "./create-account.scss";
import { FriendlyCaptcha } from "../../components/form/friendly-captcha";
import { IS_PROD } from "../../core/constants";

const form = new RegistrationForm();

const CreateAccountPage = observer(() => {
  const [emailEditable, setEmailEditable] = useState(true);
  const [accountCreated, setAccountCreated] = useState(false);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const history = useHistory();

  useInitialEffect(() => {
    const token = Storage.getInviteToken();
    if (token) {
      AppStore.loadInvitation().then((invite) => {
        if (invite) {
          form.populate(invite);
          setEmailEditable(false);
        }
      });
    } else {
      Storage.setSelectedPlanTier(getUrlParam("plan"));
      Storage.setPromoCode(getUrlParam("promoCode"));
      form.clearFormData();
    }
  });

  const register = async () => {
    await AppStore.registerByEmail(form);
    if (AppStore.isLoggedIn) {
      history.push("/");
    } else {
      setAccountCreated(true);
    }
  };

  const captchaSuccessCallback = (solution) => {
    form.captchaSolution = solution;
    setCaptchaSuccess(true);
  };

  const captchaErrorCallback = () => {
    setCaptchaSuccess(false);
  };

  return (
    <Page name="create-account" title="Create New Account" noHeader titleSize="huge">
      <Segment placeholder>
        {!accountCreated && (
          <Form id="create-account-form">
            <Grid>
              <GridRow columns={2} id={"create-account-email-container"}>
                <GridColumn>
                  <Form.Input
                    autoFocus
                    disabled={!emailEditable}
                    error={form.getErrorText("email")}
                    label="Email"
                    value={form.email}
                    onChange={form.onChange("email")}
                  />
                </GridColumn>
              </GridRow>
              <GridRow columns={2}>
                <GridColumn id={"create-account-firstName-container"}>
                  <Form.Input
                    label="First Name"
                    error={form.getErrorText("firstName")}
                    value={form.firstName}
                    onChange={form.onChange("firstName")}
                  />
                </GridColumn>
                <GridColumn id={"create-account-lastName-container"}>
                  <Form.Input
                    label="Last Name"
                    error={form.getErrorText("lastName")}
                    value={form.lastName}
                    onChange={form.onChange("lastName")}
                  />
                </GridColumn>
              </GridRow>
              <GridRow columns={2}>
                <GridColumn id={"create-account-password-container"}>
                  <Form.Input
                    label="Password"
                    type="password"
                    error={form.getErrorText("password")}
                    value={form.password}
                    onChange={form.onChange("password")}
                  />
                </GridColumn>
                <GridColumn id={"create-account-repeatPassword-container"}>
                  <Form.Input
                    label="Repeat Password"
                    type="password"
                    error={form.getErrorText("passwordRepeat")}
                    value={form.passwordRepeat}
                    onChange={form.onChange("passwordRepeat")}
                  />
                </GridColumn>
              </GridRow>
              {IS_PROD && <GridRow centered={true}>
                <FriendlyCaptcha successCallback={captchaSuccessCallback}
                                 errCallback={captchaErrorCallback} />
              </GridRow>}
              <GridRow>
                <Button
                  loading={form.isSubmitting}
                  type="submit"
                  color="green"
                  size="large"
                  disabled={IS_PROD && !captchaSuccess}
                  onClick={register}
                >
                  Create Account
                </Button>
              </GridRow>
            </Grid>
          </Form>
        )}
        {accountCreated && (
          <h2 id={"account-creation-successful-message"}>
            You’re almost there! Check your email. <br />
            Once your email address is verified you can get started.
          </h2>
        )}
      </Segment>
    </Page>
  );
});

export default CreateAccountPage;
