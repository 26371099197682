import ProjectSettings from "components/form/project-settings";
import Project from "models/project";

interface Props {
  project: Project;
}

const SettingsTab = ({ project }: Props) => (
  <div className="settings-tab">
    <ProjectSettings project={project} />
  </div>
);

export default SettingsTab;
