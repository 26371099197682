import { useInitialEffect } from "core/react-utils";
import "./test-plans.scss";
import { observer } from "mobx-react-lite";
import { Button, Icon } from "semantic-ui-react";
import { AppStore, ModalStore, TestPlanStore } from "stores";
import Page from "pages/page";
import { Icons } from "core/constants";
import { useHistory } from "react-router";
import SortableTable from "components/sortable-table";
import ThreeDotMenu from "components/three-dot-menu";
import { TestPlan } from "models/test-plan";
import { Link } from "react-router-dom";
import DatetimeDisplay from "components/datetime-display";

interface TestPlanRowProps {
  testPlan: TestPlan;
  push: (path: string) => any;
}

async function deleteTestPlan(id: number) {
  ModalStore.confirmation.show(
    async (okClicked: boolean) => {
      if (okClicked) {
        await TestPlanStore.deleteTestPlan(id);
      }
    },
    {
      message: `Are you sure you want to delete this Test Plan?`,
      okText: "Delete",
      okColor: "red",
    },
  );
}

const TestPlanRow = observer(({ testPlan, push }: TestPlanRowProps) => {
  const isAdmin = AppStore.userIsAdmin;

  let actions = [
    {
      icon: Icons.run,
      text: "Run Test Plan",
      onClick: async () => {
        await TestPlanStore.runTestPlan(testPlan.id);
        push(`test-plans/${testPlan.id}/runs`);
      },
    },
    {
      icon: Icons.edit,
      text: "Edit Settings",
      onClick: () => push(`test-plans/${testPlan.id}/settings`),
    },
    {
      icon: Icons.delete,
      text: "Delete Test Plan",
      onClick: () => deleteTestPlan(testPlan.id),
    },
  ];

  if (!isAdmin) {
    actions = [actions[0]];
  }

  if (!testPlan.hasTestSuites) {
    actions.shift();
  }

  return (
    <tr>
      <td className="name">
        <Link to={`test-plans/${testPlan.id}`}>{testPlan.name}</Link>
      </td>
      <td className="goal">{testPlan.goal}</td>
      <td className="date">
        <DatetimeDisplay datetime={testPlan.createdAt} dateOnly />
      </td>
      <td className="date-time">
        <DatetimeDisplay datetime={testPlan.lastExecutedAt} />
      </td>
      {isAdmin && (
        <td className="actions">
          <ThreeDotMenu items={actions} />
        </td>
      )}
    </tr>
  );
});

const TestPlansPage = observer(() => {
  const history = useHistory();

  useInitialEffect(() => {
    TestPlanStore.loadAll();
  });

  return (
    <Page name="test-plans" title="Test Plans" icon={Icons.testPlan}>
      <div className="page-actions">
        {AppStore.userIsAdmin && (
          <Button color="green" onClick={() => history.push("test-plans/new")}>
            <Icon name="plus" />
            Add New Test Plan
          </Button>
        )}
      </div>
      <SortableTable
        className="test-plan-list"
        loading={!TestPlanStore.loaded}
        loadingText="Loading Test Plans"
        headerColumns={[
          {
            className: "name",
            content: "Name",
            sortKey: "name",
            sortTransform: (s) => s.name.toLowerCase(),
          },
          {
            className: "goal",
            content: "Objective/Goal",
            sortKey: "goal",
            sortTransform: (s) => s.goal.toLowerCase(),
          },
          {
            className: "date",
            content: "Date Created",
            sortKey: "createdAt",
          },
          {
            className: "date",
            content: "Last Executed",
            sortKey: "lastExecuted",
          },
          { className: "actions", content: "" },
        ]}
        items={TestPlanStore.active}
        renderRow={(s) => <TestPlanRow key={s.id} testPlan={s} push={history.push} />}
        defaultSortKey="name"
        emptyMessage={
          <div>
            <h2>Add your first test plan!</h2>
            <p>
              Test plans can be used to organize different suites of tests to run in a certain
              order, to setup or teardown data, etc.
            </p>
            <br />
            <p>
              <a target="_blank" href="https://docs.testery.io/get-to-know-testery/test-plans">
                Learn more
              </a>
            </p>
          </div>
        }
      />
    </Page>
  );
});

export default TestPlansPage;
