import { Button, Icon, Tab } from "semantic-ui-react";
import SortableTable from "components/sortable-table";
import { AppStore, ModalStore, TestDistributionAnalysisRuleStore } from "stores";
import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import ThreeDotMenu from "components/three-dot-menu";
import { Icons } from "core/constants";
import { useHistory } from "react-router";
import { TestDistributionRule } from "models/settings";
import { organizationUrl } from "core/utils";

function deleteRule(rule: TestDistributionRule) {
  ModalStore.confirmation.show(
    async (okClicked: boolean) => {
      if (okClicked) {
        await TestDistributionAnalysisRuleStore.deleteRule(rule.id);
      }
    },
    {
      message: `Are you sure you want to delete this rule?`,
      okText: "Delete",
      okColor: "red",
    },
  );
}

function editRule(rule: TestDistributionRule, history: any) {
  history.push(organizationUrl(`test-distribution-analysis-rule/${rule.id}`))
}

const TestDistributionSettingsTab = observer(() => {
  const history = useHistory();

  useInitialEffect(async () => {
    await TestDistributionAnalysisRuleStore.loadTestDistributionRules();
  });

  return (
    <Tab.Pane className="test-distribution-settings">
      <div className="page-actions">
        {AppStore.userIsAdmin && (
          <Button color="green" onClick={() => history.push(organizationUrl("test-distribution-analysis-rule/new"))}>
            <Icon name="plus" />
            Add New Rule
          </Button>
        )}
      </div>
      <SortableTable
          title="Current Test Distribution Analysis Rules"
          loading={!TestDistributionAnalysisRuleStore.testDistributionRulesLoaded}
          loadingText="Loading Test Distribution Analysis Rules"
          className="testDistributionRules"
          headerColumns={[
            {
              content: "Priority",
              sortKey: "orderToApply"
            },
            {
              content: "Rule Type",
              sortKey: "ruleType"
            },
            {
              content: "Distribution Type Assigned",
              sortKey: "assignedTo"
            },
            {
              content: "Project",
              sortKey: "projectName"
            },
            {
              content: "Test Suite",
              sortKey: "testSuiteId"
            },
            {
              content: "Test Filters",
              sortKey: "includePatterns"
            },
            {
              content: "",
              className: "actions",
            }
          ]}
          items={TestDistributionAnalysisRuleStore.items}
          renderRow={(testDistributionRule) => (
            <tr key={testDistributionRule.id}>
              <td>{testDistributionRule.orderToApply}</td>
              <td>{testDistributionRule.ruleType}</td>
              <td>{testDistributionRule.assignedTo}</td>
              <td>{testDistributionRule.projectName}</td>
              <td>{testDistributionRule.testSuiteName}</td>
              <td>{testDistributionRule.includePatterns}</td>
              <td>
                <div className="actions">
                  <ThreeDotMenu
                    items={[
                      {
                        icon: Icons.edit,
                        text: "Edit Rule",
                        onClick: () => editRule(testDistributionRule, history),
                      },
                      { icon: Icons.delete, text: "Delete Rule", onClick: () => deleteRule(testDistributionRule)},
                    ]}
                  />
                </div>
              </td>
            </tr>
          )}
          defaultSortKey="orderToApply"
        />
    </Tab.Pane>
  );
});

export default TestDistributionSettingsTab;
