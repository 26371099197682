import Select from "./select";
import { SettingsStore } from "stores";
import { observer } from "mobx-react-lite";
import { useEffect, useInitialEffect } from "core/react-utils";

interface Props {
  value: number;
  onChange: (id: number) => any;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
  pipelineStageId?: number;
}

const EnvironmentSelect = observer(
  ({ onChange, value, placeholder, error, label, pipelineStageId }: Props) => {
    const environments = pipelineStageId
      ? SettingsStore.activeEnvironments.filter((e) => e.pipelineStageId == pipelineStageId)
      : SettingsStore.activeEnvironments;

    const env = SettingsStore.environments.find((e) => e.id == value) || null;

    useInitialEffect(() => {
      SettingsStore.ensureEnvironments();
    });

    useEffect(() => {
      if (pipelineStageId && env?.pipelineStageId != pipelineStageId) {
        onChange(null);
      }
    }, [pipelineStageId, value]);

    const options = environments.map(environment => {
      const label = (environment.name === environment.key) ? environment.name : `${environment.name} ${environment.key}`;
      return { label: label, value: environment.key, environmentName: environment.name, environmentId: environment.id };
    });

    return (
      <Select
        className="environment-select"
        placeholder={placeholder}
        isSearchable={true}
        onChange={(option) => onChange(option && option.environmentId)}
        autoDefault={!placeholder}
        isClearable={!!placeholder && !!value}
        value={env ? {
          label: (env.name === env.key) ? env.name : `${env.name} ${env.key}`,
          value: env.key,
          environmentName: env.name,
          environmentId: env.id,
        } : null
        }
        options={options}
        formatOption={(option) =>
          option.environmentName == option.value ? (
            option.environmentName
          ) : (
            <div>
              {option.environmentName}
              <div className="info">{option.value}</div>
            </div>
          )
        }
        valueField="environmentId"
        labelField="name"
        error={error}
        label={label}
      />
    );
  },
);

export default EnvironmentSelect;
