import { observer } from "mobx-react-lite";
import { TestRun } from "models/test-run";
import "./logs-tab.scss";
import { TestRunHistoryStore } from "stores";
import { useInitialEffect } from "core/react-utils";
import { Accordion, Icon } from "semantic-ui-react";
import { useState } from "react";
import { isBlank, isNotBlank } from "../../core/utils";
import Spinner from "../../components/spinner";

interface Props {
  testRun: TestRun;
}

const LogsTab = observer(({ testRun }: Props) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [loading, setLoading] = useState(false);

  useInitialEffect(async () => {
    setLoading(true);
    await TestRunHistoryStore.loadLogs(testRun.id);
    setLoading(false);
  });

  const handleAccordionClick = async (_e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  if (loading)
    return <Spinner text={"Loading Runner Logs"} />;

  const logItems = TestRunHistoryStore.logItems;
  const accordionData = [];
  let index = 0;

  if (logItems && logItems.length > 0) {
    logItems.forEach(log => {
      if (isNotBlank(log.testListGenerationLog) || isNotBlank(log.runnerSetupLog)) {
        accordionData.push(
          <div key={`container-${index}`}>
            <Accordion.Title index={index} key={`test-run-log-title-${index}`} active={activeIndex === index}
                             onClick={handleAccordionClick}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {activeIndex === index && <Icon name={"caret down"} />}
                  {activeIndex !== index && <Icon name={"caret right"} />}
                  Runner: {log.ecsTaskArn}
                </div>
                <div>
                  Test List Generation Log:
                  {isNotBlank(log.testListGenerationLog) && <Icon style={{ marginLeft: "6px" }} name={"checkmark"} />}
                  {isBlank(log.testListGenerationLog) && <Icon style={{ marginLeft: "6px" }} name={"x"} />}
                </div>
              </div>
            </Accordion.Title>

            <Accordion.Content key={`test-run-log-content-${index}`} active={activeIndex === index}>
              {isNotBlank(log.runnerSetupLog) && <><h4 key={`header-${index}`}>Runner Setup Log</h4>
                <pre><code>{log.runnerSetupLog}</code></pre>
              </>}
              {isNotBlank(log.testListGenerationLog) && <><h4 key={`header-${index}`}>Test List Generation Log</h4>
                <pre><code>{log.testListGenerationLog}</code></pre>
              </>}
            </Accordion.Content>
          </div>,
        );

        index++;
      }
    });
  }

  return (
    <div className="logs-tab">
      <Accordion fluid styled>
        {accordionData}
      </Accordion>
    </div>
  );
});

export default LogsTab;
