import SortableTable from "components/sortable-table";
import ThreeDotMenu from "components/three-dot-menu";
import { Icons } from "core/constants";
import { setPageInfo, useInitialEffect, useNavigation } from "core/react-utils";
import { relativeLink } from "core/utils";
import { observer } from "mobx-react-lite";
import { Workflow } from "models/workflow";
import Page from "pages/page";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { AppStore, ModalStore, WorkflowStore } from "stores";
import "./workflows.scss";

const WorkflowsPage = observer(() => {
  setPageInfo("workflows", "Qality Workflows");
  const nav = useNavigation(relativeLink("workflows/new"));

  useInitialEffect(() => {
    WorkflowStore.loadAll();
  });

  return (
    <Page name="workflows" title="Quality Workflows" icon={Icons.workflow}>
      <div className="page-actions">
        {AppStore.userIsAdmin && (
          <Button color="green" onClick={nav}>
            <Icon name="plus" />
            Add New Workflow
          </Button>
        )}
      </div>
      <SortableTable
        className="workflow-list"
        loading={!WorkflowStore.loaded}
        loadingText="Loading Quality Workflows"
        headerColumns={[
          {
            className: "name",
            content: "Name",
            sortKey: "name",
            sortTransform: (s) => s.name.toLowerCase(),
          },
          { className: "actions", content: "", hide: !AppStore.userIsAdmin },
        ]}
        items={WorkflowStore.active}
        renderRow={(p) => <WorkflowRow key={p.id} workflow={p} />}
        defaultSortKey="name"
        emptyMessage={
          <div>
            <h2>Add your first qualty workflow!</h2>
            <p>Quality workflows can be used to define quality steps.</p>
          </div>
        }
      />
    </Page>
  );
});

interface WorkflowRowProps {
  workflow: Workflow;
}

const WorkflowRow = observer(({ workflow }: WorkflowRowProps) => (
  <tr>
    <td className="name">
      <Link to={relativeLink(`workflows/${workflow.id}`)}>{workflow.name}</Link>
    </td>
    {AppStore.userIsAdmin && (
      <td className="actions">
        <ThreeDotMenu
          items={[{ icon: Icons.delete, text: "Delete Workflow", onClick: () => deleteWorkflow(workflow) }]}
        />
      </td>
    )}
  </tr>
));

async function deleteWorkflow(workflow: Workflow) {
  ModalStore.confirmation.show(
    async (okClicked: boolean) => {
      if (okClicked) {
        await WorkflowStore.delete(workflow.id);
      }
    },
    {
      message: `Are you sure you want to archive the ${workflow.name} workflow?`,
      okText: "Archive",
      okColor: "red",
    },
  );
}

export default WorkflowsPage;
