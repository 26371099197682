import { Auth0DecodedHash, Auth0ParseHashError, WebAuth } from "auth0-js";
import { AUTH_CLIENT_ID, AUTH_DOMAIN, GIT_HUB_APP_INSTALL_URL } from "./constants";
import { getLoginProvider, setAuthInfo, setLoginProvider, setPromoCode, setSelectedPlanTier } from "./storage";
import { AuthReason } from "models/settings";
import { ModalStore } from "stores";

function createWebAuth() {
  return new WebAuth({
    domain: AUTH_DOMAIN,
    clientID: AUTH_CLIENT_ID,
    responseType: "token id_token",
    redirectUri: window.location.origin + "/auth-return",
  });
}

export function loginAuthorize(provider: string) {
  setLoginProvider(provider);
  createWebAuth().authorize({
    connection: provider,
    scope: "openid",
  });
}

export function doSignup(provider: string = null, plan: string, promoCode?: string) {
  setSelectedPlanTier(plan || null);
  setAuthInfo(null, AuthReason.Setup);
  setPromoCode(promoCode);
  loginAuthorize(provider || getLoginProvider());
}

export function repositoryAuthorize(provider: string, personalAccount: boolean = false) {
  if (provider == "github" && !personalAccount) {
    ModalStore.confirmation.show(
      async (isPersonalAccount: boolean) => {
        doRepositoryAuthorize(provider, isPersonalAccount);
      },
      {
        message: "Are you linking a GitHub Organization or a Personal Account?",
        okText: "Personal Account",
        okInverted: true,
        okColor: "green",
        cancelText: "GitHub Organization",
        cancelInverted: true,
        cancelColor: "green",
        hideButtonIcons: true,
        size: "small",
      },
    );
  } else {
    doRepositoryAuthorize(provider, false);
  }
}

function doRepositoryAuthorize(provider: string, personalAccount: boolean = false) {
  if (provider == "github" && !personalAccount) {
    window.location.href = GIT_HUB_APP_INSTALL_URL;
  } else {
    setLoginProvider(provider);
    createWebAuth().authorize({
      connection: provider,
      scope: "profile repository offline_access",
    });
  }
}

export function handleAuthReturn(): Promise<string> {
  return new Promise((resolve) => {
    createWebAuth().parseHash((err: Auth0ParseHashError, authResult: Auth0DecodedHash) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        resolve(authResult.idTokenPayload.sub);
      } else if (err) {
        console.error(
          "Could not handle auth return. Error: " + err.error + " (" + err.errorDescription + ")",
        );
        resolve(null);
      } else {
        resolve(null);
      }
    });
  });
}
