import { ModalStore } from "stores";
import { Button, Icon, Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Carousel } from "react-responsive-carousel";

const UpsellModal = observer(() => {
  const { upsellModal } = ModalStore;
  const requiredTier = upsellModal.state;

  const tierTwoImages = [
    "/img/upsell/tier2-1.png",
    "/img/upsell/tier2-2.png",
    "/img/upsell/tier2-3.png",
  ];

  const openInNewTab = () => {
    window.open("https://testery.atlassian.net/servicedesk/customer/portal/1", "_blank", "noreferrer");
  };

  return (
    <Modal open={upsellModal.showing}>
      <Modal.Header>
        Looking for more features?
      </Modal.Header>
      <Modal.Content>
        {requiredTier === 2 ? (
          <Carousel swipeable={true} emulateTouch={true} useKeyboardArrows={true} autoPlay={true} interval={5000}
                    showThumbs={false}>
            {tierTwoImages.map((url) => {
              return <img alt={""} src={url} />;
            })}
          </Carousel>
        ) : (
          <>
            <h2 style={{fontWeight: "lighter"}}>AI Workflow</h2>
            <div style={{border: "3px solid #5fc953", borderRadius: "2px", width: "75px"}}/>
            <h3 style={{fontWeight: "lighter"}}>Support More Complex Testing Scenarios with Testery AI Workflow</h3>
            <h4 style={{fontWeight: "lighter"}}>Upgrade to Testery AI Workflow to orchestrate test runs that span multiple teams, environments, and frameworks.</h4>
            <div>With Testery AI Workflow, you can have:</div>
            <div style={{display: "flex", marginTop: "20px"}}>
              <div><Icon style={{color: "#5fc953", fontSize: "2em"}} name={"checkmark box"} /> End-to-end test runs that have tests that live in different projects and frameworks or run against multiple environments.</div>
              <div><Icon style={{color: "#5fc953", fontSize: "2em"}} name={"checkmark box"} /> Setup/teardown phases that run before/after test runs even if those steps live in different repositories.</div>
              <div><Icon style={{color: "#5fc953", fontSize: "2em"}} name={"checkmark box"} /> Split test runs into multiple phases and optionally stop test runs early if earlier phases have test failures.</div>
            </div>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content={"Close"} onClick={() => upsellModal.hide()} />
        <Button positive={true} onClick={openInNewTab}>Contact Us</Button>
      </Modal.Actions>
    </Modal>
  );
});

export default UpsellModal;
