import { substringAfter } from "core/utils";
import { observer } from "mobx-react-lite";
import { Environment } from "models/settings";
import { Card, Popup } from "semantic-ui-react";
import "./environment-card.scss";
import { TestRun } from "models/test-run";
import TestRunCard from "./test-run-card";
import { chain } from "lodash";

interface Props {
  environment: Environment;
  testRuns: TestRun[];
}

const EnvironmentCard = observer(({ environment, testRuns }: Props) => {
  return (
    <Card className="environment-card">
      <Card.Content className="header">
        <Popup position={"bottom center"} content={environment.name}
               trigger={<div className="name text-overflow">{environment.name}</div>} />
        {environment.url && environment.url.length > 0 && (
          <a href={environment.url} target="_blank">
            {substringAfter(environment.url, "//")}
          </a>
        )}
      </Card.Content>
      <Card.Content className="tests">
        {<div className="title">{testRuns.length > 0 ? "" : "No "}Recent Activity</div>}
        {chain(testRuns)
          .sortBy("id")
          .reverse()
          .map((t) => <TestRunCard key={t.id} testRun={t} />)
          .value()}
      </Card.Content>
    </Card>
  );
});

export default EnvironmentCard;
