import { toLowerWithDashes } from "core/utils";
import { Form } from "semantic-ui-react";

interface Props {
  value: string;
  error?: boolean | string;
  label?: string;
  name?: string;
  onChange: (value: string) => any;
  onBlur?: () => any;
}

const KeyInput = ({ onChange, onBlur, value, name, error, label }: Props) => {
  return (
    <Form.Input
      className="key-input"
      onChange={(v) => onChange(toLowerWithDashes(v.target.value))}
      value={value ? value.toString() : ""}
      error={error}
      label={label}
      onBlur={onBlur}
      name={name}
    />
  );
};

export default KeyInput;
