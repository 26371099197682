import DatetimeDisplay from "components/datetime-display";
import DurationDisplay from "components/duration-display";
import IconButton from "components/icon-button";
import TestRunProgress from "components/test-run-progress";
import { calculateDuration } from "core/date-utils";
import { observer } from "mobx-react-lite";
import { TestPlanRun } from "models/test-plan";
import { Icon } from "semantic-ui-react";
import { ModalStore, TestPlanStore } from "stores";
import StageResults from "./stage-results";

interface Props {
  run: TestPlanRun;
}

const stopPlanRun = async (testPlanId: number, runId: number) => {
  ModalStore.confirmation.show(
    async (okClicked) => {
      if (okClicked) {
        await TestPlanStore.stopRun(testPlanId, runId);
      }
    },
    {
      message: "Are you sure you stop this run?",
      okColor: "red",
      okText: "Stop Run",
    },
  );
};

const ResultRow = observer(({ run }: Props) => (
  <>
    <tr className="test-plan-result-row" key={0}>
      <td className="date">
        <DatetimeDisplay datetime={run.createdAt} />
      </td>
      <td className="status">
        {run.isRunning && <Icon name="circle notch" loading={true} />}
        {run.status}
        {run.isCanceled && (
          <div className="cancel-info">
            {run.statusUpdatedBy != null && (
              <p>Canceled by {run.statusUpdatedBy.email}</p>
            )}
            {run.statusUpdatedBy == null && <p>Canceled By CLI</p>}
          </div>
        )}
      </td>
      <td className="results" colSpan={3}>
        {run.totalTestCount > 0 && (
          <>
            <TestRunProgress
              pass={run.passTestCount}
              fail={run.failTestCount}
              total={run.totalTestCount}
            />
            <div className="plan-result-info">
              {run.passStageCount} of {run.totalStageCount} stages pass
              {run.failStageCount > 0 && <span> with {run.failStageCount} fail</span>}
              {run.skippedStageCount > 0 && <span> and {run.skippedStageCount} skipped</span>}
            </div>
          </>
        )}
      </td>
      <td className="duration">
        <DurationDisplay duration={calculateDuration(run.createdAt, run.completedAt)} />
        {run.isRunning && (
          <IconButton
            label="Stop Run"
            icon="stop circle outline"
            onClick={() => stopPlanRun(run.testPlanId, run.id)}
          />
        )}
      </td>
      <td className="actions">
        <IconButton
          icon={run.isOpen ? "chevron up" : "chevron down"}
          onClick={() => TestPlanStore.toggleTestPlanRunOpen(run.id)}
        />
      </td>
    </tr>
    {run.isOpen &&
      run.stages.map((s) => (
        <StageResults
          key={s.id}
          stage={s}
          stageComplete={run.isCompleted || run.nextStageStarted(s.id)}
        />
      ))}
  </>
));

export default ResultRow;
