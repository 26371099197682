import { SemanticICONS } from "semantic-ui-react";
import ENVIRONMENT from "./environment";

export const EMPTY_OBJECT = Object.freeze({});
export const EMPTY_ARRAY = Object.freeze([]);
export const IDENTITY_FUNC = (x) => x;
export const EMTPY_FUNC = () => null;
export const TRUE_FUNC = () => true;

export const IS_PROD = ENVIRONMENT.indexOf("prod") != -1;
export const IS_DEV = ENVIRONMENT.indexOf("dev") != -1;
export const IS_LOCAL = ENVIRONMENT.indexOf("local") != -1;
export const IS_NOT_PROD = !IS_PROD;
export const RUNNING_LOCALLY = window.location.host.includes("localhost");

export const API_URL = {
  local: "http://localhost:8080",
  dev: "https://api.dev.testery.io",
  prod: "https://api.testery.io",
}[ENVIRONMENT];

export const AUTH_DOMAIN = {
  local: "testery-dev.auth0.com",
  dev: "testery-dev.auth0.com",
  prod: "testery.auth0.com",
}[ENVIRONMENT];

export const AUTH_CLIENT_ID = {
  local: "0tdSpdEIHUiG0XDqz9fHRfpmlgvd0wfs",
  dev: "0tdSpdEIHUiG0XDqz9fHRfpmlgvd0wfs",
  prod: "yiXfUQehbrTqSlmQc5v3emTAS5M3y0Pg",
}[ENVIRONMENT];

export const STRIPE_KEY = {
  local: "pk_test_owLd8GSRqyi8dbRZUfVsJeRO",
  dev: "pk_test_owLd8GSRqyi8dbRZUfVsJeRO",
  prod: "pk_live_nCC7Nl0nyZLnAiAbWpL5ABzQ",
}[ENVIRONMENT];

export const GIT_HUB_APP_INSTALL_URL = {
  local: "https://github.com/apps/testery-dev/installations/new",
  dev: "https://github.com/apps/testery-dev/installations/new",
  prod: "https://github.com/apps/testery/installations/new",
}[ENVIRONMENT];

export const SLACK_CLIENT_ID = {
  local: "15014727845.3296270505991",
  dev: "15014727845.3296270505991",
  prod: "15014727845.624392511120",
}[ENVIRONMENT];

export const JIRA_CLIENT_ID = {
  local: "FCaFhleRsD1FCvSUKjAP5BDlQ7mpXfvz",
  dev: "p72Uqgm6aaeAfjuGIJm4SoJQwNupRQax",
  prod: "iWOjJXxfY2B5Z6S5fk7uEhT3SJ8wJNBJ",
}[ENVIRONMENT];

export const GROWTHBOOK_CLIENT_KEY = {
  local: "sdk-Hky6iPmOplexfkA2",
  dev: "sdk-Hky6iPmOplexfkA2",
  prod: "sdk-OwLLWnoRzvJ2Xg2",
}[ENVIRONMENT];

export const GOOGLE_ANALYTICS_ID = {
  local: "G-NFS0YXK8ZC",
  dev: "G-NFS0YXK8ZC",
  prod: "G-B26D56PL3L",
}[ENVIRONMENT]

export const Icons = {
  testRuns: "tasks" as SemanticICONS,
  project: "folder outline" as SemanticICONS,
  workflow: "fork" as SemanticICONS,
  testPlan: "clipboard list" as SemanticICONS,
  alert: "bell outline" as SemanticICONS,
  environment: "server" as SemanticICONS,
  settings: "settings" as SemanticICONS,
  triggers: "clock outline" as SemanticICONS,
  dashboard: "dashboard" as SemanticICONS,
  pipelines: "th" as SemanticICONS,
  delete: "trash" as SemanticICONS,
  edit: "edit" as SemanticICONS,
  run: "play" as SemanticICONS,
  threeDotMenu: "ellipsis vertical" as SemanticICONS,
  terminal: "terminal" as SemanticICONS,
  image: "image outline" as SemanticICONS,
  video: "video play" as SemanticICONS,
  stethoscope: "stethoscope" as SemanticICONS,
};

export const UPLOAD_ARTIFACTS_HELP_URL =
  "https://docs.testery.io/testery-integration/testery-cli-docs#upload-build-artifacts";
export const TESTNG_HELP_URL = "https://app.gitbook.com/@testery/s/testery/framework-specific-guidance/testng";
export const DYNAMIC_ENV_HELP_URL = "https://docs.testery.io/get-to-know-testery/test-plans#add-test-suites-to-stage";
