import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Page from "pages/page";
import { Icons } from "core/constants";
import { rerunWhileComponentLoaded } from "core/react-utils";
import { AppStore, DashboardStore, InsightStore, PipelineStore, ProjectStore, SettingsStore, TestRunStore } from "stores";
import { observer } from "mobx-react-lite";
import { Card } from "semantic-ui-react";
import Spinner from "components/spinner";
import DatePicker from "react-datepicker";
import ProjectSelect from "components/form/project-select";
import { subMonths } from "date-fns";
import BranchSelect from "components/form/branch-select";
import BuildSelect from "components/form/build-select";
import "./dashboard.scss";
import PipelineStageCard from "../../components/dashboard/pipeline-stage-card";
import { organizationUrl } from "core/utils";



const DashboardPage = observer(() => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();


  useEffect(() => {
    async function loadData() {
      await PipelineStore.ensureLoaded();
      await ProjectStore.ensureLoaded();
      await InsightStore.ensureLoaded();
      await TestRunStore.ensureLoaded();
      if (!DashboardStore.testRunsLoaded) {
        await DashboardStore.loadLatestForEnvironments();
      }
      if (TestRunStore.testRuns.length === 0) {
          history.push(organizationUrl("get-started"));
  }
      setLoading(false);
    }

    void loadData();
  }, [DashboardStore.testRunsLoaded]);

  rerunWhileComponentLoaded(async () => {
    await DashboardStore.loadLatestForEnvironments();
  }, 60);

  const project = ProjectStore.find(DashboardStore.selectedProjectId);

  const showNoStage =
    (SettingsStore.hasEnvironmentWithNoPipelineStage &&
      DashboardStore.noStageEnvironmentHasTestRun) ||
    !SettingsStore.hasEnvironmentWithPipelineStage;

  return (
    <Page name="dashboard" title="Dashboard" icon={Icons.dashboard}>
      {(loading || !DashboardStore.testRunsLoaded) && <Spinner size={25} />}
      {!loading && (
        <>
          {DashboardStore.testRunsLoaded && (
            <div>
              <div className="list-filter-container">
                <div className="list-filters">
                  <DatePicker
                    isClearable
                    selected={DashboardStore.selectedDate}
                    onChange={DashboardStore.updateSelectedDate}
                    placeholderText="Any Date"
                    minDate={subMonths(new Date(), 2)}
                  />
                  <ProjectSelect
                    value={DashboardStore.selectedProjectId}
                    placeholder="Any Project"
                    onChange={DashboardStore.updateSelectedProject}
                  />
                  {project && (
                    <BranchSelect
                      placeholder="Any Branch"
                      value={DashboardStore.selectedBranch}
                      onChange={DashboardStore.updateSelectedBranch}
                      provider={project.gitProvider}
                      owner={project.gitOwner}
                      repo={project.gitRepo}
                      hideIfEmpty={true}
                    />
                  )}
                  {project && (
                    <BuildSelect
                      placeholder="Any Build"
                      project={project}
                      value={DashboardStore.selectedBuild}
                      onChange={DashboardStore.updateSelectedBuild}
                      hideIfNoBuilds={true}
                    />
                  )}
                </div>
              </div>
              <Card.Group className="pipelines" centered>
                {PipelineStore.sortedItems.map((s) => (
                  <PipelineStageCard
                    key={s.id}
                    pipelineStageName={s.name}
                    pipelineStageId={s.id}
                    testRuns={DashboardStore.testRuns}
                  />
                ))}
                {showNoStage && (
                  <PipelineStageCard
                    key={0}
                    pipelineStageName="No Stage"
                    pipelineStageId={null}
                    testRuns={DashboardStore.testRuns}
                    showCreatePipelineInfo={
                      !SettingsStore.hasEnvironmentWithPipelineStage && AppStore.userIsAdmin
                    }
                  />
                )}
              </Card.Group>
            </div>
          )}
        </>
      )}
    </Page>
  );
});

export default DashboardPage;
