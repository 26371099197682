import Project, { TestFramework } from "models/project";
import Select from "./select";
import { ProjectStore } from "stores";
import { observer } from "mobx-react-lite";
import { RefObject } from "react";

interface Props {
  value: number[];
  onChange: (ids: number[]) => any;
  placeholder?: string;
  itemRef?: RefObject<HTMLElement>;
  error?: boolean | string;
  label?: string;
  allowOtherFramework?: boolean;
  onlyWithDeploys?: boolean;
  anyOption?: boolean;
}

const ProjectSelectMulti = observer(
  ({
     value,
     onChange,
     placeholder,
     itemRef,
     error,
     label,
     anyOption,
     allowOtherFramework,
     onlyWithDeploys,
   }: Props) => {
    let projects = [...ProjectStore.active];

    if (onlyWithDeploys) {
      projects = projects.filter((p) => p.hasDeploy);
    } else if (!allowOtherFramework) {
      projects = projects.filter((p) => p.testingFramework != TestFramework.Other);
    }

    if (anyOption) {
      projects.unshift(new Project({ id: 0, name: "Any Project" }));
    }

    const toProjectList = (ids: number[]) => ids.map((i) => {
      const project = projects.find((p) => p.id == i);
      return {
        label: (project.name === project.key || !project.key) ? project.name : `${project.name} ${project.key}`,
        value: project.key,
        projectName: project.name,
        projectId: project.id,
      };
    });

    const options = projects.map(project => {
      const label = (project.name === project.key || !project.key) ? project.name : `${project.name} ${project.key}`;
      return { label: label, value: project.key, projectName: project.name, projectId: project.id };
    });

    return (
      <Select
        className="project-select"
        placeholder={placeholder || ""}
        value={toProjectList(value)}
        isClearable={!!placeholder && !!value}
        isSearchable={true}
        isMulti={true}
        onChangeMulti={(v) => onChange(v.map((p) => p.projectId))}
        options={options}
        valueField="projectId"
        formatOption={(option) =>
          option.value != option.label && option.value ? (
            <div>
              {option.projectName}
              <div className="info">Key: {option.value}</div>
            </div>
          ) : (
            option.label
          )
        }
        autoDefault={!placeholder}
        itemRef={itemRef}
        error={error}
        label={label}
      />
    );
  },
);

export default ProjectSelectMulti;
