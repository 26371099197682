import "./confirmation.scss";
import { observer } from "mobx-react-lite";
import { ModalStore } from "stores";
import { Modal, Button, Icon } from "semantic-ui-react";
import { isString } from "lodash";

const Confirmation = observer(() => {
  const { confirmation } = ModalStore;
  const state = confirmation.state;

  let message = state.message;

  if (isString(message)) {
    message = <h3>{state.message}</h3>;
  }

  let helperBubbleIcon;

  switch(state.helperStyle) {
    case "hint": helperBubbleIcon = "pin";
      break;
    case "warning": helperBubbleIcon = "warning circle";
      break;
    case "danger": helperBubbleIcon = "alarm";
      break;
    default:
      helperBubbleIcon = "pin";
  }

  const helperBubbleLink = (
    <a href={state.helperLinkHref} target={"_blank"}>{state.helperLinkText ? state.helperLinkText : state.helperLinkHref}</a>
  );

  const modalHint = (
    <div className={"helperBubble " + state.helperStyle} style={{display: "flex"}}>
      <div className={"icon"}><Icon name={helperBubbleIcon}/></div>
      <div className={"text"}>
        {state.helperText}
        {state.helperLinkHref && helperBubbleLink}
      </div>
    </div>
  );

  return (
    <Modal open={confirmation.showing} size={state.size || "mini"}>
      {state.header && <Modal.Header>{state.header}</Modal.Header>}
      <Modal.Content>
        <div className="confirmation">{message}</div>
        {state.helperText && modalHint}
      </Modal.Content>
      <Modal.Actions>
        {!state.hideCancel && (
          <Button
            size="small"
            onClick={() => confirmation.hide(false)}
            color={state.cancelColor}
            inverted={state.cancelInverted}
          >
            {!state.hideButtonIcons && <Icon name="times" />}
            {state.cancelText || "Cancel"}
          </Button>
        )}
        {!state.hideOk && (
          <Button
            size="small"
            onClick={() => {
              confirmation.hide(true)
              if(state.okClick != null)
                state.okClick();
            }}
            color={state.okColor || "green"}
            inverted={state.okInverted}
          >
            {!state.hideButtonIcons && <Icon name={state.okIcon ? state.okIcon : "check"} />}
            {state.okText || "OK"}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
});

export default Confirmation;
