import { calculateDuration } from "core/date-utils";
import { type } from "core/serialization";
import { numberWithTh } from "core/utils";
import { capitalize, padStart } from "lodash";
import { computed, observable } from "mobx";
import { Account } from "./account";
import Project from "./project";
import { TestRunner, TestRunnerStatus, TestRunStatus } from "./test-run";

export class TestRunData {
  running: number = 0;
  queued: number = 0;
  submitted: number = 0;
}

export class PerformanceCounts {
  requests: number = 0;
  averageTime: number = 0;
  averageDbTime: number = 0;
}

export class PerformanceCountsData {
  @type(PerformanceCounts)
  web = new PerformanceCounts();
  @type(PerformanceCounts)
  message = new PerformanceCounts();
}

export class HeaderData {
  accounts: number = 0;
  users: number = 0;
  @type(TestRunData)
  testRuns = new TestRunData();
  @type(PerformanceCountsData)
  lastHourPerformance = new PerformanceCountsData();
}

export class TestRunInfo {
  id: number;
  status: TestRunStatus;
  @type(Account)
  account: Account;
  @type(Project)
  project: Project;
  createdAt: string;
  startTime: string;
  endTime: string;
  testCount: number;
  remainingTests: number;
  currentRunnerCount: number;
  remainingMessages: number;
  runnersNeeded: number;
  maxRunners: number;
  idleRunnerCount: number;

  @computed
  get calculatedDuration() {
    return calculateDuration(this.startTime, this.endTime);
  }
}

export class PerformanceDataExtra {
  path: string;
  method: string;
  CLI: boolean;
  userId: number;
  accountId: number;
}

export class MonitorData {
  monitorType: string;
  name: string;
  start: number;
  startTime: string;
  dbRequests: number;
  dbTime: number;
  duration: number;
  @type(PerformanceDataExtra)
  extra: PerformanceDataExtra;
}

export class PerformanceData {
  type: string;
  count: number;
  time: number;
  dbCount: number;
  dbTime: number;
}

export class Performance {
  time: string;
  @type(PerformanceData)
  data: PerformanceData[];
}

export class AccountCounts {
  year: number = 0;
  month: number = 0;
  testRuns: number = 0;
  totalTests: number = 0;
  totalTestingTime: number = 0;

  get totalTestingMinutes() {
    return Math.round(this.totalTestingTime / 60000);
  }
}

export class RunnerCounts {
  windows: number = 0;
  linux: number = 0;
}

export class AccountData {
  @type(RunnerCounts)
  runnerCounts = new RunnerCounts();
  @type(AccountCounts)
  counts: AccountCounts[] = [];
}

export enum TestRunnerAgentStatus {
  Created = "Created",
  Running = "Running",
  Exited = "Exited",
  Deprecated = "Deprecated",
  Draining = "Draining",
}

export class TestRunnerAgentData {
  processes: string[];
  currentRunners: string[];
  waitingForCleanUp: number;
  availableDiskSpace: number;
}

export class TestRunnerAgent {
  id: number;
  createdAt: string;
  updatedAt: string;
  serverId: string;
  lastTestRunCompletedAt: string;
  @observable
  status: TestRunnerAgentStatus;
  version: string;
  windowsAwsAmiId: string;
  os: string;
  cluster: string;
  capacity: number;
  replacedById: number;
  @type(TestRunnerAgentData)
  data: TestRunnerAgentData;
  testRunners: Array<TestRunner>

  get shortVersion() {
    if (!this.version || this.version.length < 10) return this.version;

    return this.version.substring(0, 10);
  }

  get nonExitedRunners(): Array<TestRunner> {
    if (!this.testRunners)
      return [];

    return this.testRunners.filter(testRunner => testRunner.status != TestRunnerStatus.Exited)
  }
}

export class ScheduledJob {
  id: number;
  @observable
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
  lastStartTime: string;
  frequencyInMinutes: number;
  hourOfDayUtc: number;
  minuteOfHour: number;
  dayOfMonth: number;
  monthOfYear: number;
  dayOfWeek: string;
  className: string;
  functionName: string;

  get frequencyDisplay() {
    let display = "";

    if (this.frequencyInMinutes) {
      display =
        this.frequencyInMinutes == 1 ? "Every minute" : `Every ${this.frequencyInMinutes} minutes`;
    }

    if (this.monthOfYear != null) {
      display += ` On ${this.monthOfYear}/${this.dayOfMonth}`;
    } else if (this.dayOfMonth != null) {
      display += ` On the ${numberWithTh(this.dayOfMonth)}`;
    }

    if (this.hourOfDayUtc != null) {
      display += `At ${this.hourOfDayUtc}:${padStart(this.minuteOfHour.toString(), 2, "0")} UTC`;
    }

    if (this.dayOfWeek) {
      display += " Every " + capitalize(this.dayOfWeek);
    }

    return display;
  }
}
