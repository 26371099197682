import { useEffect, useState } from "react";
import Select from "./select";
import { JsxElement } from "typescript";
import { ProjectStore, TestRunStore } from "stores";

interface Props {
  value: Array<string>;
  error?: boolean | string;
  label?: string | JsxElement;
  placeholder?: string;
  onChange: (v: Array<string>) => any;
}

const BranchMultiSelect = ({
                             onChange,
                             value,
                             label,
                             error,
                             placeholder,
                           }: Props) => {
  const [options, setOptions] = useState([] as string[]);
  const [loading, setLoading] = useState(false);

  const project = ProjectStore.active.find(p => p.id == TestRunStore.filterProjectId);

  useEffect(() => {
    setLoading(true);
    if (project != null && project.gitProvider) {
      ProjectStore.loadBranches(project.gitProvider, project.gitOwner, project.gitRepo)
        .then(branches => {
          setOptions(branches.map(b => b.name));
          setLoading(false);
        });
    } else {
      setOptions([]);
      setLoading(false);
    }
  }, [TestRunStore.filterProjectId, TestRunStore.filterBranches]);

  return (
    <Select
      disabled={project == null || !project.gitProvider}
      placeholder={placeholder}
      isClearable={!!value && !!placeholder}
      className="branch-multi-select"
      isLoading={loading}
      value={value}
      options={options}
      isSearchable={true}
      isMulti={true}
      onChangeMulti={options => onChange(options)}
      label={label}
      error={error}
      isCreatable={true}
      createOptionText="Branch:"
    />
  );
};

export default BranchMultiSelect;
