import { isNotBlank } from "core/utils";
import { action, computed, observable, reaction } from "mobx";
import { ActionType, AlertStatus, EventType } from "models/settings";
import { validUrl } from "core/validation";
import Form from "./form";

export default class AlertForm extends Form {
  @observable id: number;
  @observable eventType: EventType;
  @observable actionType: ActionType;
  @observable criteria: string;
  @observable destination: string;
  @observable status: AlertStatus;
  @observable testRunId: number;

  validators = {
    destination: [
      {
        validator: (destination: string) =>
          !this.eventType || this.actionType != ActionType.TRIGGER_WEBHOOK || validUrl(destination),
        message: "You must provide a valid Webhook URL",
      },
      {
        validator: (destination: string) =>
          !this.eventType || this.actionType != ActionType.SEND_SLACK || isNotBlank(destination),
        message: "You must provide a Slack Channel",
      },
      {
        validator: (destination: string) =>
          !this.eventType || this.actionType != ActionType.SEND_MICROSOFT_TEAMS || validUrl(destination),
        message: "You must provide a valid Microsoft Teams Incoming Webhook URL",
      },
    ],
  };

  constructor() {
    super();

    reaction(
      () => this.actionType,
      () => {
        if (
          this.destination?.length > 0 &&
          (
            (this.actionType == ActionType.SEND_SLACK && this.destination.startsWith("http")) ||
            (this.actionType == ActionType.TRIGGER_WEBHOOK && !this.destination.startsWith("http")) ||
            (this.actionType == ActionType.SEND_MICROSOFT_TEAMS && !this.destination.startsWith("http")) ||
            this.actionType == ActionType.TRIGGER_OPSGENIE
          )
        ) {
          this.updateField("destination", "");
        }
      },
    );
  }

  @computed
  get destinationLabel() {
    if (!this.eventType) return null;
    if (this.actionType == ActionType.SEND_SLACK) return "Slack Channel";
    if (this.actionType == ActionType.SEND_MICROSOFT_TEAMS) return "Microsoft Teams Incoming Webhook URL";
    if (this.actionType == ActionType.TRIGGER_WEBHOOK) return "Webhook URL";
    if (this.actionType == ActionType.SEND_EMAIL) return "Email Address";
    return null;
  }

  @action
  setDefaults() {
    this.id = null;
    this.eventType = null;
    this.actionType = ActionType.SEND_SLACK;
    this.criteria = "";
    this.destination = "";
    this.status = AlertStatus.ACTIVE;
    this.testRunId = null;
  }
}
