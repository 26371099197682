import { observer } from "mobx-react-lite";
import { TestPlanSuite } from "models/test-plan";
import { Draggable } from "react-beautiful-dnd";
import Suite from "./suite";

interface Props {
  suite: TestPlanSuite;
  index: number;
}

const SuiteDraggable = observer(({ suite, index }: Props) => (
  <Draggable draggableId={`suite-${suite.id}`} index={index} type="suite">
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={provided.draggableProps.style}
      >
        <Suite suite={suite} />
      </div>
    )}
  </Draggable>
));

export default SuiteDraggable;
