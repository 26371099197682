import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardMeta,
  Confirm,
  Icon,
  Tab,
} from "semantic-ui-react";
import { AppStore, ModalStore, TeamStore, UserStore } from "stores";
import TesteryTable from "../../components/testery-table";
import UserSelect from "../../components/form/user-select";
import { Team } from "../../models/team";
import { useState } from "react";
import { runInAction } from "mobx";
import { NotificationManager } from "react-notifications";

const TeamsTab = observer(() => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
  const [teamToDelete, setTeamToDelete] = useState<number>();

  useInitialEffect(() => {
    UserStore.loadUserRoles().then(() => TeamStore.loadTeams());
  });

  const editTeam = (team: Team) => {
    runInAction(() => {
      ModalStore.teamInfo.show(null, team);
    });
  };

  const handleUserAdd = async (userId: number, teamId: number) => {
    await TeamStore.addUserToTeam(userId, teamId).then(() => {
      NotificationManager.success("Successfully added user to team.", "Success", 2000);
    });
  };

  const handleUserDelete = async (userId: number, teamId: number) => {
    await TeamStore.deleteUserFromTeam(userId, teamId).then(() => {
      NotificationManager.success("Successfully removed user from team.", "Success", 2000);
    });
  };

  const deleteTeam = async () => {
    await TeamStore.deleteTeam(teamToDelete);
    setConfirmDeleteOpen(false);
  };

  const isAdmin = AppStore.userIsAdmin;

  const teamCards = [];
  TeamStore.teams.forEach(team => {
    const teamUsers = team.users;
    const usersTableData = [];
    const userIdsOnTeam = [];
    teamUsers.forEach(teamUser => {
      userIdsOnTeam.push(teamUser.userId);
      const user = UserStore.getUser(teamUser.userId);
      if (user) {
        if (isAdmin) {
          usersTableData.push([user.fullName, user.email,
            <Button basic={true} negative={true} onClick={() => handleUserDelete(teamUser.userId, team.id)}>
              Remove From Team
            </Button>,
          ]);
        } else {
          usersTableData.push([user.fullName, user.email]);
        }
      }
    });

    const tableHeaders = ["User Name", "User Email"];
    if (isAdmin)
      tableHeaders.push("");

    teamCards.push(
      <Card key={team.id}>
        <CardContent>
          <CardHeader>{team.name}</CardHeader>
          <CardMeta>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {team.email && <div><Icon name={"mail outline"} /> {team.email}</div>}
              {team.slackChannel && <div><Icon name={"slack hash"} /> {team.slackChannel}</div>}
            </div>
          </CardMeta>
          <CardDescription>{team.description}</CardDescription>
        </CardContent>
        <CardContent className={"body"}>
          <TesteryTable tableHeaders={tableHeaders} tableData={usersTableData}
                        removeBoxShadow={true} />
        </CardContent>
        {isAdmin &&
          <CardContent extra={true}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <UserSelect onSelect={userId => handleUserAdd(userId, team.id)} usersAlreadyOnTeam={userIdsOnTeam} />
              <div>
                <Button positive={true} onClick={() => editTeam(team)}><Icon name={"pencil"} /> Edit Team Info</Button>
                <Button negative={true} onClick={() => {
                  setTeamToDelete(team.id);
                  setConfirmDeleteOpen(true);
                }}>
                  <Icon name={"trash"} /> Delete Team
                </Button>
              </div>
            </div>
          </CardContent>
        }
      </Card>,
    );
  });

  let content = <h3>No Teams Found</h3>;
  if (teamCards.length > 0)
    content = (
      <>
        <h3>Teams</h3>
        {teamCards}
      </>
    );

  return (
    <Tab.Pane className="teams">
      <Confirm open={confirmDeleteOpen} onCancel={() => setConfirmDeleteOpen(false)}
               onConfirm={deleteTeam} header={"Delete Team?"}
               content={"Are you sure you'd like to delete this team?"} />
      {isAdmin &&
        <Button positive={true} onClick={() => ModalStore.teamInfo.show()}>
          <Icon name={"plus"} />
          Create New Team
        </Button>
      }
      {content}
    </Tab.Pane>
  );
});

export default TeamsTab;
