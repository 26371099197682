import { TestRunTestComparisonChartData } from "../models/analytics";
import { TestStatus } from "../models/test-run";

interface Props {
  data: TestRunTestComparisonChartData;
}

const NewTestFailuresComparison = ({ data }: Props) => {
  const runOneTests = data.runOneTests.results;
  const runTwoTests = data.runTwoTests.results;

  if (!runOneTests || !runTwoTests)
    return (<h3>Please select two test runs for comparison</h3>);

  if (runOneTests.length === 0 || runTwoTests.length === 0)
    return (<h3>No tests to compare</h3>);

  if (runOneTests[0].projectId != runTwoTests[0].projectId)
    return (<h3>Test runs are from different projects</h3>);

  const earlierTestRunTests = runOneTests[0].testRunId < runTwoTests[0].testRunId ? runOneTests : runTwoTests;
  const laterTestRunTests = runOneTests[0].testRunId > runTwoTests[0].testRunId ? runOneTests : runTwoTests;

  const displayData = new Array<any>();

  laterTestRunTests.forEach(laterTestRunTest => {
    const matchedTestsFromEarlierTestRun = earlierTestRunTests.filter(earlierTestRunTest => earlierTestRunTest.projectTestId === laterTestRunTest.projectTestId);
    let latestCompDataFromEarlierTestRun = null;
    matchedTestsFromEarlierTestRun.forEach(matchedTest => {
      if (!latestCompDataFromEarlierTestRun || matchedTest.id > latestCompDataFromEarlierTestRun.id)
        latestCompDataFromEarlierTestRun = matchedTest;
    });

    if (latestCompDataFromEarlierTestRun && laterTestRunTest.status === TestStatus.FAIL && latestCompDataFromEarlierTestRun.status === TestStatus.PASS)
      displayData.push(
        <tr key={Math.random()}>
          <td>{laterTestRunTest.name}</td>
        </tr>,
      );
  });

  return (
    <div style={{ width: "100%" }}>
      <table>
        <thead>
        <tr>
          <th>Test Name</th>
        </tr>
        </thead>
        <tbody>
        {displayData}
        </tbody>
      </table>
    </div>
  );
};

export default NewTestFailuresComparison;
