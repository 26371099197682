import { useEffect } from "react";
import Select from "./select";
import { observer } from "mobx-react-lite";
import { ProjectStore } from "stores";
import { TriggerType } from "models/test-trigger";

interface Props {
  value: TriggerType;
  onChange: (t: TriggerType) => any;
  error?: boolean | string;
  label?: string;
  noNowOption?: boolean;
  testPlanOption?: boolean;
}

const nowOption = { value: TriggerType.now, label: "Run tests now" };
const intervalOption = { value: TriggerType.interval, label: "Run tests on schedule" };
const intervalTestPlanOption = {
  value: TriggerType.testPlanInterval,
  label: "Run Test Plan on schedule",
};
const deployTestPlanOption = {
  value: TriggerType.testPlanDeploy,
  label: "Run Test Plan on deploy",
};
const deployOption = { value: TriggerType.deploy, label: "Run tests on deploy" };

const TriggerTypeSelect = observer(({ onChange, value, error, label, noNowOption, testPlanOption }: Props) => {
  useEffect(() => {
    if (value == TriggerType.now && noNowOption) {
      onChange(TriggerType.interval);
    }
  }, [value]);
  const options = [nowOption, intervalOption];

  if (ProjectStore.hasProjectWithDeploy) {
    options.push(deployOption);
  }

  if (testPlanOption) {
    options.push(intervalTestPlanOption);

    if (ProjectStore.hasProjectWithDeploy) {
      options.push(deployTestPlanOption);
    }
  }

  if (noNowOption) {
    options.shift();
  }

  return (
    <Select
      className="trigger-type"
      onChange={(e) => onChange(TriggerType[e.value])}
      value={options.find((o) => o.value === value)}
      options={options}
      label={label}
      error={error}
    />
  );
});

export default TriggerTypeSelect;
