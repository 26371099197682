import Select from "./select";
import { ActionType } from "models/settings";

interface Props {
  value: ActionType;
  onChange: (value: ActionType) => any;
  placeholder?: string;
  error?: boolean | string;
  label?: string;
  eventType?: string;
}

const options = [
  { label: "Send Slack Message", value: ActionType.SEND_SLACK },
  { label: "Send Microsoft Teams Message", value: ActionType.SEND_MICROSOFT_TEAMS },
  { label: "Call Webhook", value: ActionType.TRIGGER_WEBHOOK },
  { label: "OpsGenie", value: ActionType.TRIGGER_OPSGENIE },
  { label: "Send Email", value: ActionType.SEND_EMAIL}
];

const AlertActionSelect = ({ onChange, value, placeholder, error, label, eventType }: Props) => {
  var filteredOptions = options;
  if (eventType == "TESTRUN_ANALYSIS_COMPLETE") {
    filteredOptions = options.filter((o) => o.value == ActionType.SEND_SLACK || o.value == ActionType.SEND_EMAIL);
  } else {
      filteredOptions = options.filter((o) => o.value == ActionType.SEND_SLACK || o.value == ActionType.SEND_MICROSOFT_TEAMS || o.value == ActionType.TRIGGER_WEBHOOK || o.value == ActionType.TRIGGER_OPSGENIE);
  }
  return (
    <Select
      className="alert-action-select"
      placeholder={placeholder || ""}
      onChange={(e) => onChange(e.value)}
      value={options.find((o) => o.value == value)}
      isClearable={false}
      options={filteredOptions}
      error={error}
      label={label}
    />
  );
};

export default AlertActionSelect;
