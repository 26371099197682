import { observer } from "mobx-react-lite";
import { AuditLog } from "models/settings";
import { v4 as uuidv4 } from 'uuid';
import "./audit-trail.scss";


interface Props {
    logItems: AuditLog[];
}

const AuditTrail = observer(({logItems}: Props) => {
    return (
        <table className="audit-trail">
            <thead>
                <tr>
                    <th className="timeColumn">Time</th>
                    <th className="messageColumn">Message</th>
                </tr>
            </thead>
            <tbody>
                {logItems && logItems.length > 0 && (
                    logItems.map(log => <AuditLogLine log={log} key={uuidv4()} />)
                )}
            </tbody>
        </table>
    )
})

export default AuditTrail;


interface AuditLogLineProps {
    log: AuditLog;
    key: string;
  }
  
  const AuditLogLine = observer(({ log }: AuditLogLineProps) => {
    var detailMessage = JSON.parse(log.message)
    return (
      <>
        <tr>
          <td className="timeColumn">{log.time}</td><td className="messageColumn"><pre>{detailMessage.message.replace('Old variables:', '\n\nOld variables:\n').replace('New variables:', '\n\nNew variables:\n')}</pre></td>
        </tr>
      </>
    );
  })