import { action, computed, observable, reaction } from "mobx";
import Form from "./form";
import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  differenceInCalendarDays,
  isBefore,
  startOfDay,
  subDays,
} from "date-fns";
import { Timeframe, TimeframeFrequency } from "models/analytics";
import { formatForMillisOffset } from "../../core/date-utils";

export default class ChartOptionsForm extends Form {
  @observable start: Date;
  @observable end: Date;
  @observable environmentId: number;
  @observable projectId: number;
  @observable branch: string;
  @observable pipelineStageId: number;
  @observable frequency: TimeframeFrequency;
  @observable timeframe: Timeframe;
  @observable testRunOneId: number;
  @observable testRunTwoId: number;

  constructor() {
    super();

    reaction(
      () => this.projectId,
      () => {
        this.branch = null;
      },
    );

    reaction(
      () => this.timeframe,
      () => {
        if (this.allowHourly) {
          this.frequency = TimeframeFrequency.Hourly;
        } else if (this.isHourly) {
          this.frequency = TimeframeFrequency.Daily;
        }

        if (
          this.isMonthly &&
          this.timeframe != Timeframe.Last60Days &&
          this.timeframe != Timeframe.Last90Days
        ) {
          this.frequency = TimeframeFrequency.Weekly;
        }

        if (this.isWeekly && Timeframe.Last7Days == this.timeframe) {
          this.frequency = TimeframeFrequency.Daily;
        }
      },
    );
  }

  @action
  setDefaults() {
    this.environmentId = null;
    this.projectId = null;
    this.pipelineStageId = null;
    this.branch = null;
    this.frequency = TimeframeFrequency.Hourly;
    this.updateTimeframe(Timeframe.Today);
  }

  @action
  updateTestRunOneId(testRunOneId: number) {
    this.testRunOneId = testRunOneId;
  }

  @action
  updateTestRunTwoId(testRunTwoId: number) {
    this.testRunTwoId = testRunTwoId;
  }

  @action
  updateTimeframe(timeframe: Timeframe) {
    this.timeframe = timeframe;
    if (timeframe == Timeframe.Today) {
      this.start = startOfDay(new Date());
      this.end = addDays(this.start, 1);
    } else {
      this.end = startOfDay(new Date());
      var daysBack: number;

      if (timeframe == Timeframe.Yesterday) {
        daysBack = 1;
      } else if (timeframe == Timeframe.Last7Days) {
        daysBack = 7;
      } else if (timeframe == Timeframe.Last30Days) {
        daysBack = 30;
      } else if (timeframe == Timeframe.Last60Days) {
        daysBack = 60;
      } else if (timeframe == Timeframe.Last90Days) {
        daysBack = 90;
      }
      this.start = subDays(this.end, daysBack);
    }
  }

  @computed
  get numberOfDays() {
    if (!this.start || !this.end) return 0;

    return differenceInCalendarDays(this.end, this.start);
  }

  get isWeekly() {
    return this.frequency == TimeframeFrequency.Weekly;
  }

  get isHourly() {
    return this.frequency == TimeframeFrequency.Hourly;
  }

  get isDaily() {
    return this.frequency == TimeframeFrequency.Daily;
  }

  get isMonthly() {
    return this.frequency == TimeframeFrequency.Monthly;
  }

  @computed
  get allowHourly() {
    return this.timeframe == Timeframe.Today || this.timeframe == Timeframe.Yesterday;
  }

  @computed
  get dates() {
    let step = addWeeks;

    if (this.isHourly) {
      step = addHours;
    } else if (this.isDaily) {
      step = addDays;
    } else if (this.isMonthly) {
      step = addMonths;
    }

    const dates = [] as Date[];

    for (let date = this.start; isBefore(date, this.end); date = step(date, 1)) {
      dates.push(date);
    }

    return dates;
  }

  buildUrlParams() {
    let params = `start=${formatForMillisOffset(this.start)}&end=${formatForMillisOffset(this.end)}`;

    if (this.environmentId) params += `&environmentId=${this.environmentId}`;
    if (this.projectId) params += `&projectId=${this.projectId}`;
    if (this.branch) params += `&branch=${this.branch}`;
    if (this.pipelineStageId) params += `&pipelineStageId=${this.pipelineStageId}`;

    return params;
  }
}
