import { observer } from "mobx-react-lite";
import { ModalStore, ProjectStore, TestPlanStore, TestSuiteStore } from "stores";
import { Modal, Button, Icon, Form, Card, Radio, Popup } from "semantic-ui-react";
import "./test-plan-suite-edit.scss";
import TestPlanSuiteForm from "models/forms/test-plan-suite-form";
import TestRunVersionSelect from "components/form/test-run-version-select";
import ProjectSelect from "components/form/project-select";
import EnvironmentSelect from "components/form/environment-select";
import TestRunOptions from "components/form/test-run-options";
import TestSuiteForm from "models/forms/test-suite-form";
import { useEffect } from "core/react-utils";
import { reaction } from "mobx";
import { DYNAMIC_ENV_HELP_URL } from "core/constants";


const form = new TestPlanSuiteForm();
const testSuiteForm = new TestSuiteForm();
const { testPlanSuiteEdit } = ModalStore;

async function saveTestPlanStage() {
  if (!form.id) {
    const stage = TestPlanStore.findTestPlanStage(form.testPlanStageId);
    form.updateField("order", stage.activeSuites.length);
  }
  if (testSuiteForm.testOptionsType == "suite") {
    if (testSuiteForm.testSuiteId) {
      form.updateField("testSuiteId", testSuiteForm.testSuiteId);
    } else {
      testSuiteForm.addError("testSuiteId", "You must select a Test Suite");
    }
  } else {
    const testSuite = await TestSuiteStore.save(form.projectId, testSuiteForm);
    form.updateField("testSuiteId", testSuite.id);
  }
  await TestPlanStore.saveTestPlanSuite(form);
  testPlanSuiteEdit.hide();
}

testPlanSuiteEdit.onHidden = form.clearFormData;
testPlanSuiteEdit.beforeShown = () => {
  TestSuiteStore.ensureLoaded();
  form.clearFormData();
  testSuiteForm.clearFormData();
  testSuiteForm.id = testPlanSuiteEdit.state.testSuiteId;
  form.populate(testPlanSuiteEdit.state);
};

reaction(
  () => form.projectId,
  () => {
    const project = ProjectStore.find(form.projectId);
    if (project) {
      testSuiteForm.populateFromProject(project);
    }
  },
);

const TestPlanSuiteEdit = observer(() => {
  const project = ProjectStore.find(form.projectId);

  useEffect(async () => {
    testSuiteForm.updateField(
      "testOptionsType",
      TestSuiteStore.projectHasActiveTestSuites(project?.id) ? "suite" : "all",
    );
  }, [project?.id]);

  return (
    <Modal open={testPlanSuiteEdit.showing} size="small">
      <Modal.Header>{testPlanSuiteEdit.state.id ? "Edit" : "New"} Test Plan Suite</Modal.Header>
      <Modal.Content>
        <Form className="test-plan-suite-edit">
          {!form.useDynamicEnvironment && (<EnvironmentSelect
            placeholder="Select Environment"
            label="Environment"
            value={form.environmentId}
            onChange={form.onChange("environmentId")}
            error={form.getErrorText("environmentId")}
          />)}
          <Radio
              checked={form.useDynamicEnvironment}
              onChange={(_, data) => form.updateField("useDynamicEnvironment", data.checked)}
              name="useDynamicEnvironment"
              label="Use Dynamic Environment"
              toggle={true}
          />
          <Popup
            key="useDynamicEnvironmentPopup"
            hoverable={true}
            content={
              <span>
                <p>
                  You can use dynamic expressions to determine the environment for each test plan execution.
                </p>
                <p>
                  <a target="_blank" href={DYNAMIC_ENV_HELP_URL}>
                    See our documentation on how to do that.
                  </a>
                </p>
              </span>
            }
            trigger={<Icon name="question circle" />}
          />
          {form.useDynamicEnvironment && (<Form.Input
            label="Dynamic Environment"
            value={form.dynamicEnvironment}
            onChange={form.onChange("dynamicEnvironment")}
            error={form.getErrorText("dynamicEnvironment")}
          />)}
          <ProjectSelect
            label="Project"
            value={form.projectId}
            onChange={form.onChange("projectId")}
            error={form.getErrorText("projectId")}
            placeholder="Select Project"
          />
          {project && (
            <Card style={{ width: "100%" }}>
              <Card.Content>
                <h4>Version</h4>
                <TestRunVersionSelect project={project} form={form} />
              </Card.Content>
            </Card>
          )}
          {project && (
            <Card style={{ width: "100%" }}>
              <Card.Content>
                <TestRunOptions
                  project={project}
                  form={testSuiteForm}
                  title="Test Suite"
                  createTestSuite
                  hidePriority
                />
              </Card.Content>
            </Card>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={() => testPlanSuiteEdit.hide()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button onClick={saveTestPlanStage} loading={form.isSubmitting} color="green" type="submit">
          <Icon name="check" />
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default TestPlanSuiteEdit;
