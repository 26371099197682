import { useState } from "react";
import "./commit-info.scss";
import { observer } from "mobx-react-lite";
import { ModalStore, ProjectStore, TestRunStore } from "stores";
import { Modal, Icon, Button } from "semantic-ui-react";
import Project, { Commit, Deploy, ProjectCommit } from "models/project";
import { buildRefUrl, isNotBlank } from "core/utils";
import Spinner from "components/spinner";
import DatetimeDisplay from "components/datetime-display";

const CommitInfo = observer(() => {
  const [deploys, setDeploys] = useState([] as Deploy[]);
  const [commits, setCommits] = useState([] as Commit[]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const testRun = ModalStore.commitInfo.state;

  ModalStore.commitInfo.onClose = () => {
    setLoading(true);
    setError(false);
  };

  ModalStore.commitInfo.onShown = async () => {
    try {
      setError(false);
      setLoading(true);
      const tr = ModalStore.commitInfo.state;
      setDeploys([]);
      const values = await TestRunStore.loadDeploysForTestRun(tr.id);
      setDeploys(values);
      const projectCommits = values.map((v) => {
        return { projectId: v.projectId, commit: v.commit } as ProjectCommit;
      });

      if (!projectCommits.find((pc) => pc.projectId == tr.projectId && pc.commit == tr.gitRef)) {
        projectCommits.push({ projectId: tr.projectId, commit: tr.gitRef });
      }

      setCommits(await ProjectStore.loadCommitList(projectCommits));

      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

  const nonTestRunDeploys = deploys.filter((d) => d.projectId != testRun.projectId);

  const testRunDeploy =
    deploys.find((d) => d.projectId == testRun.projectId) ||
    new Deploy({
      projectId: testRun.projectId,
      commit: testRun.gitRef,
      environmentId: testRun.environmentId,
      branch: testRun.branch,
      build: testRun.build,
      createdAt: testRun.startTime,
    });

  const findCommit = (deploy: Deploy) =>
    commits.find((c) => c.projectId == deploy.projectId && c.id == deploy.commit);

  return (
    <Modal open={ModalStore.commitInfo.showing} size="small" className="commit-info">
      <Modal.Header>Commit Details</Modal.Header>
      <Modal.Content>
        {error && <h3>Failed to load commit info, please click Close and try again!</h3>}
        {loading && !error && <Spinner />}
        {!loading && !error && (
          <div>
            <DeployDisplay
              deploy={testRunDeploy}
              commit={findCommit(testRunDeploy)}
              project={ProjectStore.find(testRunDeploy.projectId)}
            />
            {nonTestRunDeploys.map((d) => (
              <DeployDisplay
                key={d.id}
                deploy={d}
                commit={findCommit(d)}
                project={ProjectStore.find(d.projectId)}
              />
            ))}
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => ModalStore.commitInfo.hide()}>
          <Icon name="times" />
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

interface DeployProps {
  deploy: Deploy;
  commit: Commit;
  project: Project;
}

const DeployDisplay = ({ deploy, commit, project }: DeployProps) => {
  const refLink = buildRefUrl(project, deploy.commit);

  return (
    <div className="deploy">
      <div className="item project">{project.name}</div>
      <div className="left">
        {refLink && (
          <div className="item">
            Commit ID: <a href={refLink}>{deploy.commit.substring(0, 20)}</a>
          </div>
        )}
        {isNotBlank(deploy.branch) && <div className="item">Branch: {deploy.branch}</div>}
        {deploy.build && <div className="item">Build: {deploy.build.name}</div>}
      </div>
      <div className="right">
        {commit && <div className="user item">Contributor: {commit.user}</div>}
        {commit && (
          <div className="date item">
            Date: <DatetimeDisplay datetime={commit.date} />
          </div>
        )}
      </div>
      <br />
      {commit && <div className="message">Message: {commit.message}</div>}
    </div>
  );
};

export default CommitInfo;
