import { useInitialEffect, setPageInfo } from "core/react-utils";
import { observer } from "mobx-react-lite";
import { Button, Icon, Tab } from "semantic-ui-react";
import { AppStore, RunnerPoolStore } from "stores";
import { useHistory } from "react-router";
import RunnerPools from "components/runner-pools";

const RunnerPoolsTab = observer(() => {
  setPageInfo("environments", "Pipeline Stages");
  const history = useHistory();

  useInitialEffect(() => {
    RunnerPoolStore.loadAll();
  });

  return (
    <Tab.Pane className="pipeline-stages-tab">
      <div>
        Runner pools allow your to allocate resources between your different pipeline stages. For example, 
        you can reserve a certain number of runners for your production stages, and a different number 
        for pre-production stages. 
      </div>
      <div className="page-actions">
        {AppStore.userIsAdmin && (
          <Button color="green" onClick={() => history.push("runner-pools/new")}>
            <Icon name="plus" />
            Add New Runner Pool
          </Button>
        )}
      </div>
      <RunnerPools />
    </Tab.Pane>
  );
});

export default RunnerPoolsTab;
