import API from "core/api";
import { action, computed } from "mobx";
import TestRunForm from "models/forms/test-run-form";
import TestSuiteForm from "models/forms/test-suite-form";
import { TestSuite } from "models/project";
import { ItemStoreList } from "./item-store";
import { TestRunStoreClass } from "./test-run-store";

export class TestSuiteStoreClass extends ItemStoreList<TestSuite> {
  private readonly TestRunStore: TestRunStoreClass;

  constructor(TestRunStore: TestRunStoreClass) {
    super();
    this.TestRunStore = TestRunStore;
  }

  async doLoadAll() {
    const result = await API.getList("/test-suites", TestSuite);
    return result.data;
  }

  async load(id: number) {
    await this.ensureLoaded();
    const existing = this.find(id);
    const result = await API.get(`/projects/${existing.projectId}/test-suites/${id}`, TestSuite);
    return this.update(result.data);
  }

  @computed
  get active() {
    return this.items.filter((s) => !s.archived);
  }

  getForProject(projectId: number) {
    return this.items.filter((s) => s.projectId == projectId);
  }

  getActiveForProject(projectId: number) {
    return this.active.filter((s) => s.projectId == projectId);
  }

  projectHasActiveTestSuites(projectId: number) {
    return this.getActiveForProject(projectId).length > 0;
  }

  async save(projectId: number, data: TestSuiteForm) {
    return data.submit(async (data) => {
      const [action, url] = data.id
        ? [API.patch, `/projects/${projectId}/test-suites/${data.id}`]
        : [API.post, `/projects/${projectId}/test-suites`];
      const result = await action(url, data, TestSuite);
      return this.update(result.data);
    });
  }

  async delete(projectId: number, testSuiteId: number) {
    await API.delete(`/projects/${projectId}/test-suites/${testSuiteId}`);
    return this.load(testSuiteId);
  }

  @action
  async run(form: TestRunForm) {
    return form.submit((data) =>
      this.TestRunStore.createTestRun({ buildId: data.buildName, ...data }),
    );
  }
}
