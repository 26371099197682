import DatetimeDisplay from "components/datetime-display";
import DurationDisplay from "components/duration-display";
import IconButton from "components/icon-button";
import Spinner from "components/spinner";
import { calculateDuration } from "core/date-utils";
import { orderBy } from "lodash";
import { observer } from "mobx-react-lite";
import Project, { TestFramework } from "models/project";
import { StatusColors, TestFile, TestRun, TestRunTest, TestStatus } from "models/test-run";
import { Link } from "react-router-dom";
import { Checkbox, Icon, Label } from "semantic-ui-react";
import { AppStore, SettingsStore, TestRunStore } from "stores";
import { ReviewType } from "../../models/review-data";
import AssignReviewUserSelect from "../form/assign-review-user-select";
import ReviewStatusSelect from "../form/review-status-select";
import "./test-result-row.scss";
import { useState } from "react";
import { AuthorizationProvider } from "../../models/settings";

interface Props {
  testRun: TestRun;
  testFile: TestFile;
  project: Project;
  testTimeoutSeconds: number;
  rerunTests: (testRunTests: Array<TestRunTest>) => any;
  filter: (status: TestRunTest) => boolean;
  open: boolean;
  toggleOpen: () => any;
  toggleRerunOption: (testRunTest: TestRunTest, rerun: boolean) => any;
  refresh: (testRunId: number) => any;
}

const TestResultRow = observer(
  ({
     testRun,
     project,
     testFile,
     testTimeoutSeconds,
     filter,
     rerunTests,
     open,
     toggleOpen,
     toggleRerunOption,
     refresh,
   }: Props) => {
    const [isAllRerunHidden, setIsAllRerunHidden] = useState(false);

    const testsByStatus = testFile.testsByStatus;
    const tests = [
      ...testsByStatus[TestStatus.RUNNING],
      ...testsByStatus[TestStatus.NOT_RUN],
      ...testsByStatus[TestStatus.FAIL],
      ...testsByStatus[TestStatus.IGNORED],
      ...testsByStatus[TestStatus.PASS],
      ...testsByStatus[TestStatus.PENDING],
    ];

    const fileTimeout = testTimeoutSeconds * 1000 * testFile.latestTests.length <= testFile.duration;
    const isOpen = open || testFile.hasSelectedTest;

    return (
      <>
        {testFile.latestTests.find((t) => filter(t)) && (
          <FileRow
            open={isOpen}
            testRun={testRun}
            toggleOpen={toggleOpen}
            testFile={testFile}
            testTimeoutSeconds={testTimeoutSeconds}
            rerunTests={rerunTests}
            setIsAllRerunHidden={setIsAllRerunHidden}
            isAllRerunHidden={isAllRerunHidden}
          />
        )}
        {isOpen &&
          tests
            .filter((t) => filter(t))
            .map((t) => (
              <TestRow
                key={t.id}
                testRun={testRun}
                testRunTest={t}
                testRunTests={testFile.tests.filter((tft) => tft.parentId == t.parentId)}
                project={project}
                fileTimeout={fileTimeout}
                testTimeoutSeconds={testTimeoutSeconds}
                rerunTests={rerunTests}
                isAllRerunHidden={isAllRerunHidden}
                toggleRerunOption={toggleRerunOption}
                refresh={refresh}
              />
            ))}
      </>
    );
  },
);

export default TestResultRow;

interface FileRowProps {
  testRun: TestRun;
  testFile: TestFile;
  testTimeoutSeconds: number;
  open: boolean;
  toggleOpen: () => any;
  rerunTests: (testRunTests: Array<TestRunTest>) => any;
  setIsAllRerunHidden: (isAllRerunHidden: boolean) => any;
  isAllRerunHidden: boolean;
}

const FileRow = observer(({
                            testRun,
                            testFile,
                            testTimeoutSeconds,
                            rerunTests,
                            open,
                            toggleOpen,
                            setIsAllRerunHidden,
                            isAllRerunHidden,
                          }: FileRowProps) => {
  const groupParentId = testFile.latestTests.find((t) => t.groupParentId)?.groupParentId;
  const link = groupParentId ? `${testFile.testRunId}/tests/${groupParentId}` : null;

  const isPassOrFail = testFile.status == TestStatus.FAIL || testFile.status == TestStatus.PASS;

  const allowRerun = isPassOrFail || testFile.status == TestStatus.NOT_RUN;

  const duration = testFile.duration;

  const name = testFile.name;

  return (
    <tr className={`test-result-row file-row ${testFile.status.toLocaleLowerCase()}`}>
      <td className="status">
        <Label style={{ background: StatusColors[testFile.status], color: "#FFF" }} onClick={toggleOpen}>
          <Icon name={open ? "minus square outline" : "plus square outline"} />
          {testFile.statusDisplay}
        </Label>
      </td>
      {link ? (
        <td className="test-name">
          <Link to={link}>{name}</Link>
          <a href={link} target="_blank">
            <Icon title="Open in new window" name="external alternate" />
          </a>
        </td>
      ) : (
        <td className="test-name">{name}</td>
      )}
      <td />
      <td />
      <td />
      <td className="duration">
        {!testRun.defaultToParallelizeByFile ? (
          <div />
        ) : testFile.status == TestStatus.FAIL &&
        testTimeoutSeconds * 1000 * testFile.latestTests.length <= duration ? (
          <span>Timeout</span>
        ) : (
          <DurationDisplay duration={isPassOrFail ? duration || 1 : duration} />
        )}
      </td>
      {AppStore.account.isLegacyOrTierTwoPlusPlan() &&
        <>
          <td className="rerun">
            {allowRerun && <Icon name="redo" link style={{ display: isAllRerunHidden ? "none" : "inline-block" }}
                                 onClick={() => {
                                   setIsAllRerunHidden(true);
                                   rerunTests(testFile.latestTests);
                                 }} />}
          </td>
          <td width={"15px"} />
        </>
      }
    </tr>
  );
});

interface TestRowProps {
  testRun: TestRun;
  testRunTest: TestRunTest;
  testRunTests: TestRunTest[];
  project: Project;
  testTimeoutSeconds: number;
  fileTimeout: boolean;
  rerunTests: (testRunTests: Array<TestRunTest>) => any;
  isAllRerunHidden: boolean;
  toggleRerunOption: (testRunTest: TestRunTest, rerun: boolean) => any;
  refresh: (testRunId: number) => any;
}

const TestRow = observer(
  ({
     project,
     testRun,
     testRunTest,
     testRunTests,
     fileTimeout,
     testTimeoutSeconds,
     rerunTests,
     isAllRerunHidden,
     toggleRerunOption,
     refresh,
   }: TestRowProps) => {
    const [isRerunHidden, setIsRerunHidden] = useState(false);

    const clickable = testRunTest.id && testRunTest.hasResults;

    const isPassOrFail = testRunTest.status == TestStatus.FAIL || testRunTest.status == TestStatus.PASS;

    const allowRerun = isPassOrFail || testRunTest.status == TestStatus.NOT_RUN;

    const duration =
      testRunTest.status == TestStatus.RUNNING ? calculateDuration(testRunTest.startTime) : testRunTest.duration;

    let name = testRunTest.projectTest.name;

    if (project.testingFramework == TestFramework.NUnit && testRunTest.projectTest.fileFilter) {
      name = name.substring(testRunTest.projectTest.fileFilter.length + 1);
    }

    const open = TestRunStore.isTestRunTestOpen(testRunTest.parentId);

    const jiraAuth = SettingsStore.authorizations.find(auth => auth.provider == AuthorizationProvider.Jira);

    return (
      <>
        <tr
          key={testRunTest.id}
          className={`test-result-row ${testRunTest.status.toLocaleLowerCase()} under-file ${
            testRunTest.selected ? "selected" : ""
          }`}
        >
          <td className={`status ${testRunTest.quarantined ? "quarantined" : ""}`}>
            <Label basic={true} style={{borderColor: StatusColors[testRunTest.status], color: StatusColors[testRunTest.status]}}>
              {testRunTests.length > 1 ? (
                <IconButton
                  icon={open ? "minus square outline" : "plus square outline"}
                  onClick={() => TestRunStore.toggleTestRunTestOpen(testRunTest.parentId)}
                />
              ) : null}
              {testRunTest.status == TestStatus.RUNNING ? (
                <Spinner noText={true} size={18} />
              ) : (
                testRunTest.statusDisplay
              )}
            </Label>
          </td>
          {clickable ? (
            <td className={`test-name ${testRunTest.quarantined ? "quarantined" : ""}`}>
              <Link to={testRunTest.link}>{name}</Link>
              <a href={testRunTest.link} target="_blank">
                <Icon title="Open in new window" name="external alternate" />
              </a>
            </td>
          ) : (
            <td className={`test-name ${testRunTest.quarantined ? "quarantined" : ""}`}>{name}</td>
          )}
          <td>
            <AssignReviewUserSelect
              reviewType={ReviewType.TEST_RUN_TEST}
              testRunId={testRun.id}
              testRunReviewerUserId={testRun.reviewerUserId}
              testRunTestId={testRunTest.id}
              testRunTestReviewerUserId={testRunTest.reviewerUserId}
            />
          </td>
          <td>
            <ReviewStatusSelect
              reviewType={ReviewType.TEST_RUN_TEST}
              testRunId={testRun.id}
              testRunReviewStatusId={null}
              testRunTestId={testRunTest.id}
              testRunTestReviewStatusId={testRunTest.reviewStatusId}
              refresh={refresh}
            />
          </td>
          <td style={{ display: "flex", flexDirection: "column" }}>
            {jiraAuth && testRunTest.testRunTestTrackedIssues.map(trackedIssue => <a
              href={`${jiraAuth.baseUrl}/browse/${trackedIssue.issueKey}`}
              target={"_blank"}>{trackedIssue.issueKey} <Icon name={"external alternate"} /></a>)}
          </td>
          <td className="duration">
            {testRunTest.quarantined && <Label>Quarantined</Label>}
            {testRunTest.status == TestStatus.FAIL &&
            (testTimeoutSeconds * 1000 <= duration || (!duration && fileTimeout)) ? (
              <span>Timeout</span>
            ) : testRunTest.groupParentId ? (
              <div />
            ) : (
              <span>
                {testRunTest.flaky ? (
                  <Label color="orange" horizontal>
                    Flaky
                  </Label>
                ) : null}
                <DurationDisplay duration={isPassOrFail ? duration || 1 : duration} />
              </span>
            )}
          </td>
          {AppStore.account.isLegacyOrTierTwoPlusPlan() &&
            <>
              <td className="rerun">{allowRerun &&
                <Icon name="redo" link style={{ display: isRerunHidden || isAllRerunHidden ? "none" : "inline-block" }}
                      onClick={() => {
                        setIsRerunHidden(true);
                        rerunTests([testRunTest]);
                      }} />
              }
              </td>
              <td>
                <Checkbox onChange={(_event, data) => toggleRerunOption(testRunTest, data.checked)} />
              </td>
            </>
          }
        </tr>
        {open && testRunTests.length > 1 && (
          <tr className="individual-results">
            <td colSpan={3}>
              <table>
                <thead>
                <tr>
                  <th>Status</th>
                  <th>Start Time</th>
                  <th>Duration</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {orderBy(testRunTests, "id", "desc").map((t) => (
                  <tr key={t.id}>
                    <td className="status">
                      <Label color={StatusColors[t.status]}>{t.statusDisplay}</Label>
                    </td>
                    <td className="time">
                      <DatetimeDisplay datetime={t.startTime} hideYear={true} showSecond={true} />
                    </td>
                    <td className="time">
                      <DurationDisplay duration={t.duration} />
                    </td>
                    <td className="link">
                      {t.hasResults && (
                        <>
                          <Link to={t.link}>View Result</Link>
                          <a href={t.link} target="_blank">
                            <Icon title="Open in new window" name="external alternate" />
                          </a>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
      </>
    );
  },
);
