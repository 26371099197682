import API from "./api";
import { SLACK_CLIENT_ID } from "./constants";

export function getSlackAuthUrl() {
  let urlRoot = "https://slack.com/oauth/v2/authorize";
  /**
     * The following values should be passed as GET parameters:
          client_id - issued when you created your app (required)
          scope - permissions to request (see below) (required)
          redirect_uri - URL to redirect back to (see below) (optional)
          state - unique string to be passed back upon completion (optional)
          team - Slack team ID of a workspace to attempt to restrict to (optional)
     */
  let scope = "users:read,chat:write,incoming-webhook,commands";
  // let state = ''; // optional
  // let team = ''; // optional
  return urlRoot +
    "?client_id=" +
    SLACK_CLIENT_ID +
    "&scope=" +
    scope +
    "&redirect_uri=" +
    getSlackRedirectUrl();
}

function getSlackRedirectUrl() {
  return window.location.origin + "/alerts";
}

export async function authorizeSlack(code: string): Promise<any> {
  const result = await API.post("/authorizations/slack", {
    code,
    redirectUri: getSlackRedirectUrl(),
  });
  return result.data;
}
