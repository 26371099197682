import { observer } from "mobx-react-lite";
import { TestRun } from "models/test-run";
import "./logs-tab.scss";
import { Card } from "semantic-ui-react";
import MessageDisplay from "../../components/message-display";
import { TestRunStore } from "../../stores";
import MessageCreate from "../../components/message-create";
import "./messages-tab.scss";

interface Props {
  testRun: TestRun;
}

const MessagesTab = observer(({ testRun }: Props) => {

  const messages = TestRunStore.getMessages(testRun.id);

  return (
    <Card className="messages-card" style={{ minWidth: "500px" }}>
      <Card.Content className="header">
        <Card.Header>Messages</Card.Header>
      </Card.Content>
      <Card.Content className="messages">
        {messages.map((m) => (
          <MessageDisplay key={m.id} message={m} />
        ))}
        {messages.length == 0 && (
          <div className="message-display">
            <div className="message">Add a message and collaborate with your team about this test run</div>
          </div>
        )}
      </Card.Content>
      <Card.Content className="footer">
        <MessageCreate testRunId={testRun.id} />
      </Card.Content>
    </Card>
  );
});

export default MessagesTab;
