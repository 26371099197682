import { buildRepoLink, buildRefUrl } from "core/utils";
import "./repo-link.scss";
import { Icon } from "semantic-ui-react";
import ProjectForm from "models/forms/project-form";
import Project from "models/project";

interface Props {
  project: Project | ProjectForm;
  gitRef?: string;
  hideProvierIcon?: boolean;
  displayText?: string;
  newWindow?: boolean;
}

const RepoLink = ({ project, gitRef, hideProvierIcon, displayText, newWindow }: Props) => (
  <div className="repo-link">
    {project && project.gitProvider && !hideProvierIcon && (
      <Icon name={project.gitProvider == "GitHub" ? "github" : "bitbucket"} />
    )}
    <a
      onClick={(e) => e.stopPropagation()}
      href={gitRef ? buildRefUrl(project, gitRef) : buildRepoLink(project)}
      target={newWindow ? "_blank" : null}
    >
      {displayText || (gitRef && gitRef.substring(0, 15)) || project.gitRepo}
    </a>
  </div>
);

export default RepoLink;
