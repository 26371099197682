import Form from "./form";
import { observable, action } from "mobx";
import { notEmpty } from "core/validation";
import { ExecutionType } from "models/test-plan";

export default class TestPlanStageForm extends Form {
  @observable id: number;
  @observable testPlanId: number;
  @observable name: string;
  @observable description: string;
  @observable executionType: ExecutionType;
  @observable alwaysRun: boolean;
  @observable order: number;

  validators = { name: notEmpty };

  @action
  setDefaults() {
    this.name = "";
    this.description = "";
    this.id = null;
    this.testPlanId = null;
    this.executionType = ExecutionType.Parallel;
    this.alwaysRun = false;
  }
}
