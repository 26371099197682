import { Icon, Popup, Tab } from "semantic-ui-react";
import SortableTable from "components/sortable-table";
import { SettingsStore } from "stores";
import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";



const RunnerConfigurationsTab = observer(() => {
  useInitialEffect(async () => {
    await SettingsStore.loadRunnerConfigurations();
  });

  function getKeyContentWithHelp(): JSX.Element {
    return (
      <span>Key <Popup
      hoverable={true}
      wide="very"
      content={
        <span>
          You can specify the test stack using your testery.yml file.<br />
          <pre><code >
          test_stack:<br />
          &nbsp;&nbsp;configuration_key: SPECIFY_KEY_HERE
          </code></pre>
        </span>
      }
      trigger={<Icon name="question circle" />}
    />
    </span>
    );
  }

  return (
    <Tab.Pane className="runner-configurations">
      <SortableTable
          title="Current Test Stacks"
          loading={!SettingsStore.runnerConfigurationsLoaded}
          loadingText="Loading Test Stacks"
          className="runnerConfigurations"
          headerColumns={[
            {
              content: "Runner Type",
              sortKey: "runnerType"
            },
            {
              content: "Chrome Version",
              sortKey: "chrome"
            },
            {
              content: "Node Version",
              sortKey: "nodejs"
            },
            {
              content: "Python Version",
              sortKey: "python"
            },
            {
              content: getKeyContentWithHelp(),
              sortKey: "key"
            },
            {
              content: "Default",
              sortKey: "isDefault"
            },
          ]}
          items={SettingsStore.activeTestStacks()}
          renderRow={(runnerConfiguration) => (
            <tr key={runnerConfiguration.key}>
              <td>{runnerConfiguration.runnerType}</td>
              <td>{runnerConfiguration.chrome}</td>
              <td>{runnerConfiguration.nodejs}</td>
              <td>{runnerConfiguration.python}</td>
              <td>{runnerConfiguration.key}</td>
              <td>{runnerConfiguration.isDefault ? <Icon name="check" /> : ""}</td>
            </tr>
          )}
          defaultSortKey="runnerType"
        />
    </Tab.Pane>
  );
});

export default RunnerConfigurationsTab;
