import { useInitialEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import BuildForm from "models/forms/build-form";
import Page from "pages/page";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button, Form, Grid, Icon } from "semantic-ui-react";
import { BuildStore, ProjectStore } from "stores";
import "./build-edit.scss";

const form = new BuildForm();



async function saveBuild(projectId: number, push: (path: string) => any) {
  await BuildStore.save(projectId, form);
  push("../builds");
}

const BuildEditPage = observer(() => {
  const { buildId, projectKey } = useParams<{ buildId: string; projectKey: string }>();
  const [buildLoaded, setbuildLoaded] = useState(false);
  const history = useHistory();

  const isNew = !buildId;
  const title = (isNew ? "New" : "Edit") + " Build";
  const project = ProjectStore.findByKey(projectKey);

  useInitialEffect(async () => {
    await ProjectStore.ensureLoaded();
    setbuildLoaded(true);
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      form.file = e.target.files[0];
    }
  };

  return (
    <Page title={title} name="build-edit">
      {project && (
        <Form>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    label="Name"
                    value={form.name}
                    onChange={form.onChange("name")}
                    error={form.getErrorText("name")}
                  />
                </Grid.Column>
                <Grid.Column>
                <Form.Input label="Build Artifact" id="file" type="file" onChange={handleFileChange} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <section className="form-actions">
            <Button type="button" onClick={() => history.goBack()}>
              <Icon name="times" />
              Cancel
            </Button>
            <Button
              onClick={() => saveBuild(project.id, history.push)}
              loading={!buildLoaded || form.isSubmitting}
              color="green"
              type="submit"
            >
              <Icon name="check" />
              Save
            </Button>
          </section>
        </Form>
      )}
    </Page>
  );
});

export default BuildEditPage;
