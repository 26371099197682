import { observer } from "mobx-react-lite";
import { useInitialEffect } from "core/react-utils";
import { ReleaseStore } from "stores";
import { Accordion, Button, Form, Table } from "semantic-ui-react";
import ReleaseForm from "../../models/forms/release-form";
import { NotificationManager } from "react-notifications";
import { useState } from "react";
import { ReleaseDecision } from "../../models/release";
import ReleaseCriteriaRow from "../../components/release/release-criteria-row";
import ReleaseInfo from "../../components/release/release-info";
import { format, parseISO } from "date-fns";
import IconButton from "../../components/icon-button";

const releaseForm = new ReleaseForm();

interface Props {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

const ReleasesTab = observer(({ activeIndex, setActiveIndex }: Props) => {
  const [releaseCriteria, setReleaseCriteria] = useState<Array<typeof ReleaseCriteriaRow>>([]);
  const [activePage, setActivePage] = useState<number>(1);

  useInitialEffect(async () => {
    await ReleaseStore.loadReleases(activePage);
  });

  const handleAccordionClick = async (_e, titleProps, release) => {
    releaseForm.clearFormData();
    releaseForm.clearErrors();
    releaseForm.populate(release);

    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    let checkboxIndex = 0;
    if (newIndex != -1) {
      await ReleaseStore.loadReleaseCriteria(release.id);
      const releaseCriteriaList = [];
      ReleaseStore.releaseCriteria.forEach(releaseCriteria => {
        releaseCriteriaList.push(<ReleaseCriteriaRow key={Math.random()} index={checkboxIndex} release={release}
                                                     releaseCriteria={releaseCriteria} />);
        checkboxIndex++;
      });
      setReleaseCriteria(releaseCriteriaList);
    } else {
      setReleaseCriteria([]);
    }

    setActiveIndex(newIndex);
  };

  const handleReleaseUpdate = async (form: ReleaseForm) => {
    await ReleaseStore.updateRelease(form);
    setActiveIndex(-1);
    NotificationManager.success("Successfully updated release!", null, 2000);
    await ReleaseStore.loadReleases(activePage);
  };

  function handlePageChange(direction: number) {
    const newPage = direction == 0 ? 1 : (activePage + direction);
    setActivePage(newPage);
    ReleaseStore.loadReleases(newPage);
  }

  const accordionEntries = [];
  let index = 0;
  ReleaseStore.releases.forEach(release => {
    accordionEntries.push(
      <Form key={Math.random()}>
        <Accordion.Title key={`accordion-title-${index}`} active={activeIndex === index} index={index}
                         onClick={async (e, titleProps) => await handleAccordionClick(e, titleProps, release)}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ minWidth: "300px" }}>{release.title}</div>
            <div id={`release-proposed-release-date-display-${index}`} style={{ width: "250px" }}>Proposed Release
              Date: {release.proposedReleaseDate ?
                format(parseISO(release.proposedReleaseDate + "T00:00:00"), "MM/dd/yyyy") : "N/A"}</div>
            <div style={{ width: "250px" }}>Actual Release Date: {release.actualReleaseDate ?
              format(parseISO(release.actualReleaseDate + "T00:00:00"), "MM/dd/yyyy") : "N/A"}</div>
            <div style={{ width: "200px" }}>Release
              Decision: {release.decision ? ReleaseDecision[release.decision] : "N/A"}</div>
          </div>
        </Accordion.Title>

        <Accordion.Content key={`accordion-content-${index}`} active={activeIndex === index}>
          <ReleaseInfo form={releaseForm} releaseCriteria={releaseCriteria} submitForm={handleReleaseUpdate} />
        </Accordion.Content>
      </Form>,
    );
    index++;
  });

  return (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <Accordion fluid styled>
              {accordionEntries}
            </Accordion>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell>
            <div style={{ display: "flex", alignItems: "center" }}>
              {activePage > 1 &&
                <IconButton marginRight={0} icon={"chevron left"} onClick={() => handlePageChange(-1)} />
              }
              <div style={{ display: "flex", paddingLeft: "5px", paddingRight: "5px" }}>Page {activePage}</div>
              {ReleaseStore.releases && ReleaseStore.releases.length > 0 && (
                <IconButton marginRight={0} icon={"chevron right"} onClick={() => handlePageChange(1)} />
              )}
              <Button style={{ background: "transparent" }} disabled={activePage == 1}
                      onClick={() => handlePageChange(0)}>Reset to page 1</Button>
            </div>
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});

export default ReleasesTab;
