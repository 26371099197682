import Form from "./form";
import { action, observable } from "mobx";
import { notEmpty } from "core/validation";

export default class TeamInfoForm extends Form {
  @observable id: number;
  @observable name: string;
  @observable description: string;
  @observable email: string;
  @observable slackChannel: string;

  validators = {
    name: { validator: notEmpty, message: "Name is required" },
  };

  @action.bound
  setDefaults() {
    this.name = "";
    this.description = "";
    this.email = "";
    this.slackChannel = "";
  }
}
