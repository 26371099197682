import { useState } from "react";
import { RouteComponentProps } from "react-router";
import { useInitialEffect } from "core/react-utils";
import { AuthReason } from "models/settings";
import * as Storage from "core/storage";
import { getUrlParam } from "core/utils";
import { max } from "lodash";
import { AppStore, SettingsStore } from "stores";
import Spinner from "components/spinner";
import Page from "pages/page";
import { authorizeSlack } from "core/slack";
import { authorizeJira } from "../../core/jira";

interface Props extends RouteComponentProps<any> {}

async function onLoad(props: Props, setAuthReturnDone: (v: boolean) => any) {
  try {
    let { url, reason } = Storage.popAuthInfo();

    if (reason == AuthReason.SlackIntegration || reason == AuthReason.JiraIntegration) {
      const code = getUrlParam("code")
      switch(reason) {
        case AuthReason.SlackIntegration :
          await authorizeSlack(code);
          break;
        case AuthReason.JiraIntegration :
          await authorizeJira(code);
          break;
      }
      SettingsStore.loadAuthorizations();
      props.history.replace(url);
    } else {
      const authId = await AppStore.authReturn();

      if (authId) {
        if (reason == AuthReason.Login) {
          await AppStore.authLogin(authId);
          const defaultUrl = AppStore.accountSetup
            ? `/${AppStore.selectedAccountName}/test-runs`
            : "/";
          url = url || defaultUrl;
        } else if (reason == AuthReason.Integration) {
          await AppStore.authIntegration(authId);
        } else {
          await AppStore.register(authId);
          const accountId = max(AppStore.user.roles.map((r) => r.accountId));
          await AppStore.selectAccount(accountId);
          url = "/";
        }

        if (AppStore.isLoggedIn) {
          props.history.replace(url);
        } else {
          setAuthReturnDone(true);
        }
      } else {
        setAuthReturnDone(true);
      }
    }
  } catch (e) {
    setAuthReturnDone(true);
  }
}

const AuthReturnPage = (props: Props) => {
  const [authReturnDone, setAuthReturnDone] = useState(false);

  useInitialEffect(() => {
    onLoad(props, setAuthReturnDone);
  });

  return (
    <Page name="auth-return" title="Authorization" noHeader={true} titleSize="none">
      {authReturnDone ? (
        <h3>Unable to finish at this time. Please try again. If problem persists, please <a href={"https://testery.atlassian.net/servicedesk/customer/portal/1"} target={"_blank"} style={{color: "#4183c4"}}>report the issue here</a>.</h3>
      ) : (
        <Spinner text="Finishing" size={24} />
      )}
    </Page>
  );
};

export default AuthReturnPage;
