import API from "core/api";
import { action, observable, runInAction } from "mobx";
import { Release, ReleaseCriteria, ReleaseCriteriaTemplate } from "../models/release";
import ReleaseForm, { ReleaseCriteriaTemplateForm } from "../models/forms/release-form";

export class ReleaseStoreClass {
  @observable releases: Array<Release> = [];
  @observable releaseCriteria: Array<ReleaseCriteria> = [];
  @observable releaseCriteriaTemplates: Array<ReleaseCriteriaTemplate> = [];

  @action
  async clear() {
    this.releases = [];
    this.releaseCriteria = [];
    this.releaseCriteriaTemplates = [];
  }

  @action.bound
  async loadReleases(page: number) {
    const result = await API.getList(`/release?offset=${(page - 1) * 10}&limit=${10}`, Release);

    return runInAction(() => {
      this.releases = result.data;
      return this.releases;
    });
  }

  @action.bound
  async loadReleaseCriteria(releaseId: number) {
    const result = await API.getList(`/release/${releaseId}/release-criteria`, ReleaseCriteria);

    return runInAction(() => {
      this.releaseCriteria = result.data;
      return this.releaseCriteria;
    });
  }

  @action.bound
  async loadReleaseCriteriaTemplates() {
    const result = await API.getList(`/release-criteria-template`, ReleaseCriteriaTemplate);

    return runInAction(() => {
      this.releaseCriteriaTemplates = result.data;
      return this.releaseCriteriaTemplates;
    });
  }

  @action.bound
  async createRelease(release: Release) {
    await API.post("/release", release);
  }

  @action.bound
  async toggleReleaseCriteria(releaseCriteria: ReleaseCriteria) {
    const response = await API.put(`/release/${releaseCriteria.releaseId}/release-criteria/${releaseCriteria.id}/toggle`, null, ReleaseCriteria);
    return response.data;
  }

  @action
  async updateRelease(form: ReleaseForm) {
    await API.put(`/release/${form.id}`, form, Release);
  }

  @action
  async createReleaseCriteriaTemplate(releaseCriteriaTemplate: any) {
    await API.post(`/release/release-criteria-template`, releaseCriteriaTemplate);
  }

  @action
  async updateReleaseCriteriaTemplate(form: ReleaseCriteriaTemplateForm) {
    await API.put(`/release/release-criteria-template`, form);
  }

  @action
  async archiveReleaseCriteriaTemplate(releaseCriteriaTemplateId: number) {
    await API.delete(`/release/release-criteria-template/${releaseCriteriaTemplateId}`);
  }
}
