import { TestRunTestComparisonChartData } from "../models/analytics";

interface Props {
  data: TestRunTestComparisonChartData;
}

const SlowerTestsComparison = ({ data }: Props) => {
  const runOneTests = data.runOneTests.results;
  const runTwoTests = data.runTwoTests.results;

  if (!runOneTests || !runTwoTests)
    return (<h3>Please select two test runs for comparison</h3>);

  if (runOneTests.length === 0 || runTwoTests.length === 0)
    return (<h3>No tests to compare</h3>);

  if (runOneTests[0].projectId != runTwoTests[0].projectId)
    return (<h3>Test runs are from different projects</h3>);

  const earlierTestRunTests = runOneTests[0].testRunId < runTwoTests[0].testRunId ? runOneTests : runTwoTests;
  const laterTestRunTests = runOneTests[0].testRunId > runTwoTests[0].testRunId ? runOneTests : runTwoTests;

  const slowerTests = new Map<number, any>();
  laterTestRunTests.forEach(laterTestRunTest => {
    const matchedTests = earlierTestRunTests.filter(earlierTestRunTest => earlierTestRunTest.projectTestId === laterTestRunTest.projectTestId);
    let latestCompData = null;
    matchedTests.forEach(matchedTest => {
      if (!latestCompData || matchedTest.id > latestCompData.id)
        latestCompData = matchedTest;
    });

    if (latestCompData && laterTestRunTest.duration > latestCompData.duration)
      slowerTests.set(
        laterTestRunTest.duration - latestCompData.duration,
        (
          <tr key={Math.random()}>
            <td>{laterTestRunTest.name}</td>
            <td>{laterTestRunTest.duration - latestCompData.duration}ms</td>
          </tr>
        ),
      );
  });

  const sortedSlowerTests = [...slowerTests.entries()].sort((a, b) => b[0] - a[0]);

  const displayData = new Array<any>();
  sortedSlowerTests.forEach(test => {
    if (displayData.length < 10)
      displayData.push(test[1]);
  });

  return (
    <div style={{ width: "100%" }}>
      <table>
        <thead>
        <tr>
          <th>Test Name</th>
          <th>Ran Slower By</th>
        </tr>
        </thead>
        <tbody>
        {displayData}
        </tbody>
      </table>
    </div>
  );
};

export default SlowerTestsComparison;
