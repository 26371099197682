import { notEmpty } from "core/validation";
import { action, observable } from "mobx";
import Form from "./form";

export default class WorkflowForm extends Form {
  @observable id: number;
  @observable name: string;

  validators = { name: notEmpty };

  @action
  setDefaults() {
    this.name = "";
  }
}
