import API from "./api";
import { AppStore, TrackedIssueStore } from "../stores";
import { JIRA_CLIENT_ID } from "./constants";

export function getJiraAuthUrl() {
  const urlRoot = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&";
  const scope = "offline_access write:jira-work read:jira-work read:jira-user manage:jira-configuration";
  const state = hash(AppStore.user.id.toString());

  return encodeURI(urlRoot +
    "client_id=" +
    JIRA_CLIENT_ID +
    "&scope=" +
    scope +
    "&state=" +
    state +
    "&redirect_uri=" +
    getJiraRedirectUrl() +
    "&response_type=code&prompt=consent");
}

function getJiraRedirectUrl() {
  return window.location.origin + "/alerts";
}

export async function authorizeJira(code: string): Promise<any> {
  const result = await API.post("/authorizations/jira", {
    code,
    redirectUri: getJiraRedirectUrl(),
  });
  await TrackedIssueStore.loadJiraProjects("");
  return result.data;
}

function hash(str: string, seed: number = 0) {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}
