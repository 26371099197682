import { observer } from "mobx-react-lite";
import { useInitialEffect } from "core/react-utils";
import { AnalyticsStore } from "stores";
import { Card, Icon, Popup, Table } from "semantic-ui-react";
import { avgDisplay, numberWithCommas, percentDisplay } from "core/utils";
import { displayBarChart } from "core/charts";
import { FlakyChartData } from "models/analytics";
import ChartTab from "./chart-tab";

const emptyData = new FlakyChartData();

const FlakyTestsTab = observer(() => {
  const form = AnalyticsStore.flakyChartData.options;
  const data = AnalyticsStore.flakyChartData.data || emptyData;

  useInitialEffect(() => {
    AnalyticsStore.loadFlakyChartData();
  });

  const tableBody = [];
  data.projectTestsData.forEach((map) => {
    const projectTest = map["second"];
    tableBody.push(
      <Table.Row>
        <Table.Cell>{projectTest.name}</Table.Cell>
        <Table.Cell>{projectTest.file}</Table.Cell>
        <Table.Cell>{map["first"]}</Table.Cell>
        <Table.Cell>{projectTest.quarantined ? "Yes" : "No"}</Table.Cell>
      </Table.Row>,
    );
  });

  return (
    <>
      <ChartTab
        chartData={AnalyticsStore.flakyChartData}
        drawChart={(id: string, labels: string[]) =>
          displayBarChart(id, labels, {
            label: "Flaky Tests",
            backgroundColor: "#f0ad4e",
            data: data.counts(form.frequency, form.dates),
          })
        }
        header={() => (
          <>
            <Card.Group centered>
              <Card>
                <Card.Content>
                  <div className="name">
                    Total Flaky Tests{" "}
                    <Popup
                      position="top center"
                      content="Number of non-unique flaky tests across test runs. A single flaky test will count each time it is run."
                      trigger={<Icon name="question circle outline" />}
                    />
                  </div>
                  <div className="value">{numberWithCommas(data.flakyTests.totalTests)}</div>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <div className="name">
                    Total Tests{" "}
                    <Popup
                      position="top center"
                      content="Number of non-unique tests across test runs. A single test is counted once for each test run."
                      trigger={<Icon name="question circle outline" />}
                    />
                  </div>
                  <div className="value">{numberWithCommas(data.allTests.totalTests)}</div>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <div className="name">
                    Test Flakiness{" "}
                    <Popup
                      position="top center"
                      content="Number of (flaky tests / total tests).  Represents % of tests that were run that were flaky."
                      trigger={<Icon name="question circle outline" />}
                    />
                  </div>
                  <div className="value">
                    {percentDisplay(data.flakyTests.totalTests, data.allTests.totalTests)}
                  </div>
                </Card.Content>
              </Card>
            </Card.Group>
            <Card.Group centered>
              <Card>
                <Card.Content>
                  <div className="name">
                    Unique Flaky Tests{" "}
                    <Popup
                      position="top center"
                      content="Number of unique flaky tests across test runs. A test is only counted once even if it was flaky multiple times across test runs."
                      trigger={<Icon name="question circle outline" />}
                    />
                  </div>
                  <div className="value">{numberWithCommas(data.flakyTests.uniqueTests)}</div>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <div className="name">
                    Unique Tests{" "}
                    <Popup
                      position="top center"
                      content="Number of unique tests that were run in selected timeframe."
                      trigger={<Icon name="question circle outline" />}
                    />
                  </div>
                  <div className="value">{numberWithCommas(data.allTests.uniqueTests)}</div>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <div className="name">
                    Avg Flaky Tests Per Test Run{" "}
                    <Popup
                      position="top center"
                      content="Average number of non-unique flaky tests per test run. Calculated as (total flaky tests / total test runs)."
                      trigger={<Icon name="question circle outline" />}
                    />
                  </div>
                  <div className="value">
                    {avgDisplay(data.flakyTests.totalTests, data.allTests.totalTestRuns)}
                  </div>
                </Card.Content>
              </Card>
            </Card.Group>
          </>
        )}
      />
      {tableBody.length > 0 && (
        <Table>
          <Table.Header>
            <Table.Cell>Test Name</Table.Cell>
            <Table.Cell>Test File</Table.Cell>
            <Table.Cell>Flaky Occurrences</Table.Cell>
            <Table.Cell>Quarantined</Table.Cell>
          </Table.Header>
          <Table.Body>
            {tableBody}
          </Table.Body>
        </Table>
      )}
    </>
  );
});

export default FlakyTestsTab;
