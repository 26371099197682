import { observer } from "mobx-react-lite";
import { ModalStore, AppStore } from "stores";
import { Modal, Button, Icon, Form } from "semantic-ui-react";
import UpdatePasswordForm from "models/forms/update-password-form";

const form = new UpdatePasswordForm();

const UpdatePassword = observer(() => {
  const savePassword = async () => {
    await AppStore.updatePassword(form);
    ModalStore.updatePassword.hide();
  };

  ModalStore.updatePassword.onHidden = form.clearFormData;

  return (
    <Modal open={ModalStore.updatePassword.showing} size="mini">
      <Modal.Header>Update Password</Modal.Header>
      <Modal.Content>
        <Form onSubmit={savePassword}>
          <Form.Input
            label="Password"
            value={form.password}
            error={form.getErrorText("password")}
            onChange={form.onChange("password")}
            type="password"
            autoFocus
          />
          <Form.Input
            label="Repeat Password"
            value={form.passwordRepeat}
            error={form.getErrorText("passwordRepeat")}
            onChange={form.onChange("passwordRepeat")}
            type="password"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {!ModalStore.updatePassword.state?.hideCancel && (
          <Button size="small" onClick={() => ModalStore.updatePassword.hide()}>
            <Icon name="times" />
            Cancel
          </Button>
        )}
        <Button size="small" onClick={savePassword} color="green" loading={form.isSubmitting}>
          <Icon name="check" />
          Update
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default UpdatePassword;
