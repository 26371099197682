import { setPageInfo, useInitialEffect } from "core/react-utils";
import { useHistory } from "react-router";
import { AppStore } from "stores";

const HomePage = () => {
  setPageInfo("home", "Home");

  const history = useHistory();

  useInitialEffect(() => history.replace(`/${AppStore.selectedAccountName}/dashboard`));

  return (
    <div className="page">
      <header>
        <h1>Welcome to the Testery platform</h1>
      </header>
      <div className="page-content"></div>
    </div>
  );
};

export default HomePage;
